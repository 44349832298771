import { createContext } from "react";
import { SupportChainType } from "../event/navigation";

export interface IChain {
    Selected: SupportChainType;
    Connected: SupportChainType;
}
export const defaultChainContext: IChain = {
    Selected: "SOL",
    Connected: "NON",
}
export const ChainContext = createContext(defaultChainContext);