import React, {FC} from "react";
import "../assets/scss/articleItem.scss";

interface IArticle {
    title: string;
    article: string;
}

export const ArticleItem: FC<IArticle> = (article: IArticle) => {
    return (
        <section className="article-item">
            <span>{article.title}</span>
            <p>{article.article}</p>
        </section>
    );
}
