import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/poolCard.css";
import "../assets/css/stakeGenerated.css";
import { StakingNavigateContext } from "../contexts/stakingNavigateContext";
import { Define } from "../define/define";
import { apiClient, Global } from "../global/global";
import { IStakingPoolSummary } from "../prot/protocols/staking/PtlGetPoolList";
import { IStakingAccountSummary } from "../prot/protocols/staking/PtlGetStakeAccounts";
import { StakingPoolState } from "../prot/protocols/staking/Staking";
import ToolTips from "./toolTips";

function PoolCard(props: { zoom: number, info?: IStakingPoolSummary, account: IStakingAccountSummary }) {
    const ctx = useContext(StakingNavigateContext);
    const [TotalGenerateIcon, setTotalGenerateIcon] = useState([]);
  const [CoinIcon, setCoinIcon] = useState([])
    const navigate = useNavigate();

    const onClick = (e: any) => {
        if (e.target.className == "generate-icon" || e.target.className =="total-generate-multi")
            return;
        console.log(e.target.className);

        ctx.area = props.info.area;
        ctx.poolId = props.info.poolId;
        navigate(Define.Page.MyStake);
    }

    const hasReceive = () => {
        if (!props.account)
            return false;
        if (props.account.staked.length > 0)
            return true;
        for (const iterator of props.account.generator) {
            if (iterator.rewards > 0)
                return true;
        }
        return false;
    }

    useEffect(() => {
        if (props.info)
            fetchIcon();
    }, [props.info])

    const fetchIcon = async () => {
        const call = await apiClient.callApi("staking/GetStakeCoin", { addresses: props.info.generator.map(v => v.address) })
        // console.log(call.res.list, props.info.generator);

        if (call.isSucc) {
            setCoinIcon(call.res.list)
            setTotalGenerateIcon(call.res.list.map((v, i) => (
                <span className="generate-icon" key={i} style={{ backgroundImage: `url(${v.imgUrl})` }} >
                    {/* <span>Total generated：{props.info.generator.find(a => a.address == v.address).totalGenerated.toFixed(2)}</span> */}
                </span>
            )));
        }
    }

    const getTotalGenerated = () => {
        if (props.info.generator.length == 0)
            return <h4>0</h4>;
        // else if (props.info.generator.length == 1)
        //     return <h4>{props.info.generator[0].totalGenerated}</h4>;
        // else
            return <div className="total-generate-multi" 
                onMouseEnter={(e) => {
                    Global.AddOverlay("totalGenerated", <ToolTips key="tt" left={e.clientX} top={e.clientY} lines={
                        props.info.generator.map((v, i) => {
                            if (CoinIcon.length == 0)
                                return v.totalGenerated.toString();
                            const coin = CoinIcon.find(a => a.address == v.address);
                            return `${coin.symbol}:${v.totalGenerated}`;
                        })
                    } />)
                }}
                onMouseLeave={(e) => {
                    Global.RemoveOverlay("totalGenerated")
                }}
            
            >{TotalGenerateIcon}</div>;
    }

    return (<div onClick={onClick} className={`pool-card ${hasReceive() ? "pool-card-on" : ""}`} style={{ zoom: props.zoom ? props.zoom : 1 }}>
        <div className={`rect ${hasReceive() ? "rect-on" : ""}`}>
            <div className="icon" style={{ backgroundImage: `url(${props.info.imgUrl})` }}>
            </div>
            <div className="info">
                <h1>{props.info.name}</h1>
                <div className="card-detail">
                    <div>
                        <h2>Pool ID</h2>
                        <h3>{`#${props.info.poolId}`}</h3>
                    </div>
                    <div>
                        <h2>Pool State</h2>
                        <h3>{StakingPoolState[props.info.state].toUpperCase()}</h3>
                    </div>
                    <div>
                        <h2>StartDate</h2>
                        <h3>{props.info.openUTC.toLocaleDateString().replaceAll("/", ".")}</h3>
                    </div>
                    <div>
                        <h2>EndDate</h2>
                        <h3>{props.info.closeUTC.toLocaleDateString().replaceAll("/", ".")}</h3>
                    </div>
                </div>
                <p className="total-title">Total Generated Coin</p>
                {getTotalGenerated()}
            </div>
        </div>
    </div>);
}

export default PoolCard;