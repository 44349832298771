import { MouseEvent } from "react";
import "../assets/css/featurePools.css"
import { GameIconIntro } from "../prot/protocols/market/PtlGetRecentListed";
import ComingSoon from "../assets/images/home/coming-soon.jpg";

function FeaturePoolsItem(props: {
    info: GameIconIntro, shadow?: boolean,
    onClick?: (info: GameIconIntro, rect: DOMRect) => void
}) {
    const { iconUrl } = props.info;

    return (<div className={ `feature-pools-item` } onClick={(e: MouseEvent) => {
        // const target = e.target as HTMLImageElement;
        // props.onClick(props.info, target.getBoundingClientRect());
    }} style={props.shadow ? { boxShadow: "8px 8px 10px black" } : {}}>
        <img src={iconUrl} alt="" />
    </div>);
}

export default FeaturePoolsItem;