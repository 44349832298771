import React, { createContext, FC, useCallback, useContext, useState } from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "../assets/scss/header.scss";
import cs from "classnames";
import { Wallets } from "./wallets";
import { Define, OpenVersion, ShowVersion } from "../define/define";
import { apiClient, Global } from "../global/global";
import Logo from "../assets/images/logo.png";
import Category from "../assets/images/layout/category.png";
import LastText from "../assets/images/home/last/last-text.jpg";
import CloseRound from "../assets/images/home/last/close-round.png";
import { Home } from "../pages/home";
import { Community } from "../pages/community";
import { Map } from "../pages/map";
import { Marketplace } from "../pages/marketplace";
import { Blog } from "../pages/blog";
import { LitePaper } from "../pages/litePaper";
import { Profile } from "../pages/profile";
import EventCenter from "../event/eventCenter";
import Swap from "../pages/swap";
import Market from "../pages/market";
import Games from "../pages/games";
import GameStore from "../pages/gameStore";
import ProfileV2 from "../pages/profileV2";
import CommodityPage from "../pages/commodity";
import GameDetails from "../pages/gameDetails";
import { SupportChainType } from "../event/navigation";
import { ChainContext } from "../contexts/chainContext";
import Staking from "../pages/staking";
import StakingPool from "../pages/stakingPool";
import MyStake from "../pages/myStake";
import {StakingNavigateContext} from "../contexts/stakingNavigateContext";

const pdf = require("../assets/litepaper.pdf");

export const WalletsContext = createContext(false);

export const Header: FC = () => {
    const navigate = useNavigate();
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [isOpenDrawerOverly, setIsOpenDrawerOverly] = useState(false);
    const [currentNavigation, setCurrentNavigation] = useState(Define.Page.Home);
    const [isShowWallet, setIsShowWallet] = useState(false);
    const [isShowCityBackground, setIsShowCityBackground] = useState(true);
    const [isConnectWallet, setIsConnectWallet] = useState(false);
    const [isCheckMobile, setIsCheckMobile] = useState(false);
    const [page, setPage] = useState(Define.Page.Home);
    const Chain = useContext(ChainContext);
    const ctx = useContext(StakingNavigateContext);

    const changeNavigation = (navigation: string) => {

        setPage(navigation);
        switch (navigation) {
            case Define.Page.Home:
                navigate(navigation);
                let element = document.getElementsByName(navigation);
                if (element != null) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
                setIsShowCityBackground(false);
                break;
            case Define.Page.Community:
                navigate(Define.Page.Home);
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    let element = document.getElementById("community");
                    if (element != null) {
                        window.scrollTo(0, element.offsetTop);
                    }
                }, 500);
                setIsShowCityBackground(false);
                break;
            case Define.Page.Marketplace:
            case Define.Page.Games:
            case Define.Page.Staking:
            case Define.Page.Market:
                // Global.AddMessage("Coming soon.", undefined, " ");

                if (ShowVersion <= OpenVersion.Center) {
                    Global.AddMessage("Coming soon.", undefined, " ");
                } else {
                    navigate(navigation);
                    setIsShowCityBackground(true);
                }
                break;
            case Define.Page.Swap:
                navigate(navigation);
                setIsShowCityBackground(true);
                break;
            case Define.Page.Blog:
                Global.Open("https://meta2150s.medium.com/");
                break;
            case Define.Page.LitePaper:
                Global.Open(pdf);
                break;
            case Define.Page.Profile:
                setIsShowCityBackground(true);
                break;
            case Define.Page.MyStake:
                ctx.area = "oceania"
                navigate(navigation);
                break;
            default:
                navigate(navigation);
                setIsShowCityBackground(false);
                break;
        }
    }

    const changeNavigationDisplay = (navigation: string) => {

        setPage(navigation);
        switch (navigation) {
            case Define.Page.Home:
                let element = document.getElementsByName(navigation);
                if (element != null) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
                setIsShowCityBackground(false);
                break;
            case Define.Page.Community:
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    let element = document.getElementById("community");
                    if (element != null) {
                        window.scrollTo(0, element.offsetTop);
                    }
                }, 500);
                setIsShowCityBackground(false);
                break;
            case Define.Page.Marketplace:
                if (!Global.OpenSell) {
                    Global.AddMessage("Coming soon.", undefined, " ");
                } else {
                    setIsShowCityBackground(true);
                }
                break;
            case Define.Page.Swap:
                navigate(navigation);
                setIsShowCityBackground(true);
                break;
            case Define.Page.Blog:
                Global.Open("https://meta2150s.medium.com/");
                break;
            case Define.Page.LitePaper:
                Global.Open(pdf);
                break;
            case Define.Page.Profile:
                setIsShowCityBackground(true);
                break;
            default:
                setIsShowCityBackground(false);
                break;
        }
    }

    const onConnectWallet = (isConnect: boolean) => {
        setIsConnectWallet(isConnect);
    }

    const onNavigation = (navigation: string) => {

        if (!Global.OpenSell) {
            if (navigation != Define.Page.Marketplace && navigation != Define.Page.Blog && navigation != Define.Page.LitePaper) {
                setCurrentNavigation(navigation);
            }
        } else {
            if (navigation != Define.Page.Blog && navigation != Define.Page.LitePaper) {
                setCurrentNavigation(navigation);
            }
        }
        if (navigation == Define.Page.Home) {
            apiClient.callApi("analyze/SendEvent", { event: "Home" });
        }
        if (navigation == Define.Page.Staking) {
            apiClient.callApi("analyze/SendEvent", { event: "Stake" });
        }
        if (navigation == Define.Page.Games) {
            apiClient.callApi("analyze/SendEvent", { event: "GameStore" });
        }
        if (navigation == Define.Page.Market) {
            apiClient.callApi("analyze/SendEvent", { event: "MarketPlace" });
        }
        console.log(navigation);

        changeNavigationDisplay(navigation);
    }

    const onNavigationClick = (navigation: string, isMobile: boolean) => {
        if (/^http:\/\/|^https:\/\//.test(navigation)) {
            window.location.href = navigation;
            return;
        }
        if (!Global.OpenSell) {
            if (navigation != Define.Page.Marketplace && navigation != Define.Page.Blog && navigation != Define.Page.LitePaper) {
                setCurrentNavigation(navigation);
            }
        } else {
            if (navigation != Define.Page.Blog && navigation != Define.Page.LitePaper) {
                setCurrentNavigation(navigation);
            }
        }

        setIsCheckMobile(isMobile);
        if (isMobile) {
            onChangeDrawer(true);
        }
        console.log(navigation);

        changeNavigation(navigation);
    }

    const onConnectWalletClick = (isMobile: boolean) => {
        setIsCheckMobile(isMobile);

        if (currentNavigation == Define.Page.Home) {
            apiClient.callApi("analyze/SendEvent", { event: "HomeConnectWallet" });
        }
        if (currentNavigation == Define.Page.Staking) {
            apiClient.callApi("analyze/SendEvent", { event: "StakeConnectWallet" });
        }
        if (currentNavigation == Define.Page.Market) {
            apiClient.callApi("analyze/SendEvent", { event: "GameStoreConnectWallet" });
        }
        if (currentNavigation == Define.Page.Games) {
            apiClient.callApi("analyze/SendEvent", { event: "MarketPlaceConnectWallet" });
        }
        if (isConnectWallet) {
            if (page === Define.Page.Profile) {
                EventCenter.emit(Define.Event.ShowProfile, true);
            } else {

                if (Chain.Connected == "SOL")
                    navigate(Define.Page.Profile);
                else if (Chain.Connected == "BNB")
                    navigate(Define.Page.ProfileV2);
            }
            if (isMobile) {
                onChangeDrawer(true);
            }
        } else {
            setIsShowWallet(true);

            if (isMobile) {
                onChangeDrawer(false);
            } else {
                changeScroll(false);
            }
        }
    }

    const onChangeWalletState = useCallback((isConnect: boolean) => {
        Chain.Connected = Chain.Selected;
        if (Chain.Connected == "SOL")
            Global.EthProvider = null;
        else if (Chain.Connected == "BNB")
            Global.WalletAdapter = null;
        setIsConnectWallet(isConnect);
        setIsShowWallet(false);
        EventCenter.emit(Define.Event.RefreshProfile, true);

        if (!isCheckMobile) {
            onChangeDrawer(true);
        }
    }, [isCheckMobile]);

    const onShowWallet = (chain: SupportChainType) => {
        console.log(chain, Chain.Connected);

        if (!isConnectWallet || chain != Chain.Connected) {
            console.log("SHOW WALLET", chain);

            Chain.Selected = chain;
            setIsShowWallet(true);

            if (isCheckMobile) {
                onChangeDrawer(false);
            } else {
                changeScroll(false);
            }
        }
    }

    const changeScroll = (isScroll: boolean) => {
        const scrollVal = isScroll ? "auto" : "hidden";
        const element = document.getElementById("root");
        if (element != null) {
            element.style.overflow = scrollVal;
            element.style.overflowX = "hidden";
        }

        const elements = document.getElementsByTagName("body");
        if (elements != null) {
            elements[0].style.overflow = scrollVal;
            elements[0].style.overflowX = "hidden";
        }
    }

    const onChangeDrawer = useCallback((isChange: boolean) => {
        if (isChange) {
            changeScroll(true);
            setIsOpenDrawer(false);
            let nextTick = setTimeout(() => {
                clearTimeout(nextTick);
                setIsOpenDrawerOverly(false);
            }, 500);
        } else {
            changeScroll(false);
            setIsOpenDrawerOverly(true);
            let _nextTick = setTimeout(() => {
                clearTimeout(_nextTick);
                setIsOpenDrawer(true);
            });
        }
    }, []);

    const getRouter = () => {
        const marketRouters = [
            <Route key={1} path={Define.Page.Marketplace}
                element={<Marketplace onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
            <Route key={2} path={Define.Page.Games}
                element={<Games onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
            <Route key={3} path={Define.Page.Market}
                element={<Market onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
            <Route key={4} path={Define.Page.GameStore}
                element={<GameStore onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
            <Route key={5} path={Define.Page.GameDetail}
                element={<GameDetails onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
            <Route key={6} path={Define.Page.ProfileV2}
                element={<ProfileV2 onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
        ];
        const stakingRouters = [
            <Route key={7} path={Define.Page.Staking}
                element={<Staking onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
            <Route key={8} path={Define.Page.StakingPool}
                element={<StakingPool onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
            <Route key={9} path={Define.Page.MyStake}
                element={<MyStake onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />,
        ];

        return (<Routes>
            <Route path={Define.Page.Home}
                element={<Home onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
            <Route path={Define.Page.Community}
                element={<Community onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
            <Route path={Define.Page.Map}
                element={<Map onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
            <Route path={Define.Page.Commodity}
                element={<CommodityPage onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
            <Route path={Define.Page.Swap}
                element={<Swap onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
            <Route path={Define.Page.Profile}
                element={<Profile onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />

            {ShowVersion >= OpenVersion.Market && marketRouters}
            {ShowVersion >= OpenVersion.Staking && stakingRouters}
            <Route path="*"
                element={<Navigate to={Define.Page.Home} />} />
        </Routes>)
    }

    const Router = getRouter();
    //     (<Routes>
    //     <Route path={Define.Page.Home}
    //         element={<Home onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Community}
    //         element={<Community onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Map}
    //         element={<Map onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Marketplace}
    //         element={<Marketplace onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Commodity}
    //         element={<CommodityPage onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Swap}
    //         element={<Swap onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Profile}
    //         element={<Profile onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Games}
    //         element={<Games onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Market}
    //         element={<Market onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.GameStore}
    //         element={<GameStore onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.GameDetail}
    //         element={<GameDetails onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.ProfileV2}
    //         element={<ProfileV2 onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.Staking}
    //         element={<Staking onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.StakingPool}
    //         element={<StakingPool onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path={Define.Page.MyStake}
    //         element={<MyStake onConnectWallet={onConnectWallet} onShowWallet={onShowWallet} onNavigation={onNavigation} />} />
    //     <Route path="*"
    //         element={<Navigate to={Define.Page.Home} />} />
    // </Routes>);

    return (
        <section>
            <section className="background-color" />
            {
                isShowWallet
                    ?
                    <WalletsContext.Provider value={isShowWallet}>
                        <Wallets onChangeWalletState={onChangeWalletState} />
                    </WalletsContext.Provider>
                    :
                    null
            }
            <section className="header">
                <div className="content">
                    <img src={Logo} alt="-_-" srcSet="" draggable={false} onClick={() => { navigate(Define.Page.Home, { replace: true }) }} />
                    <section className="header-right">
                        <ul>
                            {/* {
                                Global.WalletAdapter && Global.WalletAdapter.connected && Global.WalletAdapter.publicKey.toBase58() == DEFAULTS.CREATOR ?
                                    <li
                                        key={'admin'}
                                        className={cs({checked: currentNavigation === 'admin'})}
                                        onClick={() => onNavigationClick('admin', false)}>
                                        {'Admin'}
                                    </li>
                                    :
                                    <></>
                            } */}

                            {
                                Define.Navigations.map((navigation, index) =>
                                    <li
                                        key={index}
                                        className={cs({ checked: currentNavigation === navigation.name, burn_to_earn:"Metabot" === navigation.title })}
                                        onClick={() => onNavigationClick(navigation.name, false)}>
                                        {navigation.title}
                                    </li>)
                            }
                        </ul>
                        <div className={isConnectWallet ? "manage-btn" : "connect-btn"}
                            onClick={() => onConnectWalletClick(false)} />
                    </section>
                    <img className="mobile-switch" src={Category} onClick={() => onChangeDrawer(false)} alt="-_-"
                        draggable={false} />
                </div>
                <div className={cs({ overlay: true, "move-in-overlay": isOpenDrawerOverly })}>
                    <div className={cs({ "drawer-top": true, "move-in-drawer": isOpenDrawer })}>
                        <section className="top">
                            <img src={LastText} alt="-_-" draggable={false} />
                            <img
                                className="close"
                                src={CloseRound}
                                onClick={() => onChangeDrawer(true)}
                                alt="-_-"
                                draggable={false} />
                        </section>
                        {
                            Define.Navigations.map((navigation, index) => {
                                return (
                                    <span key={index}
                                        className={cs({ checked: currentNavigation === navigation.name })}
                                        onClick={() => onNavigationClick(navigation.name, true)}>
                                        {navigation.title}
                                    </span>
                                );
                            })
                        }
                        <section className={isConnectWallet ? "manage-btn" : "connect-btn"}
                            onClick={() => onConnectWalletClick(true)} />
                    </div>
                </div>
            </section>
            <section className="sizedbox" />
            {
                isShowCityBackground
                    ?
                    <section>
                        <section className="city-background" />
                        <section className="city-background-mask" />
                    </section>
                    :
                    null
            }

            {Router}
        </section>
    );
}
