import { INft } from "../contexts/nftContext";
import { IMap } from "../contexts/mapContext";
import { INftMap } from "../contexts/nftMapContext";
import { INftItem } from "../contexts/nftItemContext";
import { IType } from "../contexts/typeContext";
import { MintCache } from "../contexts/minCache";
import { apiClient, Global } from "./global";

export class GlobalConfig {
    public static NftConfigList = new Array<INft>();
    public static MapConfigList = new Array<IMap>();
    public static NftItemConfigList = new Array<INftItem>();
    public static NftMapConfigList = new Array<INftMap>();
    public static TypeConfigList = new Array<IType>();
    public static NFTMapDic = new Map<string, INftMap>();

    /**
     * 初始化配置文件
     */
    public static async InitConfigs() {
        await this.loadNftConfigs();
        await this.loadItemConfigs();
        await this.loadMapConfigs();
        await this.loadNftItemConfigs();
        await this.loadNftMapConfigs();
    }
    public static async loadNftItemConfigs() {
        this.NftItemConfigList = [];
        const res = (await apiClient.callApi("auction/GetSells", {
            filter: {
                project: "Official",
                category: "item",
                chain: "SOL",
                spl: "SOL",
            },
            mustSell: false,
            needHistory: false,
        }));
        if (!res.isSucc)
            return;
        const list = res.res.list;
        list?.forEach((v, index) => {
            let strs = v.uri.split("/");
            let json = strs[strs.length - 1];
            let jsonStrs = json.split(".");
            let nftId = Number(jsonStrs[0]);
            let nftConfig = GlobalConfig.getNftConfig(nftId);

            this.NftItemConfigList.push({
                id: index,
                config: nftConfig,
                mint_address: v.mintAddress,
                item: v,
            })
        })
        // console.log(list, this.NftItemConfigList);

        // const nftItemConfigs: MintCache[] = (await Global.ConfigAsync["nft_item"]).Mint;
        // this.NftItemConfigList = new Array<INftItem>();
        // let index = 0;
        // if (!nftItemConfigs)
        //     return;
        // for (let i = 0; i < nftItemConfigs.length; ++i) {
        //     let nftItemConfig = nftItemConfigs[i];
        //     let nftAddresses = nftItemConfig.MintAddress;
        //     let strs = nftItemConfig.File.split(".");
        //     let nftId = Number(strs[0]);
        //     let nftConfig = GlobalConfig.getNftConfig(nftId);
        //     for (let j = 0; j < nftAddresses.length; ++j) {
        //         let minAddress = nftAddresses[j];
        //         ++index;
        //         let nft: INftItem = {
        //             id: index,
        //             config: nftConfig,
        //             mint_address: minAddress
        //         }
        //         this.NftItemConfigList.push(nft);
        //     }
        // }
    }

    public static async loadNftMapConfigs() {
        this.NftMapConfigList = [];
        const res = (await apiClient.callApi("auction/GetSells", {
            filter: {
                project: "Official",
                category: "map",
                chain: "SOL",
                spl: "SOL",
            },
            mustSell: false,
            needHistory: false,
        }));
        if (!res.isSucc)
            return;
        const list = res.res.list;
        this.NftMapConfigList = [];
        list?.forEach((v, index) => {
            let strs = v.uri.split("/");
            let json = strs[strs.length - 1];
            let jsonStrs = json.split(".");
            let nftId = Number(jsonStrs[0]);
            let nftConfig = GlobalConfig.getMapConfig(nftId);
            const nft = {
                id: index,
                config: nftConfig,
                mint_address: v.mintAddress,
                item: v,
            };
            this.NftMapConfigList.push(nft);
            this.NFTMapDic.set(nft.mint_address, nft);
        })
        // console.log(list, this.NftMapConfigList);

        // const nftItemConfigs: MintCache[] = (await Global.ConfigAsync["nft_map"]).Mint;

        // this.NftMapConfigList = new Array<INftMap>();
        // let index = 0;
        // for (let i = 0; i < nftItemConfigs.length; ++i) {
        //     let nftItemConfig = nftItemConfigs[i];
        //     let nftAddresses = nftItemConfig.MintAddress;
        //     let strs = nftItemConfig.File.split(".");
        //     let nftId = Number(strs[0]);
        //     let mapConfig = GlobalConfig.getMapConfig(nftId);
        //     for (let j = 0; j < nftAddresses.length; ++j) {
        //         let minAddress = nftAddresses[j];
        //         ++index;
        //         let nft: INftMap = {
        //             id: index,
        //             config: mapConfig,
        //             mint_address: minAddress
        //         }
        //         this.NftMapConfigList.push(nft);
        //         this.NFTMapDic.set(nft.mint_address, nft);
        //     }
        // }
    }
    public static async loadNftConfigs() {
        const nftConfigs: INft[] = (await Global.ConfigAsync["config_item"]);
        this.NftConfigList = nftConfigs;
    }

    public static async loadItemConfigs() {
        const typeConfigs: INft[] = (await Global.ConfigAsync["config_type"]);
        this.TypeConfigList = typeConfigs;
    }

    public static async loadMapConfigs() {
        const mapConfigs: IMap[] = (await Global.ConfigAsync["config_map"]);
        this.MapConfigList = mapConfigs;
    }

    public static getNftConfig(id: number): INft {
        let config = null;
        for (let i = 0; i < this.NftConfigList.length; ++i) {
            let nftConfig = this.NftConfigList[i];
            if (nftConfig.id === id) {
                config = nftConfig;
                break;
            }
        }
        return config;
    }

    public static getMapConfig(id: number): IMap {
        let config = null;
        for (let i = 0; i < this.MapConfigList.length; ++i) {
            let mapConfig = this.MapConfigList[i];
            if (mapConfig.FieldID === id) {
                config = mapConfig;
                break;
            }
        }
        return config;
    }

    public static getTypeConfig(id: number): IType {
        let config = null;
        for (let i = 0; i < this.TypeConfigList.length; ++i) {
            let item = this.TypeConfigList[i];
            if (item.id === id) {
                config = item;
                break;
            }
        }
        return config;
    }

}