import Picture1 from "../assets/images/home/picture-1.png";
import Picture2 from "../assets/images/home/picture-2.png";
import Picture3 from "../assets/images/home/bot-11.png";
import Picture4 from "../assets/images/home/bot-21.png";
import PhantomIcon from "../assets/images/wallets/phantom.png";
import SolletIcon from "../assets/images/wallets/sollet.png";
import SolflareIcon from "../assets/images/wallets/solflare.png";
import SlopeIcon from "../assets/images/wallets/slope.png";
import Coin98Icon from "../assets/images/wallets/coin98.png";
import MathWalletIcon from "../assets/images/wallets/mathwallet.png";
import LedgerIcon from "../assets/images/wallets/ledger.png";
import solIcon from '../assets/images/profile/sol.png';
import usdcIcon from '../assets/images/profile/usdc.png';
import sypIcon from '../assets/images/profile/syp.png';
import mbttIcon from '../assets/images/profile/mbtt.png';
import mitcoinIcon from '../assets/images/profile/mitcoin.png';
import binanceIcon from "../assets/images/wallets/Binance.png";
import metamaskIcon from "../assets/images/wallets/metamask-fox.svg";
import okxIcon from "../assets/images/wallets/okx.png";
import foxIcon from "../assets/images/wallets/FoxWallet.svg";
import { PublicKey } from "@solana/web3.js";


//show version
// 1 中心化
// 2 商城
// 3 质押

export enum OpenVersion {
    Old = 0,
    Center,
    Market,
    Staking,
}

export const ShowVersion = (() => OpenVersion.Staking)();

function getNavigationConfig() {
    if (ShowVersion == OpenVersion.Center || ShowVersion == OpenVersion.Old)
        return [
            { name: "/home", title: "Home", },
            { name: "/community", title: "Community", },
            { name: "/map", title: "Map", },
            { name: "/marketplace", title: "Marketplace", },
            { name: "/swap", title: "Swap", },
            { name: "/blog", title: "Blog", },
            { name: "/litePaper", title: "Lite Paper", },
        ];
    else if (ShowVersion == OpenVersion.Market)
        return [
            { name: "/home", title: "Home", },
            { name: "/map", title: "Map", },
            { name: "/games", title: "Games", },
            { name: "/market", title: "Marketplace", },
            { name: "/swap", title: "Swap", },
            { name: "https://meta2150s.com/metabot/", title: "Metabot", },
            // { name: "/staking/mine", title: "Burn to Earn", },
        ];
    else if (ShowVersion == OpenVersion.Staking)
        return [
            { name: "/home", title: "Home", },
            { name: "/map", title: "Map", },
            { name: "/games", title: "Games", },
            { name: "/market", title: "Marketplace", },
            { name: "/staking", title: "Staking", },
            { name: "/swap", title: "Swap", },
            { name: "https://meta2150s.com/metabot/", title: "Metabot", },
            // { name: "/staking/mine", title: "Burn to Earn", },
        ];
}

export class Define {
    public static INVALID: number = -1;
    public static RarityType = ["None", "Common", "Uncommon", "Rare", "Epic", "Legendary"];
    public static CategoryType = ["Initial NFTs", "Lands", "Architectures", "Robots", "Human", "Resources", "Equipments"];
    public static DetailCategoryType = ["Architecture", "Robot", "Human", "Resource", "Equipment"];
    public static SortType = ["Select", "Name(A-Z)", "Name(Z-A)", "Newest", "Oldest", "Low Price", "High Price"];
    public static TokenType = ["USDC", "SOL", "SYP"];
    public static GenderType = ["None", "Man", "Woman"];

    public static USDCAddress: string = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
    public static SYPAddress: string = "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ";
    public static SOLAddress: string = "So11111111111111111111111111111111111111112";
    public static MBTTAddress: string = "";
    public static MITCOINAddress: string = "";
    public static BSCAddress: string = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";

    public static Category = class {
        public static InitialNFTs: number = 1;
        public static Lands: number = 2;
        public static Architectures: number = 3;
        public static Robots: number = 4;
        public static Human: number = 5;
        public static Resources: number = 6;
        public static Equipments: number = 7;
    }

    public static ProfileCategory = class {
        public static All: number = 1;
        public static Lands: number = 2;
        public static Architectures: number = 3;
        public static Robots: number = 4;
        public static Human: number = 5;
        public static Resources: number = 6;
        public static Equipments: number = 7;
    }

    public static Items: { [key: string]: string } = {
        '0': "MITCOIN",
        '1': "Water",
        '2': "Electricity",
        '3': "Superconduction Circuit",
        '4': "Quantum data plane Control",
        '5': "Measurement Plane",
        '6': "Quamtun Processint Unit",
        '7': "Super Quantum Computer",
        '8': "Quantum Computer",
    }

    public static Categories = [
        {
            name: "initialNFTs",
            title: "Initial NFTs",
            category: Define.Category.InitialNFTs,
        },
        {
            name: "lands",
            title: "Lands",
            category: Define.Category.Lands,
        },
        {
            name: "architectures",
            title: "Architectures",
            category: Define.Category.Architectures,
        },
        {
            name: "robots",
            title: "Robots",
            category: Define.Category.Robots,
        },
        {
            name: "human",
            title: "Human",
            category: Define.Category.Human,
        },
        {
            name: "resources",
            title: "Resources",
            category: Define.Category.Resources,
        },
        {
            name: "equipment",
            title: "Equipments",
            category: Define.Category.Equipments,
        },
    ];

    public static ProfileCategories = [
        {
            name: "lands",
            title: "Lands",
            category: Define.ProfileCategory.Lands,
        },
        {
            name: "architectures",
            title: "Architectures",
            category: Define.ProfileCategory.Architectures,
        },
        {
            name: "robots",
            title: "Robots",
            category: Define.ProfileCategory.Robots,
        },
        {
            name: "all",
            title: "All",
            category: Define.ProfileCategory.All,
        },
        {
            name: "human",
            title: "Human",
            category: Define.ProfileCategory.Human,
        },
        {
            name: "resources",
            title: "Resources",
            category: Define.ProfileCategory.Resources,
        },
        {
            name: "equipment",
            title: "Equipments",
            category: Define.ProfileCategory.Equipments,
        },
    ];

    public static Singles = [
        {
            title: "Welcome to Meta2150s",
            img: Picture1,
            article: "Meta2150s is a multi-chain platform for GameFi and NFT that integrates LandFi, marketplace, NFT staking, and DAO structure. Its land NFTs represents the ownership of the platform.",
        },
        {
            title: `Multi-chain GameFi aggregator`,
            img: Picture2,
            article: `Integrates multiple Games and NFTs from multi chains, including Solana, Ethereum, BNB Chain, Avalanche, etc., allowing users to enter various games through this all-in-one platform.`
        },
        {
            title: "In-game NFT rental platform",
            img: Picture3,
            article: `Provide a platform for NFT rental and player-to-player NFT exchange through different games, allowing players to rent NFT or exchange NFT with other players. `,
        },
        {
            title: "NFT staking in Meta2150s platform",
            img: Picture4,
            article: `For those genesis NFTs launched by other GameFi projects before their game online and some collectible and ornamental NFTs , Meta2150s provides a use case for them.`,
        },
    ];

    public static BNBWallets = [
        {
            name: "Metamask",
            icon: metamaskIcon,
        },
        {
            name: "Binance Wallet",
            icon: binanceIcon,
        },
        {
            name:"OKX Wallet",
            icon:okxIcon,
        },
        {
            name:"Fox Wallet",
            icon: foxIcon,
        }
    ];

    public static SOLWallets = [
        {
            name: "Phantom",
            icon: PhantomIcon,
        },
        {
            name: "Sollet",
            icon: SolletIcon,
        },
        {
            name: "Solflare",
            icon: SolflareIcon,
        },
        {
            name: "Slope",
            icon: SlopeIcon,
        },
        {
            name: "Coin98",
            icon: Coin98Icon,
        },
        {
            name: "Math Wallet",
            icon: MathWalletIcon,
        },
        {
            name: "Ledger",
            icon: LedgerIcon,
        },
        {
            name: "OKX Wallet",
            icon: okxIcon,
        },
        {
            name: "Fox Wallet",
            icon: foxIcon,
        }
    ];

    public static Navigations = getNavigationConfig();

    public static Page = class {
        public static None: string = "none";
        public static Home: string = "/home";
        public static Community: string = "/community";
        public static Map: string = "/map";
        public static Marketplace: string = "/marketplace";
        public static Market: string = "/market";
        public static Swap: string = "/swap";
        public static Games: string = "/games";
        public static ProfileV2: string = "/profileV2";
        public static Blog: string = "/blog";
        public static LitePaper: string = "/litePaper";
        public static Profile: string = "/profile";
        public static Admin: string = "/admin";
        public static GameStore: string = "/game/store";
        public static GameDetail: string = "/game/detail";
        public static Commodity: string = "/commodity";
        public static Staking: string = "/staking";
        public static StakingPool: string = "/staking/pool";
        public static MyStake: string = "/staking/mine";
    }

    public static Buy = class {
        public static Buy: number = 1;
        public static Sell: number = 2;
    }

    public static Token = class {
        public static USDC: number = 0;
        public static SOL: number = 1;
        public static SYP: number = 2;
        public static MBTT: number = 3;
        public static MITCOIN: number = 4;
    }

    public static Wallet = class {
        public static None: string = "None";
        public static Phantom: string = "Phantom";
        public static Sollet: string = "Sollet";
        public static Solflare: string = "Solflare";
        public static Slope: string = "Slope";
        public static Coin98: string = "Coin98";
        public static MathWallet: string = "Math Wallet";
        public static Ledger: string = "Ledger";
        public static OKX: string = "OKX Wallet";
        public static FoxWallet:string="Fox Wallet";

        public static Metamask: string = "Metamask";
        public static BinanceWallet: string = "Binance Wallet"
    }

    public static Sort = class {
        public static Select: number = 0;
        public static NameAtoZ: number = 1;
        public static NameZtoA: number = 2;
        public static Newest: number = 3;
        public static Oldest: number = 4;
        public static LowPrice: number = 5;
        public static HighPrice: number = 6;
    }

    public static Enter = class {
        public static Marketplace: number = 0;
        public static Profile: number = 1;
    }

    public static Nft = class {
        public static Item: string = "item";
        public static Map: string = "map";
    }

    public static TokenIcon = {
        [Define.Token.USDC]: usdcIcon,
        [Define.Token.SOL]: solIcon,
        [Define.Token.SYP]: sypIcon,
        [Define.Token.MBTT]: mbttIcon,
        [Define.Token.MITCOIN]: mitcoinIcon,
    }

    public static Event = class {
        public static RefreshMarketplace: string = "RefreshMarketplace";
        public static RefreshProfile: string = "RefreshProfile";
        public static ShowProfile: string = "ShowProfile";
    }

    public static IncreaseCoefficient: number = 10000;

}

export class SaveAccount {
    counts_0: number[] = new Array(32).fill(0);
    counts_1: number[] = new Array(32).fill(0);
    counts_2: number[] = new Array(32).fill(0);
    counts_3: number[] = new Array(32).fill(0);
    init: boolean = false;

    constructor(fields: { counts_0: number[], counts_1: number[], counts_2: number[], counts_3: number[], init: boolean } | undefined = undefined) {
        if (fields) {
            this.counts_0 = fields.counts_0;
            this.counts_1 = fields.counts_1;
            this.counts_2 = fields.counts_2;
            this.counts_3 = fields.counts_3;
            this.init = fields.init;
        }
    }
}

/**
 * Borsh schema definition for greeting accounts
 */
export const SaveAccountSchema = new Map([
    [SaveAccount, {
        kind: 'struct',
        fields: [
            ['counts_0', ['u32', 32]],
            ['counts_1', ['u32', 32]],
            ['counts_2', ['u32', 32]],
            ['counts_3', ['u32', 32]],
            ['init', 'u8'],
        ]
    }],
]);
