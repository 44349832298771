import React, { FC, useEffect } from 'react'
import { domainToASCII } from 'url';
import { Define } from '../define/define';
import { INavigation } from '../event/navigation'
import { Global } from '../global/global';
import '../assets/css/swap.css';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';


const Swap: FC<INavigation> = (navigation: INavigation) => {
  const { onShowWallet, onNavigation } = navigation;
  useEffect(() => {
    onNavigation(Define.Page.Swap);
  }, []);

  const domain = "meta2150s.com"//localhost"meta2150s.com
  document.domain = domain;
  const target = `https://meta2150s.com/sproxy/?domain=${domain}`;

  return (
    <section id='swap-page'>
      <div className='widget show' id="widget">
        <iframe src={target} className="widget-iframe" id='widget_iframe'></iframe>
      </div>
    </section>
  )
}

export default Swap


