import { SPLTokenType } from "../types";

export enum TradeState {
    CanSell,
    OnSale,//在售
    Selling,//交易中,锁定
    Sold,//售出
    Canceled,//已取消
    WaitForConfirm,//等待确认
}

/**
 * 商品
 */
export interface Commodity {
    order: string;//订单号：链+币种+卖家前四位+nft前四位+yyyymmddhhmmss+四位随机数
    contractOrderNo?: string;//合约订单编号,solidity合约编号
    createdTimeUTC: string;//售卖时间
    soldTimeUTC: string;//售出时间
    canceledTimeUTC: string;//取消售卖时间
    isOfficial: boolean;//是否官方售卖
    price: string;//价格
    seller: string;//卖家
    buyer: string;//买家
    tradeState: TradeState;//交易状态
    spl: SPLTokenType;//货币类型
}
