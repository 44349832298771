import { useContext, useEffect, useState } from "react";
import { Define } from "../define/define";
import { INavigation } from "../event/navigation";
import "../assets/css/gameStore.css";
import "../assets/css/newMarketCommon.css"
import GameFilterBar from "../components/gameFilterBar";
import CommodityItem from "../components/commodityItem";
import { dummyCommodity } from "../define/dummyInfo";
import "../assets/css/marketplaceHome.css"
import GameHeaderBackground from "../components/gameHeaderBackground";
import SocialIcons from "../components/socialIcons";
import { GameStoreContext } from "../contexts/gameContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { apiClient } from "../global/global";
import { DbNFT } from "../prot/protocols/db/DbNFT";
import { DbProject } from "../prot/protocols/db/DbProject";
import { TradeState } from "../prot/protocols/auction/Commodity";
import { useNavigate } from "react-router-dom";


function GameStore(navigation: INavigation) {
    const ctx = useContext(GameStoreContext)
    const { onShowWallet, onNavigation } = navigation;
    const navigate = useNavigate();
    const [Listings, setListings] = useState([] as DbNFT[]);
    const [FilteredListings, setFilteredListings] = useState([] as DbNFT[]);
    const [ProjectInfo, setProjectInfo] = useState(null as DbProject);
    const [IsLowToHigh, setIsLowToHigh] = useState(true);
    const [Search, setSearch] = useState("")
    const [analyzeItems, setAnalyzeItems] = useState([
        {
            title: "FLOOR PRICE",
            value: "--",
        },
        {
            title: "TOTAL VOLUME",
            value: "--",
        },
        {
            title: "AVG SALE VOLUME",
            value: "--",
        },
        {
            title: "TOTAL LISTED",
            value: "--",
        },
        {
            title: "TOTAL SUPPLY",
            value: "--",
        },
        {
            title: "UNIQUE HOLDERS",
            value: "--",
        },
    ]);
    useEffect(() => {
        if (ctx.project == "default") {
            navigate(Define.Page.Market);
            return;
        }

        onNavigation(Define.Page.GameStore);
        fetchListings();
        fetchProjectInfo();
    }, []);
    useEffect(() => {
        setFilteredListings(Listings.filter(v => !v.order.isOfficial));

    }, [ctx, Listings]);
    const onFilterClicked = (filter: string) => {
        if (filter == "official") {
            setFilteredListings(Listings.filter(v => v.order.isOfficial));
        }
        else if (filter == "items") {
            setFilteredListings(Listings.filter(v => !v.order.isOfficial));
        }
    }
    const fetchListings = async () => {
        const res = await apiClient.callApi("market/GetProjectListing", { project: ctx.project, start: Listings.length, count: 20 });
        if (res.isSucc) {
            setListings(res.res.list);
        }
    }
    const fetchProjectInfo = async () => {
        const call = await apiClient.callApi("market/GetProjectDetail", { project: ctx.project });
        if (call.isSucc) {
            setProjectInfo(call.res);

            if (call.res?.analyze)
                setAnalyzeItems([
                    {
                        title: "FLOOR PRICE",
                        value: call.res.analyze.floorPrice ? call.res.analyze.floorPrice.toFixed(2) : "0",
                    },
                    {
                        title: "TOTAL VOLUME",
                        value: call.res.analyze.totalVolume.toFixed(2),
                    },
                    {
                        title: "AVG SALE VOLUME",
                        value: (call.res.analyze.totalPurchased == 0 ? 0 : call.res.analyze.totalVolume / call.res.analyze.totalPurchased).toFixed(2),
                    },
                    {
                        title: "TOTAL LISTED",
                        value: call.res.analyze.totalListed.toString(),
                    },
                    {
                        title: "TOTAL SUPPLY",
                        value: call.res.analyze.totalSupply == -1 ? "--" : call.res.analyze.totalSupply.toString(),
                    },
                    {
                        title: "UNIQUE HOLDERS",
                        value: call.res.analyze.uniqueHolders.toString(),
                    },
                ]);
        }
    }

    const onReturnClicked = () => {
        navigate(Define.Page.Market);
    }


    return (<section className="market-container-common">
        <GameHeaderBackground src={ProjectInfo?.market?.bannerImageUrl} />
        <div className="game-store-body">
            <div id="game-store-detail">
                <div id="game-detail-shadow-box"></div>
                <img className="game-store-header-logo" src={ProjectInfo?.games.titleImage} />
                <p>{`${ProjectInfo?.market?.description.slice(0, 300)}`}</p>
                <SocialIcons direction="rtl" home={ProjectInfo?.market?.homeUrl} twitter={ProjectInfo?.market?.twitterUrl} discord={ProjectInfo?.market?.discordUrl} telegram={ProjectInfo?.market?.telegramUrl} />
                <div className="game-store-analyze">
                    {analyzeItems.map((v, i) =>
                        <div className="game-store-analyze-bg" key={i}>
                            <div>
                                <span>
                                    <h6>{v.title}</h6>
                                    <p>{v.value}</p>
                                </span>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <div className="market-container-common-bg rect-corner " style={{ marginTop: "7px" }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    justifyContent: "center",
                }}>
                    <div className="game-store-return-button">
                        <button className="return-button-common" onClick={onReturnClicked}>Return</button>
                    </div>
                    <h1>Market Place</h1>
                    <div id="title-line" style={{ marginLeft: "36px", marginRight: "36px", marginBottom: "7px" }} />
                    <GameFilterBar onFilterClicked={onFilterClicked} onPriceSortChange={setIsLowToHigh} onSearch={setSearch} />
                    <div style={{ maxWidth: "100%", height: "100%", margin: 25 }}>
                        <InfiniteScroll style={{ minHeight: "300px", overflow: "hidden" }} className="game-items" next={fetchListings} hasMore={true} loader={""} dataLength={FilteredListings.length} >
                            {
                                FilteredListings
                                    .filter(v => {
                                        if (!Search || Search == "")
                                            return true;

                                        return v.name.toLowerCase().match(Search);
                                    })
                                    .sort(
                                        (a, b) => {
                                            if (IsLowToHigh)
                                                return parseFloat(a.order.price) - parseFloat(b.order.price);
                                            else
                                                return parseFloat(b.order.price) - parseFloat(a.order.price);
                                        }
                                    )
                                    .map((v, i) =>
                                        <CommodityItem key={i} nft={v} gameIcon={ProjectInfo?.gameIconUrl} />)
                            }
                        </InfiniteScroll>
                    </div>

                </div>

            </div>
        </div>

    </section>);
}

export default GameStore;