import { useEffect, useState } from "react";
import "../assets/css/gameIconList.css";
import "../assets/css/market.css";
import { apiClient, Global } from "../global/global";
import { GameIconIntro } from "../prot/protocols/market/PtlGetRecentListed";
import GameIcon from "./gameIcon";

function GameIconList(props: { onSelectGame: (game: GameIconIntro, pos: { left: number, top: number }) => void }) {
    const [gameList, setGameList] = useState([] as GameIconIntro[]);
    // const [selectedGame, setSelectedGame] = useState(null);
    // const [IconPosition, setIconPosition] = useState({ left: 0, top: 0 })
    const [GameIconContainer, setGameIconContainer] = useState(null as HTMLDivElement)
    useEffect(() => {
        console.log(window.innerHeight, window.outerHeight);

        //获取游戏列表
        getList();
    }, []);

    const getList = async () => {
        Global.ShowLoading(true);
        const res = await apiClient.callApi("market/GetProjectList", { filter: "game" });
        if (res.isSucc) {
            console.log(res.res.list);

            setGameList(res.res.list.filter(v => v.isOnline).sort((a, b) => a.name == "Official" ? -1 : 1).map(v => {
                return {
                    project: v.name,
                    iconUrl: v.gameIconUrl,
                }
            }))
        }
        Global.ShowLoading(false);

    }

    return (<div className="market-container-common-bg" onClick={(e) => {
        const target = e.target as HTMLDivElement;
        if (target.className == "market-game-icon-container") {
            // setSelectedGame(null);
            props.onSelectGame(null, null);
        }
    }}>
        <div className="market-game-icon-container" style={{}} ref={setGameIconContainer}>
            {gameList.map((v, i) => <GameIcon key={i} info={v} onClick={async (info: GameIconIntro, rect: DOMRect) => {
                // if (selectedGame) {
                //     setSelectedGame(null);
                //     await waitSeconds(0.01);
                //     setSelectedGame(info);
                // }
                // else
                //     setSelectedGame(info);
                const ctn = GameIconContainer.getBoundingClientRect();
                // setIconPosition({
                //     left: rect.left,
                //     top: rect.top - ctn.top,
                // });
                props.onSelectGame(info, {
                    left: rect.left,
                    top: rect.top - ctn.top,
                })

            }} />)}
        </div>
        {/* {selectedGame ?
            // <div id="block-all" style={{
            //     position: "absolute",
            //     top: "0px",
            //     left: "0px",
            //     width: "100%",
            //     height: "100%",
            // }} >
            <GameIconDetail info={selectedGame} targetRect={IconPosition}></GameIconDetail>
            // </div>
            : <></>} */}
    </div>);
}

export default GameIconList;