import "../assets/css/gameIcon.css"
import { useContext, useEffect, useState } from "react";
import { apiClient, Global } from "../global/global";
import { useNavigate } from "react-router-dom";
import { Define } from "../define/define";
import { GameIconIntro } from "../prot/protocols/market/PtlGetRecentListed";
import { DbProject } from "../prot/protocols/db/DbProject";
import { GameStoreContext } from "../contexts/gameContext";

function GameIconDetail(props: { info?: GameIconIntro, targetRect?: { left: number, top: number } }) {
    // const { icon, name, genre, developer, platform, developmentState } = props.info;
    const [ContainerRef, setContainerRef] = useState(null as HTMLDivElement);
    const [Width, setWidth] = useState(window.innerWidth);
    const [Info, setInfo] = useState({} as DbProject)
    const navigate = useNavigate();
    const ctx = useContext(GameStoreContext);

    useEffect(() => {
        getInfo();
        setWidth(window.innerWidth);
    }, [window.innerWidth, props.info])

    const getInfo = async () => {
        const res = await apiClient.callApi("market/GetProjectDetail", { project: props.info.project });
        if (res.isSucc) {
            setInfo(res.res);
        }
    }

    const getLeft = () => {
        let left = props.targetRect.left - 14;

        if (!ContainerRef)
            return 100000;

        let width = ContainerRef.getBoundingClientRect().width + parseInt(ContainerRef.style.marginRight);
        // console.log(left, width, Width, left + width, parseInt(ContainerRef.style.marginRight));

        if (left + width > Width) {
            const offset = Width - width;
            return offset;
            // left -= (Global.ScreenWidth - )
            // console.log(offset);
            // left = offset;
        }
        return left;
    }
    const getTop = () => {
        return props.targetRect.top - 6;
    }

    const onPlayClick = () => {
        Global.Open(Info.playUrl)
    }

    const onStoreClick = () => {
        if (props.info.project == "Official") {
            navigate(Define.Page.Marketplace);
            return;
        }
        ctx.project = props.info.project;
        navigate(Define.Page.GameDetail);
    }

    return (<div className="game-icon-detail" ref={setContainerRef} style={{ display: props.info ? "flex" : "none", left: getLeft(), top: getTop(), marginRight: 5 }}>
        <img src={Info?.gameIconUrl} onClick={onStoreClick} />
        <div className="game-icon-detail-labels">
            <h1 id="game-icon-detail-title">{Info.title}</h1>
            <div>
                <i>GENRE</i>
                <span>{Info?.games?.genre}</span>
            </div>
            <div>
                <i>DEVELOPER</i>
                <span>{Info?.games?.developer}</span>
            </div>
            <div>
                <i>PLATFORM</i>
                <span>{Info?.games?.platform}</span>
            </div>
            <div>
                <i>DEVELOPMENT STATUS</i>
                <span>{Info?.games?.developmentStatus}</span>
            </div>
            <div id="buttons">
                {Info?.playUrl ? <button id="play-button" onClick={onPlayClick}>PLAY</button> : <></>}
                <button id="visit-button" onClick={onStoreClick}>VISIT STORE</button>
            </div>
        </div>
    </div>);
}

export default GameIconDetail;