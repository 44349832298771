import { DbNFT } from "../prot/protocols/db/DbNFT";
import { IStakingPoolSummary } from "../prot/protocols/staking/PtlGetPoolList";
import { StakingGenerateType, StakingPoolState } from "../prot/protocols/staking/Staking";

// export const dummyStakingPool: IStakingPoolSummary = {
//     poolId: "asd30930",
//     totalForce: 1330,
//     totalBonus: 450,
//     area: "asia",
//     iconUrl: "",
//     imgUrl: "",
//     name: "test1",
//     durabilityLoss: 10,
//     state: StakingPoolState.closed,
//     openUTC: new Date(),
//     closeUTC: new Date(),
//     rewardUTC: new Date(),
//     landEffectEndUTC: new Date(),
//     officialRewardFee: 0,
//     commissionRate: 0,
//     updateTime: new Date(),
//     stakedCount: 0,
//     generator: [],
//     stakeFeeRate: 0
// };

export const dummyCommodity: DbNFT = {
    "_id": "630c462ee9dd1399f5bd071a",
    "name": "Hyper-computing Center",
    "mintAddress": "J6uQyNk9SY7h8kXbEXy9yJ4QGXRKaP2iux8RgYkhev9H",
    "uri": "https://ipfs.io/ipfs/bafybeidwppgwwu77krg7ttu3n6exlsvrfftsycducahcpkj2egq25wv4ve/3001.json",
    "data": {
        "image": "https://ipfs.io/ipfs/bafybeidgvtw4bbhinfhflnf3rmzufmspx5dry4qzs6o5524mx5t3vc2riu/3001.jpg",
        "name": "Hyper-computing Center",
        "description": "The Hyper-computing Center is the ultimate goal pursued by all adventurers. It can bring endless wealth to adventurers. From the moment you own it, it signals that wealth is within your reach. Of course, it is also expensive. But you'll soon find that for the wealth it brings you, it's all worth it.",
        "symbol": "MTB",
        "attributes": [
            {
                "trait_type": "Category",
                "value": "Architectures"
            },
            {
                "trait_type": "Rarity",
                "value": "Common"
            },
            {
                "trait_type": "Level",
                "value": 1
            },
            {
                "trait_type": "increase",
                "value": 0
            },
            {
                "trait_type": "Length",
                "value": "110(m)"
            },
            {
                "trait_type": "Width",
                "value": "110(m)"
            },
            {
                "trait_type": "Height",
                "value": "305(m)"
            },
            {
                "trait_type": "wartung_des_computers",
                "value": 50
            },
            {
                "trait_type": "Energy Consumption",
                "value": "Water,Electricity"
            },
            {
                "trait_type": "Output",
                "value": "MitCoin"
            },
            {
                "trait_type": "Human Commander",
                "value": 1
            }
        ]
    },
    "chain": "SOL",
    "category": "item",
    "project": "Official",
    "order": {
        "order": "SOLSOL5iTdJ6uQ20220829125301f4d3",
        "createdTimeUTC": "2022-08-29T04:53:01.690+-480",
        "soldTimeUTC": "2022-08-29T04:53:05.575+-480",
        "canceledTimeUTC": "",
        "isOfficial": true,
        "price": "0.1",
        "seller": "5iTdQzbpwVcvVV5ehqeeXRmnGD38pzwCPjNc9YxuQBEa",
        "buyer": "AEq77GAbewgpo6GHxvWPS7c16Y9WjoTt7JCgr3aMTcyg",
        "tradeState": 1,
        "spl": "SOL"
    },
}

export const dummyGames = [
    {
        project: "test1",
        name: "Test1",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-2.jpg",
        genre: "SLG",
        developer: "WHO123",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test2",
        name: "Test2",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-12.jpg",
        genre: "SLG",
        developer: "WHO456",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test3",
        name: "Test3",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-0.jpg",
        genre: "SLG",
        developer: "ruadddd",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test4",
        name: "Test3",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-15.jpg",
        genre: "SLG",
        developer: "WHO",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test2",
        name: "Test5",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-12.jpg",
        genre: "SLG",
        developer: "WHO",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test3",
        name: "Test6",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-0.jpg",
        genre: "SLG",
        developer: "WHO",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test4",
        name: "Test7",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-15.jpg",
        genre: "SLG",
        developer: "WHO",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test4",
        name: "Test7",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-15.jpg",
        genre: "SLG",
        developer: "WHO",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test4",
        name: "Test7",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-15.jpg",
        genre: "SLG",
        developer: "WHO",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test4",
        name: "Test7",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-15.jpg",
        genre: "SLG",
        developer: "WHO",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
    {
        project: "test4",
        name: "Test7",
        icon: "https://icon-library.com/images/gaming-icon/gaming-icon-15.jpg",
        genre: "SLG",
        developer: "WHO",
        platform: "PC",
        developmentState: "In Development",
        storeUrl: "https://google.com",
    },
]
