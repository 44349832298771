import { EvmAddress, EvmChain } from '@moralisweb3/evm-utils';
import Moralis from 'moralis';
import { BigNumber, Contract, ethers, providers } from "ethers";
import { ClientEvn, Global } from '../global/global';
import abi from "./TokenTransABI.json";

function getEvmNet() {
    if (ClientEvn == "Dev" || ClientEvn == "Local" || ClientEvn == "RDev" || ClientEvn == "Test")
        return EvmChain.BSC_TESTNET;
    else
        return EvmChain.BSC;
}

Moralis.start({
    apiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImZiN2FiMzNmLTk0OGMtNDJhNC1iODNjLWUyZWQ4NjNiM2JlZCIsIm9yZ0lkIjoiMzM0MDc0IiwidXNlcklkIjoiMzQzNDg1IiwidHlwZUlkIjoiMDIzNWI0NzQtMDBlZi00Zjg1LWE2ODYtZTZjMGE5YzU3MzAzIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODQzMDMxMzYsImV4cCI6NDg0MDA2MzEzNn0.D7SFCpxUA5HuRL0qm-E_3tpAKAhYAAVoX73tGqEymuE',
});
export const EvmNet = getEvmNet();

export function createProvider(rpc: any) {
    let _provider: providers.Web3Provider = null;

    return () => {
        try {
            if (!_provider) {
                _provider = new providers.Web3Provider(rpc);
            }
        } catch (error) {
            console.error(_provider);
        }
        return _provider;
    };
}
function createRPC(rpc: string) {
    let _eth: any = null
    return () => {
        if (!_eth)
            _eth = (window as any)[rpc];
        return _eth
    };
}
export const getEthers = createRPC("ethereum");
export const getOkxRPC = createRPC("okxwallet");
export const getFoxRPC = createRPC("foxwallet");
export const getMetamask = createProvider(getEthers());
export const getOKX = createProvider(getOkxRPC());
export const getFox = createProvider(getFoxRPC());
export function GetContract(isOfficial: boolean) {
    return new Contract(
        isOfficial ? Global.Contract.Chains.BSC.ThirdAuctionContract : Global.Contract.Chains.BSC.PlayerAuctionContract,
        abi,
        getMetamask().getSigner(0),
    );
}

export async function GetNFTs(owner: string) {

    try {
        console.log("GetNFTs start", owner);
        return await Moralis.EvmApi.nft.getWalletNFTs({ address: owner, chain: EvmNet });
    } catch (error) {
        console.error(error);
    }
}

export async function GetToken(tokenAddress: string) {

    const result = await Moralis.EvmApi.token.getWalletTokenBalances({ address: Global.WalletAddress, chain: EvmNet })
    for (const iterator of result.result) {
        if (iterator.token.contractAddress.lowercase == tokenAddress.toLowerCase()) {
            return iterator.value;
        }
    }
}


export async function GetNFTInfo(address: string, tokenId: string) {


    // const owners = await Moralis.EvmApi.nft.getNFTOwners({ address, chain, tokenId });
    const info = await Moralis.EvmApi.nft.getNFTMetadata({
        address,
        chain: EvmNet,
        tokenId,
    })
    info.raw.owner_of = await GetNFTOwner(address, tokenId)
    console.log(address, tokenId, info.result.tokenUri);
    return info;

}

const tokenAbi = [
    {
        "constant": false,
        "inputs": [
            {
                "name": "spender",
                "type": "address"
            },
            {
                "name": "tokens",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "name": "",
                "type": "uint8"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }
];
const nftABI: any = [{
    inputs: [
        {
            internalType: "address",
            name: "to",
            type: "address"
        },
        {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256"
        }
    ],
    name: "approve",
    outputs: [] as any[],
    stateMutability: "nonpayable",
    type: "function"
},
{
    "inputs": [{
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "ownerOf",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
        {
            "internalType": "address",
            "name": "operator",
            "type": "address"
        },
        {
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
        }
    ],
    "name": "setApprovalForAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
];

const tsAbi: any = [{ "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "target", "type": "address" }, { "indexed": false, "internalType": "address[]", "name": "tokens", "type": "address[]" }, { "indexed": false, "internalType": "uint256[]", "name": "ids", "type": "uint256[]" }, { "indexed": false, "internalType": "bool[]", "name": "isNft", "type": "bool[]" }], "name": "SendToken", "type": "event" }, { "inputs": [{ "internalType": "address", "name": "target", "type": "address" }, { "internalType": "address[]", "name": "tokens", "type": "address[]" }, { "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }, { "internalType": "bool[]", "name": "isNft", "type": "bool[]" }], "name": "sendToken", "outputs": [], "stateMutability": "payable", "type": "function" }];
export async function ApproveSell(contractAddress: string, nftAddress: string, id: number) {
    const nft = new Contract(
        nftAddress,
        nftABI,
        getMetamask().getSigner(0),
    );
    try {
        const tx = await nft.approve(contractAddress, id);
        console.log(tx);
        await tx.wait();
        return true;
    } catch (error) {
        return false;
    }
}

export async function ApproveAllNFT(target: string, nftAddress: string) {
    const nft = new Contract(nftAddress, nftABI, getMetamask().getSigner(0))
    const tx = await nft.setApprovalForAll(target, true);
    console.log(tx);
    await tx.wait();
    return tx.hash;
}
export async function ApproveToken(target: string, address: string, amount: BigNumber) {
    const token = new Contract(address, tokenAbi, getMetamask().getSigner(0),);

    const tx = await token.approve(target, amount);
    await tx.wait();
    return tx.hash;
}
export async function TokenTransfer(contractAddress: string, target: string, address: string[], amount: BigNumber[], isNft: boolean[]) {
    const contract = new Contract(contractAddress, tsAbi, getMetamask().getSigner(0));
    console.log(address, amount.toString());
    
    const tx = await contract.sendToken(target, address, amount, isNft);
    await tx.wait();
    return tx.hash;
}

export async function GetNFTOwner(nftAddress: string, tokenId: string) {
    const nft = new Contract(
        nftAddress,
        nftABI,
        getMetamask().getSigner(0),
    );
    try {
        return await nft.ownerOf(BigNumber.from(tokenId));
    } catch (error) {
        return "--";
    }
}