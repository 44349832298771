import { useEffect, useState } from "react";
import "../assets/css/indicator.css"

function Indicator(props: { index: number, count: number, onChangeIndex: (index: number) => void }) {
    const [dots, setDots] = useState([])

    useEffect(() => {
        
        const list = [];
        for (let index = 0; index < props.count; index++) {
            list.push(index);
        }
        setDots(list);
        console.log("DOTS", dots);

        //   return () => {
        //     props.count
        //   }
    }, [props.count])


    return (
        <div className="indicator">
            {dots.map((v, i) => {
                return  props.index == i ?
                    < div key={i} className="indicator-dot-selected" onClick={() => props.onChangeIndex(i)} />
                    :
                    < div key={i} className="indicator-dot" onClick={() => props.onChangeIndex(i)} />
            })}
        </div>
    );
}

export default Indicator;