import { ChangeEvent, ChangeEventHandler, useContext, useEffect, useState } from "react";
import { Define } from "../define/define";
import { INavigation, SupportChainType } from "../event/navigation";
import "../assets/css/profileV2.css"
import "../assets/css/newMarketCommon.css"
import solIcon from "../assets/images/icon/SOL.png";
import bnbIcon from "../assets/images/icon/BNB.png";
import GameSearchBar from "../components/gameSearchBar";
import CollectionFolder, { IProjectCategory } from "../components/collectionFolder";
import { apiClient, Global } from "../global/global";
import { getTokensByOwner, TokenGet } from "../common/tokenGet";
import * as SPLToken from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { waitSeconds } from "../utils";
import { DbNFT } from "../prot/protocols/db/DbNFT";
import { useNavigate } from "react-router-dom";
import ChainSelector from "../components/chainSelector";
import { ChainContext } from "../contexts/chainContext";
import EventCenter from "../event/eventCenter";
import { GetNFTs } from "../common/EvmAuction";
import { BigNumber } from "ethers";


function ProfileV2(navigation: INavigation) {
    const navigate = useNavigate();
    const Chain = useContext(ChainContext);

    const { onShowWallet, onConnectWallet, onNavigation } = navigation;
    const [Tokens, setTokens] = useState([
        {
            icon: solIcon,
            value: "--",
        },
    ]);
    const [SelectedProject, setSelectedProject] = useState("others");
    const [SelectCollection, setSelectCollection] = useState("owned" as "owned" | "listed" | "unverified");
    const [Search, setSearch] = useState("");
    const [Unverified, setUnverified] = useState([]);
    const [Projects, setProjects] = useState({} as { [key: string]: IProjectCategory });
    const [SelectedChain, setSelectedChain] = useState(Chain.Selected);
    const [ProjectIcon, setProjectIcon] = useState({} as { [key: string]: string })

    useEffect(() => {
        setSelectedChain(Chain.Selected);
        getProjectList();
    }, [])
    useEffect(() => {

        onNavigation(Define.Page.ProfileV2);
        if (SelectedChain == "SOL")
            fetchSolOwnNFT();
        else if (SelectedChain == "BNB")
            fetchBNBOwnNFT();
    }, [SelectedChain]);
    const onRefreshProfile = (isLoadNftConfig: boolean) => {
        if (!isLoadNftConfig)
            return;
        if (Chain.Connected == "SOL")
            fetchSolOwnNFT();
        else if (Chain.Connected == "BNB")
            fetchBNBOwnNFT();
        setSelectedChain(Chain.Connected)
    }
    useEffect(() => {
        EventCenter.addListener(Define.Event.RefreshProfile, onRefreshProfile);
        return () => {
            EventCenter.removeListener(Define.Event.RefreshProfile, onRefreshProfile);
        }
    }, [onRefreshProfile]);

    const getProjectList = async () => {
        const res = await apiClient.callApi("market/GetProjectList", { filter: "base" });
        if (res.isSucc) {
            let map: { [key: string]: string } = {};
            for (const iterator of res.res.list) {
                map[iterator.name] = iterator.gameIconUrl;
            }
            setProjectIcon(map);
        }
    }

    const onDisconnectClick = () => {
        onConnectWallet(false);
        navigate(Define.Page.Home);
    }

    const onChainChange = (chain: SupportChainType) => {
        Chain.Selected = chain;
        setSelectedChain(chain)
    }
    const fetchBNBOwnNFT = async () => {
        console.log("CHANGE TO BNB");
        Global.ShowLoading(true);

        if (!Global.WalletConnected("BNB")) {
            onShowWallet("BNB");
            Global.ShowLoading(false);
            return;
        }
        console.log("FETCH BNB");
        setTokens([{
            icon: bnbIcon,
            value: "--",
        }]);
        //获取币安nft
        const addr = await Global.EthProvider.getSigner().getAddress();
        const balance = (await Global.EthProvider.getBalance(addr)).div(BigNumber.from(10).pow(9)).toNumber();

        setTokens([{
            icon: bnbIcon,
            value: (balance / Math.pow(10, 9)).toFixed(2),
        }]);
        const unverified: string[] = [];
        const projects = await fetchProjectInfo();
        let res = await GetNFTs(addr);
        while (true) {

            const call = await apiClient.callApi("market/GetNFTByMint", {
                mint: res.raw.result.map(v => `${v.token_address.toLowerCase()}_${v.token_id}`),
                owner: addr,
            })
            // for (const key in projects) {
            //     if (Object.prototype.hasOwnProperty.call(projects, key)) {
            //         projects[key].listed = [];
            //         projects[key].owned = [];
            //     }
            // }
            if (call.isSucc) {

                call.res.owned.forEach(a => { projects[a.project].owned.push(a) });
                call.res.listed.forEach(a => { projects[a.project].listed.push(a) });
                for (const iterator of call.res.unverified) {
                    unverified.push(iterator);
                }
                console.log(projects);

                setProjects(projects);
                setUnverified(unverified);
            }
            else {
                console.error(call.err);
                await waitSeconds(5);
            }
            if (!res.hasNext())
                break;
            res = await res.next();
        }

        Global.ShowLoading(false);

    }
    const fetchSolOwnNFT = async () => {
        console.error("CHANGE TO SOL");
        Global.ShowLoading(true);

        const unverified: string[] = [];
        setTokens([{
            icon: solIcon,
            value: "--",
        }]);
        if (!Global.WalletConnected("SOL")) {
            onShowWallet("SOL");
            Global.ShowLoading(false);
            return;
        }
        const projects = await fetchProjectInfo();
        const sol = await TokenGet.getBalance(Global.Connection) / Global.AdminConfig.Decimals[Define.Token.SOL];
        setTokens(pre => {
            pre[0] = {
                icon: solIcon,
                value: sol.toString(),
            };
            return pre;
        });
        const tokenAccounts = await getTokensByOwner(Global.WalletAdapter.publicKey);
        //通过token检查有用nft
        let start = 0, end = 0;
        while (true) {
            end = Math.min(start + 100, tokenAccounts.length);

            const mint = tokenAccounts.slice(start, end);
            if (mint.length == 0)
                break;
            const call = await apiClient.callApi("market/GetNFTByMint", {
                mint,
                owner: Global.WalletAdapter.publicKey.toBase58(),
            })
            // for (const key in projects) {
            //     if (Object.prototype.hasOwnProperty.call(projects, key)) {
            //         projects[key].listed = [];
            //         projects[key].owned = [];
            //     }
            // }
            if (call.isSucc) {
                console.log(call.res);

                start += 100;
                call.res.owned.forEach(a => { projects[a.project].owned.push(a) });
                call.res.listed.forEach(a => { projects[a.project].listed.push(a) });
                for (const iterator of call.res.unverified) {
                    unverified.push(iterator);
                }
                console.log(projects);

                setProjects(projects);
                setUnverified(unverified);
            }
            else {
                console.error(call.err);
                await waitSeconds(5);
            }
        }

        console.log("FETCH COMPLETE");
        Global.ShowLoading(false);

    }

    const fetchProjectInfo = async () => {
        const projects: { [key: string]: IProjectCategory } = {};
        const call = await apiClient.callApi("market/GetProjectList", { filter: "base" });
        if (call.isSucc) {
            call.res.list.forEach(v => {
                projects[v.name] = {
                    title: v.title,
                    project: v.name,
                    owned: [],
                    listed: [],
                }
            });
        }
        return projects;
    }

    const onProjectSelected = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        console.log(Projects, Object.values(Projects));
        if (e.target.value == "official") {
            if (Global.WalletConnected("SOL"))
                navigate(Define.Page.Profile)
            else
                onShowWallet("SOL");
            return;
        }

        setSelectedProject(e.target.value);
        setSelectCollection("owned");
    }
    const onCollectionSelected = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectCollection(e.target.value as "owned" | "listed" | "unverified");
    }

    const ProjectFiltered = () => {

        if (SelectedProject == "official")
            return <CollectionFolder ProjectIcon={ProjectIcon} search={Search} title="Meta2150s" project={Projects["Official"]} filter={SelectCollection} />

        if (SelectCollection == "unverified") {
            console.log("unverified");

            return <CollectionFolder ProjectIcon={ProjectIcon} search={Search} title="Unverified" mintAddress={Unverified} filter={SelectCollection} />
        }
        return Object.values(Projects)
            .filter(v => v.project != "Official" && (v.owned.length != 0 || v.listed.length != 0))
            .map((v, i) => <CollectionFolder ProjectIcon={ProjectIcon} search={Search} key={i} title={v.title} project={v} isFolder filter={SelectCollection} />);
    }


    const getWalletAddress = () => {
        if (Global.WalletConnected("SOL"))
            return Global.getAddrShort(Global.WalletAdapter.publicKey.toBase58());
        else if (Global.WalletConnected("BNB"))
            return Global.getAddrShort(Global.WalletAddress);
        return "";
    }

    return (<section className="market-container-common">
        <div className="market-container-common-bg">
            <ChainSelector
                onChanged={onChainChange}
                style={{
                    position: "relative",
                    right: "calc(166px - 100%)",
                    top: "10px",
                    // float: "right",
                }} />
            <div className="profile-basic-info">
                <h1 style={{ marginTop: 0 }}>PROFILE</h1>
                <div id="title-line" />
                <div>
                    <div id="profile-texts">
                        <div>
                            <h2>WALLET ADDRESS:</h2>
                            <h3>{getWalletAddress()}</h3>
                        </div>
                        <button onClick={onDisconnectClick}>Disconnect</button>
                        <div>
                            <h2>GAME DURATION:</h2>
                            <h3>0 Days</h3>
                        </div>
                        <div>
                            <h2>ORGANIZATION:</h2>
                            <h3>NONE</h3>
                        </div>
                        <div>
                            <h2>NET WORTH:</h2>
                            <h3>$0.00</h3>
                        </div>
                    </div>
                    <div id="token-assets">
                        <h2>TOKEN ASSETS:</h2>
                        <span>
                            {Tokens.map((v, i) => {
                                return (<div key={i}>
                                    <img src={v.icon} alt="" />
                                    <h3>{v.value}</h3>
                                </div>
                                )
                            })}
                        </span>

                    </div>
                </div>
            </div>
            <div className="profile-collection">
                <h1>NFT COLLECTION</h1>
                <div id="project-filter">
                    <div>
                        <input type="radio" id="official" name="project" value="official" checked={SelectedProject == "official"} onChange={onProjectSelected} />
                        <label htmlFor="official">Meta2150s</label>
                    </div>
                    <div>
                        <input type="radio" id="others" name="project" value="others" checked={SelectedProject == "others"} onChange={onProjectSelected} />
                        <label htmlFor="others">Others</label>
                    </div>
                </div>
                <div id="collection-filter">
                    <div >
                    </div>
                    <div>
                        <div>
                            <input type="radio" id="myItems" name="collection" value="owned" checked={SelectCollection == "owned"} onChange={onCollectionSelected} />
                            <label htmlFor="myItems">My Items</label>
                        </div>
                        <div>
                            <input type="radio" id="listed" name="collection" value="listed" checked={SelectCollection == "listed"} onChange={onCollectionSelected} />
                            <label htmlFor="listed">Listed</label>
                        </div>
                        {
                            SelectedProject == "official" ?
                                <></>
                                :
                                <div>
                                    <input type="radio" id="unverified" name="collection" value="unverified" checked={SelectCollection == "unverified"} onChange={onCollectionSelected} />
                                    <label htmlFor="unverified">Unverified</label>
                                </div>
                        }

                    </div>
                    <div id="search-bar-position">
                        <GameSearchBar onValueChanged={setSearch} />
                    </div>
                </div>
                <div id="collection-container">
                    {ProjectFiltered()}
                </div>
            </div>
        </div>
    </section>);
}

export default ProfileV2;