import React, { Component, useContext, useEffect } from 'react'
import LandItem from './landItem'
import closeImg from '../assets/images/map/close.png'
import Search from '../assets/images/map/search.png'
import InfiniteScroll from 'react-infinite-scroll-component';
import { GetOfficialItemPrice, Global, } from '../global/global';
import { ILandDetail, ISelectedLand, LandMarketContext } from '../contexts/LandMarketContext';
import axios from 'axios';
import { Define } from '../define/define';
import { GlobalConfigContext } from '../contexts/GlobalConfigContext';
import { config } from 'process';
import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js'
import { getParsedAccountByMint } from '@nfteyez/sol-rayz';
import { OfficialItem } from '../prot/protocols/base';
import { TradeState } from '../prot/protocols/auction/Commodity';

// import mapJson from '../assets/json/map_1.json';

interface ILandListState {
    isLow2High: boolean,
    searchName: string,
    min: number,
    max: number,
    showCoin: number,
    levelFilter: number,
    // items: any[],
    showCount: number,
    itemsFiltered: ISelectedLand[],
}


export default class LandList extends Component<{ refreshMap: Function, setWalletShow: Function, setBuyComplete: Function }, ILandListState> {
    static contextType = LandMarketContext;
    context!: React.ContextType<typeof LandMarketContext>
    // declare context: React.ContextType<typeof LandMarketContext>
    levelList: { [key: number]: string; } = {
        [-1]: 'All'
    };
    coinList: number[] = [];

    /**
     *
     */
    constructor(props: any) {
        super(props);
        for (let index = 0; index < 5; index++) {
            this.levelList[1 << index] = (index + 1).toString();
        }
        Object.values(Define.Token).forEach(val => this.coinList.push(val))
        this.state = {
            isLow2High: true,
            searchName: '',
            min: 0,
            max: Infinity,
            showCoin: Define.Token.SOL,
            levelFilter: -1,
            // items: [],
            showCount: 10,
            itemsFiltered: [],
        };

        setTimeout(async () => {
            this.setState({ itemsFiltered: this.getFilteredItems(10) })
        }, 1000);
    }

    render() {

        return (
            <GlobalConfigContext.Consumer>
                {
                    config => (this.renderList(config))
                }
            </GlobalConfigContext.Consumer>
        )
    }

    componentDidMount() {
        this.onMessageCB = this.onMessage.bind(this);
        window.addEventListener("show-landlist", this.onMessageCB, true);
    }
    componentWillUnmount(): void {
        window.removeEventListener("show-landlist", this.onMessageCB, true);
    }
    onMessageCB: any;
    onMessage(evt: any) {
        // console.log(evt);
        console.log("RUA");

        setTimeout(async () => {
            this.setState({ itemsFiltered: this.getFilteredItems(10) })
        }, 10);
    }

    renderList(config: any) {
        return (
            <section className={this.context.landMarketShow ? 'market-bg' : 'market-bg-hide'}>
                <div className='market-container'>
                    <div className='separate'>
                        <b className='map-h1'>Market</b>
                        <button style={{
                            backgroundColor: 'transparent',
                            border: 'transparent',
                            padding: 0,
                            cursor: 'pointer',
                        }}>
                            <img src={closeImg} onClick={() => this.context.setLandMarketShow(false)} />
                        </button>
                    </div>
                    <div className='separate'>
                        <div className="search">
                            <input placeholder="Search Location"
                                onChange={(event) => {
                                    this.setState({ searchName: event.target.value });
                                }} />
                            <img src={Search} onClick={this.onSearchClicked.bind(this)} />
                        </div>
                        <div style={{ display: "flex" }}>
                            <input className="search-price" placeholder="Min Price" type='number'
                                min={0} defaultValue={0}
                                onChange={
                                    event => {
                                        let min = event.target.valueAsNumber;
                                        if (Number.isNaN(min))
                                            min = 0;
                                        this.setState({ min: min });
                                    }
                                } />
                            <b style={{ padding: '4px' }}>~</b>
                            <input className="search-price" placeholder="Max Price" type='number'
                                min={0} defaultValue={Infinity}
                                onChange={
                                    event => {
                                        let max = event.target.valueAsNumber;
                                        console.log(max);

                                        if (Number.isNaN(max))
                                            max = Infinity;
                                        this.setState({ max: max });
                                    }} />
                        </div>
                    </div>
                    <div className='separate'>
                        <div>
                            <select name="PriceSort" onChange={ref => {
                                let changed = ref.target.value == 'LowToHigh';

                                this.setState({
                                    isLow2High: changed
                                }, () => {
                                    console.log(this.state.isLow2High);
                                    this.refreshLand();
                                })
                            }}>
                                <option value="LowToHigh">Price Low To High</option>
                                <option value="HighToLow">Price High To Low</option>
                            </select>
                        </div>
                        <div className='separate'>
                            <select name="TierFlit"
                                defaultValue={-1}
                                onChange={ref => {
                                    this.setState({
                                        levelFilter: Number.parseInt(ref.target.value)
                                    }, () => {
                                        this.refreshLand();
                                    })
                                }}>
                                {
                                    Object.keys(this.levelList).map(
                                        (key, index) => (
                                            <option key={index}
                                                value={Number.parseInt(key)}>Tier {this.levelList[Number.parseInt(key)]}</option>
                                        )
                                    )
                                }
                                {/* <option value={1 << 0 | 1 << 1 | 1 << 2}>Tier All</option>
                                            <option value={1 << 0}>Tier 1</option>
                                            <option value={1 << 1}>Tier 2</option>
                                            <option value={1 << 2}>Tier 3</option> */}
                            </select>
                            <b style={{ padding: '10px' }}> </b>
                            <select name="PriceType" onChange={ref => {
                                this.context.setCoin(Number.parseInt(ref.target.value));
                                this.setState({ showCoin: Number.parseInt(ref.target.value) });
                            }}>
                                {this.coinList.filter(
                                    (val) => config.ShowCoin[val])
                                    .map((key, index) =>
                                    (<option key={index}
                                        value={key}>{Define.TokenType[key]}</option>)
                                    )}
                                {/* <option value={COIN.SOL}>SOL</option>
                                            <option value={COIN.USDC}>USDC</option> */}
                            </select>
                        </div>
                    </div>
                </div>
                <div id="scrollableDiv">
                    <InfiniteScroll
                        className='land-container'
                        dataLength={this.state.itemsFiltered.length} //This is important field to render the next data
                        next={this.loadNextLand}
                        hasMore={true}
                        loader={<h4 style={{ color: 'black' }}></h4>}
                        scrollableTarget="scrollableDiv"
                        refreshFunction={this.refreshLand}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>end</b>
                            </p>
                        }
                    >
                        {this.state.itemsFiltered.filter(it => {
                            const isOfficial = it.official.supply[0] > 0;
                            // if (!isOfficial && it.order.tokenType != this.state.showCoin)
                            //     return false;
                            return true;
                        }).map((i, index) => (
                            <LandItem setBuyComplete={this.props.setBuyComplete} owner={i.owner} isSell={true} refresh={this.props.refreshMap} key={index} official={i.official} config={i.config} setWalletShow={this.props.setWalletShow} />
                        ))}
                    </InfiniteScroll>
                </div>
            </section>
        )
    }


    onSearchClicked() {
        // console.log('search', this.minPrice?.valueAsNumber, this.maxPrice?.valueAsNumber, this.searchName?.value);
        this.refreshLand();
    }

    loadNextLand = () => {
        console.log('load')
        this.setState((prevState: Pick<ILandListState, 'showCount'>) => {
            prevState.showCount += 10;
            return prevState;
        }, () => {
            this.refreshLand();
        });
    }

    async refreshLand() {
        const list = Object.values(this.context.sellLands);

        let reg = new RegExp(this.state.searchName.toLowerCase());
        let sortP = this.state.isLow2High ?
            (a: ISelectedLand, b: ISelectedLand) => {
                const pa = a.price;
                const pb = b.price;
                return pa - pb;
            }
            :
            (a: ISelectedLand, b: ISelectedLand) => {
                const pa = a.price;
                const pb = b.price;
                return pb - pa;
            }
        const filtered = list.filter(
            it => {
                if (it.official.nft) {
                    if (it.official.nft.order.tradeState != TradeState.OnSale)
                        return false;
                }
                if (it.official.supply[1] == 0)
                    return false;
                let price = GetOfficialItemPrice(it.official);
                let res = ((1 << (it.config.Level - 1)) & this.state.levelFilter) && (price >= this.state.min && price <= this.state.max);
                if (this.state.searchName != undefined) {
                    const t = `${it.config.Country} ${it.config.Province} ${it.config.City} ${it.config.FieldID}`.toLowerCase();
                    res = res && reg.test(t);
                }
                return res;
            })


        this.setState({ itemsFiltered: filtered.sort(sortP).slice(0, this.state.showCount) });
    }

    getFilteredItems(count: number) {
        const list = Object.values(this.context.sellLands);
        let reg = new RegExp(this.state.searchName.toLowerCase());
        let sortP = this.state.isLow2High ?
            (a: ISelectedLand, b: ISelectedLand) => {
                const pa = a.price;
                const pb = b.price;
                return pa - pb;
            }
            :
            (a: ISelectedLand, b: ISelectedLand) => {
                const pa = a.price;
                const pb = b.price;
                return pb - pa;
            }
        const filtered = list.filter(
            it => {
                if (it.official.nft) {
                    if (it.official.nft.order.tradeState != TradeState.OnSale)
                        return false;
                }
                if (it.official.supply[1] == 0)
                    return false;
                let price = GetOfficialItemPrice(it.official);
                it.price = price;
                let res = ((1 << (it.config.Level - 1)) & this.state.levelFilter) && (price >= this.state.min && price <= this.state.max);
                if (this.state.searchName != undefined)
                    res = res && reg.test((`${it.config.Country} ${it.config.Province} ${it.config.City} ${it.config.FieldID}`)!.toLowerCase());
                return res;
            }).sort(sortP).slice(0, count)


        return filtered;
    }
}
