export const layerConfig = {
    country: {
        // border: {
        //     id: "country-borders",
        //     type: "line",
        //     source: "states",
        //     "source-layer": "country",
        //     minzoom: 1,
        //     layout: {},
        //     paint: {
        //         "line-color": "#041421",
        //         "line-width": 5,
        //         "line-opacity": 0.1,
        //     },
        // },
        fill: {
            id: "country-fills",
            type: "fill",
            source: "states",
            "source-layer": "country",
            minzoom: 0,
            maxzoom: 3,
            layout: {},
            paint: {
                "fill-color": [
                    "case",
                    ["boolean", ["feature-state", "select"], false],
                    "#7e858d",
                    "#7e858d",
                ],
                "fill-opacity": [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    0.5,
                    0.0,
                ],
            },
        },
    },
    province: {
        // border: {
        //     id: "province-borders",
        //     type: "line",
        //     source: "states",
        //     "source-layer": "province",
        //     minzoom: 3,
        //     // maxzoom: 5,
        //     layout: {},
        //     paint: {
        //         "line-color": "#041421",
        //         "line-width": 2,
        //         "line-opacity": 0.2,
        //     },
        // },
        fill: {
            id: "province-fills",
            type: "fill",
            source: "states",
            "source-layer": "province",
            minzoom: 3,
            maxzoom: 6.5,
            layout: {},
            paint: {
                "fill-color": [
                    "case",
                    ["boolean", ["feature-state", "select"], false],
                    "#7e858d",
                    "#7e858d",
                ],
                "fill-opacity": [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    0.5,
                    0,
                ],
            },
        },
    },
    city: {
        fill: {
            id: "city-fills",
            type: "fill",
            source: "states",
            "source-layer": "city",
            minzoom: 6.5,
            layout: {},
            paint: {
                "fill-color": [
                    "case",
                    ["boolean", ["feature-state", "select"], false],
                    "#54535b",
                    "#7e858d",
                ],
                "fill-opacity": [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    0.8,
                    0.8,
                ],
            },
        },
        border: {
            id: "city-borders",
            type: "line",
            source: "states",
            "source-layer": "city",
            minzoom: 6.5,
            layout: {},
            paint: {
                "line-color": "#000000",
                "line-width": 1,
                "line-opacity": 0.6,
            },
        },
    },
};

