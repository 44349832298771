import React, { Component, useContext, useEffect } from 'react'
import LandItem from './landItem'
import closeImg from '../assets/images/map/close.png'
import Search from '../assets/images/map/search.png'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Global, } from '../global/global';
import { ILandDetail, LandMarketContext } from '../contexts/LandMarketContext';
import axios from 'axios';
import { Define } from '../define/define';
import { GlobalConfigContext } from '../contexts/GlobalConfigContext';
import { config } from 'process';
import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js'
import { getParsedAccountByMint, getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import { GlobalConfig } from '../global/globalConfig';
import { OfficialItem } from '../prot/protocols/base';
import { INftMap } from '../contexts/nftMapContext';

// import mapJson from '../assets/json/map_1.json';

interface ILandListState {
    searchName: string,
    levelFilter: number,
    showCount: number,
    itemsFiltered: any[],
}


export default class ManageLand extends Component<{
    landList: INftMap[], refreshMap: Function, setWalletShow: Function
}, ILandListState> {
    static contextType = LandMarketContext;
    context!: React.ContextType<typeof LandMarketContext>
    // declare context: React.ContextType<typeof LandMarketContext>
    levelList: { [key: number]: string; } = {
        [-1]: 'All'
    };
    coinList: number[] = [];

    /**
     *
     */
    constructor(props: any) {
        super(props);
        for (let index = 0; index < 5; index++) {
            this.levelList[1 << index] = (index + 1).toString();
        }
        Object.values(Define.Token).forEach(val => this.coinList.push(val))
        this.state = {
            searchName: '',
            levelFilter: -1,
            showCount: 10,
            itemsFiltered: [],
        };
        setTimeout(async () => {
            // await this.props.refreshMap();
            this.setState({ itemsFiltered: this.getFilteredItems(10) })
        }, 100);
    }

    render() {

        return (
            <GlobalConfigContext.Consumer>
                {
                    config => (this.renderList(config))
                }
            </GlobalConfigContext.Consumer>
        )
    }

    renderList(config: any) {

        return (
            <section className={this.context.ownedListShow ? 'market-bg' : 'market-bg-hide'}>
                <div className='market-container'>
                    <div className='separate'>
                        <b className='map-h1'>Owned</b>
                        <button style={{
                            backgroundColor: 'transparent',
                            border: 'transparent',
                            padding: 0,
                            cursor: 'pointer',
                        }}>
                            <img src={closeImg} onClick={() => this.context.setOwnedListShow(false)} />
                        </button>
                    </div>
                    <div className='separate-left'>
                        <div className="search">
                            <input placeholder="Search Location"
                                onChange={(event) => {
                                    this.setState({ searchName: event.target.value });
                                }} />
                            <img src={Search} onClick={this.onSearchClicked.bind(this)} />
                        </div>

                        <select name="TierFlit"
                            defaultValue={-1}
                            onChange={ref => {
                                this.setState({
                                    levelFilter: Number.parseInt(ref.target.value)
                                }, () => {
                                    this.refreshLand();
                                })
                            }}>
                            {
                                Object.keys(this.levelList).map(
                                    (key, index) => (
                                        <option key={index}
                                            value={Number.parseInt(key)}>Tier {this.levelList[Number.parseInt(key)]}</option>
                                    )
                                )
                            }
                            {/* <option value={1 << 0 | 1 << 1 | 1 << 2}>Tier All</option>
                                            <option value={1 << 0}>Tier 1</option>
                                            <option value={1 << 1}>Tier 2</option>
                                            <option value={1 << 2}>Tier 3</option> */}
                        </select>
                    </div>

                </div>
                <div id="scrollableDiv">
                    <InfiniteScroll
                        className='land-container'
                        dataLength={this.state.itemsFiltered?.length || 0} //This is important field to render the next data
                        next={this.loadNextLand}
                        hasMore={true}
                        loader={<h4 style={{ color: 'black' }}></h4>}
                        scrollableTarget="scrollableDiv"
                        refreshFunction={this.refreshLand}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>end</b>
                            </p>
                        }
                    >
                        {this.state.itemsFiltered?.map((i, index) => (
                            <LandItem setBuyComplete={undefined} owner={i.owner} isSell={false} refresh={this.props.refreshMap} key={index} official={i.official} config={i.config} setWalletShow={this.props.setWalletShow} />
                        ))}
                    </InfiniteScroll>
                </div>
            </section>
        )
    }


    onSearchClicked() {
        // console.log('search', this.minPrice?.valueAsNumber, this.maxPrice?.valueAsNumber, this.searchName?.value);
        this.refreshLand();
    }

    loadNextLand = () => {
        console.log('load')
        this.setState((prevState: Pick<ILandListState, 'showCount'>) => {
            prevState.showCount += 10;
            return prevState;
        }, () => {
            this.refreshLand();
        });
    }

    async refreshLand() {
        const list = this.props.landList;
        let reg = new RegExp(this.state.searchName.toLowerCase());
        const filtered = list.filter(
            it => {
                let res = (1 << (it.config.Level - 1) & this.state.levelFilter) != 0;
                if (this.state.searchName != undefined) {
                    const t = `${it.config.Country} ${it.config.Province} ${it.config.City} ${it.config.FieldID}`.toLowerCase();
                    res = res && reg.test(t);
                }
                return res;
            })
        console.log(filtered);


        this.setState({ itemsFiltered: filtered.slice(0, this.state.showCount) });
    }

    getFilteredItems(count: number) {
        const list = this.props.landList;

        console.log(this.props.landList);

        let reg = new RegExp(this.state.searchName.toLowerCase());
        const res = list.filter(
            it => {
                let res = (1 << (it.config.Level - 1) & this.state.levelFilter) != 0;
                console.log(res);

                if (this.state.searchName != undefined && this.state.searchName != "")
                    res = res && reg.test((`${it.config.Country} ${it.config.Province} ${it.config.City} ${it.config.FieldID}`)!.toLowerCase());

                return res;
            }).slice(0, count)

        return res;
    }

    componentWillReceiveProps(nextProps: Readonly<{ landList: any[]; refreshMap: Function; setWalletShow: Function; }>, nextContext: any): void {
        // console.log(nextProps.landList, this.props.landList.length);

        if (nextProps.landList.length != this.props.landList.length) {
            console.log("CHANGED");
            setTimeout(() => {
               this.refreshLand(); 
            }, 100);
            // this.setState({ itemsFiltered: this.getFilteredItems(10) })
        }
    }
}
