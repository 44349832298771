import { useContext, useEffect, useState } from "react";
import "../assets/css/gameIconListV2.css";
import "../assets/css/market.css";
import { useNavigate } from "react-router-dom";
import { apiClient, Global } from "../global/global";
import { GameIconIntro } from "../prot/protocols/market/PtlGetRecentListed";
import GameIcon from "./gameIconV2";
import { Define } from "../define/define";
import { GameStoreContext } from "../contexts/gameContext";

function GameIconList(props: { onSelectGame: (game: GameIconIntro, pos: { left: number, top: number }) => void }) {
    const [gameList, setGameList] = useState([] as GameIconIntro[]);
    const navigate = useNavigate();
    const ctx = useContext(GameStoreContext);
    // const [selectedGame, setSelectedGame] = useState(null);
    // const [IconPosition, setIconPosition] = useState({ left: 0, top: 0 })
    const [GameIconContainer, setGameIconContainer] = useState(null as HTMLDivElement)
    useEffect(() => {
        console.log(window.innerHeight, window.outerHeight);

        //获取游戏列表
        getList();
    }, []);

    const getList = async () => {
        // Global.ShowLoading(true);
        const res = await apiClient.callApi("market/GetProjectList", { filter: "game" });
        if (res.isSucc) {
            console.log(res.res.list);

            setGameList(res.res.list.filter(v => v.isOnline).sort((a, b) => a.name == "Official" ? -1 : 1).map(v => {
                return {
                    project: v.name,
                    iconUrl: v.gameIconUrl,
                }
            }).filter((item, i) => {
                return i < 28;
            }))
        }
        // Global.ShowLoading(false);

    }

    return (<div className="market-game-box" onClick={(e) => {
        const target = e.target as HTMLDivElement;
        if (target.className == "market-game-icon-container-v2") {
            // setSelectedGame(null);
            props.onSelectGame(null, null);
        }
    }}>
        <div className="market-game-icon-container-v2" style={{}} ref={setGameIconContainer}>
            {gameList.map((v, i) => <GameIcon three={i % 11 < 6} key={i} info={v} onClick={async (info: GameIconIntro, rect: DOMRect) => {
                ctx.project = info.project;
                navigate(Define.Page.GameDetail);
            }} />)}
        </div>
    </div>);
}

export default GameIconList;