import { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/gameIconListV2.css";
import "../assets/css/market.css";
import { GameStoreContext } from "../contexts/gameContext";
import { Define } from "../define/define";
import { apiClient } from "../global/global";
import { GameIconIntro } from "../prot/protocols/market/PtlGetRecentListed";
import FeaturePoolsItem from "./featurePoolsItem";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/navigation';
import ComingSoon from "../assets/images/home/coming-soon.jpg";

function RecentProjects() {
    const [gameList, setGameList] = useState([] as GameIconIntro[]);
    const [ContainerRef, setContainerRef] = useState(null as HTMLDivElement);
    const [ContainerWidth, setContainerWidth] = useState("100%")
    const [swiperController, setSwiperController] = useState(null);
    const navigate = useNavigate();
    const ctx = useContext(GameStoreContext);
    const [currentIndex, setCurrentIndex] = useState(1);
    useEffect(() => {
        if (swiperController) {
            setCurrentIndex(currentIndex);
            swiperController.slideTo(currentIndex);
        }
    }, [currentIndex, swiperController]);
    const onSwiper = useCallback((swiper: any) => {
        setSwiperController(swiper);
    }, []);
    const onSlideChange = useCallback(() => {
        if (swiperController) {
            setCurrentIndex(swiperController.activeIndex);
        }
    }, [swiperController]);
    const onSwiperClick = useCallback((index: number) => {
        let tempIndex = index + (swiperController && swiperController.activeIndex ? swiperController.activeIndex : 0);
        setCurrentIndex(tempIndex);
    }, [swiperController]);
    const onClick = (info: GameIconIntro) => {
        if (info.project == "Official") {
            navigate(Define.Page.Marketplace);
            return;
        }
        ctx.project = info.project;
        navigate(Define.Page.GameStore);
    }
    const getList = async () => {
        const res = await apiClient.callApi("market/GetRecentListed", {});
        if (res.isSucc) {
            setGameList(res.res.list.map((item, i) => {
                if (i === 0) {
                    item.iconUrl = "https://meta2150s.com/storage/KatanaInu/2024311/photo_2024-03-11_13-04-06.a4042.jpg";
                } else if (i === 1) {
                    item.iconUrl = "https://meta2150s.com/storage/Karmaverse/2024312/GFQSCVSbwAAw0XC.39497.jpg";
                } else {
                    item.iconUrl = ComingSoon;
                }
                return item;
            }).filter((item, i) => {
                return i < 3;
            }))
        }
    }
    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        const onResize = () => {
            setContainerWidth(getWidth());
        }
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])


    const getWidth = () => {
        console.log("width");

        if (!ContainerRef)
            return "100%"

        const maxColumn = 6;
        const curWidth = ContainerRef.clientWidth - 36 * 2;
        const minColumn = 1;
        let column = maxColumn;
        let iconWidth = 1200;
        for (; column >= minColumn; column--) {
            iconWidth = (curWidth - (column - 1) * 24) / column - 10;
            console.log(iconWidth);

            if (iconWidth > 180)
                break;
        }
        const offset = column - gameList.length;
        if (offset < 0)
            return "100%";

        const w = curWidth - offset * iconWidth - 24 * (offset - 1);

        return w + "px";
    }

    return (<div id="recent-listed" ref={setContainerRef}>
        <div className="list-wrapper">
            <Swiper
                slidesPerView={3}
                autoplay={{
                    disableOnInteraction: true,
                }}
                loop
                spaceBetween={20}
                onSwiper={onSwiper}
                onSlideChange={onSlideChange}
                centeredSlidesBounds
            > {
                gameList.map((v, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <FeaturePoolsItem key={i} info={v} shadow onClick={onClick} />
                        </SwiperSlide>
                    );
                })
            }
            </Swiper>
            <div className="list-swiper-button-prev" onClick={() => onSwiperClick(1)}></div>
            <div className="list-swiper-button-next" onClick={() => onSwiperClick(-1)}></div>
        </div>
    </div>);
}

export default RecentProjects;