import { ServiceProto } from 'tsrpc-proto';
import { ReqGetEventAll, ResGetEventAll } from './analyze/PtlGetEventAll';
import { ReqGetEventCount, ResGetEventCount } from './analyze/PtlGetEventCount';
import { ReqSendEvent, ResSendEvent } from './analyze/PtlSendEvent';
import { ReqAddOfficialConfig, ResAddOfficialConfig } from './auction/PtlAddOfficialConfig';
import { ReqAddOfficialItem, ResAddOfficialItem } from './auction/PtlAddOfficialItem';
import { ReqBuy, ResBuy } from './auction/PtlBuy';
import { ReqBuyOfficialItem, ResBuyOfficialItem } from './auction/PtlBuyOfficialItem';
import { ReqCancelSell, ResCancelSell } from './auction/PtlCancelSell';
import { ReqGetBuyOrder, ResGetBuyOrder } from './auction/PtlGetBuyOrder';
import { ReqGetCancelSellOrder, ResGetCancelSellOrder } from './auction/PtlGetCancelSellOrder';
import { ReqGetOfficialItemOrder, ResGetOfficialItemOrder } from './auction/PtlGetOfficialItemOrder';
import { ReqGetOfficialMarketplace, ResGetOfficialMarketplace } from './auction/PtlGetOfficialMarketplace';
import { ReqGetSellOrder, ResGetSellOrder } from './auction/PtlGetSellOrder';
import { ReqGetSells, ResGetSells } from './auction/PtlGetSells';
import { ReqSell, ResSell } from './auction/PtlSell';
import { ReqSetOfficial, ResSetOfficial } from './auction/PtlSetOfficial';
import { ReqThirdPartySell, ResThirdPartySell } from './auction/PtlThirdPartySell';
import { ReqGetCollections, ResGetCollections } from './market/PtlGetCollections';
import { ReqGetNFTByMint, ResGetNFTByMint } from './market/PtlGetNFTByMint';
import { ReqGetNFTListing, ResGetNFTListing } from './market/PtlGetNFTListing';
import { ReqGetProjectDetail, ResGetProjectDetail } from './market/PtlGetProjectDetail';
import { ReqGetProjectList, ResGetProjectList } from './market/PtlGetProjectList';
import { ReqGetProjectListing, ResGetProjectListing } from './market/PtlGetProjectListing';
import { ReqGetRecentListed, ResGetRecentListed } from './market/PtlGetRecentListed';
import { ReqGetScrollBanners, ResGetScrollBanners } from './market/PtlGetScrollBanners';
import { ReqCheckServerState, ResCheckServerState } from './PtlCheckServerState';
import { ReqAddProject, ResAddProject } from './settings/PtlAddProject';
import { ReqAddProjectNFT, ResAddProjectNFT } from './settings/PtlAddProjectNFT';
import { ReqChangeKeypair, ResChangeKeypair } from './settings/PtlChangeKeypair';
import { ReqGetAdmin, ResGetAdmin } from './settings/PtlGetAdmin';
import { ReqGetSettings, ResGetSettings } from './settings/PtlGetSettings';
import { ReqLoginAdmin, ResLoginAdmin } from './settings/PtlLoginAdmin';
import { ReqMintAndSendNFT, ResMintAndSendNFT } from './settings/PtlMintAndSendNFT';
import { ReqRefreshProjectHolders, ResRefreshProjectHolders } from './settings/PtlRefreshProjectHolders';
import { ReqRemoveStakeCoin, ResRemoveStakeCoin } from './settings/PtlRemoveStakeCoin';
import { ReqResetPassword, ResResetPassword } from './settings/PtlResetPassword';
import { ReqSetAdmin, ResSetAdmin } from './settings/PtlSetAdmin';
import { ReqSetContractFee, ResSetContractFee } from './settings/PtlSetContractFee';
import { ReqUpdateProject, ResUpdateProject } from './settings/PtlUpdateProject';
import { ReqAddStakeCoin, ResAddStakeCoin } from './staking/PtlAddStakeCoin';
import { ReqAddStakeNFT, ResAddStakeNFT } from './staking/PtlAddStakeNFT';
import { ReqAddStakePool, ResAddStakePool } from './staking/PtlAddStakePool';
import { ReqGetAreaInfo, ResGetAreaInfo } from './staking/PtlGetAreaInfo';
import { ReqGetPoolDetail, ResGetPoolDetail } from './staking/PtlGetPoolDetail';
import { ReqGetPoolList, ResGetPoolList } from './staking/PtlGetPoolList';
import { ReqGetRepairOrder, ResGetRepairOrder } from './staking/PtlGetRepairOrder';
import { ReqGetStakeAccounts, ResGetStakeAccounts } from './staking/PtlGetStakeAccounts';
import { ReqGetStakeCoin, ResGetStakeCoin } from './staking/PtlGetStakeCoin';
import { ReqGetStakeOrder, ResGetStakeOrder } from './staking/PtlGetStakeOrder';
import { ReqGetStakingNFT, ResGetStakingNFT } from './staking/PtlGetStakingNFT';
import { ReqGetUnStakeState, ResGetUnStakeState } from './staking/PtlGetUnStakeState';
import { ReqHarvest, ResHarvest } from './staking/PtlHarvest';
import { ReqRemoveStakeNFT, ResRemoveStakeNFT } from './staking/PtlRemoveStakeNFT';
import { ReqRepair, ResRepair } from './staking/PtlRepair';
import { ReqRepairStakeNFT, ResRepairStakeNFT } from './staking/PtlRepairStakeNFT';
import { ReqSetPoolShow, ResSetPoolShow } from './staking/PtlSetPoolShow';
import { ReqSetStakeArea, ResSetStakeArea } from './staking/PtlSetStakeArea';
import { ReqUnStakeNFT, ResUnStakeNFT } from './staking/PtlUnStakeNFT';
import { ReqUpdatePool, ResUpdatePool } from './staking/PtlUpdatePool';

export interface ServiceType {
    api: {
        "analyze/GetEventAll": {
            req: ReqGetEventAll,
            res: ResGetEventAll
        },
        "analyze/GetEventCount": {
            req: ReqGetEventCount,
            res: ResGetEventCount
        },
        "analyze/SendEvent": {
            req: ReqSendEvent,
            res: ResSendEvent
        },
        "auction/AddOfficialConfig": {
            req: ReqAddOfficialConfig,
            res: ResAddOfficialConfig
        },
        "auction/AddOfficialItem": {
            req: ReqAddOfficialItem,
            res: ResAddOfficialItem
        },
        "auction/Buy": {
            req: ReqBuy,
            res: ResBuy
        },
        "auction/BuyOfficialItem": {
            req: ReqBuyOfficialItem,
            res: ResBuyOfficialItem
        },
        "auction/CancelSell": {
            req: ReqCancelSell,
            res: ResCancelSell
        },
        "auction/GetBuyOrder": {
            req: ReqGetBuyOrder,
            res: ResGetBuyOrder
        },
        "auction/GetCancelSellOrder": {
            req: ReqGetCancelSellOrder,
            res: ResGetCancelSellOrder
        },
        "auction/GetOfficialItemOrder": {
            req: ReqGetOfficialItemOrder,
            res: ResGetOfficialItemOrder
        },
        "auction/GetOfficialMarketplace": {
            req: ReqGetOfficialMarketplace,
            res: ResGetOfficialMarketplace
        },
        "auction/GetSellOrder": {
            req: ReqGetSellOrder,
            res: ResGetSellOrder
        },
        "auction/GetSells": {
            req: ReqGetSells,
            res: ResGetSells
        },
        "auction/Sell": {
            req: ReqSell,
            res: ResSell
        },
        "auction/SetOfficial": {
            req: ReqSetOfficial,
            res: ResSetOfficial
        },
        "auction/ThirdPartySell": {
            req: ReqThirdPartySell,
            res: ResThirdPartySell
        },
        "market/GetCollections": {
            req: ReqGetCollections,
            res: ResGetCollections
        },
        "market/GetNFTByMint": {
            req: ReqGetNFTByMint,
            res: ResGetNFTByMint
        },
        "market/GetNFTListing": {
            req: ReqGetNFTListing,
            res: ResGetNFTListing
        },
        "market/GetProjectDetail": {
            req: ReqGetProjectDetail,
            res: ResGetProjectDetail
        },
        "market/GetProjectList": {
            req: ReqGetProjectList,
            res: ResGetProjectList
        },
        "market/GetProjectListing": {
            req: ReqGetProjectListing,
            res: ResGetProjectListing
        },
        "market/GetRecentListed": {
            req: ReqGetRecentListed,
            res: ResGetRecentListed
        },
        "market/GetScrollBanners": {
            req: ReqGetScrollBanners,
            res: ResGetScrollBanners
        },
        "CheckServerState": {
            req: ReqCheckServerState,
            res: ResCheckServerState
        },
        "settings/AddProject": {
            req: ReqAddProject,
            res: ResAddProject
        },
        "settings/AddProjectNFT": {
            req: ReqAddProjectNFT,
            res: ResAddProjectNFT
        },
        "settings/ChangeKeypair": {
            req: ReqChangeKeypair,
            res: ResChangeKeypair
        },
        "settings/GetAdmin": {
            req: ReqGetAdmin,
            res: ResGetAdmin
        },
        "settings/GetSettings": {
            req: ReqGetSettings,
            res: ResGetSettings
        },
        "settings/LoginAdmin": {
            req: ReqLoginAdmin,
            res: ResLoginAdmin
        },
        "settings/MintAndSendNFT": {
            req: ReqMintAndSendNFT,
            res: ResMintAndSendNFT
        },
        "settings/RefreshProjectHolders": {
            req: ReqRefreshProjectHolders,
            res: ResRefreshProjectHolders
        },
        "settings/RemoveStakeCoin": {
            req: ReqRemoveStakeCoin,
            res: ResRemoveStakeCoin
        },
        "settings/ResetPassword": {
            req: ReqResetPassword,
            res: ResResetPassword
        },
        "settings/SetAdmin": {
            req: ReqSetAdmin,
            res: ResSetAdmin
        },
        "settings/SetContractFee": {
            req: ReqSetContractFee,
            res: ResSetContractFee
        },
        "settings/UpdateProject": {
            req: ReqUpdateProject,
            res: ResUpdateProject
        },
        "staking/AddStakeCoin": {
            req: ReqAddStakeCoin,
            res: ResAddStakeCoin
        },
        "staking/AddStakeNFT": {
            req: ReqAddStakeNFT,
            res: ResAddStakeNFT
        },
        "staking/AddStakePool": {
            req: ReqAddStakePool,
            res: ResAddStakePool
        },
        "staking/GetAreaInfo": {
            req: ReqGetAreaInfo,
            res: ResGetAreaInfo
        },
        "staking/GetPoolDetail": {
            req: ReqGetPoolDetail,
            res: ResGetPoolDetail
        },
        "staking/GetPoolList": {
            req: ReqGetPoolList,
            res: ResGetPoolList
        },
        "staking/GetRepairOrder": {
            req: ReqGetRepairOrder,
            res: ResGetRepairOrder
        },
        "staking/GetStakeAccounts": {
            req: ReqGetStakeAccounts,
            res: ResGetStakeAccounts
        },
        "staking/GetStakeCoin": {
            req: ReqGetStakeCoin,
            res: ResGetStakeCoin
        },
        "staking/GetStakeOrder": {
            req: ReqGetStakeOrder,
            res: ResGetStakeOrder
        },
        "staking/GetStakingNFT": {
            req: ReqGetStakingNFT,
            res: ResGetStakingNFT
        },
        "staking/GetUnStakeState": {
            req: ReqGetUnStakeState,
            res: ResGetUnStakeState
        },
        "staking/Harvest": {
            req: ReqHarvest,
            res: ResHarvest
        },
        "staking/RemoveStakeNFT": {
            req: ReqRemoveStakeNFT,
            res: ResRemoveStakeNFT
        },
        "staking/Repair": {
            req: ReqRepair,
            res: ResRepair
        },
        "staking/RepairStakeNFT": {
            req: ReqRepairStakeNFT,
            res: ResRepairStakeNFT
        },
        "staking/SetPoolShow": {
            req: ReqSetPoolShow,
            res: ResSetPoolShow
        },
        "staking/SetStakeArea": {
            req: ReqSetStakeArea,
            res: ResSetStakeArea
        },
        "staking/UnStakeNFT": {
            req: ReqUnStakeNFT,
            res: ResUnStakeNFT
        },
        "staking/UpdatePool": {
            req: ReqUpdatePool,
            res: ResUpdatePool
        }
    },
    msg: {

    }
}

export const serviceProto: ServiceProto<ServiceType> = {
    "version": 125,
    "services": [
        {
            "id": 71,
            "name": "analyze/GetEventAll",
            "type": "api",
            "conf": {}
        },
        {
            "id": 72,
            "name": "analyze/GetEventCount",
            "type": "api",
            "conf": {}
        },
        {
            "id": 73,
            "name": "analyze/SendEvent",
            "type": "api",
            "conf": {}
        },
        {
            "id": 42,
            "name": "auction/AddOfficialConfig",
            "type": "api"
        },
        {
            "id": 14,
            "name": "auction/AddOfficialItem",
            "type": "api"
        },
        {
            "id": 2,
            "name": "auction/Buy",
            "type": "api"
        },
        {
            "id": 15,
            "name": "auction/BuyOfficialItem",
            "type": "api"
        },
        {
            "id": 5,
            "name": "auction/CancelSell",
            "type": "api"
        },
        {
            "id": 19,
            "name": "auction/GetBuyOrder",
            "type": "api"
        },
        {
            "id": 25,
            "name": "auction/GetCancelSellOrder",
            "type": "api"
        },
        {
            "id": 16,
            "name": "auction/GetOfficialItemOrder",
            "type": "api"
        },
        {
            "id": 17,
            "name": "auction/GetOfficialMarketplace",
            "type": "api"
        },
        {
            "id": 18,
            "name": "auction/GetSellOrder",
            "type": "api"
        },
        {
            "id": 10,
            "name": "auction/GetSells",
            "type": "api"
        },
        {
            "id": 3,
            "name": "auction/Sell",
            "type": "api"
        },
        {
            "id": 45,
            "name": "auction/SetOfficial",
            "type": "api"
        },
        {
            "id": 41,
            "name": "auction/ThirdPartySell",
            "type": "api"
        },
        {
            "id": 26,
            "name": "market/GetCollections",
            "type": "api"
        },
        {
            "id": 37,
            "name": "market/GetNFTByMint",
            "type": "api"
        },
        {
            "id": 36,
            "name": "market/GetNFTListing",
            "type": "api"
        },
        {
            "id": 35,
            "name": "market/GetProjectDetail",
            "type": "api"
        },
        {
            "id": 29,
            "name": "market/GetProjectList",
            "type": "api",
            "conf": {}
        },
        {
            "id": 32,
            "name": "market/GetProjectListing",
            "type": "api"
        },
        {
            "id": 30,
            "name": "market/GetRecentListed",
            "type": "api"
        },
        {
            "id": 34,
            "name": "market/GetScrollBanners",
            "type": "api"
        },
        {
            "id": 12,
            "name": "CheckServerState",
            "type": "api"
        },
        {
            "id": 20,
            "name": "settings/AddProject",
            "type": "api"
        },
        {
            "id": 40,
            "name": "settings/AddProjectNFT",
            "type": "api"
        },
        {
            "id": 31,
            "name": "settings/ChangeKeypair",
            "type": "api"
        },
        {
            "id": 39,
            "name": "settings/GetAdmin",
            "type": "api"
        },
        {
            "id": 6,
            "name": "settings/GetSettings",
            "type": "api"
        },
        {
            "id": 7,
            "name": "settings/LoginAdmin",
            "type": "api"
        },
        {
            "id": 43,
            "name": "settings/MintAndSendNFT",
            "type": "api"
        },
        {
            "id": 38,
            "name": "settings/RefreshProjectHolders",
            "type": "api",
            "conf": {}
        },
        {
            "id": 57,
            "name": "settings/RemoveStakeCoin",
            "type": "api",
            "conf": {}
        },
        {
            "id": 9,
            "name": "settings/ResetPassword",
            "type": "api"
        },
        {
            "id": 8,
            "name": "settings/SetAdmin",
            "type": "api"
        },
        {
            "id": 44,
            "name": "settings/SetContractFee",
            "type": "api"
        },
        {
            "id": 33,
            "name": "settings/UpdateProject",
            "type": "api"
        },
        {
            "id": 47,
            "name": "staking/AddStakeCoin",
            "type": "api"
        },
        {
            "id": 54,
            "name": "staking/AddStakeNFT",
            "type": "api"
        },
        {
            "id": 49,
            "name": "staking/AddStakePool",
            "type": "api"
        },
        {
            "id": 52,
            "name": "staking/GetAreaInfo",
            "type": "api"
        },
        {
            "id": 66,
            "name": "staking/GetPoolDetail",
            "type": "api"
        },
        {
            "id": 50,
            "name": "staking/GetPoolList",
            "type": "api"
        },
        {
            "id": 64,
            "name": "staking/GetRepairOrder",
            "type": "api",
            "conf": {}
        },
        {
            "id": 53,
            "name": "staking/GetStakeAccounts",
            "type": "api",
            "conf": {}
        },
        {
            "id": 59,
            "name": "staking/GetStakeCoin",
            "type": "api"
        },
        {
            "id": 56,
            "name": "staking/GetStakeOrder",
            "type": "api"
        },
        {
            "id": 60,
            "name": "staking/GetStakingNFT",
            "type": "api"
        },
        {
            "id": 67,
            "name": "staking/GetUnStakeState",
            "type": "api",
            "conf": {}
        },
        {
            "id": 69,
            "name": "staking/Harvest",
            "type": "api"
        },
        {
            "id": 58,
            "name": "staking/RemoveStakeNFT",
            "type": "api",
            "conf": {}
        },
        {
            "id": 65,
            "name": "staking/Repair",
            "type": "api",
            "conf": {}
        },
        {
            "id": 61,
            "name": "staking/RepairStakeNFT",
            "type": "api"
        },
        {
            "id": 70,
            "name": "staking/SetPoolShow",
            "type": "api"
        },
        {
            "id": 51,
            "name": "staking/SetStakeArea",
            "type": "api"
        },
        {
            "id": 68,
            "name": "staking/UnStakeNFT",
            "type": "api",
            "conf": {}
        },
        {
            "id": 55,
            "name": "staking/UpdatePool",
            "type": "api"
        }
    ],
    "types": {
        "analyze/PtlGetEventAll/ReqGetEventAll": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ]
        },
        "base/BaseRequest": {
            "type": "Interface"
        },
        "analyze/PtlGetEventAll/ResGetEventAll": {
            "type": "Interface",
            "extends": [
                {
                    "id": 1,
                    "type": {
                        "type": "Partial",
                        "target": {
                            "type": "Reference",
                            "target": "db/DbAnalyze/DbAnalyze"
                        }
                    }
                }
            ]
        },
        "db/DbAnalyze/DbAnalyze": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "_id",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "Env",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "Home",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 7,
                    "name": "Stake",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "MarketPlace",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "GameStore",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 8,
                    "name": "HomeConnectWallet",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 9,
                    "name": "StakeConnectWallet",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "MarketPlaceConnectWallet",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "GameStoreConnectWallet",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "analyze/PtlGetEventCount/ReqGetEventCount": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "event",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "analyze/PtlGetEventCount/ResGetEventCount": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "base/BaseResponse": {
            "type": "Interface"
        },
        "analyze/PtlSendEvent/ReqSendEvent": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "event",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "analyze/PtlSendEvent/ResSendEvent": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "auction/PtlAddOfficialConfig/ReqAddOfficialConfig": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "configUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "nftListUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "auction/PtlAddOfficialConfig/ResAddOfficialConfig": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlAddOfficialItem/ReqAddOfficialItem": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "items",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 7,
                                    "name": "name",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 8,
                                    "name": "uri",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 0,
                                    "name": "id",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 1,
                                    "name": "category",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 2,
                                    "name": "supply",
                                    "type": {
                                        "type": "Number"
                                    }
                                },
                                {
                                    "id": 3,
                                    "name": "price",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 4,
                                    "name": "chain",
                                    "type": {
                                        "type": "Reference",
                                        "target": "types/ChainType"
                                    }
                                },
                                {
                                    "id": 5,
                                    "name": "spl",
                                    "type": {
                                        "type": "Reference",
                                        "target": "types/SPLTokenType"
                                    }
                                },
                                {
                                    "id": 6,
                                    "name": "isOnline",
                                    "type": {
                                        "type": "Boolean"
                                    }
                                },
                                {
                                    "id": 9,
                                    "name": "nfts",
                                    "type": {
                                        "type": "Array",
                                        "elementType": {
                                            "type": "String"
                                        }
                                    }
                                }
                            ]
                        }
                    }
                },
                {
                    "id": 3,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "types/ChainType": {
            "type": "Union",
            "members": [
                {
                    "id": 0,
                    "type": {
                        "type": "Literal",
                        "literal": "SOL"
                    }
                },
                {
                    "id": 2,
                    "type": {
                        "type": "Literal",
                        "literal": "BSC"
                    }
                }
            ]
        },
        "types/SPLTokenType": {
            "type": "Union",
            "members": [
                {
                    "id": 0,
                    "type": {
                        "type": "Literal",
                        "literal": "SOL"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Literal",
                        "literal": "SYP"
                    }
                },
                {
                    "id": 2,
                    "type": {
                        "type": "Literal",
                        "literal": "USDC"
                    }
                },
                {
                    "id": 3,
                    "type": {
                        "type": "Literal",
                        "literal": "MITCOIN"
                    }
                },
                {
                    "id": 4,
                    "type": {
                        "type": "Literal",
                        "literal": "MBTT"
                    }
                },
                {
                    "id": 7,
                    "type": {
                        "type": "Literal",
                        "literal": "BNB"
                    }
                }
            ]
        },
        "auction/PtlAddOfficialItem/ResAddOfficialItem": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlBuy/ReqBuy": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbItemID/WithOrderID"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "buyer",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "transaction",
                    "type": {
                        "type": "Buffer"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "contractOrderNo",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "db/DbItemID/WithOrderID": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "orderId",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    }
                }
            ]
        },
        "auction/PtlBuy/ResBuy": {
            "type": "Interface",
            "properties": [
                {
                    "id": 3,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "txId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlBuyOfficialItem/ReqBuyOfficialItem": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbItemID/WithItemOrderID"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "buyer",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "transaction",
                    "type": {
                        "type": "Buffer"
                    }
                },
                {
                    "id": 4,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "db/DbItemID/WithItemOrderID": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "itemId",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    }
                },
                {
                    "id": 1,
                    "name": "orderId",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    }
                }
            ]
        },
        "auction/PtlBuyOfficialItem/ResBuyOfficialItem": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "txId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlCancelSell/ReqCancelSell": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbItemID/WithOrderID"
                    }
                }
            ],
            "properties": [
                {
                    "id": 4,
                    "name": "transaction",
                    "type": {
                        "type": "Buffer"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlCancelSell/ResCancelSell": {
            "type": "Interface",
            "properties": [
                {
                    "id": 3,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "txId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlGetBuyOrder/ReqGetBuyOrder": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "buyer",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "price",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "spl",
                    "type": {
                        "type": "Reference",
                        "target": "types/SPLTokenType"
                    }
                },
                {
                    "id": 4,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 5,
                    "name": "category",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlGetBuyOrder/ResGetBuyOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbItemID/WithOrderID"
                    }
                }
            ],
            "properties": [
                {
                    "id": 1,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 0,
                    "name": "transaction",
                    "type": {
                        "type": "Buffer"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "contractOrderNo",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "auction/PtlGetCancelSellOrder/ReqGetCancelSellOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlGetCancelSellOrder/ResGetCancelSellOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbItemID/WithOrderID"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "transaction",
                    "type": {
                        "type": "Buffer"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "contractOrderNo",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "auction/PtlGetOfficialItemOrder/ReqGetOfficialItemOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 2,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "base/OfficialItem"
                        },
                        "keys": [
                            "supply",
                            "nft",
                            "soldNfts"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "buyer",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "base/OfficialItem": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "category",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "spl",
                    "type": {
                        "type": "Reference",
                        "target": "types/SPLTokenType"
                    }
                },
                {
                    "id": 3,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 4,
                    "name": "supply",
                    "type": {
                        "type": "Tuple",
                        "elementTypes": [
                            {
                                "type": "Number"
                            },
                            {
                                "type": "Number"
                            }
                        ]
                    }
                },
                {
                    "id": 5,
                    "name": "price",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "soldNfts",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                },
                {
                    "id": 9,
                    "name": "nft",
                    "type": {
                        "type": "Reference",
                        "target": "db/DbNFT/DbNFT"
                    },
                    "optional": true
                }
            ]
        },
        "db/DbNFT/DbNFT": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "_id",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "uri",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "data",
                    "type": {
                        "type": "Reference",
                        "target": "db/DbNFTDetail/DbNFTDetail"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 6,
                    "name": "category",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 11,
                    "name": "commodityHistory",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "auction/Commodity/Commodity"
                        }
                    },
                    "optional": true
                },
                {
                    "id": 12,
                    "name": "order",
                    "type": {
                        "type": "Reference",
                        "target": "auction/Commodity/Commodity"
                    }
                }
            ]
        },
        "db/DbNFTDetail/DbNFTDetail": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "image",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "img",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "name",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "desc",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "description",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "symbol",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "seller_fee_basis_points",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 8,
                    "name": "tokenStandard",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "collection",
                                "type": {
                                    "type": "Interface",
                                    "properties": [
                                        {
                                            "id": 0,
                                            "name": "verified",
                                            "type": {
                                                "type": "Number"
                                            },
                                            "optional": true
                                        },
                                        {
                                            "id": 1,
                                            "name": "key",
                                            "type": {
                                                "type": "String"
                                            },
                                            "optional": true
                                        }
                                    ]
                                },
                                "optional": true
                            }
                        ]
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "attributes",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 0,
                                    "name": "trait_type",
                                    "type": {
                                        "type": "String"
                                    },
                                    "optional": true
                                },
                                {
                                    "id": 1,
                                    "name": "value",
                                    "type": {
                                        "type": "Union",
                                        "members": [
                                            {
                                                "id": 0,
                                                "type": {
                                                    "type": "String"
                                                }
                                            },
                                            {
                                                "id": 1,
                                                "type": {
                                                    "type": "Number"
                                                }
                                            }
                                        ]
                                    },
                                    "optional": true
                                }
                            ]
                        }
                    },
                    "optional": true
                },
                {
                    "id": 9,
                    "name": "FieldID",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 10,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "auction/Commodity/Commodity": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 14,
                    "name": "contractOrderNo",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "createdTimeUTC",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 12,
                    "name": "soldTimeUTC",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "canceledTimeUTC",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "isOfficial",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 5,
                    "name": "price",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "seller",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "buyer",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "tradeState",
                    "type": {
                        "type": "Reference",
                        "target": "auction/Commodity/TradeState"
                    }
                },
                {
                    "id": 13,
                    "name": "spl",
                    "type": {
                        "type": "Reference",
                        "target": "types/SPLTokenType"
                    }
                }
            ]
        },
        "auction/Commodity/TradeState": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": 0
                },
                {
                    "id": 1,
                    "value": 1
                },
                {
                    "id": 2,
                    "value": 2
                },
                {
                    "id": 3,
                    "value": 3
                },
                {
                    "id": 4,
                    "value": 4
                },
                {
                    "id": 5,
                    "value": 5
                }
            ]
        },
        "auction/PtlGetOfficialItemOrder/ResGetOfficialItemOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbItemID/WithItemOrderID"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "transaction",
                    "type": {
                        "type": "Buffer"
                    }
                }
            ]
        },
        "auction/PtlGetOfficialMarketplace/ReqGetOfficialMarketplace": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 1,
                    "name": "category",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "needNft",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 4,
                    "name": "id",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "auction/PtlGetOfficialMarketplace/ResGetOfficialMarketplace": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "items",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "base/OfficialItem"
                        }
                    }
                }
            ]
        },
        "auction/PtlGetSellOrder/ReqGetSellOrder": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 1,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "category",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "spl",
                    "type": {
                        "type": "Reference",
                        "target": "types/SPLTokenType"
                    }
                },
                {
                    "id": 5,
                    "name": "seller",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "price",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlGetSellOrder/ResGetSellOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbItemID/WithOrderID"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "transaction",
                    "type": {
                        "type": "Buffer"
                    },
                    "optional": true
                }
            ]
        },
        "auction/PtlGetSells/ReqGetSells": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "filter",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 4,
                                "name": "id",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 0,
                                "name": "name",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 1,
                                "name": "category",
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 2,
                                "name": "chain",
                                "type": {
                                    "type": "Reference",
                                    "target": "types/ChainType"
                                }
                            },
                            {
                                "id": 5,
                                "name": "spl",
                                "type": {
                                    "type": "Reference",
                                    "target": "types/SPLTokenType"
                                }
                            },
                            {
                                "id": 3,
                                "name": "price",
                                "type": {
                                    "type": "Tuple",
                                    "elementTypes": [
                                        {
                                            "type": "Number"
                                        },
                                        {
                                            "type": "Number"
                                        }
                                    ]
                                },
                                "optional": true
                            },
                            {
                                "id": 6,
                                "name": "project",
                                "type": {
                                    "type": "String"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 3,
                    "name": "needHistory",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 2,
                    "name": "mustSell",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Tuple",
                        "elementTypes": [
                            {
                                "type": "Number"
                            },
                            {
                                "type": "Number"
                            }
                        ]
                    },
                    "optional": true
                }
            ]
        },
        "auction/PtlGetSells/ResGetSells": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "db/DbNFT/DbNFT"
                        }
                    }
                }
            ]
        },
        "auction/PtlSell/ReqSell": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbItemID/WithOrderID"
                    }
                }
            ],
            "properties": [
                {
                    "id": 2,
                    "name": "seller",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "order",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "transaction",
                    "type": {
                        "type": "Buffer"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "contractOrder",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlSell/ResSell": {
            "type": "Interface",
            "properties": [
                {
                    "id": 3,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "txId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlSetOfficial/ReqSetOfficial": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "mintUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "showUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "sellUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "sendUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "addUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "auction/PtlSetOfficial/ResSetOfficial": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlThirdPartySell/ReqThirdPartySell": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "url",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "auction/PtlThirdPartySell/ResThirdPartySell": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "market/PtlGetCollections/ReqGetCollections": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "type",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "Literal",
                                    "literal": "recommend"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal",
                                    "literal": "all"
                                }
                            }
                        ]
                    }
                }
            ]
        },
        "market/PtlGetCollections/ResGetCollections": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "market/PtlGetCollections/CollectionIntro"
                        }
                    }
                }
            ]
        },
        "market/PtlGetCollections/CollectionIntro": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "developer",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "imageUrl",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "market/PtlGetNFTByMint/ReqGetNFTByMint": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "owner",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "mint",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                }
            ]
        },
        "market/PtlGetNFTByMint/ResGetNFTByMint": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "owned",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "db/DbNFT/DbNFT"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "listed",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "db/DbNFT/DbNFT"
                        }
                    }
                },
                {
                    "id": 2,
                    "name": "unverified",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                }
            ]
        },
        "market/PtlGetNFTListing/ReqGetNFTListing": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "market/PtlGetNFTListing/ResGetNFTListing": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbNFT/DbNFT"
                    }
                }
            ]
        },
        "market/PtlGetProjectDetail/ReqGetProjectDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "market/PtlGetProjectDetail/ResGetProjectDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbProject/DbProject"
                    }
                }
            ]
        },
        "db/DbProject/DbProject": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "_id",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    },
                    "optional": true
                },
                {
                    "id": 14,
                    "name": "contractNo",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 11,
                    "name": "recommendPos",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 10,
                    "name": "createTime",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "gameIconUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "collectionImageUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "playUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 12,
                    "name": "isOnline",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 6,
                    "name": "home",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "imageUrl",
                                "type": {
                                    "type": "String"
                                }
                            }
                        ]
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "games",
                    "type": {
                        "type": "Reference",
                        "target": "db/DbProject/IProjectGames"
                    },
                    "optional": true
                },
                {
                    "id": 8,
                    "name": "market",
                    "type": {
                        "type": "Reference",
                        "target": "db/DbProject/IProjectMarket"
                    },
                    "optional": true
                },
                {
                    "id": 9,
                    "name": "auction",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 5,
                                "name": "updateAuthority",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 0,
                                "name": "transactionFee",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 1,
                                "name": "listingFee",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 6,
                                "name": "nftListUrl",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 2,
                                "name": "nftList",
                                "type": {
                                    "type": "Array",
                                    "elementType": {
                                        "type": "String"
                                    }
                                },
                                "optional": true
                            },
                            {
                                "id": 3,
                                "name": "onChainCollection",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 4,
                                "name": "hashListAddress",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            }
                        ]
                    },
                    "optional": true
                },
                {
                    "id": 13,
                    "name": "analyze",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "floorPrice",
                                "type": {
                                    "type": "Number"
                                },
                                "optional": true
                            },
                            {
                                "id": 1,
                                "name": "totalVolume",
                                "type": {
                                    "type": "Number"
                                },
                                "optional": true
                            },
                            {
                                "id": 6,
                                "name": "totalPurchased",
                                "type": {
                                    "type": "Number"
                                },
                                "optional": true
                            },
                            {
                                "id": 3,
                                "name": "totalListed",
                                "type": {
                                    "type": "Number"
                                },
                                "optional": true
                            },
                            {
                                "id": 4,
                                "name": "totalSupply",
                                "type": {
                                    "type": "Number"
                                },
                                "optional": true
                            },
                            {
                                "id": 5,
                                "name": "uniqueHolders",
                                "type": {
                                    "type": "Number"
                                },
                                "optional": true
                            }
                        ]
                    },
                    "optional": true
                },
                {
                    "id": 15,
                    "name": "staking",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "keyPair",
                                "type": {
                                    "type": "String"
                                }
                            }
                        ]
                    },
                    "optional": true
                }
            ]
        },
        "db/DbProject/IProjectGames": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "titleImage",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "description",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "genre",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "developer",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "platform",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "developmentStatus",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "videoUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "videoPreviewImageUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "previewImageUrls",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                }
            ]
        },
        "db/DbProject/IProjectMarket": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "description",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "bannerImageUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "homeUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "twitterUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "discordUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "telegramUrl",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "market/PtlGetProjectList/ReqGetProjectList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "filter",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "Literal",
                                    "literal": "base"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal",
                                    "literal": "game"
                                }
                            },
                            {
                                "id": 2,
                                "type": {
                                    "type": "Literal",
                                    "literal": "market"
                                }
                            },
                            {
                                "id": 3,
                                "type": {
                                    "type": "Literal",
                                    "literal": "full"
                                }
                            }
                        ]
                    }
                }
            ]
        },
        "market/PtlGetProjectList/ResGetProjectList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "db/DbProject/DbProject"
                        }
                    }
                }
            ]
        },
        "market/PtlGetProjectListing/ReqGetProjectListing": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "project",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "start",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "market/PtlGetProjectListing/ResGetProjectListing": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "db/DbNFT/DbNFT"
                        }
                    }
                }
            ]
        },
        "market/PtlGetRecentListed/ReqGetRecentListed": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ]
        },
        "market/PtlGetRecentListed/ResGetRecentListed": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "market/PtlGetRecentListed/GameIconIntro"
                        }
                    }
                }
            ]
        },
        "market/PtlGetRecentListed/GameIconIntro": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "iconUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "market/PtlGetScrollBanners/ReqGetScrollBanners": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "market/PtlGetScrollBanners/ResGetScrollBanners": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 0,
                                    "name": "url",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 1,
                                    "name": "project",
                                    "type": {
                                        "type": "String"
                                    }
                                }
                            ]
                        }
                    }
                }
            ]
        },
        "PtlCheckServerState/ReqCheckServerState": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlCheckServerState/ResCheckServerState": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "state",
                    "type": {
                        "type": "Reference",
                        "target": "types/ServerState"
                    }
                },
                {
                    "id": 1,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "error",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "types/ServerState": {
            "type": "Union",
            "members": [
                {
                    "id": 0,
                    "type": {
                        "type": "Literal",
                        "literal": "free"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Literal",
                        "literal": "addingNFT"
                    }
                },
                {
                    "id": 2,
                    "type": {
                        "type": "Literal",
                        "literal": "loadingNFT"
                    }
                },
                {
                    "id": 3,
                    "type": {
                        "type": "Literal",
                        "literal": "sellingNFT"
                    }
                },
                {
                    "id": 4,
                    "type": {
                        "type": "Literal",
                        "literal": "mintingNFT"
                    }
                },
                {
                    "id": 5,
                    "type": {
                        "type": "Literal",
                        "literal": "sendingNFT"
                    }
                }
            ]
        },
        "settings/PtlAddProject/ReqAddProject": {
            "type": "Interface",
            "extends": [
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbProject/DbProject"
                    }
                }
            ],
            "properties": [
                {
                    "id": 7,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlAddProject/ResAddProject": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlAddProjectNFT/ReqAddProjectNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "nftGetAddress",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "hasListAddress",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "onChainCollection",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "settings/PtlAddProjectNFT/ResAddProjectNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlChangeKeypair/ReqChangeKeypair": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "OfficialAuctionKeyPair",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "ThirdAuctionKeyPair",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "PlayerAuctionKeyPair",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "settings/PtlChangeKeypair/ResChangeKeypair": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlGetAdmin/ReqGetAdmin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlGetAdmin/ResGetAdmin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "settings/Settings/Settings"
                    }
                }
            ]
        },
        "settings/Settings/Settings": {
            "type": "Interface",
            "properties": [
                {
                    "id": 21,
                    "name": "Account",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "UserName",
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "name": "Password",
                                "type": {
                                    "type": "String"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 14,
                    "name": "ThirdAuctionKeyPair",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 15,
                    "name": "PlayerAuctionKeyPair",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 16,
                    "name": "OfficialAuctionKeyPair",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 25,
                    "name": "StakingCostAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 22,
                    "name": "Chains",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "BSC",
                                "type": {
                                    "type": "Interface",
                                    "properties": [
                                        {
                                            "id": 0,
                                            "name": "ThirdAuctionPrivatekey",
                                            "type": {
                                                "type": "String"
                                            }
                                        },
                                        {
                                            "id": 1,
                                            "name": "ThirdAuctionContract",
                                            "type": {
                                                "type": "String"
                                            }
                                        },
                                        {
                                            "id": 2,
                                            "name": "PlayerAuctionPrivatekey",
                                            "type": {
                                                "type": "String"
                                            }
                                        },
                                        {
                                            "id": 3,
                                            "name": "PlayerAuctionContract",
                                            "type": {
                                                "type": "String"
                                            }
                                        },
                                        {
                                            "id": 6,
                                            "name": "StakingContractAddress",
                                            "type": {
                                                "type": "String"
                                            }
                                        }
                                    ]
                                }
                            }
                        ],
                        "indexSignature": {
                            "keyType": "String",
                            "type": {
                                "type": "Interface",
                                "properties": [
                                    {
                                        "id": 0,
                                        "name": "ThirdAuctionPrivatekey",
                                        "type": {
                                            "type": "String"
                                        }
                                    },
                                    {
                                        "id": 1,
                                        "name": "ThirdAuctionContract",
                                        "type": {
                                            "type": "String"
                                        }
                                    },
                                    {
                                        "id": 2,
                                        "name": "PlayerAuctionPrivatekey",
                                        "type": {
                                            "type": "String"
                                        }
                                    },
                                    {
                                        "id": 3,
                                        "name": "PlayerAuctionContract",
                                        "type": {
                                            "type": "String"
                                        }
                                    },
                                    {
                                        "id": 6,
                                        "name": "StakingContractAddress",
                                        "type": {
                                            "type": "String"
                                        }
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    "id": 23,
                    "name": "Configs",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "MapConfigURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 1,
                                "name": "ItemConfigURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 2,
                                "name": "ConfigTypeURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 3,
                                "name": "MapMetaURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 4,
                                "name": "ItemMetaURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 5,
                                "name": "CountryTagURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 6,
                                "name": "ProvinceTagURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 7,
                                "name": "CityTagURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 8,
                                "name": "WhiteListURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            }
                        ]
                    }
                },
                {
                    "id": 6,
                    "name": "WhiteList",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                },
                {
                    "id": 7,
                    "name": "WhiteListOnly",
                    "type": {
                        "type": "Boolean"
                    }
                }
            ],
            "indexSignature": {
                "keyType": "String",
                "type": {
                    "type": "Any"
                }
            }
        },
        "settings/PtlGetSettings/ReqGetSettings": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlGetSettings/ResGetSettings": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "Configs",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "MapConfigURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 1,
                                "name": "ItemConfigURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 2,
                                "name": "ConfigTypeURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 3,
                                "name": "CountryTagURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 4,
                                "name": "ProvinceTagURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            },
                            {
                                "id": 5,
                                "name": "CityTagURL",
                                "type": {
                                    "type": "String"
                                },
                                "optional": true
                            }
                        ]
                    }
                },
                {
                    "id": 2,
                    "name": "StakingCostAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "Chains",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "BSC",
                                "type": {
                                    "type": "Interface",
                                    "properties": [
                                        {
                                            "id": 0,
                                            "name": "ThirdAuctionContract",
                                            "type": {
                                                "type": "String"
                                            }
                                        },
                                        {
                                            "id": 1,
                                            "name": "PlayerAuctionContract",
                                            "type": {
                                                "type": "String"
                                            }
                                        },
                                        {
                                            "id": 2,
                                            "name": "StakingContractAddress",
                                            "type": {
                                                "type": "String"
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 3,
                    "name": "WhiteList",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                },
                {
                    "id": 4,
                    "name": "WhiteListOnly",
                    "type": {
                        "type": "Boolean"
                    }
                }
            ]
        },
        "settings/PtlLoginAdmin/ReqLoginAdmin": {
            "type": "Interface",
            "properties": [
                {
                    "id": 2,
                    "name": "auth",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "name",
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "name": "password",
                                "type": {
                                    "type": "String"
                                }
                            }
                        ]
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "token",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "settings/PtlLoginAdmin/ResLoginAdmin": {
            "type": "Interface",
            "properties": [
                {
                    "id": 2,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlMintAndSendNFT/ReqMintAndSendNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "configUrl",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlMintAndSendNFT/ResMintAndSendNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlRefreshProjectHolders/ReqRefreshProjectHolders": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlRefreshProjectHolders/ResRefreshProjectHolders": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "settings/PtlRemoveStakeCoin/ReqRemoveStakeCoin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlRemoveStakeCoin/ResRemoveStakeCoin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "settings/PtlResetPassword/ReqResetPassword": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "oldPassword",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "newPassword",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlResetPassword/ResResetPassword": {
            "type": "Interface",
            "properties": [
                {
                    "id": 2,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlSetAdmin/ReqSetAdmin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 1,
                    "type": {
                        "type": "Partial",
                        "target": {
                            "type": "Reference",
                            "target": "settings/Settings/Settings"
                        }
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlSetAdmin/ResSetAdmin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "settings/Settings/Settings"
                    }
                }
            ]
        },
        "settings/PtlSetContractFee/ReqSetContractFee": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "project",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "listingFee",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "transactionFee",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlSetContractFee/ResSetContractFee": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "settings/PtlUpdateProject/ReqUpdateProject": {
            "type": "Interface",
            "extends": [
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Partial",
                            "target": {
                                "type": "Reference",
                                "target": "db/DbProject/DbProject"
                            }
                        },
                        "keys": [
                            "games",
                            "market"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "_id",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    }
                },
                {
                    "id": 2,
                    "name": "games",
                    "type": {
                        "type": "Partial",
                        "target": {
                            "type": "Reference",
                            "target": "db/DbProject/IProjectGames"
                        }
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "market",
                    "type": {
                        "type": "Partial",
                        "target": {
                            "type": "Reference",
                            "target": "db/DbProject/IProjectMarket"
                        }
                    },
                    "optional": true
                }
            ]
        },
        "settings/PtlUpdateProject/ResUpdateProject": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "staking/PtlAddStakeCoin/ReqAddStakeCoin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "db/DbStaking/DbStakeCoin"
                    }
                }
            ],
            "properties": [
                {
                    "id": 4,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "db/DbStaking/DbStakeCoin": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "_id",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "symbol",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "imgUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 6,
                    "name": "decimal",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "staking/PtlAddStakeCoin/ResAddStakeCoin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "staking/PtlAddStakeNFT/ReqAddStakeNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 7,
                    "name": "orderId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "hashAddress",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlAddStakeNFT/ResAddStakeNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "staking/PtlAddStakePool/ReqAddStakePool": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "area",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 16,
                    "name": "iconUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 10,
                    "name": "imgUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 11,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 12,
                    "name": "durabilityLoss",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 17,
                    "name": "supportChain",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "openUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 5,
                    "name": "closeUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 6,
                    "name": "rewardUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 7,
                    "name": "landEffectEndUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 13,
                    "name": "officialRewardFee",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 14,
                    "name": "commissionRate",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 15,
                    "name": "stakeFeeRate",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "officialConfigURL",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "partnerConfigURL",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "generateConfigURL",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlAddStakePool/ResAddStakePool": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "walletAddress",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 0,
                                    "name": "chain",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 1,
                                    "name": "address",
                                    "type": {
                                        "type": "String"
                                    }
                                }
                            ]
                        }
                    }
                }
            ]
        },
        "staking/PtlGetAreaInfo/ReqGetAreaInfo": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ]
        },
        "staking/PtlGetAreaInfo/ResGetAreaInfo": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/PtlGetAreaInfo/IAreaSummary"
                        }
                    }
                }
            ]
        },
        "staking/PtlGetAreaInfo/IAreaSummary": {
            "type": "Interface",
            "extends": [
                {
                    "id": 3,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakingArea"
                        },
                        "keys": [
                            "generator"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "poolImages",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                },
                {
                    "id": 3,
                    "name": "generator",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 2,
                                    "name": "address",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 0,
                                    "name": "closedGenerated",
                                    "type": {
                                        "type": "Number"
                                    }
                                },
                                {
                                    "id": 1,
                                    "name": "totalGenerated",
                                    "type": {
                                        "type": "Number"
                                    }
                                }
                            ]
                        }
                    }
                }
            ]
        },
        "staking/Staking/IStakingArea": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "imgUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "pool",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "landStaked",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "nftStaked",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 8,
                    "name": "generator",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakingAreaReward"
                        }
                    }
                }
            ]
        },
        "staking/Staking/IStakingAreaReward": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "closedGenerated",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 2,
                    "name": "totalGenerated",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                }
            ]
        },
        "staking/PtlGetPoolDetail/ReqGetPoolDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlGetPoolDetail/ResGetPoolDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "staking/PtlGetPoolList/IStakingPoolSummary"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "bscWallet",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "solWallet",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlGetPoolList/IStakingPoolSummary": {
            "type": "Interface",
            "extends": [
                {
                    "id": 2,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakingPool"
                        },
                        "keys": [
                            "generator",
                            "totalForce",
                            "totalBonus",
                            "bscWallet",
                            "solWallet"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 1,
                    "name": "totalForce",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "totalBonus",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "generator",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/PtlGetPoolList/IStakingPoolRewardSummary"
                        }
                    }
                },
                {
                    "id": 8,
                    "name": "bscStakingAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "solStakingAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "bscWallet",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "solWallet",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "staking/Staking/IStakingPool": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "area",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 33,
                    "name": "isShow",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 27,
                    "name": "iconUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "imgUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "durabilityLoss",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 34,
                    "name": "supportChain",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "state",
                    "type": {
                        "type": "Reference",
                        "target": "staking/Staking/StakingPoolState"
                    }
                },
                {
                    "id": 10,
                    "name": "openUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 11,
                    "name": "closeUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 12,
                    "name": "rewardUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 13,
                    "name": "landEffectEndUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 14,
                    "name": "officialRewardFee",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 15,
                    "name": "commissionRate",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 24,
                    "name": "stakeFeeRate",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 17,
                    "name": "updateTime",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 19,
                    "name": "totalForce",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 20,
                    "name": "totalBonus",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 21,
                    "name": "stakedCount",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 22,
                    "name": "generator",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakingPoolReward"
                        }
                    }
                },
                {
                    "id": 25,
                    "name": "solWallet",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 26,
                    "name": "bscWallet",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 28,
                    "name": "computerStaked",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 29,
                    "name": "landStaked",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 30,
                    "name": "otherStaked",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 31,
                    "name": "partnerStaked",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 32,
                    "name": "tokenStaked",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 35,
                    "name": "realForce",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    },
                    "optional": true
                }
            ]
        },
        "staking/Staking/StakingPoolState": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": -1
                },
                {
                    "id": 1,
                    "value": 0
                },
                {
                    "id": 2,
                    "value": 1
                },
                {
                    "id": 3,
                    "value": 2
                },
                {
                    "id": 4,
                    "value": 3
                }
            ]
        },
        "staking/Staking/IStakingPoolReward": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "commissionAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "generateType",
                    "type": {
                        "type": "Reference",
                        "target": "staking/Staking/StakingGenerateType"
                    }
                },
                {
                    "id": 2,
                    "name": "produceLimit1",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 3,
                    "name": "produceLimit2",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 4,
                    "name": "totalGenerated",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 5,
                    "name": "reward",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                }
            ]
        },
        "staking/Staking/StakingGenerateType": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": -1
                },
                {
                    "id": 1,
                    "value": 0
                },
                {
                    "id": 2,
                    "value": 1
                }
            ]
        },
        "staking/PtlGetPoolList/IStakingPoolRewardSummary": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "commissionAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "generateType",
                    "type": {
                        "type": "Reference",
                        "target": "staking/Staking/StakingGenerateType"
                    }
                },
                {
                    "id": 2,
                    "name": "produceLimit1",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "produceLimit2",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "totalGenerated",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "reward",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "staking/PtlGetPoolList/ReqGetPoolList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "area",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "deprecated",
                    "type": {
                        "type": "Boolean"
                    },
                    "optional": true
                }
            ]
        },
        "staking/PtlGetPoolList/ResGetPoolList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 1,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/PtlGetPoolList/IStakingPoolSummary"
                        }
                    }
                }
            ]
        },
        "staking/PtlGetRepairOrder/ReqGetRepairOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 2,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "date",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "target",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "price",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "tokens",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 0,
                                    "name": "_id",
                                    "type": {
                                        "type": "Reference",
                                        "target": "?mongodb/ObjectId"
                                    }
                                },
                                {
                                    "id": 1,
                                    "name": "address",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 2,
                                    "name": "amount",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 3,
                                    "name": "isNft",
                                    "type": {
                                        "type": "Boolean"
                                    }
                                }
                            ]
                        }
                    }
                }
            ]
        },
        "staking/PtlGetRepairOrder/ResGetRepairOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "target",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "orderId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "costAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "price",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "date",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "chain",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "addresses",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                }
            ]
        },
        "staking/PtlGetStakeAccounts/ReqGetStakeAccounts": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlGetStakeAccounts/ResGetStakeAccounts": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/PtlGetStakeAccounts/IStakingAccountSummary"
                        }
                    }
                }
            ]
        },
        "staking/PtlGetStakeAccounts/IStakingAccountSummary": {
            "type": "Interface",
            "extends": [
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakingAccount"
                        },
                        "keys": [
                            "staked",
                            "totalForce",
                            "generator"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "totalForce",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "staked",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/PtlGetStakeAccounts/IStakedNFTSummary"
                        }
                    }
                },
                {
                    "id": 5,
                    "name": "generator",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/PtlGetStakeAccounts/IStakingAccountRewardSummary"
                        }
                    }
                }
            ]
        },
        "staking/Staking/IStakingAccount": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "staked",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakedNFT"
                        }
                    }
                },
                {
                    "id": 3,
                    "name": "updateTime",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 4,
                    "name": "harvestAuthority",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "commissionAuthority",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 6,
                    "name": "totalForce",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 7,
                    "name": "totalBonus",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 10,
                    "name": "lands",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 11,
                    "name": "quantumForce",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 12,
                    "name": "hyperQuantumForce",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 13,
                    "name": "nfts",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 15,
                    "name": "generator",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakingAccountReward"
                        }
                    }
                }
            ]
        },
        "staking/Staking/IStakedNFT": {
            "type": "Interface",
            "properties": [
                {
                    "id": 16,
                    "name": "_id",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    }
                },
                {
                    "id": 0,
                    "name": "type",
                    "type": {
                        "type": "Reference",
                        "target": "staking/Staking/StakeItemType"
                    }
                },
                {
                    "id": 1,
                    "name": "amount",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 17,
                    "name": "adjustedAmount",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "state",
                    "type": {
                        "type": "Reference",
                        "target": "staking/Staking/StakedNFTState"
                    }
                },
                {
                    "id": 3,
                    "name": "force",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 4,
                    "name": "bonus",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "id",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "stakeUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 8,
                    "name": "startUTC",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 9,
                    "name": "harvestAuthority",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 10,
                    "name": "commissionAuthority",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 11,
                    "name": "durability",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 12,
                    "name": "durabilityLimit",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 13,
                    "name": "recoverTime",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 14,
                    "name": "toolCost",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 15,
                    "name": "repairTime",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 18,
                    "name": "isFreeze",
                    "type": {
                        "type": "Boolean"
                    },
                    "optional": true
                }
            ]
        },
        "staking/Staking/StakeItemType": {
            "type": "Union",
            "members": [
                {
                    "id": 0,
                    "type": {
                        "type": "Literal",
                        "literal": "computer"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Literal",
                        "literal": "hyper_computer"
                    }
                },
                {
                    "id": 2,
                    "type": {
                        "type": "Literal",
                        "literal": "land"
                    }
                },
                {
                    "id": 3,
                    "type": {
                        "type": "Literal",
                        "literal": "partner"
                    }
                },
                {
                    "id": 4,
                    "type": {
                        "type": "Literal",
                        "literal": "other"
                    }
                },
                {
                    "id": 5,
                    "type": {
                        "type": "Literal",
                        "literal": "token"
                    }
                }
            ]
        },
        "staking/Staking/StakedNFTState": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": -1
                },
                {
                    "id": 1,
                    "value": 0
                },
                {
                    "id": 2,
                    "value": 1
                },
                {
                    "id": 3,
                    "value": 2
                }
            ]
        },
        "staking/Staking/IStakingAccountReward": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "commissionAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "totalGenerated",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 2,
                    "name": "rewardPerMinute",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 3,
                    "name": "rewards",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                }
            ]
        },
        "staking/PtlGetStakeAccounts/IStakedNFTSummary": {
            "type": "Interface",
            "extends": [
                {
                    "id": 2,
                    "type": {
                        "target": {
                            "type": "Partial",
                            "target": {
                                "type": "Reference",
                                "target": "staking/Staking/IStakedNFT"
                            }
                        },
                        "keys": [
                            "amount",
                            "force",
                            "toolCost"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 2,
                    "name": "amount",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 0,
                    "name": "force",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "toolCost",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "staking/PtlGetStakeAccounts/IStakingAccountRewardSummary": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "commissionAddress",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "totalGenerated",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "rewardPerMinute",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "rewards",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "staking/PtlGetStakeCoin/ReqGetStakeCoin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "addresses",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                }
            ]
        },
        "staking/PtlGetStakeCoin/ResGetStakeCoin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "db/DbStaking/DbStakeCoin"
                        }
                    }
                }
            ]
        },
        "staking/PtlGetStakeOrder/ReqGetStakeOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 2,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "date",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "target",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "price",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 8,
                    "name": "isTake",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 6,
                    "name": "tokens",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 0,
                                    "name": "_id",
                                    "type": {
                                        "type": "Reference",
                                        "target": "?mongodb/ObjectId"
                                    }
                                },
                                {
                                    "id": 1,
                                    "name": "address",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 2,
                                    "name": "amount",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 3,
                                    "name": "isNft",
                                    "type": {
                                        "type": "Boolean"
                                    }
                                },
                                {
                                    "id": 4,
                                    "name": "decimal",
                                    "type": {
                                        "type": "Number"
                                    }
                                }
                            ]
                        }
                    }
                }
            ]
        },
        "staking/PtlGetStakeOrder/ResGetStakeOrder": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "target",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "orderId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "price",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "costAddress",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlGetStakingNFT/ReqGetStakingNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "tokens",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                },
                {
                    "id": 4,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlGetStakingNFT/ResGetStakingNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 2,
                    "name": "items",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "staking/PtlGetStakingNFT/IStakeNFTItemSummary"
                        }
                    }
                },
                {
                    "id": 3,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlGetStakingNFT/IStakeNFTItemSummary": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "_id",
                    "type": {
                        "type": "Reference",
                        "target": "?mongodb/ObjectId"
                    }
                },
                {
                    "id": 1,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "isNft",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 3,
                    "name": "nft",
                    "type": {
                        "type": "Reference",
                        "target": "db/DbNFT/DbNFT"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "item",
                    "type": {
                        "type": "Reference",
                        "target": "staking/PtlGetStakingNFT/IStakingPoolItemSummary"
                    }
                },
                {
                    "id": 5,
                    "name": "staked",
                    "type": {
                        "type": "Reference",
                        "target": "staking/PtlGetStakeAccounts/IStakedNFTSummary"
                    },
                    "optional": true
                }
            ]
        },
        "staking/PtlGetStakingNFT/IStakingPoolItemSummary": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakingPoolItem"
                        },
                        "keys": [
                            "force",
                            "toolCost",
                            "price"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "force",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "toolCost",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "price",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "staking/Staking/IStakingPoolItem": {
            "type": "Interface",
            "properties": [
                {
                    "id": 17,
                    "name": "key",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "mintAddress",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "name",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "force",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "bonus",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "type",
                    "type": {
                        "type": "Reference",
                        "target": "staking/Staking/StakeItemType"
                    }
                },
                {
                    "id": 19,
                    "name": "display",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 18,
                    "name": "collection",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "tag",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 8,
                    "name": "tagParam",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 9,
                    "name": "toolCost",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    },
                    "optional": true
                },
                {
                    "id": 10,
                    "name": "repairTime",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 11,
                    "name": "harvestAuthority",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 12,
                    "name": "commissionAuthority",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 13,
                    "name": "price",
                    "type": {
                        "type": "Number",
                        "scalarType": "bigint"
                    }
                },
                {
                    "id": 14,
                    "name": "exchange",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 15,
                    "name": "durability",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 16,
                    "name": "durabilityLimit",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "staking/PtlGetUnStakeState/ReqGetUnStakeState": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "orderId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlGetUnStakeState/ResGetUnStakeState": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 3,
                    "name": "complete",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 4,
                    "name": "tokenAddress",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "send",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "processed",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "transCount",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "hashAddress",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    },
                    "optional": true
                }
            ]
        },
        "staking/PtlHarvest/ReqHarvest": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlHarvest/ResHarvest": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "hashAddress",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                }
            ]
        },
        "staking/PtlRemoveStakeNFT/ReqRemoveStakeNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "orderId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "hashAddress",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlRemoveStakeNFT/ResRemoveStakeNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "staking/PtlRepair/ReqRepair": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "orderId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "hashAddress",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlRepair/ResRepair": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "staking/PtlRepairStakeNFT/ReqRepairStakeNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "nfts",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "String"
                        }
                    }
                },
                {
                    "id": 2,
                    "name": "txId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlRepairStakeNFT/ResRepairStakeNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "staking/PtlSetPoolShow/ReqSetPoolShow": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "show",
                    "type": {
                        "type": "Boolean"
                    }
                }
            ]
        },
        "staking/PtlSetPoolShow/ResSetPoolShow": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "staking/PtlSetStakeArea/ReqSetStakeArea": {
            "type": "Interface",
            "extends": [
                {
                    "id": 2,
                    "type": {
                        "type": "Partial",
                        "target": {
                            "type": "Reference",
                            "target": "staking/Staking/IStakingArea"
                        }
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlSetStakeArea/ResSetStakeArea": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "staking/PtlUnStakeNFT/ReqUnStakeNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "chain",
                    "type": {
                        "type": "Reference",
                        "target": "types/ChainType"
                    }
                },
                {
                    "id": 2,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "date",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "target",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "price",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "isTake",
                    "type": {
                        "type": "Boolean"
                    }
                },
                {
                    "id": 7,
                    "name": "tokens",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 0,
                                    "name": "_id",
                                    "type": {
                                        "type": "Reference",
                                        "target": "?mongodb/ObjectId"
                                    }
                                },
                                {
                                    "id": 1,
                                    "name": "address",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 2,
                                    "name": "amount",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 3,
                                    "name": "isNft",
                                    "type": {
                                        "type": "Boolean"
                                    }
                                }
                            ]
                        }
                    }
                }
            ]
        },
        "staking/PtlUnStakeNFT/ResUnStakeNFT": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "orderId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "staking/PtlUpdatePool/ReqUpdatePool": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "poolId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 19,
                    "name": "supportChain",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "iconUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "imgUrl",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 8,
                    "name": "name",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 9,
                    "name": "durabilityLoss",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 10,
                    "name": "openUTC",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 11,
                    "name": "closeUTC",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 12,
                    "name": "rewardUTC",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 13,
                    "name": "landEffectEndUTC",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 14,
                    "name": "officialRewardFee",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 15,
                    "name": "commissionRate",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 16,
                    "name": "stakeFeeRate",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 17,
                    "name": "officialConfigURL",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 18,
                    "name": "partnerConfigURL",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "generator",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Interface",
                            "properties": [
                                {
                                    "id": 0,
                                    "name": "address",
                                    "type": {
                                        "type": "String"
                                    }
                                },
                                {
                                    "id": 1,
                                    "name": "produceLimit1",
                                    "type": {
                                        "type": "Number"
                                    },
                                    "optional": true
                                },
                                {
                                    "id": 2,
                                    "name": "produceLimit2",
                                    "type": {
                                        "type": "Number"
                                    },
                                    "optional": true
                                }
                            ]
                        }
                    },
                    "optional": true
                }
            ]
        },
        "staking/PtlUpdatePool/ResUpdatePool": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        }
    }
};