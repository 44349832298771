import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "../assets/css/marketplaceHome.css"
import { apiClient } from "../global/global";
import { DbNFT } from "../prot/protocols/db/DbNFT";
import { DbProject } from "../prot/protocols/db/DbProject";
import CommodityItem from "./commodityItem";
import MarketProjectTitle from "./marketProjectTitle";

function MarketPlaceHome() {
    const [chain, setChain] = useState("all");
    const [Project, setProject] = useState("default");
    const [titleIndex, setTitleIndex] = useState(-1);
    const [ProjectList, setProjectList] = useState([] as DbProject[]);
    const [ProjectIcon, setProjectIcon] = useState({} as { [key: string]: string })
    const [Listings, setListings] = useState([] as DbNFT[]);
    const [FilteredListings, setFilteredListings] = useState([] as DbNFT[]);
    const [TitleList, setTitleList] = useState(null as HTMLElement);
    const [clientHeight, setClientHeight] = useState(300);
    useEffect(() => {
        let isMounted = true;
        getProjectList(isMounted);
        getListings(isMounted);
        return () => { isMounted = false };
    }, []);
    useEffect(() => {

        setFilteredListings(Listings.filter(v => {

            if (Project == "default")
                return true;
            else
                return v.project == Project;
        }));
        if (TitleList) {
            console.log(TitleList.clientHeight);
            
            setClientHeight(300);
        }
    }, [Project, Listings]);


    const getProjectList = async (isMounted: boolean) => {
        const res = await apiClient.callApi("market/GetProjectList", { filter: "base" });
        if (isMounted && res.isSucc) {
            const list = res.res.list.filter(v => v.isOnline).reverse();
            setProjectList(list);
            let map: { [key: string]: string } = {};
            for (const iterator of list) {
                map[iterator.name] = iterator.gameIconUrl;
            }
            setProjectIcon(map);
        }
    }
    const getListings = async (isMounted: boolean = true) => {
        const res = await apiClient.callApi("market/GetProjectListing", { start: Listings.length, count: 20 });
        if (isMounted && res.isSucc) {
            setListings(res.res.list);
        }
    }

    const onChainChanged = (event: any) => {
        const value = event.target.value;
        setChain(value);
    }


    return (<div>
        <h1>Market Place</h1>
        <div id="title-line" style={{ marginLeft: "36px", marginRight: "36px" }} />
        <div className="market-place-header">
            <div>
                <input type="radio" id="all" name="chain" value="all" checked={chain == "all"} onChange={onChainChanged} />
                <label htmlFor="all">All</label>
            </div>
            <div>
                <input type="radio" id="solana" name="chain" value="SOL" checked={chain == "SOL"} onChange={onChainChanged} />
                <label htmlFor="solana">Solana</label>
            </div>
            <div>
                <input type="radio" id="binance" name="chain" value="BSC" checked={chain == "BSC"} onChange={onChainChanged} />
                <label htmlFor="binance">BNB Chain</label>
            </div>
        </div>
        <div className="market-place-game-container">
            <div id="project" ref={setTitleList}>
                {
                    ProjectList.map((v, i) => {
                        return <MarketProjectTitle game={v} key={i} selected={titleIndex} index={i} onIndexChange={(idx) => {
                            setTitleIndex(idx);
                            if (idx == -1)
                                setProject("default")
                            else
                                setProject(ProjectList[idx].name);
                        }} />
                    })
                }
            </div>
            <div style={{ width: "100%", height: "100%" }}>
                <InfiniteScroll style={{ minHeight: TitleList ? clientHeight : "300px", overflow: "hidden" }} className="game-items" next={getListings} hasMore={true} loader={""} dataLength={FilteredListings.length} >
                    {
                        FilteredListings
                            .filter(v => {
                                if (chain == "all")
                                    return true;
                                return v.chain == chain;
                            })
                            .map((v, i) =>
                                <CommodityItem key={i} nft={v} gameIcon={ProjectIcon[v.project]} />)
                    }
                </InfiniteScroll>
            </div>
        </div>
    </div>);
}

export default MarketPlaceHome;