import { Keypair } from "@solana/web3.js";
import { useEffect, useState } from "react";
import "../assets/css/myStakingControl.css"
import { Global } from "../global/global";
import { IStakingPoolSummary } from "../prot/protocols/staking/PtlGetPoolList";
import { IStakingAccountSummary } from "../prot/protocols/staking/PtlGetStakeAccounts";
import ToolTips from "./toolTips";

const d2ms = 24 * 60 * 60 * 1000;
const h2ms = 60 * 60 * 1000;
const m2ms = 60 * 1000;
const s2ms = 1000;

function MyStakingControl(props: { account: IStakingAccountSummary, pool: IStakingPoolSummary, force: number, bonus: number, selected: string, onRefresh: () => void, onHarvest: () => void, onSelected: (value: string) => void }) {

    const [Nonce, setNonce] = useState("");

    useEffect(() => {
        setNonce(Math.random().toString());
    }, []);

    const hasHarvestReward = () => {
        if (!props.account)
            return false;
        let reward = 0;
        props.account.generator.forEach(v => reward += v.rewards);
        return reward > 0;
    }
    const hasHarvestAuthority = () => {
        if (!props.account)
            return false;
        if (props.pool.closeUTC.getTime() < (new Date()).getTime())
            return hasHarvestReward();
        const auth = props.account.harvestAuthority > 0;
        // const time = ((new Date()).getTime() - props.pool.rewardUTC.getTime()) >= 0;

        return auth;
    }
    const canHarvest = () => {
        if (hasHarvestAuthority() && hasHarvestReward() && reachHarvestTime())
            return true;
        if (hasCommissionAuthority() && reachCommissionTime())
            return true;

        return false;
    }
    const hasCommissionAuthority = () => {
        if (!props.account)
            return false;
        // const time = ((new Date()).getTime() - props.pool.closeUTC.getTime()) >= 0;
        return props.account.commissionAuthority > 0;
    }
    const reachHarvestTime = () => {
        if (!props.account)
            return false;
        // console.log(props.pool.rewardUTC.getTime(), (new Date()).getTime());

        const time = (new Date()).getTime() - props.pool.rewardUTC.getTime();
        return time > 0;
    };
    const reachCommissionTime = () => {
        if (!props.account)
            return false;
        const time = (new Date()).getTime() - props.pool.closeUTC.getTime();
        return time > 0;
    }

    const needShowHarvestTime = () => {

        if (hasHarvestAuthority()) {
            if (!reachHarvestTime() && props.account.totalForce > 0)
                return true;
            if (reachHarvestTime())
                return !hasHarvestReward();
        }
        if (hasCommissionAuthority()) {
            if (!reachCommissionTime() && props.account.totalBonus > 0)
                return true;
        }

        return false;
    }

    const harvestTimeCountDown = () => {
        let offset = 0;
        const now = (new Date()).getTime();

        if (hasHarvestAuthority() && reachHarvestTime() && !hasHarvestReward() && props.account.totalForce > 0) {
            offset = 60 * 1000;
        }
        else {
            if (props.account.harvestAuthority > 0)
                offset = Math.max(props.pool.rewardUTC.getTime() - now, 0);
            if (props.account.commissionAuthority > 0) {
                let com = Math.max(props.pool.closeUTC.getTime() - now, 0);
                if (offset != 0)
                    offset = Math.min(com, offset);
                else
                    offset = com;
            }
        }

        let left = offset;
        const d = Math.floor(left / d2ms);
        left -= d * d2ms;
        const h = Math.floor(left / h2ms);
        left -= h * h2ms;
        const m = Math.floor(left / m2ms);
        left -= m * m2ms;
        const s = Math.floor(left / s2ms);

        if (offset == 0)
            return offset;

        return `Claimable Left ${d}Day ${h}:${m}:${s}`;
    }

    const onChange = (e: any) => {
        props.onSelected(e.target.value);
    }

    const onRefreshClick = () => {
        props.onRefresh();
    }
    const onHarvestClick = () => {
        props.onHarvest();
    }

    return (<div className="my-control-container">
        <div id="my-info">
            <h1>Total  Force:</h1>
            <h2>{(props.force * (1 + props.bonus / 10000)).toFixed(2)}</h2>
            <h1>Bonus:</h1>
            <h2>{`${props.bonus / 100}%`}</h2>
            <div id="my-control">
                <button onClick={onRefreshClick}>Refresh</button>
                <button style={{ position: "relative" }} onClick={onHarvestClick} disabled={!canHarvest()}>
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} onMouseEnter={(e) => {
                        if (needShowHarvestTime()) {
                            if (typeof harvestTimeCountDown() == 'string')
                                Global.AddOverlay("totalGenerated", <ToolTips key="tt" left={e.clientX} top={e.clientY} lines={[harvestTimeCountDown() as string]} />)
                        }
                    }}
                        onMouseLeave={(e) => {
                            if (needShowHarvestTime()) {
                                if (typeof harvestTimeCountDown() == 'string')
                                    Global.RemoveOverlay("totalGenerated")
                            }
                        }} />Harvest</button>
            </div>
        </div>

        <div id="my-filter">
            <input type="radio" id={`start-earn${Nonce}`} value="start-earn" name={`my-control${Nonce}`} checked={props.selected == "start-earn"} onChange={onChange} />
            <label htmlFor={`start-earn${Nonce}`}>StartEarning</label>
            <input type="radio" id={`my-staked${Nonce}`} value="my-staked" name={`my-control${Nonce}`} checked={props.selected == "my-staked"} onChange={onChange} />
            <label htmlFor={`my-staked${Nonce}`}>My Staked</label>
        </div>
    </div >);
}

export default MyStakingControl;