import { Define, SaveAccount, SaveAccountSchema } from "../define/define";
import events from 'events';
import {
    PhantomWalletAdapter,
    SolletExtensionWalletAdapter,
    SolflareWalletAdapter,
    SlopeWalletAdapter,
    Coin98WalletAdapter,
    MathWalletAdapter,
    LedgerWalletAdapter
} from "@solana/wallet-adapter-wallets";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { Upload, Web3Storage } from "web3.storage";
import { clusterApiUrl, Connection, ParsedAccountData, PublicKey } from "@solana/web3.js";
import { TokenGet } from "../common/tokenGet";
import * as SPLToken from "@solana/spl-token";
import { GlobalConfig } from "./globalConfig";
import { INftItem } from "../contexts/nftItemContext";
import { INftMap } from "../contexts/nftMapContext";
import { HttpClient } from "tsrpc-browser";
import { serviceProto } from "../prot/protocols/serviceProto";
import { OfficialItem } from "../prot/protocols/base";
import { ISellNft } from "../contexts/sellNftContext";
import { ChangeEvent } from "react";
import { isMetamaskConnected } from "../common/metamask";
import { providers } from "ethers";
import { ResGetSettings } from "../prot/protocols/settings/PtlGetSettings";
import { ITokenDetail } from "../components/stakeTokenWindow";
import { IStakingAccountSummary } from "../prot/protocols/staking/PtlGetStakeAccounts";


export const EE = new events.EventEmitter.EventEmitter();
export const ClientEvn = (() => { return process.env.REACT_APP_EVN })();
const config: { [key: string]: string } = {
    "Local": "http://localhost:3001/",
    "Dev": "http://192.168.1.13:3001/",
    "RDev": "http://52.27.60.194:3001/",
    "Test": "http://52.27.60.194:3002/",
    "Pub": "https://meta2150s.com/jsonapi/",
    "PubTest": "http://52.27.60.194:3004/",
}

function getNetwork() {

    if (ClientEvn == "Dev" || ClientEvn == "Local" || ClientEvn == "RDev" || ClientEvn == "Test")
        return WalletAdapterNetwork.Devnet;
    else
        return WalletAdapterNetwork.Mainnet;
}
function getSolConnection(network: WalletAdapterNetwork) {
    if (network == WalletAdapterNetwork.Devnet)
        return clusterApiUrl(Global.Network);
    else
        return "https://shy-flashy-crater.solana-mainnet.discover.quiknode.pro/0ec1dfedae7d8bc82b3c8b654d09882103633514/";
}

// export const MitCoinSOL = "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr";
// export const MitCoinBSC = "0x4F98fE05961d8589F722AeD4fB21E3b0e4C4A6E7";
// export const MitCoinBSC = "0x16617E328e85b0074770bc709777044415ea57ad";

export class Global {
    public static Network = getNetwork();
    public static Connection = new Connection(getSolConnection(getNetwork()), "confirmed");

    public static WalletType = Define.Wallet.None;
    public static WalletAdapter: PhantomWalletAdapter | SolletExtensionWalletAdapter | SolflareWalletAdapter | SlopeWalletAdapter | Coin98WalletAdapter | MathWalletAdapter | LedgerWalletAdapter = null;
    public static EthProvider: providers.Web3Provider = null;
    public static EthAddress: string = "";

    public static get WalletAddress(): string {
        if (Global.WalletConnected("SOL"))
            return Global.WalletAdapter.publicKey.toBase58()
        else if (Global.WalletConnected("BNB"))
            return Global.EthAddress;
        return "";
    }
    public static SOL: number = 0;
    public static USDC: number = 0;
    public static SYP: number = 0;
    public static MBTT: number = 0;
    public static MITCOIN: number = 0;
    public static AllNfts: { [key: number]: string[] } = [];
    public static OwnNfts = new Array<INftItem>();
    public static OwnMaps = new Array<INftMap>();
    public static MapGeoURL: string[] = [];
    public static ConfigAsync: { [key: string]: Promise<any> } = {};
    public static IsInitGlobalConfig: boolean = false;
    public static IsGetTokens: boolean = false;
    public static UnCompleteBids: ISellNft[] = [];
    public static LastBidCheckTime: number = 0;
    public static OpenSell = true;
    public static WhiteList: Set<string>;

    public static Contract: ResGetSettings;

    public static WalletConnected(chain: "SOL" | "BNB" | "" = "SOL") {
        switch (chain) {
            case "SOL": {
                // console.log(Global.WalletAdapter);
                return Global.WalletAdapter != null && Global.WalletAdapter.connected;
            }
            case "BNB":
                if (Global.EthProvider) {
                    if (Global.WalletType == Define.Wallet.BinanceWallet)
                        return true;
                    else if (Global.WalletType == Define.Wallet.Metamask)
                        return isMetamaskConnected();
                    else if (Global.WalletType == Define.Wallet.OKX)
                        return true;
                }
                break;
            default:
                return Global.WalletAdapter != null && Global.WalletAdapter.connected || Global.EthProvider;
        }
        return false;
    }

    public static AdminConfig = {
        UpdateTime: 0,
        ShowCoin: {
            [Define.Token.SOL]: true,
            [Define.Token.USDC]: false,
            [Define.Token.SYP]: false,
            [Define.Token.MBTT]: false,
            [Define.Token.MITCOIN]: false,
        },
        TokenAddress: {
            [Define.Token.SOL]: "So11111111111111111111111111111111111111112",
            [Define.Token.USDC]: ClientEvn.startsWith("Pub") ?"EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v" :"Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr",
            [Define.Token.SYP]: "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ",
            [Define.Token.MBTT]: "So11111111111111111111111111111111111111112",
            [Define.Token.MITCOIN]: "So11111111111111111111111111111111111111112",
        },
        Decimals: {
            [Define.Token.SOL]: 0,
            [Define.Token.USDC]: 0,
            [Define.Token.SYP]: 0,
            [Define.Token.MBTT]: 0,
            [Define.Token.MITCOIN]: 0,
        }
    }

    public static getEllipsis(str: string) {

        if (str === null || str === "") {
            return "";
        }

        if (str.length >= 16) {
            let subStr1 = str.substr(0, 6);
            let subStr2 = str.substr(str.length - 6, 10);
            let subStr = subStr1 + "...." + subStr2;
            return subStr;
        }
    }

    public static getEllipsis2(str: string) {
        if (str === null || str === "") {
            return "";
        }

        if (str.length >= 16) {
            let subStr1 = str.substr(0, 12);
            let subStr2 = str.substr(str.length - 18, 18);
            let subStr = subStr1 + "...." + subStr2;
            return subStr;
        }
    }

    public static formatNumber(num: number) {
        let numStr = num.toString();
        if (/[^0-9\.]/.test(numStr)) {
            return "invalid value";
        }

        numStr = numStr.replace(/^(\d*)$/, "$1.");
        numStr = (numStr + "00").replace(/(\d*\.\d\d)\d*/, "$1");
        numStr = numStr.replace(".", ",");
        let re = /(\d)(\d{3},)/;
        while (re.test(numStr)) {
            numStr = numStr.replace(re, "$1,$2");
        }

        numStr = numStr.replace(/,(\d\d)$/, ".$1");

        return numStr.replace(/^\./, "0.")
    }

    public static getAddrShort(address: string, start: number = 4, end: number = 4): string {
        if (!address || address.length <= start + end)
            return '--';
        return `${address.substring(0, start)}...${address.substring(address.length - end)}`;
    }

    public static USDCRate = {
        [Define.Token.USDC]: 1,
        [Define.Token.SOL]: 100,
        [Define.Token.SYP]: 1,
        [Define.Token.MITCOIN]: 1,
        [Define.Token.MBTT]: 1,
    }

    public static getExchangedCoinPrice(value: number, target: number, from: number = Define.Token.USDC) {
        const rate = target == from ? 1 : Global.USDCRate[target];
        return (value / Global.AdminConfig.Decimals[from]) / rate;
    }

    static GetStorageLink(cid: string, fileName?: string) {
        let result = `https://${cid}.ipfs.nftstorage.link`
        if (fileName)
            result += `/${fileName}`
        return result;
    }

    static IdsToItemNames(value: string | number) {
        if (typeof value === 'string')
            return value.split(',').map(val => Define.Items[val]).join(',');
        else
            return Define.Items[value.toString()];
    }

    static async ListFiles(client: Web3Storage, option?: { lastUpload?: Upload, maxResult?: number }) {
        let before = new Date().toISOString();
        let maxResult = 10;
        if (option) {
            maxResult = option.maxResult ? option.maxResult : maxResult;
            before = option.lastUpload ? option.lastUpload.created : before;
        }
        let plane = [];
        let uploads = [];
        for await (const item of client.list({ maxResults: maxResult, before: before })) {
            uploads.push(item);
            plane.push(client.get(item.cid).then(value => value.files()));
        }
        let files = await Promise.all(plane);
        return { uploads, files };
    }

    static async WaitSeconds(second: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, second * 1000);
        });
    }

    public static getRandom(min: number, max: number): number {
        let range = max - min;
        let rand = Math.random();
        return (min + Math.round(rand * range));
    }

    static async getTokenDecimal(connection: Connection, address: PublicKey) {
        const accountInfo = await connection.getParsedAccountInfo(new PublicKey(address));
        console.log(accountInfo, address.toBase58());
        if (!accountInfo.value)
            return 1;
        const decimals = (accountInfo.value.data as any).parsed.info.decimals
        return Math.pow(10, decimals);
    }

    public static async getTokens() {
        console.log("GET TOKENS");
        await GlobalConfig.loadNftItemConfigs();
        await GlobalConfig.loadNftMapConfigs();
        this.IsGetTokens = false;
        this.SOL = await TokenGet.getBalance(Global.Connection) / Global.AdminConfig.Decimals[Define.Token.SOL];

        let ownNfts = new Array<INftItem>();
        let ownMaps = new Array<INftMap>();

        // console.log(GlobalConfig.NftMapConfigList);

        let balance = await TokenGet.getTokenAccountsByOwner(Global.Connection, this.WalletAdapter.publicKey);
        balance.value.forEach((e) => {
            const accountInfo = SPLToken.AccountLayout.decode(e.account.data);
            // let amountStr = `${SPLToken.u64.fromBuffer(accountInfo.amount)}`;
            const amount = Number(Buffer.from(accountInfo.amount).readBigInt64LE());
            const publicKey = new PublicKey(accountInfo.mint).toString();

            // console.log(accountInfo, publicKey, amount);

            switch (publicKey) {
                case "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr":
                    console.log(amount, amount / Global.AdminConfig.Decimals[Define.Token.USDC]);
                    break;
                case Define.USDCAddress:
                    this.USDC = amount / Global.AdminConfig.Decimals[Define.Token.USDC];
                    break;
                case Define.SYPAddress:
                    this.SYP = amount / Global.AdminConfig.Decimals[Define.Token.SYP];
                    break;
                case Define.MBTTAddress:
                    this.MBTT = amount / Global.AdminConfig.Decimals[Define.Token.MBTT];
                    break;
                case Define.MITCOINAddress:
                    this.MITCOIN = amount / Global.AdminConfig.Decimals[Define.Token.MITCOIN];
                    break;
            }
            if (amount == 1) {

                const nftConfig = GlobalConfig.NftItemConfigList.find(a => a.mint_address == publicKey);
                console.log(nftConfig);

                if (nftConfig && nftConfig.config) {
                    let config = GlobalConfig.getNftConfig(nftConfig.config.id);
                    let nft: INftItem = {
                        id: config.id,
                        config: config,
                        mint_address: nftConfig.mint_address,
                        item: nftConfig.item,
                    };
                    ownNfts.push(nft);
                }
                const mapConfig = GlobalConfig.NftMapConfigList.find(a => a.mint_address == publicKey);

                if (mapConfig && mapConfig.config) {
                    let config = GlobalConfig.getMapConfig(mapConfig.config.FieldID);
                    let map: INftMap = {
                        id: config.FieldID,
                        config: config,
                        mint_address: mapConfig.mint_address,
                        item: mapConfig.item,
                    };
                    ownMaps.push(map);
                }
            }

        });

        Global.OwnNfts = Global.sortOwnerNfts(ownNfts);
        Global.OwnMaps = Global.sortOwnerMaps(ownMaps);
        Global.IsGetTokens = true;
    }

    public static sortOwnerNfts(nfts: Array<INftItem>) {
        for (let i = 0; i < nfts.length - 1; i++) {
            for (let j = i; j < nfts.length; j++) {
                let iId = nfts[i].id;
                let jId = nfts[j].id;
                if (iId > jId) {
                    let temp = nfts[i];
                    nfts[i] = nfts[j];
                    nfts[j] = temp;
                }
            }
        }
        return nfts;
    }

    public static sortOwnerMaps(maps: Array<INftMap>) {
        for (let i = 0; i < maps.length - 1; i++) {
            for (let j = i; j < maps.length; j++) {
                let iId = maps[i].id;
                let jId = maps[j].id;
                if (iId > jId) {
                    let temp = maps[i];
                    maps[i] = maps[j];
                    maps[j] = temp;
                }
            }
        }
        return maps;
    }

    public static AddMessage: (message: string, txId?: string, subMessage?: string, displayTime?: number, callback?: Function) => string;
    public static RemoveMessage: (key: string) => void;
    public static HideFloatMessage(key: string) {
        window.dispatchEvent(new CustomEvent("hide-float-message", { detail: key }))
    }

    public static AddOverlay(key: string, element: JSX.Element) { }
    public static RemoveOverlay(key: string) { }

    static matchURL = (url: string) => {
        if (url.match("http://|https://"))
            return url;
        else
            return `http://${url}`
    }
    public static Open(url: string) {
        const w = window.open('about:blank');
        w.location.href = this.matchURL(url);
    }

    public static HasTradePermission() {
        if (!Global.WalletConnected())
            return false;
        if (Global.WhiteList) {
            return Global.WhiteList.has(Global.WalletAdapter.publicKey.toBase58())
        }
        return true;
    }


    public static ShowLoading = (show: boolean) => { }
    public static ShowPop = (message: string, onClick: (isOk: boolean) => void) => { }
    public static HidePop = () => { }
    public static ShowTokenPop = (list: ITokenDetail[], msg: string, type: string, onClick: (isOk: boolean) => void) => { }
    public static HideTokenPop = () => { }
}


export const apiClient = new HttpClient(serviceProto, {
    server: config[ClientEvn],
    timeout: 15 * 60 * 1000,
    json: true,
});

export function RefreshWebsite() {
    // window.location.reload();
}

export function GetOfficialItemPrice(item: OfficialItem) {

    if (!item)
        return 0;

    if (item.supply[0] > 0)
        return parseFloat(item.price);
    else {
        if (!item.nft)
            return parseFloat(item.price);
        return parseFloat(item.nft.order.price);
    }
}

export async function GetNFTOwner(mintAddress: string) {
    const accounts = await Global.Connection.getTokenLargestAccounts(new PublicKey(mintAddress));
    const accountInfo = await Global.Connection.getParsedAccountInfo(accounts.value[0].address)
    return (accountInfo.value.data as ParsedAccountData).parsed.info.owner
}

export const LimitInputNumber = (event: ChangeEvent<HTMLInputElement>): number => {

    let str = event.target.value.replace(" ", "");
    let value = Number(str);
    console.log(value, str);

    while (isNaN(value)) {
        str = str.substring(0, str.length - 1);
        value = parseFloat(str);
        if (str.length <= 0) {
            event.target.value = "";
            return NaN;
        }
    };
    console.log(value);

    if (value < 0) {
        event.target.value = str.substring(0, str.length - 1);
        return NaN;
    }
    const nums = str.split(".");
    if (nums.length == 2) {
        nums[1] = nums[1].substring(0, 2);
        console.log(Number(nums[1]));

        if (isNaN(Number(nums[1])))
            nums[1] = nums[1].substring(0, nums[1].length - 1);
        event.target.value = nums.join(".");
        return parseFloat(event.target.value);
    }

    event.target.value = value.toString();
    return parseFloat(value.toFixed(2));
}

export const hasReceive = (account: IStakingAccountSummary) => {
    if (!account)
        return false;
    if (account.staked.length > 0)
        return true;
    for (const iterator of account.generator) {
        if (iterator.rewards > 0)
            return true;
    }
    if (account.commissionAuthority > 0)
        return true;
    return false;
}

// 获取元素的绝对位置坐标（像对于页面左上角）
export function getElementPagePosition(element: HTMLElement) {


    //计算x坐标
    var actualLeft = document.body.scrollLeft + element.getBoundingClientRect().left;

    //计算y坐标
    var actualTop = document.body.scrollTop + element.getBoundingClientRect().top;

    //返回结果
    return { x: actualLeft, y: actualTop }
}

export function copyTextToClipboard(text: string) {
    try {
        navigator.clipboard.writeText(text);
    } catch (error) {
        copyTextToClipboardFallback(text);
    }
}

function copyTextToClipboardFallback(text: string) {
    var textArea = document.createElement("textarea");

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = "0px";
    textArea.style.left = "0px";

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = "0px";

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        // console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}


export function bigintToFloat(bi: bigint, decimal: number) {
    let num = 0;
    if (bi.toString().length > 6 && decimal > 6) {
        let af = bi / (BigInt(10) ** BigInt(6));
        decimal -= 6;
        num = Number(af) / Math.pow(10, decimal);
    }
    else
        num = Number(bi) / Math.pow(10, decimal);
    return num;
}