import "../assets/css/commodity.css"
import { DbNFT } from "../prot/protocols/db/DbNFT";
import tokenIconEth from "../assets/images/icon/ETH.png";
import tokenIconBnb from "../assets/images/icon/BNB.png";
import tokenIconSol from "../assets/images/icon/SOL.png";
import tokenIconSyp from "../assets/images/profile/syp.png";
import tokenIconUSDC from "../assets/images/profile/usdc.png";
import tokenIconMitCoin from "../assets/images/icon/SOL.png";
import tokenIconMbtt from "../assets/images/icon/SOL.png";
import { TradeState } from "../prot/protocols/auction/Commodity";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CommodityContext, CommodityHistoryContext } from "../contexts/commodityContext";
import { Global } from "../global/global";
import axios from "axios";
import { EvmNet } from "../common/EvmAuction";
import { EvmChain } from "@moralisweb3/evm-utils";

const tokenIcon = {
    "ETH": tokenIconEth,
    "SOL": tokenIconSol,
    "SYP": tokenIconSyp,
    "USDC": tokenIconUSDC,
    "MITCOIN": tokenIconMitCoin,
    "MBTT": tokenIconMbtt,
    "BNB": tokenIconBnb,
}

function CommodityItem(props: { nft: DbNFT, gameIcon: string, fixedWidth?: number, light?: boolean }) {
    const { fixedWidth, light } = props;
    const [NFT, setNFT] = useState(props.nft)
    const navigate = useNavigate();
    const commodity = props.nft.order;
    const ctx = useContext(CommodityContext);
    const history = useContext(CommodityHistoryContext);
    const onClicked = () => {
        if (!props.nft.order) {
            console.log(props.nft);

            if (props.nft.chain == "SOL")
                Global.Open(`https://explorer.solana.com/address/${props.nft.mintAddress}?cluster=${Global.Network}`);
            else if (props.nft.chain == "BSC") {
                const pair = props.nft.mintAddress.split("_");
                console.log(EvmNet);

                const net = EvmNet.name == EvmChain.BSC_TESTNET.name ? "testnet." : "";
                Global.Open(`https://${net}bscscan.com/token/${pair[0]}?a=${pair[1]}`);
            }
            return;
        }
        history.push(JSON.stringify(ctx));
        for (const key in props.nft) {
            if (Object.prototype.hasOwnProperty.call(props.nft, key)) {
                (ctx as any)[key] = (props.nft as any)[key];
            }
        }
        navigate("/commodity");
    }

    useEffect(() => {

        fetchNFT(props.nft)

    }, [props.nft])

    async function fetchNFT(nft: DbNFT) {
        if (!nft.data || !nft.data.image || !nft.data.img) {
            nft.data = (await axios.get(nft.uri)).data;
        }
        setNFT(nft);
    }
    const getImage = () => {
        if (props.nft.data)
            return props.nft.data.image || (props.nft.data as any).img;
        else
            return "";
    }
    const getName = () => {

    }

    const getHeight = () => {
        if (props.nft.order)
            return props.nft.order.tradeState == TradeState.OnSale ? "calc(300 / 204 * 100%)" : "calc(250 / 204 * 100%)";
        return "calc(290 / 204 * 100%)";
    }
    return (<div className="commodity-item" style={{ minWidth: fixedWidth, maxWidth: fixedWidth, backgroundColor: light ? "#252536" : "#191927" }}>
        <div className="rect" style={{ paddingTop: getHeight() }} onClick={onClicked} >
            <div>
                <div id="commodity-image" style={{ backgroundImage: `url(${getImage()})` }} />
                <div id="commodity-name">
                    <div>{
                        props.gameIcon && props.gameIcon != "" ? <img src={props.gameIcon} alt="nft icon" /> : <></>
                    }

                    </div>
                    <p>{(props.nft.chain == "SOL" || props.nft.name.includes("#")) ? props.nft.name : `${props.nft.name} #${props.nft.mintAddress.split("_")[1]}`}</p>
                </div>
                <label id="commodity-edition"> </label>
                {
                    props.nft.order?.tradeState == TradeState.OnSale ?
                        <div>
                            <h2>LISTING PRICE</h2>
                            <div id="commodity-price">
                                <div>
                                    <img src={tokenIcon[commodity.spl]} alt="price token" />
                                </div>
                                <p>{commodity.price}</p>
                            </div>
                        </div>
                        : <></>
                }

            </div>
        </div>
    </div>);
}

export default CommodityItem;