import React, { FC, useContext, useEffect, useState } from "react";
import {
    Coin98WalletAdapter,
    LedgerWalletAdapter,
    MathWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import "../assets/scss/wallets.scss";
import { Define, OpenVersion, ShowVersion } from "../define/define";
import { Global } from "../global/global";
import Line from "../assets/images/wallets/line.png";
import { useNavigate } from "react-router-dom";
import ChainSelector from "./chainSelector";
import { BscConnector } from "@binance-chain/bsc-connector";
import { metamaskConnect } from "../common/metamask";
import { SupportChainType } from "../event/navigation";
import { ChainContext } from "../contexts/chainContext";
import { providers } from "ethers";
import { createProvider, getFox, getMetamask, getOKX } from "../common/EvmAuction";
import { okxConnect } from "../common/okxwallet";
import { foxConnect } from "../common/foxwallet";

interface IChangeWalletState {
    onChangeWalletState: Function;
}
const bsc = new BscConnector({
    supportedChainIds: [56, 97] // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
})

export const Wallets: FC<IChangeWalletState> = (showWallets: IChangeWalletState) => {
    let { onChangeWalletState } = showWallets;
    const navigate = useNavigate();
    const Chain = useContext(ChainContext);
    const [SelectedChain, setSelectedChain] = useState(Chain.Selected)
    const disConnectAllWallets = async () => {
        if (Global.WalletAdapter != null) {
            await Global.WalletAdapter.disconnect();
            Global.WalletAdapter = null;
        }
        if (Global.EthProvider != null) {
            if (Global.WalletType == Define.Wallet.Metamask) {
                console.error("cannot disconnect metamask");

            }
            else if (Global.WalletType == Define.Wallet.BinanceWallet) {
                bsc.deactivate();
            }
            Global.EthProvider = null;
            Global.WalletType = Define.Wallet.None;
        }
    }
    const onAccountChange = ([newAddress]: any) => {
        if (!newAddress) {
            console.log("no address");

            return;
        }
        console.log("new Address", newAddress);

        Global.EthAddress = newAddress;
    }
    useEffect(() => {
        if (!Global.EthProvider)
            return;

        Global.EthProvider.on("accountsChanged", onAccountChange);
        return () => {
            Global.EthProvider?.off("accountsChanged", onAccountChange);
        }
    }, [Global.EthProvider])


    const onConnectWalletClick = async (wallet: { name: string; }) => {
        await disConnectAllWallets();
        Global.WalletType = wallet.name;

        switch (wallet.name) {
            case Define.Wallet.Phantom:
                Global.WalletAdapter = new PhantomWalletAdapter();
                if (!Global.WalletAdapter.connected) {
                    try {
                        await Global.WalletAdapter.connect();
                        await Global.getTokens();
                        onChangeWalletState(true);
                        Global.WalletType = Define.Wallet.Phantom;

                        // checkBids(() => { navigate("/profile") });
                    } catch (e) {
                        console.error(e);

                        window.open("https://phantom.app/", "_blank");
                    }
                }
                break;
            case Define.Wallet.Sollet:
                Global.WalletAdapter = new SolletExtensionWalletAdapter();
                if (!Global.WalletAdapter.connected) {
                    try {
                        await Global.WalletAdapter.connect();
                        await Global.getTokens();
                        onChangeWalletState(true);
                        // checkBids(() => { navigate("/profile") });
                    } catch (e) {
                        window.open("https://solana.com/ecosystem/sollet/", "_blank");
                    }
                }
                break;
            case Define.Wallet.Solflare:
                Global.WalletAdapter = new SolflareWalletAdapter();
                if (!Global.WalletAdapter.connected) {
                    try {
                        await Global.WalletAdapter.connect();
                        await Global.getTokens();
                        onChangeWalletState(true);
                        Global.WalletType = Define.Wallet.Solflare;

                        // checkBids(() => { navigate("/profile") });
                    } catch (e) {
                        window.open("https://solflare.com/", "_blank");
                    }
                }
                break;
            case Define.Wallet.Slope:
                Global.WalletAdapter = new SlopeWalletAdapter();
                if (!Global.WalletAdapter.connected) {
                    try {
                        await Global.WalletAdapter.connect();
                        await Global.getTokens();
                        onChangeWalletState(true);
                        // checkBids(() => { navigate("/profile") });
                    } catch
                    (e) {
                        window.open("https://slope.finance/", "_blank");
                    }
                }
                break;
            case Define.Wallet.Coin98:
                Global.WalletAdapter = new Coin98WalletAdapter();
                if (!Global.WalletAdapter.connected) {
                    try {
                        await Global.WalletAdapter.connect();
                        await Global.getTokens();
                        onChangeWalletState(true);
                        Global.WalletType = Define.Wallet.Coin98;

                        // checkBids(() => { navigate("/profile") });
                    } catch (e) {
                        window.open("https://coin98.com/", "_blank");
                    }
                }
                break;
            case Define.Wallet.MathWallet:
                Global.WalletAdapter = new MathWalletAdapter();
                if (!Global.WalletAdapter.connected) {
                    try {
                        await Global.WalletAdapter.connect();
                        await Global.getTokens();
                        onChangeWalletState(true);
                        Global.WalletType = Define.Wallet.MathWallet;

                        // checkBids(() => { navigate("/profile") });
                    } catch (e) {
                        window.open("https://mathwallet.org", "_blank");
                    }
                }
                break;
            case Define.Wallet.Ledger:
                Global.WalletAdapter = new LedgerWalletAdapter();
                if (!Global.WalletAdapter.connected) {
                    try {
                        await Global.WalletAdapter.connect();
                        await Global.getTokens();
                        Global.WalletType = Define.Wallet.Ledger;
                        onChangeWalletState(true);
                        // checkBids(() => { navigate("/profile") });
                    } catch (e) {
                        window.open("https://ledger.com/", "_blank");
                    }
                }
                break;
            case Define.Wallet.Metamask:
                try {
                    console.log("CONNECT METAMASK");

                    Global.EthProvider = getMetamask(); //new providers.Web3Provider((window as any).ethereum);
                    await metamaskConnect();
                    Global.WalletType = Define.Wallet.Metamask;

                    Global.EthAddress = await (await Global.EthProvider.getSigner().getAddress()).toLowerCase();

                    onChangeWalletState(true);
                } catch (error) {
                    window.open("https://metamask.io/", "_blank");
                }
                break;
            case Define.Wallet.BinanceWallet:
                try {

                    // invoke method on bsc e.g.
                    Global.EthProvider = createProvider(await bsc.getProvider())();
                    await bsc.activate();
                    Global.WalletType = Define.Wallet.BinanceWallet;
                    Global.EthAddress = await (await Global.EthProvider.getSigner().getAddress()).toLowerCase();
                    onChangeWalletState(true);

                } catch (error) {
                    window.open("https://www.bnbchain.org/en/binance-wallet", "_blank");
                }
                break;
            case Define.Wallet.OKX:
                try {
                    console.log("CONNECT OKX");
                    //是bsc的情况
                    Global.EthProvider = getOKX(); //new providers.Web3Provider((window as any).ethereum);
                    await okxConnect(SelectedChain);
                    Global.WalletType = Define.Wallet.OKX;
                    if (SelectedChain == "BNB")
                        Global.EthAddress = await (await Global.EthProvider.getSigner().getAddress()).toLowerCase();
                    onChangeWalletState(true);
                } catch (error) {
                    console.error(error);

                    window.open("https://www.okx.com/web3/", "_blank");
                }
                break;
            case Define.Wallet.FoxWallet:
                try {
                    console.log("CONNECT FOX");
                    //是bsc的情况
                    Global.EthProvider = getFox(); //new providers.Web3Provider((window as any).ethereum);
                    await foxConnect(SelectedChain);
                    Global.WalletType = Define.Wallet.FoxWallet;
                    if (SelectedChain == "BNB")
                        Global.EthAddress = await (await Global.EthProvider.getSigner().getAddress()).toLowerCase();
                    onChangeWalletState(true);
                } catch (error) {
                    console.error(error);

                    window.open("https://foxwallet.com/download/", "_blank");
                }
                break;
                break;
        }
    }

    const onCloseClick = () => {
        onChangeWalletState(false);
    }

    // useEffect(() => {
    //     console.log(Chain.Selected)
    //     setSelectedChain(Chain.Selected);
    // }, [Chain.Selected])
    const onChainChanged = (chain: SupportChainType) => {
        setSelectedChain(chain)
    }

    return (
        <section className="wallet-background">
            <section className="wallets">
                <section className="wallet-frame" />
                {
                    ShowVersion >= OpenVersion.Market && <ChainSelector onChanged={onChainChanged} className="wallet-select-chain" />
                }
                <p className="connect-wallet">Connect Wallet</p>
                <img className="first-line" src={Line} />
                <div className="wallet-container">
                    {
                        SelectedChain == "SOL" ?
                            Define.SOLWallets.map((wallet, index) => {
                                return (
                                    <section key={index} className="wallet">
                                        <img src={wallet.icon} alt="-_-" draggable={false} />
                                        <span>{wallet.name}</span>
                                        <button className="wallet-btn" onClick={() => onConnectWalletClick(wallet)}>Connect
                                        </button>
                                        <img className="line" src={Line} alt="-_-" draggable={false} />
                                    </section>
                                );
                            })
                            :
                            Define.BNBWallets.map((wallet, index) => {
                                return (
                                    <section key={index} className="wallet">
                                        <img src={wallet.icon} alt="-_-" draggable={false} />
                                        <span>{wallet.name}</span>
                                        <button className="wallet-btn" onClick={() => onConnectWalletClick(wallet)}>Connect
                                        </button>
                                        <img className="line" src={Line} alt="-_-" draggable={false} />
                                    </section>
                                );
                            })
                    }
                </div>

                <button className="close-btn" onClick={onCloseClick}>Close</button>
            </section>
        </section>
    );
}
