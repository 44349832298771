
export async function waitSeconds(second: number) {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            resolve();
        }, second * 1000);
    })
}

export async function retryPromise(func: Function, wait: number = 2, maxTry: number = 5) {
    while (true) {
        try {
            const result = await func();

            return result;
        } catch (error) {
            console.error(error);
            await waitSeconds(wait);
            maxTry--;
        }
        if (maxTry <= 0)
            break;
    }
}