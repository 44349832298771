import { MouseEvent } from "react";
import "../assets/css/gameIconV2.css"
import { GameIconIntro } from "../prot/protocols/market/PtlGetRecentListed";

function GameIcon(props: {
    three: boolean,
    info: GameIconIntro, shadow?: boolean,
    onClick?: (info: GameIconIntro, rect: DOMRect) => void
}) {
    const { iconUrl } = props.info;

    return (<div className={ `game-icon-v2 ${props.three ? 'three-col' : 'four-col'}` } onClick={(e: MouseEvent) => {
        const target = e.target as HTMLImageElement;
        props.onClick(props.info, target.getBoundingClientRect());
    }} style={props.shadow ? { boxShadow: "8px 8px 10px black" } : {}}>
        <img src={iconUrl} alt="" />
    </div>);
}

export default GameIcon;