import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cs from "classnames";
import axios from "axios";
import Qs from "qs";
import { Swiper, SwiperSlide } from "swiper/react";
import { BaseURL, HTTP_STATUS } from "../config/config";
import "swiper/css";
import "../assets/scss/home.scss";
import { INavigation } from "../event/navigation";
import { ArticleItem } from "../components/articleItem";
import { SwiperThree } from "../components/swiperThree";
import { Define } from "../define/define";
import Bot11 from "../assets/images/home/bot-11.png";
import Bot12 from "../assets/images/home/bot-12.png";
import Bot13 from "../assets/images/home/bot-13.png";
import Bot14 from "../assets/images/home/bot-14.png";
import Bot21 from "../assets/images/home/bot-21.png";
import Bot22 from "../assets/images/home/bot-22.png";
import Bot23 from "../assets/images/home/bot-23.png";
import RightMark from "../assets/images/home/right-mark.jpg";
import LastText from "../assets/images/home/last/last-text.jpg"
import Sypool from "../assets/images/home/sypool.png";
import Oxgen from "../assets/images/home/oxgen.png";
import Logo from "../assets/images/home/logo.png";
import TapFantasy from "../assets/images/home/tapfantasy.png";
import HorizontalLine from "../assets/images/home/horizontal-line.png";
import Svc from "../assets/images/home/svc.png";
import Taureron from "../assets/images/home/taureron.png";
import Blockwall from "../assets/images/home/blockwall.png";
import Ngc from "../assets/images/home/ngc.png";
import InvestorsImg from "../assets/images/home/investors-img.png";
import RegisterButton from "../assets/images/home/register-button.png";
import CloseLoss from "../assets/images/home/last/close-loss.png";
import Success from "../assets/images/home/last/success.png";
import { Global } from "../global/global";
import partners_log from "../assets/images/home/Partners.png";
import banner1 from "../assets/images/home/banner1.jpg";
import banner2 from "../assets/images/home/banner2.jpg";
import GameIconListV2 from "../components/gameIconListV2";
import RecentProjectsV2 from "../components/recentProjectsV2";
import FeaturePools from "../components/featurePools";

const pdf = require("../assets/litepaper.pdf");

export const Home: FC<INavigation> = (navigation: INavigation) => {
    const navigate = useNavigate();
    const { onNavigation } = navigation;
    const [boxlr, setBoxlr] = useState(0);
    const [boxtb, setBoxtb] = useState(0);
    const [swiperController, setSwiperController] = useState(null);
    const [mobileSwiperController, setMobileSwiperController] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [mobileCurrentIndex, setMobileCurrentIndex] = useState(0);
    const [swiperController1, setSwiperController1] = useState(null);
    const [mobileSwiperController1, setMobileSwiperController1] = useState(null);
    const [currentIndex1, setCurrentIndex1] = useState(0);
    const [mobileCurrentIndex1, setMobileCurrentIndex1] = useState(0);
    const [emails, setEmails] = useState(null);
    const [isShowPop, setIsShowPop] = useState(false);
    const pictures3 = [Bot11, Bot12, Bot13, Bot14];
    const pictures4 = [Bot21, Bot22, Bot23];

    useEffect(() => {
        onNavigation(Define.Page.Home);
    }, []);

    const onSwiper = useCallback((swiper: any) => {
        setSwiperController(swiper);
        setCurrentIndex(swiper.activeIndex);
    }, [currentIndex, swiperController]);

    const onSlideChange = useCallback(() => {
        if (swiperController) {
            setCurrentIndex(swiperController.activeIndex);
        }
    }, [currentIndex, swiperController]);

    useEffect(() => {
        if (swiperController) {
            setCurrentIndex(currentIndex);
            swiperController.slideTo(currentIndex);
        }
    }, [currentIndex, swiperController]);

    const onSwiperClick = useCallback((index: number) => {
        setCurrentIndex(index);
    }, [currentIndex, swiperController]);

    const onMobileSwiper = useCallback((swiper: any) => {
        setMobileSwiperController(swiper);
        setMobileCurrentIndex(swiper.activeIndex);
    }, [mobileCurrentIndex, mobileSwiperController]);

    const onMobileSwiperClick = useCallback((index: number) => {
        setMobileCurrentIndex(index);
    }, [mobileCurrentIndex, mobileSwiperController]);


    const onMobileSlideChange = useCallback(() => {
        if (mobileSwiperController) {
            setMobileCurrentIndex(mobileSwiperController.activeIndex);
        }
    }, [mobileCurrentIndex, mobileSwiperController]);

    const onSwiper1 = useCallback((swiper: any) => {
        setSwiperController1(swiper);
        setCurrentIndex1(swiper.activeIndex);
    }, [currentIndex1, swiperController1]);

    const onSlideChange1 = useCallback(() => {
        if (swiperController1) {
            setCurrentIndex1(swiperController1.activeIndex);
        }
    }, [currentIndex1, swiperController1]);

    useEffect(() => {
        if (swiperController1) {
            setCurrentIndex1(currentIndex1);
            swiperController1.slideTo(currentIndex1);
        }
    }, [currentIndex1, swiperController1]);

    const onSwiperClick1 = useCallback((index: number) => {
        setCurrentIndex1(index);
    }, [currentIndex1, swiperController1]);

    const onMobileSwiper1 = useCallback((swiper: any) => {
        setMobileSwiperController1(swiper);
        setMobileCurrentIndex1(swiper.activeIndex);
    }, [mobileCurrentIndex1, mobileSwiperController1]);

    const onMobileSlideChange1 = useCallback(() => {
        if (mobileSwiperController1) {
            setMobileCurrentIndex1(mobileSwiperController1.activeIndex);
        }
    }, [mobileCurrentIndex1, mobileSwiperController1]);

    const onMobileSwiperClick1 = useCallback((index: number) => {
        setMobileCurrentIndex1(index);
    }, [mobileCurrentIndex1, mobileSwiperController1]);

    useEffect(() => {
        if (mobileSwiperController1) {
            setMobileCurrentIndex1(mobileCurrentIndex1);
            mobileSwiperController1.slideTo(mobileCurrentIndex1);
        }
    }, [mobileCurrentIndex1, mobileSwiperController1]);


    const onMoveToRegister = useCallback(() => {
        const element = document.getElementById("register");
        if (element != null) {
            window.scrollTo(0, element.offsetTop);
        }
    }, []);

    const onRegisterClick = useCallback(() => {
        if (!emails) {
            Global.AddMessage("Email address cannot be empty", undefined, " ");
            return;
        }
        const Reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (Reg.test(emails)) {
            const formR = { email: emails };
            axios.interceptors.response.use(
                response => {
                    return response
                },
                err => {
                    let response = err.response;
                    Global.AddMessage(HTTP_STATUS[response.status] || "error", undefined, " ");
                    return false;
                }
            )
            const https = axios.create({ baseURL: BaseURL })
            https.post("metabotRegister", Qs.stringify(formR), { headers: { "Content-Type": "application/x-www-form-urlencoded" } }).then((res) => {
                if (res.data) {
                    if (res.data.code === 1) {
                        setIsShowPop(true);
                    } else {
                        Global.AddMessage(res.data.msg, undefined, " ");
                    }
                }
            })
        } else {
            Global.AddMessage("Please enter the correct email address", undefined, " ");
        }
    }, [emails]);

    const onJumpClick = useCallback((name) => {
        let url = "";
        switch (name) {
            case "Medium":
                url = "https://meta2150s.medium.com//";
                break;
            case "Twitter":
                url = "https://twitter.com/Meta2150s";
                break;
            case "Discord":
                url = "https://discord.com/invite/EHXDrvAD2s";
                break;
            case "Telegram":
                url = "https://t.me/meta2150s";
                break;
        }
        if (url) {
            Global.Open(url);
        }
    }, []);

    const onSearchChange = useCallback((event) => {
        setEmails(event.target.value);
        console.log(event.target.value);
    }, [emails]);


    const countLR = useCallback((type: string) => {
        const target = document.getElementById("home");
        if (!target) {
            return "0px";
        }
        const posCenter = {
            x: target.offsetWidth / 2,
            y: (target.offsetTop + target.offsetHeight) / 2
        };
        if (boxtb > posCenter.y * 2 || boxtb < 80) {
            return "0px";
        }
        if (type === "y") {
            const res = boxtb - posCenter.y;
            return `${-(res / target.offsetHeight * 80)}px`;
        } else {
            const res = boxlr - posCenter.x;
            return `${-(res / target.offsetWidth / 2 * 10)}vw`;
        }
    }, [boxlr, boxtb]);

    const onMouseMove = useCallback((event: { pageX: number; pageY: any; }) => {
        setBoxlr(event.pageX);
        setBoxtb(event.pageY);
    }, [boxlr, boxtb]);

    const onIntroductionClick = useCallback(() => {
        let timer = setTimeout(() => {
            clearTimeout(timer);
            let element = document.getElementById("welcome");
            if (element != null) {
                window.scrollTo(0, element.offsetTop - 100);
            }
        }, 500);
    }, []);

    const onGameplayClick = useCallback(() => {
        let timer = setTimeout(() => {
            clearTimeout(timer);
            let element = document.getElementById("welcome");
            if (element != null) {
                window.scrollTo(0, element.offsetTop + 500);
            }
        }, 500);

    }, []);

    const onInvestorsClick = useCallback(() => {
        let timer = setTimeout(() => {
            clearTimeout(timer);
            let element = document.getElementById("investors");
            if (element != null) {
                window.scrollTo(0, element.offsetTop - 200);
            }
        }, 500);

    }, []);

    const onPartnersClick = useCallback(() => {
        let timer = setTimeout(() => {
            clearTimeout(timer);
            let element = document.getElementById("partners");
            if (element != null) {
                window.scrollTo(0, element.offsetTop - 300);
            }
        }, 500);

    }, []);

    const onLitepaperClick = useCallback(() => {
        window.open(pdf, "_blank");
    }, []);

    const onMapClick = useCallback(() => {
        navigate(Define.Page.Map);
    }, []);

    const onMarketplaceClick = useCallback(() => {
        if (!Global.OpenSell) {
            Global.AddMessage("Coming soon.", undefined, " ");
        } else {
            navigate(Define.Page.Marketplace);
        }
    }, []);

    return (
        <section id="home" className="home">
            <div className="top">
                <section className="top-bg" onMouseMove={onMouseMove}
                    style={{ backgroundPosition: `${countLR("x")} ${countLR("y")}` }}>
                    <div className="content">
                        <img src={LastText} alt="" draggable={false} />
                        {/* <section className="top-btn" onClick={onMoveToRegister}>REGISTER</section> */}
                    </div>
                </section>
                <SwiperThree />
            </div>
            <div className="banner">
                <a href="https://meta2150s.medium.com/join-metabot-beta-test-to-win-a-special-nft-drop-100-token-wl-spot-27f43e1e6cd8" target="_blank" rel="noreferrer">
                    <img src={banner1} alt="-_-" draggable={false} />
                </a>
            </div>
            <GameIconListV2 onSelectGame={async () => {}} />
            <div className="home-view-more">
                <a href="https://meta2150s.com/#/games">View All...</a>
            </div>
            <div className="banner">
                <a href="https://zealy.io/cw/meta2150s/questboard" target="_blank" rel="noreferrer">
                    <img src={banner2} alt="-_-" draggable={false} />
                </a>
            </div>
            <div className="module-title">
                <span>Hot Collections</span>
            </div>
            <RecentProjectsV2 />
            {/* <div className="home-view-more">
                <a href="https://meta2150s.com/#/market">View All...</a>
            </div> */}
            <div className="module-title">
                <span>Feature Pools</span>
            </div>
            <FeaturePools />
            {/* <div className="home-view-more">
                <a href="https://meta2150s.com/#/staking/pool">View All...</a>
            </div> */}
            <section className="wapper" id="welcome" style={{ display:"none" }}>
                <section className="middle"> {
                    Define.Singles.map((single, i) => {
                        if (i === 0) {
                            return (
                                <section key={i}>
                                    <section className="middle-content">
                                        <ArticleItem title={single.title} article={single.article} />
                                        <section style={{ position: "relative" }}>
                                            <img className="left" src={single.img} alt="-_-" draggable={false} />
                                            <img className="right-mark" src={RightMark} srcSet="" alt="-_-"
                                                draggable={false} />
                                        </section>
                                    </section>
                                </section>
                            );
                        } else if (i === 1) {
                            return (
                                <section key={i}>
                                    <section className="middle-second">
                                        <section style={{ position: "relative" }}>
                                            <img className="right" src={single.img} alt="-_-" draggable={false} />
                                            <img className="right-mark" src={RightMark} srcSet="" alt="-_-"
                                                draggable={false} />
                                        </section>
                                        <ArticleItem title={single.title} article={single.article} />
                                    </section>
                                </section>
                            );
                        } else if (i === 2) {
                            return (
                                <section key={i}>
                                    <section className="middle-content">
                                        <ArticleItem title={single.title} article={single.article} />
                                        <section className="swiper-top">
                                            <Swiper
                                                slidesPerView={1}
                                                spaceBetween={0}
                                                onSlideChange={onSlideChange}
                                                onSwiper={(swiper) => onSwiper(swiper)}
                                                grabCursor> {
                                                    pictures3.map((picture, j) => {
                                                        return (
                                                            <SwiperSlide key={j} className="metabot-swiper">
                                                                <img src={picture} alt="-_-" draggable={false} />
                                                            </SwiperSlide>
                                                        );
                                                    })
                                                }
                                            </Swiper>
                                            <ul className="symbol-list"> {
                                                pictures3.map((picture, j) => {
                                                    return (
                                                        <section key={j} onClick={() => onSwiperClick(j)}>
                                                            <li className={cs({ "checked-line": currentIndex === j })} />
                                                        </section>
                                                    );
                                                })
                                            }
                                            </ul>
                                        </section>
                                    </section>
                                </section>
                            );
                        } else if (i === 3) {
                            return (
                                <section key={i}>
                                    <section className="last-content">
                                        <section className="swiper-top">
                                            <Swiper
                                                slidesPerView={1}
                                                spaceBetween={0}
                                                onSlideChange={onSlideChange1}
                                                onSwiper={(swiper) => onSwiper1(swiper)}
                                                grabCursor> {
                                                    pictures4.map((picture, j) => {
                                                        return (
                                                            <SwiperSlide key={j} className="metabot-swiper">
                                                                <img src={picture} alt="-_-" draggable={false} />
                                                            </SwiperSlide>
                                                        );
                                                    })
                                                }
                                            </Swiper>
                                            <ul className="symbol-list"> {
                                                pictures4.map((picture, j) => {
                                                    return (
                                                        <section key={j} onClick={() => onSwiperClick1(j)}>
                                                            <li className={cs({ "checked-line": currentIndex1 === j })} />
                                                        </section>
                                                    );
                                                })
                                            }
                                            </ul>
                                        </section>
                                        <section className="space" />
                                        <ArticleItem title={single.title} article={single.article} />
                                    </section>
                                </section>
                            );
                        }
                    }
                    )}
                </section>
                {
                    Define.Singles.map((single, i) => {
                        if (i === 2) {
                            return (
                                <section key={i} className="single-block">
                                    <p>{single.title}</p>
                                    <article>{single.article}</article>
                                    <section className="swiper-top">
                                        <Swiper
                                            slidesPerView={1}
                                            spaceBetween={0}
                                            onSlideChange={onMobileSlideChange}
                                            onSwiper={(swiper) => onMobileSwiper(swiper)}
                                            grabCursor>{
                                                pictures3.map((picture, j) => {
                                                    return (
                                                        <SwiperSlide key={j}>
                                                            <img src={picture} alt="-_-" draggable={false} />
                                                        </SwiperSlide>
                                                    );
                                                })
                                            }
                                        </Swiper>
                                        <ul className="symbol-list"> {
                                            pictures3.map((picture, j) => {
                                                return (
                                                    <section key={j} onClick={() => onMobileSwiperClick(j)}>
                                                        <li className={cs({ "checked-line": mobileCurrentIndex === j })}></li>
                                                    </section>
                                                );
                                            })
                                        }
                                        </ul>
                                    </section>
                                </section>
                            );
                        } else if (i === 3) {
                            return (
                                <section key={i} className="single-block">
                                    <p>{single.title}</p>
                                    <article>{single.article}</article>
                                    <section className="swiper-top">
                                        <Swiper
                                            slidesPerView={1}
                                            spaceBetween={0}
                                            onSlideChange={onMobileSlideChange1}
                                            onSwiper={(swiper) => onMobileSwiper1(swiper)}
                                            grabCursor>{
                                                pictures4.map((picture, j) => {
                                                    return (
                                                        <SwiperSlide key={j}>
                                                            <img src={picture} alt="-_-" draggable={false} />
                                                        </SwiperSlide>
                                                    );
                                                })
                                            }
                                        </Swiper>
                                        <ul className="symbol-list"> {
                                            pictures4.map((picture, j) => {
                                                return (
                                                    <section key={j} onClick={() => onMobileSwiperClick1(j)}>
                                                        <li className={cs({ "checked-line": mobileCurrentIndex1 === j })}></li>
                                                    </section>
                                                );
                                            })
                                        }
                                        </ul>
                                    </section>
                                </section>
                            );
                        } else {
                            return (
                                <section key={i} className="single-block">
                                    <p>{single.title}</p>
                                    <article>{single.article}</article>
                                    <img className="block" src={single.img} alt="-_-" draggable={false} />
                                </section>
                            );
                        }
                    })
                }
            </section>
            {/* <img src={HorizontalLine} className="first-line" alt="-_-" draggable={false} /> */}
            <section className="investors" id="investors">
                <span>Investors</span>
                <div>
                    <img src={Svc} className="svc" alt="-_-" draggable={false} />
                    <img src={Taureron} className="taureron" alt="-_-" draggable={false} />
                    <img src={Blockwall} className="blockwall" alt="-_-" draggable={false} />
                    <img src={Ngc} className="ngc" alt="-_-" draggable={false} />
                    <img src={InvestorsImg} className="investors-img" alt="-_-" draggable={false} />
                </div>
            </section>
            {/* <section className="second-line" id="community">
                <hr />
            </section> */}
            <section className="footer-one" id="partners">
                <span>Our Partners</span>
                <div className="partners-logo">
                    {/* <img src={Sypool} className="sypool" alt="-_-" draggable={false} />
                    <img src={Oxgen} className="oxgen" alt="-_-" draggable={false} />
                    <img src={TapFantasy} className="tapfatasy" alt="-_-" draggable={false} /> */}
                    <img src={partners_log} className="tapfatasy" alt="-_-" draggable={false} />
                </div>

            </section>
            <section className="footer-last" id="community">
                {/* <hr /> */}
                <div className="last">
                    {/* <section className="logo" /> */}

                    <section className="vertical-line">
                        <img src={Logo} className="logo" />
                    </section>
                    <section className="last-home-region">
                        <span className="last-home">Home</span>
                        <span className="last-introduction" onClick={onIntroductionClick}>Introduction</span>
                        <span className="last-gameplay" onClick={onGameplayClick}>Gameplay</span>
                        <span className="last-investors" onClick={onInvestorsClick}>Investors</span>
                        <span className="last-partners" onClick={onPartnersClick}>Partners</span>
                    </section>
                    <section className="last-game-region">
                        <span className="last-game">Game</span>
                        <span className="last-litepaper" onClick={onLitepaperClick}>Litepaper</span>
                        <span className="last-map" onClick={onMapClick}>Map</span>
                        <span className="last-marketplace" onClick={onMarketplaceClick}>Marketplace</span>
                    </section>
                    <section className="last-community-region">
                        <span className="last-community">Community</span>
                        <section className="img-list">
                            <svg onClick={() => onJumpClick("Twitter")} viewBox="0 0 36 36" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_di)">
                                    <rect x="2" y="-0.000488281" width="32" height="32" rx="16" fill="#7474AA"
                                        fillOpacity="0.32"></rect>
                                    <g filter="url(#filter1_di)">
                                        <path
                                            d="M25.1576 12.4693C25.1648 12.6278 25.168 12.787 25.168 12.9463C25.168 17.8262 21.4538 23.4535 14.6609 23.4535C12.5749 23.4535 10.6345 22.8421 8.99982 21.7947C9.28943 21.8293 9.58306 21.8462 9.88071 21.8462C11.5438 21.849 13.1595 21.2922 14.4678 20.2654C13.697 20.2509 12.95 19.9958 12.3314 19.5358C11.7127 19.0758 11.2533 18.4339 11.0174 17.6999C11.5711 17.807 12.142 17.7852 12.6859 17.6364C11.8501 17.4676 11.0985 17.0149 10.5584 16.3551C10.0184 15.6952 9.72324 14.8689 9.72303 14.0163V13.9696C10.2358 14.2552 10.8097 14.4135 11.3963 14.4314C10.8903 14.0942 10.4754 13.6371 10.1886 13.1008C9.90191 12.5645 9.75218 11.9656 9.7528 11.3575C9.7528 10.6809 9.93541 10.047 10.2524 9.50159C11.1798 10.6425 12.3369 11.5757 13.6484 12.2404C14.9599 12.905 16.3966 13.2865 17.8651 13.3598C17.6795 12.5677 17.7606 11.7364 18.0958 10.9951C18.431 10.2538 19.0016 9.64383 19.7189 9.25998C20.4363 8.87613 21.2603 8.73984 22.063 8.87226C22.8658 9.00468 23.6024 9.39841 24.1584 9.99232C24.9852 9.82886 25.778 9.52551 26.5027 9.09533C26.2269 9.95084 25.6497 10.677 24.8784 11.1387C25.6107 11.0521 26.3258 10.856 26.9998 10.5571C26.5043 11.2989 25.8804 11.9464 25.1576 12.4693Z"
                                            fill="#E6E6E6"></path>
                                    </g>
                                </g>
                                <defs>
                                    <filter id="filter0_di" x="0" y="-0.000488281" width="36" height="36"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                            result="shape"></feBlend>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="1"></feOffset>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"></feBlend>
                                    </filter>
                                    <filter id="filter1_di" x="6.99982" y="8.823" width="22" height="18.6305"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                            result="shape"></feBlend>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.64 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"></feBlend>
                                    </filter>
                                </defs>
                            </svg>
                            <svg onClick={() => onJumpClick("Discord")} viewBox="0 0 36 36" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_di)">
                                    <rect x="2" y="-0.000488281" width="32" height="32" rx="16" fill="#7474AA"
                                        fillOpacity="0.32"></rect>
                                    <g filter="url(#filter1_di)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M20.5112 9.83971L20.5514 9.82507H20.5258L20.5112 9.83971ZM15.5483 9.8236C15.5483 9.8236 13.4745 9.74524 11.2373 11.3739C11.2373 11.3739 9.00009 15.2112 9.00009 19.9418C9.00009 19.9418 10.2406 22.0655 13.6407 22.1461C13.6407 22.1461 14.1372 21.495 14.6388 20.9231C12.729 20.3519 11.9857 19.2095 11.9857 19.2095L12.6243 19.5339L12.9963 19.6863C14.7597 20.4112 16.7003 21.2094 19.9876 20.6763L20.0388 20.6646C20.7653 20.5035 21.4924 20.3424 22.2182 20.0231C22.3514 19.9557 22.4935 19.8891 22.6429 19.8181C23.0347 19.6321 23.4777 19.4219 23.9501 19.1238C23.9501 19.1238 23.206 20.2662 21.2164 20.8374C21.6265 21.4064 22.2028 22.0582 22.2028 22.0582C24.9899 21.9981 26.3806 20.5614 26.8331 20.0941C26.9327 19.9916 26.9869 19.9352 27.0001 19.9513C27.0001 15.228 24.7497 11.3834 24.7497 11.3834C22.7381 9.91294 20.859 9.84704 20.5038 9.84557L20.2768 10.0689C22.8457 10.8012 24.0892 11.9451 24.0892 11.9451C20.3618 10.1092 16.3356 10.0045 12.6484 11.4544C12.0684 11.702 11.7367 11.8645 11.7367 11.8645C11.7367 11.8645 12.978 10.6416 15.7153 9.98837L15.5483 9.8236ZM22.3456 16.8405C22.3456 17.7435 21.6389 18.4758 20.7711 18.4758C19.9033 18.4758 19.1967 17.7435 19.1967 16.8478C19.1989 15.9442 19.907 15.2148 20.7711 15.2148V15.2112C21.6426 15.2112 22.3456 15.9435 22.3456 16.8405ZM15.1346 18.4758C16.0024 18.4758 16.709 17.7435 16.709 16.8405C16.709 15.9435 16.0075 15.2112 15.1397 15.2112L15.1346 15.2148C14.2668 15.2148 13.5601 15.9442 13.5601 16.8478C13.5601 17.7435 14.2668 18.4758 15.1346 18.4758Z"
                                            fill="#E6E6E6"></path>
                                    </g>
                                </g>
                                <defs>
                                    <filter id="filter0_di" x="0" y="-0.000488281" width="36" height="36"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                            result="shape"></feBlend>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="1"></feOffset>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"></feBlend>
                                    </filter>
                                    <filter id="filter1_di" x="7.00009" y="9.823" width="22" height="16.3231"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                            result="shape"></feBlend>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.64 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"></feBlend>
                                    </filter>
                                </defs>
                            </svg>
                            <svg onClick={() => onJumpClick("Medium")} viewBox="0 0 36 36" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_di)">
                                    <rect x="2" y="-0.000488281" width="32" height="32" rx="16" fill="#7474AA"
                                        fillOpacity="0.32"></rect>
                                    <g filter="url(#filter1_di)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M27 9.13463L25.5637 10.5584C25.5024 10.6071 25.4553 10.6714 25.4273 10.7445C25.3992 10.8176 25.3912 10.8969 25.4042 10.9741V21.4418C25.3915 21.5191 25.3996 21.5984 25.4278 21.6715C25.456 21.7446 25.5031 21.8088 25.5644 21.8576L26.9675 23.2806V23.5922H19.9078V23.2799L21.3626 21.8207C21.5044 21.6744 21.5044 21.6287 21.5044 21.4056V12.9473L17.4591 23.5649H16.9126L12.2086 12.9473V20.0631C12.1881 20.2099 12.2007 20.3595 12.2457 20.5007C12.2907 20.6419 12.3669 20.7713 12.4686 20.8791L14.359 23.2496V23.5612H9V23.2533L10.8905 20.8791C10.9907 20.7708 11.0647 20.6409 11.1066 20.4993C11.1484 20.3578 11.1571 20.2085 11.1319 20.0631V11.8367C11.1426 11.7247 11.1278 11.6118 11.0885 11.5064C11.0492 11.401 10.9866 11.3058 10.9052 11.2282L9.22745 9.13463V8.823H14.4439L18.4782 17.9703L22.0242 8.823H27V9.13463Z"
                                            fill="#E6E6E6"></path>
                                    </g>
                                </g>
                                <defs>
                                    <filter id="filter0_di" x="0" y="-0.000488281" width="36" height="36"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                            result="shape"></feBlend>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="1"></feOffset>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"></feBlend>
                                    </filter>
                                    <filter id="filter1_di" x="7" y="8.823" width="22" height="18.7692"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                            result="shape"></feBlend>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.64 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"></feBlend>
                                    </filter>
                                </defs>
                            </svg>
                            <svg onClick={() => onJumpClick("Telegram")} viewBox="0 0 36 36" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_di)">
                                    <rect x="2" y="-0.000488281" width="32" height="32" rx="16" fill="#7474AA"
                                        fillOpacity="0.32"></rect>
                                    <g filter="url(#filter1_di)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M25.8026 8.89856L9.83791 15.0535C8.74435 15.4806 8.76339 16.0866 9.65082 16.3681L13.737 17.6429L23.2173 11.6743C23.6577 11.3821 24.0658 11.5476 23.7322 11.8415L16.0607 18.7713L15.7668 22.9841C16.1948 22.9841 16.3811 22.7978 16.6013 22.5768L18.598 20.6538L22.7372 23.7043C23.4922 24.1315 24.0311 23.9113 24.2331 23.0031L26.9508 10.1983L26.9492 10.1991C27.1893 9.07903 26.5435 8.63034 25.8026 8.89856Z"
                                            fill="#E6E6E6"></path>
                                    </g>
                                </g>
                                <defs>
                                    <filter id="filter0_di" x="0" y="-0.000488281" width="36" height="36"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                            result="shape"></feBlend>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="1"></feOffset>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"></feBlend>
                                    </filter>
                                    <filter id="filter1_di" x="7.00015" y="8.823" width="22" height="19.0913"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                            result="effect1_dropShadow"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow"
                                            result="shape"></feBlend>
                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="2"></feOffset>
                                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                        <feColorMatrix type="matrix"
                                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.64 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"></feBlend>
                                    </filter>
                                </defs>
                            </svg>
                        </section>
                    </section>
                </div>
                <span className="all-rights">META2150S © 2022, All rights reserved</span>
            </section>
            {
                isShowPop ? <div className="pop">
                    <section className="pop-content">
                        <img className="close" src={CloseLoss} onClick={
                            () => setIsShowPop(false)
                        } alt="-_-" draggable={false} />
                        <img src={Success} alt="" draggable={false} />
                        <span className="success">SUCCESS！</span>
                        <div>Thank you for joining!</div>
                        Wait ‘till you see the next update…
                    </section>
                </div> : null
            }
        </section>
    );
}
