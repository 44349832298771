import { ChangeEvent, useEffect, useState } from "react";
import "../assets/css/collectionFolder.css"
import { dummyCommodity } from "../define/dummyInfo";
import CommodityItem from "./commodityItem";
import "../assets/css/marketplaceHome.css"
import { DbNFT } from "../prot/protocols/db/DbNFT";
import { getParsedAccountByMint } from "@nfteyez/sol-rayz";
import { Global } from "../global/global";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata"
import axios from "axios";
import { waitSeconds } from "../utils";
import { GetNFTInfo } from "../common/EvmAuction";
export interface IProjectCategory {
    title: string,
    project: string,
    owned: DbNFT[],
    listed: DbNFT[],
}

function CollectionFolder(props: {
    title: string,
    search: string,
    filter: "owned" | "listed" | "unverified",
    project?: IProjectCategory,
    mintAddress?: string[],
    isFolder?: boolean,
    ProjectIcon: { [key: string]: string },
}) {
    const [IsFold, setIsFold] = useState(true);
    const [GameItems, setGameItems] = useState(null as HTMLDivElement);
    const [Folder, setFolder] = useState(null as HTMLDivElement);
    const [Unverified, setUnverified] = useState([] as DbNFT[]);

    const onFoldClick = (e: ChangeEvent<HTMLInputElement>) => {
        setIsFold(e.target.checked);
        if (e.target.checked || !props.isFolder) {
            Folder.style.height = GameItems.clientHeight + "px";
        }
        else {
            Folder.style.height = "0px"
        }
    }
    useEffect(() => {
        if (Folder) {
            if (!props.isFolder) {
                Folder.style.height = GameItems.clientHeight + "px";
                setIsFold(true);
            }
            else {
                Folder.style.height = "0px";
                setIsFold(false);
            }
        }
    }, [Folder, props.isFolder, props.filter, props.project, props.mintAddress, Unverified]);

    useEffect(() => {
        if (props.mintAddress) {
            let p = fetchNFT();
            return () => {
                p.then(() => {
                    throw "break";
                })
            }
        }

    }, [props.mintAddress]);

    const fetchNFT = async () => {

        for (const mint of props.mintAddress) {
            const nft: DbNFT = {
                name: "",
                mintAddress: mint,
                uri: "",
                data: undefined,
                chain: mint.startsWith("0x") ? "BSC" : "SOL",
                category: "unverified",
                project: "unverified",
                order: undefined
            };
            try {
                if (mint.startsWith("0x")) {
                    const pair = mint.split("_");
                    const info = await GetNFTInfo(pair[0], pair[1]);
                    if (!info.result.tokenUri)
                        continue;
                    nft.uri = info.result.tokenUri;
                    nft.data = (await axios.get(nft.uri)).data
                    nft.name = `${nft.data.name} `/*#${ pair[1] }*/;
                }
                else {
                    const account = await Metadata.findByMint(Global.Connection, mint);
                    nft.uri = account.data.data.uri;
                    nft.data = (await axios.get(nft.uri)).data
                    nft.name = nft.data.name;

                }
                setUnverified(pre => {
                    return [...pre, nft]
                });
                await waitSeconds(1);
            } catch (error) {
                if (error == "break")
                    break;
                console.error(error);
                await waitSeconds(10);
            }

        }
    }


    const filteredList = () => {
        if (props.filter == "unverified") {
            return Unverified.filter(v => v.name.toLowerCase().match(props.search.toLowerCase())).map((v, i) => {
                return <CommodityItem nft={v} key={i} gameIcon={props.ProjectIcon[v.project]} />
            });
        }
        if (!props.project)
            return <></>
        if (props.filter == "listed") {
            console.log("show listed");

            return props.project.listed.map((v, i) =>
                <CommodityItem nft={v} key={i} gameIcon={props.ProjectIcon[v.project]} />
            );
        }
        if (props.filter == "owned") {
            return props.project.owned.map((v, i) =>
                <CommodityItem nft={v} key={i} gameIcon={props.ProjectIcon[v.project]} />
            );
        }
    }

    const filteredCount = () => {
        if (props.filter == "unverified") {
            return Unverified.length;
        }
        if (!props.project)
            return 0
        if (props.filter == "listed") {
            return props.project.listed.length;
        }
        if (props.filter == "owned") {
            return props.project.owned.length;
        }
    }

    return (<div className="collection-folder">
        {
            props.isFolder ?
                <div>
                    <label htmlFor={`"fold-toggle"${props.title}`} className="toggle-button" >
                        <h1>{`${props.title}(${filteredCount()})`}</h1>
                        <input type="checkbox" id={`"fold-toggle"${props.title}`} onChange={onFoldClick} />
                        <span id="tg-fold">{IsFold ? "▲" : "▼"}</span>
                    </label>
                    <div id="title-line" style={{ margin: "2px" }} />
                </div>
                :
                <></>
        }

        <div id={"fold-show"} ref={(ref) => { setFolder(ref) }} >
            <div className="game-items" style={{ backgroundColor: "transparent" }} ref={(ref) => { setGameItems(ref) }}>
                {filteredList()}
            </div>
            {
                props.isFolder ? <div id="title-line" style={{ margin: "2px" }} /> : <></>
            }

        </div>

    </div>);
}

export default CollectionFolder;