import { useContext, useEffect, useState } from "react";
import { Define } from "../define/define";
import { INavigation } from "../event/navigation";
import "../assets/css/market.css"
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from 'react-swipeable-views-utils';
import Indicator from "../components/indicator";
import RecommendCollection from "../components/recommendCollection";
import RecentProjects from "../components/recentProjects";
import MarketPlaceHome from "../components/marketplaceHome";
import rightArrow from "../assets/images/icon/PageTurn.png"
import AllCollections from "../components/allCollections";
import "../assets/css/newMarketCommon.css"
import { useNavigate } from "react-router-dom";
import { apiClient } from "../global/global";
import { GameStoreContext } from "../contexts/gameContext";
function Market(navigation: INavigation) {
    const { onShowWallet, onNavigation } = navigation;
    const navigate = useNavigate();
    const [swipeIndex, setSwipeIndex] = useState(0);
    const [showAllCollections, setShowAllCollections] = useState(false);
    const [ScrollImage, setScrollImage] = useState([
        // {
        //     url: "https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4wqI5?ver=bfb7",
        //     project: "test"
        // }
    ] as { url: string, project: string }[])
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
    const getScrollBanners = async (isMounted: boolean) => {
        const res = await apiClient.callApi("market/GetScrollBanners", { count: 6 });
        if (isMounted && res.isSucc) {
            setScrollImage(res.res.list);
        }
    }
    const storeCtx = useContext(GameStoreContext);

    useEffect(() => {
        let isMounted = true;
        onNavigation(Define.Page.Market);
        getScrollBanners(isMounted);
        return () => { isMounted = false };
    }, []);

    const onChangeIndex = (index: number) => {
        setSwipeIndex(index);
    }

    const onBannerClicked = (project: string) => {
        console.log(project);
        if (project == "Official") {
            navigate(Define.Page.Marketplace);
            return;
        }
        //设置context
        storeCtx.project = project;
        navigate(Define.Page.GameStore);
    }
    const scrollToTop = () => {
        document.getElementById("need-scroll").scroll({ top: 0, behavior: "smooth" })
    }

    const home = <div className="market-container-common-bg rect-corner" >
        <RecommendCollection onViewAllClicked={() => {
            setShowAllCollections(pre => {
                scrollToTop();
                return true;
            })
        }} />
        <RecentProjects />
        <MarketPlaceHome />
    </div>;
    const allCollections = <AllCollections onReturnClick={() => {
        setShowAllCollections(pre => {
            scrollToTop();
            return false;
        })
    }} />;

    return (
        <section className="market-container-common" id="need-scroll">
            <div className="left-page-arrow" onClick={() => { setSwipeIndex(pre => pre - 1 < 0 ? ScrollImage.length - 1 : pre - 1) }} >
                <img src={rightArrow} alt="" style={{ transform: "rotateY(180deg)" }} />
            </div>
            <div className="right-page-arrow" onClick={() => { setSwipeIndex(pre => pre + 1 >= ScrollImage.length ? 0 : pre + 1) }} >
                <img src={rightArrow} alt="" />
            </div>
            <div className="market-header" style={{ width: "100%", minHeight: "600px" }}>
                <AutoPlaySwipeableViews autoplay={true} className="swipe-container" index={swipeIndex} onChangeIndex={onChangeIndex}>
                    {ScrollImage.map((v, i) => {
                        return <div key={i} onClick={() => { onBannerClicked(v.project) }} style={{ backgroundImage: `url(${v.url})`, backgroundPosition: "center", backgroundSize: "cover", backgroundColor: "#110f24", width: "100%", height: "900px" }}></div>
                    })}
                </AutoPlaySwipeableViews>

                <div className="swipe-shadow" />
                <Indicator index={swipeIndex} count={ScrollImage.length} onChangeIndex={onChangeIndex} />
            </div>
            {showAllCollections ? allCollections : home}
        </section >
    );
}

export default Market;