import GameHeaderBackground from "../components/gameHeaderBackground";
import "../assets/css/newMarketCommon.css";
import "../assets/css/gameDetails.css";
import SocialIcons from "../components/socialIcons";
import GameFilterBar from "../components/gameFilterBar";
import CommodityItem from "../components/commodityItem";
import { useContext, useEffect, useState } from "react";
import { INavigation } from "../event/navigation";
import { Define } from "../define/define";
import { useNavigate } from "react-router-dom";
import { DbProject } from "../prot/protocols/db/DbProject";
import { apiClient, Global } from "../global/global";
import { GameStoreContext } from "../contexts/gameContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { DbNFT } from "../prot/protocols/db/DbNFT";

interface IPreview {
    src: string,
    preview: string,
    type: "video" | "image",
}
function GameDetails(navigation: INavigation) {
    const ctx = useContext(GameStoreContext)

    const navigate = useNavigate();
    const { onShowWallet, onNavigation } = navigation;
    const [preview, setPreView] = useState(<></>);
    const [Listings, setListings] = useState([] as DbNFT[]);
    const [FilteredListings, setFilteredListings] = useState([] as DbNFT[]);
    const [Project, setProject] = useState(null as DbProject);
    const [previewList, setPreViewList] = useState([] as IPreview[]);
    const [IsLowToHigh, setIsLowToHigh] = useState(true);
    const [ProjectIcon, setProjectIcon] = useState({} as { [key: string]: string })

    const [Search, setSearch] = useState("")
    useEffect(() => {
        if (ctx.project == "default") {
            navigate(Define.Page.Games);
            return;
        }
        onNavigation(Define.Page.GameDetail);
        fetchDetail();
        fetchListings();
        getProjectList();
    }, []);

    const getProjectList = async () => {
        const res = await apiClient.callApi("market/GetProjectList", { filter: "base" });
        if (res.isSucc) {
            let map: { [key: string]: string } = {};
            for (const iterator of res.res.list) {
                map[iterator.name] = iterator.gameIconUrl;
            }
            setProjectIcon(map);
        }
    }

    const fetchDetail = async () => {
        const call = await apiClient.callApi("market/GetProjectDetail", { project: ctx.project });
        if (call.isSucc) {
            console.log(call.res);

            setProject(call.res);
            console.log(call.res.games.description);

            if (call.res.games) {
                const preview: IPreview[] = [];

                preview.push({
                    src: call.res.games.videoUrl,
                    preview: call.res.games.videoPreviewImageUrl,
                    type: "video"
                })
                for (const url of call.res.games.previewImageUrls) {
                    preview.push({
                        src: url,
                        preview: url,
                        type: "image"
                    });
                }

                setPreViewList(preview);
            }
        }
    }

    const setPreviewContent = (i: number) => {
        console.log("SET PREVIEW", i);
        if (previewList.length <= i)
            return;

        const video = <video src={previewList[i].src} autoPlay muted loop />
        const ytb = <iframe src={previewList[i].src + "?autoplay=1&mute=1"}> </iframe>

        setPreView(
            previewList[i].type == "video" ?
                (previewList[i].src.startsWith("https://www.youtube.com/") ? ytb : video)
                :
                <img src={previewList[i].src} />
        );
    }

    useEffect(() => {
        setPreviewContent(0);
    }, [previewList])
    useEffect(() => {
        setFilteredListings(Listings.filter(v => !v.order.isOfficial));
    }, [ctx, Listings]);
    const onPreViewClicked = (index: number) => {
        setPreviewContent(index);
    }
    const onFilterClicked = (filter: string) => {
        if (filter == "official") {
            setFilteredListings(Listings.filter(v => v.order.isOfficial));
        }
        else if (filter == "items") {
            setFilteredListings(Listings.filter(v => !v.order.isOfficial));
        }
    }

    const fetchListings = async () => {
        const res = await apiClient.callApi("market/GetProjectListing", { project: ctx.project, start: Listings.length, count: 20 });
        if (res.isSucc) {
            setListings(res.res.list);
        }
    }
    const onReturnClicked = () => {
        navigate(Define.Page.Games);
    }

    const onPlayClick = () => {

        Global.Open(Project?.playUrl);
    }

    return (<section className="market-container-common">
        <GameHeaderBackground blur="1px" cover src={Project?.market?.bannerImageUrl} />
        <div className="game-detail-container">
            <div className="game-detail-return-button">
                <button className="return-button-common" onClick={onReturnClicked}>Return</button>
            </div>
            <div id="detail-container">
                <div id="previews">
                    <div className="video-container">
                        <div>
                            {preview}
                        </div>
                    </div>
                    <div className="preview-img-list">
                        {previewList.map((v, i) => {
                            return (<div key={i} >
                                <span>
                                    <img src={v.preview} alt="" onClick={() => { onPreViewClicked(i) }} />
                                </span>
                            </div>)
                        })}
                    </div>
                </div>

                <div id="detail-info">
                    <h1>{Project?.title}</h1>
                    <div id="info-props">
                        <div>
                            <h2>GENRE</h2>
                            <h3>{Project?.games?.genre}</h3>
                        </div>
                        <div>
                            <h2>DEVELOPER</h2>
                            <h3>{Project?.games?.developer}</h3>
                        </div>
                        <div>
                            <h2>DEVELOPMENT STATUS</h2>
                            <h3>{Project?.games?.developmentStatus}</h3>
                        </div>
                        <div>
                            <h2>PLATFORM</h2>
                            <h3>{Project?.games?.platform}</h3>
                        </div>
                    </div>
                    <p>{`${Project?.games?.description.slice(0, 300)}`}</p>
                    <SocialIcons direction="ltr" home={Project?.market?.homeUrl} twitter={Project?.market?.twitterUrl} discord={Project?.market?.discordUrl} telegram={Project?.market?.telegramUrl} />
                    {
                        Project?.playUrl ? <button onClick={onPlayClick}>PLAY</button> : <></>
                    }

                </div>
            </div>
            <div className="market-container-common-bg" >
                <GameFilterBar onFilterClicked={onFilterClicked} onPriceSortChange={setIsLowToHigh} onSearch={setSearch} />
                <div style={{ maxWidth: "100%", height: "100%", margin: 25 }}>
                    <InfiniteScroll style={{ minHeight: "300px", overflow: "hidden" }} className="game-items" next={fetchListings} hasMore={true} loader={""} dataLength={FilteredListings.length} >
                        {
                            FilteredListings
                                .filter(v => {
                                    if (!Search || Search == "")
                                        return true;

                                    return v.name.toLowerCase().match(Search);
                                })
                                .sort(
                                    (a, b) => {
                                        if (IsLowToHigh)
                                            return parseFloat(a.order.price) - parseFloat(b.order.price);
                                        else
                                            return parseFloat(b.order.price) - parseFloat(a.order.price);
                                    }
                                )
                                .map((v, i) =>
                                    <CommodityItem key={i} nft={v} gameIcon={ProjectIcon[v.project]} />)
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    </section>);
}

export default GameDetails;