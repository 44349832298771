import searchIcon from "../assets/images/icon/seach_icon.png"
import "../assets/css/gameSearchBar.css"

function GameSearchBar(props: { onValueChanged: (value: string) => void }) {
    return (<div id="filter-search-content">
        <img src={searchIcon} alt="" />
        <input className="filter-search" type="text" placeholder="Search" onChange={(e) => {
            props.onValueChanged(e.target.value.toLowerCase());
        }} />
    </div>);
}

export default GameSearchBar;