import "../assets/css/newMarketCommon.css"
import "../assets/css/commodityDetail.css"
import bnbIcon from "../assets/images/icon/BNB.png";
import solIcon from "../assets/images/icon/SOL.png";
import { apiClient, copyTextToClipboard, Global, LimitInputNumber } from "../global/global";
import { useContext, useEffect, useState } from "react";
import { TradeState } from "../prot/protocols/auction/Commodity";
import { dummyCommodity } from "../define/dummyInfo";
import CommodityItem from "./commodityItem";
import { DbNFT } from "../prot/protocols/db/DbNFT";
import { useNavigate } from "react-router-dom";
import { ParsedAccountData, PublicKey } from "@solana/web3.js";
import { Define } from "../define/define";
import { Buy, CancelSell, Sell } from "../common/ServerAuction";
import { CommodityContext, CommodityHistoryContext } from "../contexts/commodityContext";
import { DbProject } from "../prot/protocols/db/DbProject";
import axios from "axios";
import { GetNFTInfo } from "../common/EvmAuction";

function CommodityDetail(props: { commodityInfo?: DbNFT, setWalletShow: Function }) {
    const navigate = useNavigate();
    const [CanBuy, setCanBuy] = useState(false);
    const [CanSell, setCanSell] = useState(false);
    const [CanCancel, setCanCancel] = useState(false);
    const [Price, setPrice] = useState("911");
    const [Owner, setOwner] = useState("--");
    const [Collection, setCollection] = useState([] as DbNFT[]);
    const [DisableButton, setDisableButton] = useState(false);
    // const [Attributes, setAttributes] = useState(dummyCommodity.data.attributes);
    const [NFT, setNFT] = useState(dummyCommodity);
    const ctx = useContext(CommodityContext);
    const history = useContext(CommodityHistoryContext);
    const [ProjectIcon, setProjectIcon] = useState({} as { [key: string]: string });
    const [Project, setProject] = useState(null as DbProject)

    useEffect(() => {
        if (ctx._id == "630c462ee9dd1399f5bd071a") {
            navigate(Define.Page.Market);
            return;
        }
        getProjectInfo();
        getProjectList();
    }, []);
    useEffect(() => {
        console.log("refresh detail");

        refresh(props.commodityInfo);
    }, [props.commodityInfo, ctx.mintAddress]);

    const refresh = async (nft: DbNFT) => {
        console.log("REFRESH", TradeState[nft.order.tradeState]);
        if (!nft.data.image) {
            console.log("not standard nft metadata");
            nft.data = (await axios.get(nft.uri)).data;
        }
        // console.log(nft);

        setNFT(nft);
        fetchState(nft);
        await fetchCollections(nft);
    }
    const getProjectList = async () => {
        const res = await apiClient.callApi("market/GetProjectList", { filter: "base" });
        if (res.isSucc) {
            let map: { [key: string]: string } = {};
            for (const iterator of res.res.list) {
                map[iterator.name] = iterator.gameIconUrl;
            }
            // console.log(map);

            setProjectIcon(map);
        }
    }
    const getProjectInfo = async () => {
        const call = await apiClient.callApi("market/GetProjectDetail", { project: props.commodityInfo.project })
        if (call.isSucc)
            setProject(call.res);
    }

    const fetchNFT = async () => {
        const res = await apiClient.callApi("market/GetNFTListing", { mintAddress: NFT.mintAddress });
        if (res.isSucc) {
            for (const key in res.res) {
                if (Object.prototype.hasOwnProperty.call(res.res, key)) {
                    (ctx as any)[key] = (res.res as any)[key];
                }
            }
            await refresh(res.res);
        }
    }

    const fetchCollections = async (nft: DbNFT) => {

        const res = await apiClient.callApi("market/GetProjectListing", { project: nft.project, start: 0, count: 20 });
        console.log("fetch collection", res);
        if (res.isSucc) {
            const list = res.res.list.filter(v => v.mintAddress != nft.mintAddress);
            setCollection(list);
        }
    }
    const getOwner = async (mintAddress: string): Promise<string> => {
        if (mintAddress.startsWith("0x")) {
            const pair = mintAddress.split("_");
            const nft = await GetNFTInfo(pair[0], pair[1]);
            console.log(nft);

            return nft.result.ownerOf.lowercase;
        }
        else {
            const accounts = await Global.Connection.getTokenLargestAccounts(new PublicKey(mintAddress));
            const accountInfo = await Global.Connection.getParsedAccountInfo(accounts.value[0].address)
            console.log(accounts.value[0].address.toBase58());

            return (accountInfo.value.data as ParsedAccountData).parsed.info.owner;
        }
    }
    const fetchState = async (nft: DbNFT) => {
        // console.log(nft);

        const owner = nft.order.tradeState == TradeState.OnSale ? nft.order.seller : await getOwner(nft.mintAddress);
        const onSale = nft.order.tradeState == TradeState.OnSale;
        const isOwner = nft.chain == "SOL" ?
            Global.WalletConnected("SOL") && owner == Global.WalletAdapter.publicKey.toBase58()
            :
            Global.WalletConnected("BNB") && owner == Global.EthAddress;
        console.log(Global.WalletConnected("BNB"), owner, Global.EthAddress);

        console.log(isOwner);
        if (onSale)
            setPrice(nft.order.price);
        setCanBuy(onSale && !isOwner);
        setCanSell(nft.order.tradeState == TradeState.CanSell && isOwner);
        setCanCancel(onSale && isOwner);
        setOwner(owner);
    }

    const onBuyClick = async () => {
        if ((NFT.chain == "SOL" && !Global.WalletConnected("SOL")) || (NFT.chain == "BSC" && !Global.WalletConnected("BNB"))) {
            props.setWalletShow(true);
            return;
        }
        Global.ShowLoading(true);
        setDisableButton(true);
        const succ = await Buy(NFT);
        await fetchNFT();
        setDisableButton(false);
        Global.ShowLoading(false);
    }
    const onSellClick = async () => {
        setDisableButton(true);
        Global.ShowLoading(true);
        const succ = await Sell(NFT, Price);
        await fetchNFT();
        setDisableButton(false);
        Global.ShowLoading(false);
    }
    const onCancelClick = async () => {
        Global.ShowLoading(true);
        setDisableButton(true);
        const succ = await CancelSell(NFT);
        await fetchNFT();
        setDisableButton(false);
        Global.ShowLoading(false);
    }

    const tradePrice = () => {
        if (CanBuy || CanCancel)
            return <h2>{Price}</h2>
        if (CanSell)
            return <input type="text" placeholder="List Price" onChange={(e) => {
                const price = LimitInputNumber(e);
                setPrice(price.toFixed(2))
            }} />;

        return <h2>--</h2>
    }
    const tradeButton = () => {
        if (CanBuy)
            return <button className="commodity-button buy-now" disabled={DisableButton} onClick={onBuyClick}>{tradeLabel()}</button>;
        if (CanSell)
            return <button className="commodity-button list-now" disabled={DisableButton} onClick={onSellClick}>{tradeLabel()}</button>;
        if (CanCancel)
            return <button className="commodity-button cancel-list" disabled={DisableButton} onClick={onCancelClick}>{tradeLabel()}</button>;

        return <button disabled className="commodity-button cancel-list">--</button>;
    }
    const tradeLabel = () => {
        if (CanBuy)
            return "Buy Now";
        if (CanSell)
            return "List Now";
        if (CanCancel)
            return "Cancel List";
    }

    const onReturnClick = () => {
        if (history.length > 0) {
            const old = JSON.parse(history.pop());
            for (const key in old) {
                if (Object.prototype.hasOwnProperty.call(old, key)) {
                    (ctx as any)[key] = old[key];
                }
            }
        }
        navigate(-1);
    }

    const getTransactionFee = () => {

        if (!Project)
            return "0%";
        if (!Project.auction)
            return "0%";

        return (Number(Project.auction.transactionFee) / 100) + "%"
    }
    const getListingFee = () => {

        if (!Project)
            return "0%";
        if (!Project.auction)
            return "0%";

        return (Number(Project.auction.listingFee) / 100) + "%"
    }
    return (<div className="market-container-common-bg">
        <div className="commodity-return-button">
            <button className="return-button-common" onClick={onReturnClick}>Return</button>
        </div>
        <div className="commodity-card-container">
            <div className="commodity-cards">
                <div className="first-card">
                    <img className="nft-image" style={{ backgroundImage: `url(${NFT.data.image || (NFT.data as any).img})` }} src="" />
                    <div>
                        <div id="game-title">
                            <img id="game-icon" src={ProjectIcon[NFT.project]} alt="icon" />
                            <label>{NFT.name}</label>
                        </div>
                        <p>{NFT.data.description || (NFT.data as any).desc}</p>
                        <h1>Selling Price</h1>
                        <div>
                            <img src={NFT.chain == "SOL" ? solIcon : bnbIcon} />
                            {tradePrice()}
                        </div>
                        {tradeButton()}
                        <h3>{`By clicking "${tradeLabel()}",you agree to the Terms of Service.`}</h3>
                    </div>
                </div>
                <div className="second-card">
                    <h6>Details</h6>
                    <div>
                        <h1>Mint address</h1>
                        <h2 onClick={() => copyTextToClipboard(NFT.mintAddress)} style={{ cursor: "pointer" }}>{Global.getAddrShort(NFT.mintAddress)}</h2>
                    </div>
                    <div>
                        <h1>Token address</h1>
                        <h2 onClick={() => copyTextToClipboard(NFT.chain == "SOL" ? Define.SOLAddress : Define.BSCAddress)} style={{ cursor: "pointer" }}>{Global.getAddrShort(NFT.chain == "SOL" ? Define.SOLAddress : Define.BSCAddress)}</h2>
                    </div>
                    <div>
                        <h1>Owner</h1>
                        <h2 onClick={() => copyTextToClipboard(Owner ? Owner : "")} style={{ cursor: "pointer" }}>{Global.getAddrShort(Owner)}</h2>
                    </div>
                    <div>
                        <h1>Artist Royalties</h1>
                        <h2>{NFT.data.seller_fee_basis_points ? `${(NFT.data.seller_fee_basis_points / 100).toPrecision(2)}%` : "0%"}</h2>
                    </div>
                    <div>
                        <h1>Transaction Fee</h1>
                        <h2>{getTransactionFee()}</h2>
                    </div>
                    <div>
                        <h1>Listing Fee</h1>
                        <h2>{getListingFee()}</h2>
                    </div>
                </div>
                <div className="third-card">
                    <h6>Attributes</h6>
                    <div className="attributes-view">
                        <div className="attributes-content">
                            {
                                NFT.data.attributes?.map((v, i) => (
                                    <div className="attribute" key={i}>
                                        <h1>{v.trait_type}</h1>
                                        <h2>{v.value}</h2>
                                    </div>
                                ))
                            }

                        </div>

                    </div>
                </div>

            </div>
        </div>

        <h1 id="stretch-title">More From This Collection</h1>
        <div >
            {
                Collection.map(
                    (v, i) => {
                        return <CommodityItem key={i} fixedWidth={204} light nft={v} gameIcon={ProjectIcon[v.project]} />
                    }
                )
            }
        </div>

    </div>);
}

export default CommodityDetail;