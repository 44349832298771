import "../assets/css/gameHeaderBackground.css"
import defaultBG from "../assets/images/home/web-bg-top.png"
function GameHeaderBackground(props: { blur?: string, src?: string, cover?: boolean }) {
    return (<div className="game-header-background" >
        <span className="game-header-background-img" style={{ backgroundImage: `url(${props.src})`, filter: `blur(${props.blur})` }} />
        <div id={props.cover ? "cover-shadow" : ""} />
        <div className="game-header-background-shadow" />
    </div>);
}

export default GameHeaderBackground;