import { PublicKey, Transaction } from "@solana/web3.js";
import { BigNumber, utils } from "ethers";
import { Define } from "../define/define";
import { apiClient, Global, } from "../global/global";
import { Commodity, TradeState } from "../prot/protocols/auction/Commodity";
import { ApproveAllNFT, ApproveSell, ApproveToken, GetContract, TokenTransfer } from "./EvmAuction";
import BN from "bn.js";
import web3 from "web3";
import { OfficialItem } from "../prot/protocols/base";
import { DbNFT } from "../prot/protocols/db/DbNFT";
import { ObjectId } from "mongodb";
import { TransferTokens } from "./solTarnsfer";
import { DbStakeCoin, MitCoinBSC, MitCoinDecimalBSC, MitCoinDecimalSOL, MitCoinSOL } from "../prot/protocols/db/DbStaking";
import { waitSeconds } from "../utils";

export async function BuyOfficialItem(item: OfficialItem) {
    //检查钱是否足够
    if (item.chain == "SOL") {
        const balance = await Global.Connection.getBalance(Global.WalletAdapter.publicKey);
        console.log(balance, Global.AdminConfig.Decimals[Define.Token.SOL], item.price);

        if (balance < parseFloat(item.price) * Global.AdminConfig.Decimals[Define.Token.SOL]) {
            Global.AddMessage("Purchase Failed", undefined, "balance not enough.");
            return false;
        }
    }
    const orderRes = await apiClient.callApi("auction/GetOfficialItemOrder", {
        buyer: Global.WalletAdapter.publicKey.toBase58(),
        ...item,
    });

    if (!orderRes.isSucc) {
        Global.AddMessage("Purchase Failed", undefined, "please retry after a while.");
        return false;
    }

    try {
        const { transaction, mintAddress, order, orderId, itemId } = orderRes.res;
        const tx = Transaction.from(Buffer.from(transaction));
        const msgkey = Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
        const signed = await Global.WalletAdapter.signTransaction(tx);
        Global.HideFloatMessage(msgkey)
        const buyRes = await apiClient.callApi("auction/BuyOfficialItem", {
            order,
            buyer: Global.WalletAdapter.publicKey.toBase58(),
            mintAddress,
            transaction: signed.serialize().buffer,
            orderId,
            itemId,
        });
        if (!buyRes.isSucc) {
            Global.AddMessage("Purchase Failed", buyRes.err.txId);
        } else {
            Global.AddMessage("Purchase Confirmed", buyRes.res.txId);
        }
        return buyRes.isSucc;
    } catch (error) {
        Global.AddMessage("Purchase Failed", undefined, "please retry after a while.");
        return false;
    }

}

export async function Buy(nft: DbNFT) {
    const buyer = Global.WalletAddress;
    const commodity = nft.order;
    if (commodity.tradeState != TradeState.OnSale)
        return false;
    //检查钱是否足够
    if (nft.chain == "SOL") {
        const balance = await Global.Connection.getBalance(Global.WalletAdapter.publicKey);
        if (balance < parseFloat(commodity.price) * Global.AdminConfig.Decimals[Define.Token.SOL]) {
            Global.AddMessage("Purchase Failed", undefined, "balance not enough.");
            return false;
        }
    }
    else if (nft.chain == "BSC") {
        const addr = await Global.EthProvider.getSigner().getAddress();
        const balance = (await Global.EthProvider.getBalance(addr));
        if (balance.lt(BigNumber.from(web3.utils.toWei(commodity.price, "ether")))) {
            Global.AddMessage("Purchase Failed", undefined, "balance not enough.");
            return false;
        }
    }

    const orderRes = await apiClient.callApi("auction/GetBuyOrder", {
        buyer,
        mintAddress: nft.mintAddress,
        price: commodity.price,
        chain: nft.chain,
        spl: commodity.spl,
        category: nft.category,
        project: nft.project
    });
    if (!orderRes.isSucc) {
        Global.AddMessage("Purchase Failed", undefined, "please retry after a while.");
        return false;
    }
    try {
        const { transaction, order, orderId, contractOrderNo } = orderRes.res;
        if (nft.chain == "SOL") {
            const tx = Transaction.from(Buffer.from(transaction));
            const msgkey = Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            const signed = await Global.WalletAdapter.signTransaction(tx);
            Global.HideFloatMessage(msgkey)

            const buyRes = await apiClient.callApi("auction/Buy", {
                order,
                orderId,
                buyer,
                transaction: signed.serialize().buffer,
                mintAddress: nft.mintAddress,
                project: nft.project,
            });
            if (!buyRes.isSucc) {
                Global.AddMessage("Purchase Failed", buyRes.err.txId);
            }
            else
                Global.AddMessage("Purchase Confirmed", buyRes.res.txId);
            return buyRes.isSucc;
        }
        else if (nft.chain == "BSC") {
            const msgkey = Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            const orderNo = BigNumber.from(orderRes.res.contractOrderNo);
            const tx = await GetContract(nft.order.isOfficial).purchase(orderNo, { value: BigNumber.from(web3.utils.toWei(nft.order.price, "ether")) });
            Global.HideFloatMessage(msgkey);
            await tx.wait();
            console.log(tx);

            const buyRes = await apiClient.callApi("auction/Buy", {
                order,
                orderId,
                buyer,
                mintAddress: nft.mintAddress,
                project: nft.project,
            });
            if (!buyRes.isSucc) {
                Global.AddMessage("Purchase Failed", tx.hash);
            }
            else
                Global.AddMessage("Purchase Confirmed", tx.hash);
            return buyRes.isSucc;
        }

    } catch (error) {
        console.error(error);

        Global.AddMessage("Purchase Failed", undefined, "please retry after a while.");
        return false;
    }

}

export async function Sell(nft: DbNFT, price: string) {
    if (isNaN(Number(price)) || Number(price) == 0)
        return;
    const seller = Global.WalletAddress;
    try {

        if (nft.chain == "SOL") {
            const orderRes = await apiClient.callApi("auction/GetSellOrder", {
                chain: nft.chain,
                project: nft.project,
                category: nft.category,
                mintAddress: nft.mintAddress,
                spl: "SOL",
                seller,
                price,
            });
            if (!orderRes.isSucc) {
                console.log(orderRes);
                Global.AddMessage("Listing Failed", undefined, "please retry after a while.");
                throw "";
            }

            const { transaction, order, orderId } = orderRes.res;

            const tx = Transaction.from(Buffer.from(transaction));
            const msgkey = Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            const signed = await Global.WalletAdapter.signTransaction(tx);
            Global.HideFloatMessage(msgkey)

            const sellRes = await apiClient.callApi("auction/Sell", {
                seller,
                order,
                transaction: signed.serialize().buffer,
                orderId,
                project: nft.project,
            })
            if (!sellRes.isSucc) {
                Global.AddMessage("Listing Failed", sellRes.err.txId);
            }
            else
                Global.AddMessage("Listing Confirmed", sellRes.res.txId);

            return sellRes.isSucc;
        }
        else if (nft.chain == "BSC") {

            const call = await apiClient.callApi("market/GetProjectDetail", { project: nft.project });
            if (!call.isSucc)
                throw call.err;

            //1.approve nft
            const pair = nft.mintAddress.split("_");
            const msgkey = Global.AddMessage("Approve NFT", undefined, `Please Approve with YOUR WALLET`, 60000);
            const approved = await ApproveSell(
                nft.order.isOfficial ?
                    Global.Contract.Chains.BSC.ThirdAuctionContract :
                    Global.Contract.Chains.BSC.PlayerAuctionContract,
                pair[0],
                parseInt(pair[1])
            );
            if (!approved) {
                throw "approve failed";
            }
            Global.HideFloatMessage(msgkey);

            //2.卖单
            const orderRes = await apiClient.callApi("auction/GetSellOrder", {
                chain: nft.chain,
                project: nft.project,
                category: nft.category,
                mintAddress: nft.mintAddress,
                spl: "BNB",
                seller,
                price,
            });
            if (!orderRes.isSucc) {
                console.log(orderRes);
                Global.AddMessage("Listing Failed", undefined, "please retry after a while.");
                throw "";
            }
            const { order, orderId } = orderRes.res;

            //3.售卖
            const confirmKey = Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            const _price = BigNumber.from(web3.utils.toWei(price, "ether"));
            const _rate = BigNumber.from(10000);
            const _fee = call.res.auction.listingFee;
            console.log(call.res, orderRes.res);
            console.log(pair, _price.toString(), _rate.toString(), _fee);
            let hashAddress = ""
            if (_fee != "0") {
                console.log("has listing fee", nft.order.isOfficial);

                const tx = await GetContract(nft.order.isOfficial).postListing(
                    pair[0],
                    Number(pair[1]),
                    BigNumber.from(web3.utils.toWei(price, "ether")),
                    BigNumber.from(call.res.contractNo),
                    { value: _price.div(_rate).mul(_fee) }
                );
                await tx.wait();
                hashAddress = tx.hash;
            }
            else {
                console.log("no listing fee", nft.order.isOfficial);

                const tx = await GetContract(nft.order.isOfficial).postListing0(
                    pair[0],
                    Number(pair[1]),
                    BigNumber.from(web3.utils.toWei(price, "ether")),
                    BigNumber.from(call.res.contractNo),
                    // { gasLimit: BigNumber.from(30000) }
                );
                await tx.wait();
                hashAddress = tx.hash;

            }
            Global.HideFloatMessage(confirmKey);

            const sellRes = await apiClient.callApi("auction/Sell", {
                seller,
                order,
                orderId,
                project: nft.project,
            })
            if (!sellRes.isSucc) {
                Global.AddMessage("Listing Failed", hashAddress);
            }
            else
                Global.AddMessage("Listing Confirmed", hashAddress);

            return sellRes.isSucc;
        }

    } catch (error) {
        Global.AddMessage("Listing Failed");
        console.error(error);

        return false;
    }
}

export async function CancelSell(item: DbNFT) {
    console.log(item);

    const orderRes = await apiClient.callApi("auction/GetCancelSellOrder", {
        mintAddress: item.mintAddress,
        order: item.order.order,
        project: item.project,
    });

    if (!orderRes.isSucc) {
        Global.AddMessage("Listing Failed", undefined, "please retry after a while.");
        return false;
    }
    try {
        if (item.chain == "SOL") {
            const tx = Transaction.from(Buffer.from(orderRes.res.transaction));
            const msgkey = Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            const signed = await Global.WalletAdapter.signTransaction(tx);
            Global.HideFloatMessage(msgkey)

            const result = await apiClient.callApi("auction/CancelSell", {
                orderId: orderRes.res.orderId,
                transaction: signed.serialize().buffer,
                order: item.order.order,
                project: item.project,
            });
            if (result.isSucc) {
                Global.AddMessage("Cancel Listing Confirmed", result.res.txId);
            }
            else {
                Global.AddMessage("Cancel Listing Failed", result.err.txId);
            }
            return result.isSucc;
        }
        else if (item.chain == "BSC") {
            const orderNo = BigNumber.from(orderRes.res.contractOrderNo);

            const msgkey = Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            const tx = await GetContract(item.order.isOfficial).cancelListing(orderNo);
            Global.HideFloatMessage(msgkey);
            await tx.wait();
            console.log(tx);

            const result = await apiClient.callApi("auction/CancelSell", {
                orderId: orderRes.res.orderId,
                order: item.order.order,
                project: item.project,
            });
            if (result.isSucc) {
                Global.AddMessage("Cancel Listing Confirmed", tx.hash);
            }
            else {
                Global.AddMessage("Cancel Listing Failed", tx.hash);
            }
            return result.isSucc;
        }
    } catch (error) {
        console.error(error);

        Global.AddMessage("Cancel Listing Failed", undefined);
        return false;

    }

}


export async function StakeNFT(poolId: string, tokens: {
    _id: ObjectId;
    address: string;
    amount: string;
    isNft: boolean;
    decimal: number;
}[], coinList: DbStakeCoin[]) {
    const account = Global.WalletAddress;
    const chain = account.startsWith("0x") ? "BSC" : "SOL";

    try {
        const callOrder = await apiClient.callApi("staking/GetStakeOrder", {
            account,
            chain,
            tokens,
            poolId,
            isTake: false,
        })
        if (!callOrder.isSucc)
            throw callOrder.err;

        const { orderId, target, price, costAddress } = callOrder.res;

        let hashAddress = ""
        //执行合约交易
        if (chain == "BSC") {
            tokens.push({
                _id: "",
                address: MitCoinBSC,
                amount: price,
                isNft: false,
                decimal: MitCoinDecimalBSC,
            });
            //授权
            const nftContracts = new Set<string>();
            const tokenContracts: { address: string, amount: BigNumber, decimal: number }[] = [];

            const addressList = [];
            const amountList = [];
            const isNftList = [];

            for (const iterator of tokens) {
                isNftList.push(iterator.isNft);
                const pair = iterator.address.split("_");
                addressList.push(iterator.isNft ? pair[0] : iterator.address);
                amountList.push(BigNumber.from(iterator.isNft ? Number(pair[1]) : iterator.amount));

                if (iterator.isNft) {
                    if (!nftContracts.has(pair[0]))
                        nftContracts.add(pair[0]);
                }
                else
                    tokenContracts.push({ address: iterator.address, amount: BigNumber.from(iterator.amount), decimal: iterator.decimal });
            }
            console.log(addressList);

            for (const iterator of Array.from(nftContracts)) {
                Global.AddMessage("Approve NFT", undefined, `Please Approve with YOUR WALLET`);
                await ApproveAllNFT(Global.Contract.Chains.BSC.StakingContractAddress, iterator)
            }
            for (const iterator of tokenContracts) {
                let decimal = iterator.decimal;
                let num = 0;
                if (iterator.amount.toString().length > 6) {
                    decimal -= 6;
                    num = iterator.amount.div(BigInt(10) ** BigInt(6)).toNumber();
                    num = num / Math.pow(10, decimal);
                }
                else {
                    num = iterator.amount.toNumber() / Math.pow(10, decimal);
                }
                console.log(iterator.amount.toString(),iterator.decimal);
                
                Global.AddMessage("Approve Token " + num, undefined, `Please Approve with YOUR WALLET`);
                await ApproveToken(Global.Contract.Chains.BSC.StakingContractAddress, iterator.address, iterator.amount)
            }

            //发送
            Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            hashAddress = await TokenTransfer(Global.Contract.Chains.BSC.StakingContractAddress, target, addressList, amountList, isNftList);

        }
        else if (chain == "SOL") {
            //pay
            tokens.push({
                _id: "",
                address: MitCoinSOL,
                amount: price,
                isNft: false,
                decimal: MitCoinDecimalSOL,
            });
            console.log("target", target);
            Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            hashAddress = await TransferTokens(Global.WalletAdapter.publicKey, new PublicKey(target), tokens.map(v => ({
                decimal: v.decimal,
                amount: BigInt(v.amount),
                address: v.address
            })));
        }
        let unfinishedStake: { orderId: string, hashAddress: string }[] = JSON.parse(localStorage.getItem("unfinishedStake"));
        if (!unfinishedStake)
            unfinishedStake = [];
        unfinishedStake.push({ orderId, hashAddress });
        localStorage.setItem("unfinishedStake", JSON.stringify(unfinishedStake));
        //验证并添加
        const call = await apiClient.callApi("staking/AddStakeNFT", { orderId, hashAddress });

        let orderList: { orderId: string, hashAddress: string }[] = JSON.parse(localStorage.getItem("unfinishedStake"));
        orderList.splice(orderList.findIndex(a => a.orderId == orderId), 1);
        localStorage.setItem("unfinishedStake", JSON.stringify(orderList));

        if (!call.isSucc)
            throw call.err;
        Global.AddMessage("Transaction Confirmed", hashAddress);

        return call.isSucc;
    } catch (error) {
        console.error(error);
        Global.AddMessage("Stake NFT Failed");

        return false;
    }

}
export async function SolveUnfinishedOrder() {
    if (!Global.WalletConnected("BNB") && !Global.WalletConnected("SOL"))
        return;

    let orderList: { orderId: string, hashAddress: string }[] = JSON.parse(localStorage.getItem("unfinishedStake"));
    if (!orderList)
        return;
    console.log(orderList);
    Global.AddMessage("Solving Unfinished Order");

    let completeIndex: string[] = [];
    for (let index = 0; index < orderList.length; index++) {
        const { orderId, hashAddress } = orderList[index];
        const call = await apiClient.callApi("staking/AddStakeNFT", { orderId, hashAddress });
        if (call.isSucc) {
            completeIndex.push(orderId);
            Global.AddMessage("Transaction Confirmed", hashAddress);
        }
        else if (call.err.message == "order not exist")
            completeIndex.push(orderId);
    }
    for (const orderId of completeIndex) {
        orderList.splice(orderList.findIndex(a => a.orderId == orderId), 1);
    }
    localStorage.setItem("unfinishedStake", JSON.stringify(orderList));
}

export async function HarvestAll(pools: { poolId: string, account: string }[]) {
    if (pools.length == 0) {
        Global.AddMessage("Warning", undefined, "There is no available reward, wait for a while and try again.");
        return;
    }
    try {
        let transferCount = 0;
        Global.AddMessage("Order sent", undefined, "waiting for host transfer tokens.");
        for (const { poolId, account } of pools) {
            const call = await apiClient.callApi("staking/Harvest", { poolId, account });
            if (call.isSucc) {
                call.res.hashAddress.forEach(addr => Global.AddMessage("Harvest Confirmed", addr));
                transferCount += call.res.hashAddress.length;
            }
            else
                throw call.err;
        }

        if (transferCount == 0)
            Global.AddMessage("Warning", undefined, "There is no available reward, wait for a while and try again.");
    } catch (error) {
        console.error(error);
        Global.AddMessage("Harvest Failed");
    }
}

export async function Harvest(poolId: string, account: string) {
    try {
        Global.AddMessage("Order sent", undefined, "waiting for host transfer tokens.");
        const call = await apiClient.callApi("staking/Harvest", { poolId, account });
        if (call.isSucc)
            call.res.hashAddress.forEach(addr => Global.AddMessage("Harvest Confirmed", addr));
        else
            throw call.err;
    } catch (error) {
        console.error(error);
        Global.AddMessage("Harvest Failed");
    }
}

export async function RepairNft(poolId: string, tokens: {
    _id: ObjectId;
    address: string;
    amount: string;
    isNft: boolean;
    decimal: number;
}[]) {
    const account = Global.WalletAddress;
    const chain = account.startsWith("0x") ? "BSC" : "SOL";

    try {
        const call = await apiClient.callApi("staking/GetRepairOrder", {
            account,
            chain,
            tokens,
            poolId,
        });
        if (!call.isSucc)
            throw call.err;
        const { orderId, price, target } = call.res;
        let hashAddress = "";

        if (chain == "BSC") {
            //授权
            Global.AddMessage("Approve Token", undefined, `Please Approve with YOUR WALLET`);
            await ApproveToken(Global.Contract.Chains.BSC.StakingContractAddress, target, BigNumber.from(price))
            //发送
            Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            hashAddress = await TokenTransfer(Global.Contract.Chains.BSC.StakingContractAddress, target, [MitCoinBSC], [BigNumber.from(price)], [false]);
        }
        else if (chain == "SOL") {
            Global.AddMessage("Order Sent", undefined, `Please Confirm with YOUR WALLET`, 60000);
            //pay
            hashAddress = await TransferTokens(Global.WalletAdapter.publicKey, new PublicKey(target), [{
                address: MitCoinSOL,
                amount: BigInt(price),
                decimal: MitCoinDecimalSOL
            }]);
        }

        const repair = await apiClient.callApi("staking/Repair", { orderId, hashAddress });
        if (repair.isSucc)
            Global.AddMessage("Transaction Confirmed", hashAddress);
        else
            throw repair.err;
        return true;
    } catch (error) {
        console.error(error);
        Global.AddMessage("Repair Failed");

        return false;
    }
}

export async function UnStakeNFT(poolId: string, tokens: {
    _id: ObjectId;
    address: string;
    amount: string;
    isNft: boolean;
    decimal: number;
}[]) {
    const account = Global.WalletAddress;
    const chain = account.startsWith("0x") ? "BSC" : "SOL";

    try {
        Global.AddMessage("Order sent", undefined, "waiting for host transfer tokens.");
        const call = await apiClient.callApi("staking/UnStakeNFT", {
            account,
            chain,
            tokens,
            poolId,
            isTake: false,
        });
        if (!call.isSucc)
            throw call.err;
        console.log(call.res);
        let hashList = [];
        while (true) {
            const check = await apiClient.callApi("staking/GetUnStakeState", { orderId: call.res.orderId })
            if (!check.isSucc)
                throw check.err;
            console.log(check.res);

            if (check.res.complete) {
                hashList = check.res.hashAddress
                break;
            }
            await waitSeconds(5);
        }
        hashList.forEach(v => {
            Global.AddMessage("Transaction Confirmed", v);
        })

        return call.isSucc;
    } catch (error) {
        console.error(error);
        Global.AddMessage("UnStake Failed");
        return false;
    }

}