import React, { useEffect, useState } from "react";
import bg from "../assets/images/common/tc.png";
import "../assets/css/floatMessage.css";
import { Global } from "../global/global";
import { EvmChain } from "@moralisweb3/evm-utils";
import { EvmNet } from "../common/EvmAuction";

interface FloatMessageProps {
    message: string;
    sub?: string;
    txId?: string;
    displayTime?: number;
    keyId: string;
    callback: Function;
}

function FloatMessage(props: FloatMessageProps) {
    const [Show, setShow] = useState(true);

    useEffect(() => {
        const onMessageRemove = (evt: any) => {
            if (evt.detail != props.keyId)
                return;
            setShow(false);
            setTimeout(() => {
                Global.RemoveMessage(props.keyId)
            }, 500);
        }

        setTimeout(() => {
            Global.HideFloatMessage(props.keyId)
            // window.dispatchEvent(new CustomEvent("hide-float-message", { detail: this.props.keyId }))
        }, props.displayTime || 6 * 1000);
        window.addEventListener("hide-float-message", onMessageRemove, true);

        return () => {
            window.removeEventListener("hide-float-message", onMessageRemove, true);
        }
    }, [])

    const onClicked = () => {
        console.log(props.txId);
        if (props.txId && props.txId != "") {
            if (props.txId.startsWith("0x")) {
                const net = EvmNet.name == EvmChain.BSC_TESTNET.name ? "testnet." : "";
                Global.Open(`https://${net}bscscan.com/tx/${props.txId}`);
            }
            else
                Global.Open(`https://explorer.solana.com/tx/${props.txId}?cluster=${Global.Network}`)
        }
        if (props.callback)
            props.callback();
    }
    return (
        <section className={Show ? "float-message-item" : "float-message-item-hide"} onClick={onClicked} >
            <div className="float-message">
                <h1>{props.message}</h1>
                <p>{props.sub}</p>
            </div>
        </section>
    );
}

export default FloatMessage;
