import React, { FC, useEffect } from "react";
import { INavigation } from "../event/navigation";
import { Define } from "../define/define";

export const Community: FC<INavigation> = (navigation: INavigation) => {
    const { onNavigation } = navigation;

    useEffect(() => {
        onNavigation(Define.Page.Community);
    }, []);

    return (
        <div>
            Community
        </div>
    );
}
