import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import "./index.css";
import "./assets/scss/main.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <HashRouter>
        <App />
    </HashRouter>,
    document.getElementById('root')
);

const rem = () => {
    let dw = 1170;// 设计图宽度
    let sw = window.innerWidth;// 屏幕宽度
    let fontSize = Math.min((sw / dw * 100), 100);
    fontSize = Math.max(36, fontSize);
    let oHtml = document.getElementsByTagName('html')[0]
    oHtml.style.fontSize = fontSize + 'px'
    // console.log("resize rem", sw, fontSize, oHtml.style.fontSize)
};
rem();
window.addEventListener("resize", rem);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const a = [1, 2, 3, 4, 5];
a.splice(1, 1)
console.log(a);
