import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { rejects } from "assert";
import { Global } from "../global/global";

const main = {
    chainId: "0x38",
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "BNB",
        decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed1.binance.org", "https://bsc-dataseed2.binance.org", "https://bsc-dataseed3.binance.org", "https://bsc-dataseed4.binance.org", "https://bsc-dataseed1.defibit.io", "https://bsc-dataseed2.defibit.io", "https://bsc-dataseed3.defibit.io", "https://bsc-dataseed4.defibit.io", "https://bsc-dataseed1.ninicoin.io", "https://bsc-dataseed2.ninicoin.io", "https://bsc-dataseed3.ninicoin.io", "https://bsc-dataseed4.ninicoin.io", "wss://bsc-ws-node.nariox.org"],
    blockExplorerUrls: ["https://www.bscscan.com/"],
    iconUrls: []
};

const test = {
    chainId: "0x61",
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "BNB",
        decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/",
        "https://data-seed-prebsc-2-s1.binance.org:8545/",
        "https://data-seed-prebsc-1-s2.binance.org:8545/",
        "https://data-seed-prebsc-2-s2.binance.org:8545/",
        "https://data-seed-prebsc-1-s3.binance.org:8545/",
        "https://data-seed-prebsc-2-s3.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
    iconUrls: []
}

export async function metamaskConnect() {
    console.log(window.ethereum);
    if (typeof window.ethereum == 'undefined') {
        throw 'MetaMask is not installed!'
    } else {
        try {
            await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [Global.Network == WalletAdapterNetwork.Devnet ? test : main],
            })
            const result = await window.ethereum.request({ method: 'eth_requestAccounts' });
            console.log(result);
            return result[0];
        } catch (error) {
            console.log(error);
            throw "An error has occurred. Please make sure the metamask is ready to go. See error in log";
        }
    }
}
export function isMetamaskConnected(){
    if (typeof window.ethereum == 'undefined') {
        return false;
    } 
    return window.ethereum.isConnected();
}