import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/stakeOverview.css';
import btn from "../assets/images/staking/Examine.png";
import { StakingNavigateContext } from '../contexts/stakingNavigateContext';
import { Define } from '../define/define';
import { apiClient, Global } from '../global/global';
import { DbStakeCoin } from '../prot/protocols/db/DbStaking';
import { IAreaSummary } from '../prot/protocols/staking/PtlGetAreaInfo';
import "../assets/css/stakeGenerated.css"
import ToolTips from './toolTips';
function StakeOverview() {
    const ctx = useContext(StakingNavigateContext);
    const navigate = useNavigate();
    const [Areas, setAreas] = useState(null as IAreaSummary[]);
    const [CoinInfo, setCoinInfo] = useState([] as DbStakeCoin[])
    const fetchAreas = async () => {
        await fetchIcon();
        const call = await apiClient.callApi("staking/GetAreaInfo", {});
        console.log(call);

        if (call.isSucc) {
            setAreas(call.res.list);
        }
        else
            console.error(call.err);

    }

    useEffect(() => {
        fetchAreas().then(() => {
            const areas = document.getElementsByClassName("area");

            for (let index = 0; index < areas.length; index++) {
                const area = areas.item(index);
                area.addEventListener("mouseenter", onMouseEnter);
                area.addEventListener("mouseleave", onMouseLeave);
            }

        });

        const onMouseEnter = (e: any) => {
            const target = e.target as HTMLElement;
            const areas = document.getElementsByClassName("area");

            for (let index = 0; index < areas.length; index++) {
                const area = areas.item(index);
                if (target.id != area.id)
                    area.getElementsByClassName("area-shadow").item(0).id = "area-shadow-on";
                else
                    area.getElementsByClassName("area-shadow").item(0).id = "area-shadow-off";
            }
        }
        const onMouseLeave = () => {
            const areas = document.getElementsByClassName("area");
            for (let index = 0; index < areas.length; index++) {
                const area = areas.item(index);
                area.getElementsByClassName("area-shadow").item(0).id = "area-shadow-off";
            }
        }

        return () => {
            const areas = document.getElementsByClassName("area");
            for (let index = 0; index < areas.length; index++) {
                const area = areas.item(index);
                area.removeEventListener("mouseenter", onMouseEnter);
                area.removeEventListener("mouseleave", onMouseLeave);
            }
        }
    }, [])


    const onExamineClick = (area: string) => {
        ctx.area = area;
        navigate(Define.Page.StakingPool);
    }

    const fetchIcon = async () => {
        const call = await apiClient.callApi("staking/GetStakeCoin", { addresses: [] })
        if (call.isSucc) {
            setCoinInfo(call.res.list);
        }
    }

    const getTotalGenerated = (area: IAreaSummary) => {

        if (area.generator.length == 0)
            return 0;
        // else if (area.generator.length == 1)
        //     return area.generator[0].totalGenerated;
        // else
            return area.generator.slice(0, 12).map((v, i) => (
                <span className="generate-icon" key={i} style={{ backgroundImage: `url(${CoinInfo.find(a => a.address == v.address).imgUrl})` }} >
                    {/* <span>Total generated：{v.totalGenerated.toFixed(2)}</span> */}
                </span>
            ))
    }

    return (<div className="box">
        {
            Areas ?
                Areas.map((area, index) =>
                    <div key={index} className="area" id={`a${index + 1}`} style={{ backgroundImage: `url(${area.imgUrl})` }}>
                        <div className={`area_name${index + 1}`}>{area.title.toUpperCase()}</div>
                        <div className="lables">
                            <div className="land"><b>LandStaked</b></div>
                            <div className="land_msg" id="land_msg">
                                <span className="title2">Total staked:</span>
                                <span className="msg">{area.landStaked}</span>
                            </div>
                            <div className="stake_nft"><b>NFT Staked</b></div>
                            <div className="nft_msg" id="nft_msg">
                                <span className="title3">Total staked:</span>
                                <span className="msg">{area.nftStaked}</span>
                            </div>
                            <div className="total"><b>Total generated Coin</b></div>
                            <div className="total_msg total-generate-multi" id="total_msg"
                                onMouseEnter={(e) => {
                                    if (area.generator.length > 1)
                                        Global.AddOverlay("totalGenerated", <ToolTips key="tt" left={e.clientX} top={e.clientY} lines={
                                            area.generator.map((v, i) => {
                                                if (CoinInfo.length == 0)
                                                    return v.totalGenerated.toString();
                                                const coin = CoinInfo.find(a => a.address == v.address);
                                                return `${coin.symbol}:${v.totalGenerated}`;
                                            })
                                        } />)
                                }}
                                onMouseLeave={(e) => {
                                    if (area.generator.length > 1)
                                        Global.RemoveOverlay("totalGenerated")
                                }}
                            >{getTotalGenerated(area)}
                                {area.generator.length > 12 ? "..." : ""}
                            </div>
                            <div className="pro"><b>Products</b></div>
                            <div className="pro_list">
                                {area.poolImages.map((v, i) => <div key={i} style={{ backgroundImage: `url(${v})` }} ></div>)}
                            </div>
                            <div className='examine'>
                                <img src={btn} onClick={() => { onExamineClick(area.name) }} />
                            </div>
                        </div>
                        <div className='area-shadow' id='area-shadow-off'></div>
                    </div>
                )
                :
                <div />
        }
    </div >);
}

export default StakeOverview;