import { PublicKey, sendAndConfirmRawTransaction, sendAndConfirmTransaction, SystemProgram, Transaction } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, Token, ASSOCIATED_TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Global } from "../global/global";
import { actions, programs, transactions } from "@metaplex/js"
import { Account } from '@metaplex-foundation/mpl-core';
import { waitSeconds } from "../utils";
export async function TransferTokens(from: PublicKey, to: PublicKey, tokens: { address: string, amount: bigint, decimal: number }[]): Promise<string> {
    const { blockhash, lastValidBlockHeight } = await Global.Connection.getLatestBlockhash();
    console.log(tokens);

    const tx = new Transaction({
        blockhash,
        lastValidBlockHeight,
        feePayer: from,
    });
    for (const token of tokens) {
        const txs = await sendToken(from, new PublicKey(token.address), to, token.amount);
        tx.add(...txs);
    }

    const signed = await Global.WalletAdapter.signTransaction(tx);
    const txId = await Global.Connection.sendRawTransaction(signed.serialize());
    console.log(txId);
    while (true) {
        try {
            const res = await Global.Connection.confirmTransaction(txId, "confirmed");
            break;
        } catch (error) {
            await waitSeconds(10);
        }
    }
    return txId;
}

async function sendToken(from: PublicKey, mint: PublicKey, destination: PublicKey, amount: bigint) {
    const txs = [];
    const destAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, destination);
    const source = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, from);
    console.log(destAta.toBase58());
    try {
        await Account.load(Global.Connection, destAta);
    }
    catch (_a) {
        txs.push(Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, destAta, destination, from))
    }
    txs.push(Token.createTransferInstruction(TOKEN_PROGRAM_ID, source, destAta, from, [], Number(amount)));
    return txs;
}