import React, { FC, useCallback, useEffect, useState } from "react";
import "../assets/scss/profile.scss";
import { Define, OpenVersion, ShowVersion } from "../define/define";
import { apiClient, Global } from "../global/global";
import { GlobalConfig } from "../global/globalConfig";
import { INavigation } from "../event/navigation";
import { INftItem } from "../contexts/nftItemContext";
import { ISellNft } from "../contexts/sellNftContext";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { Loading } from "../components/loading";
import EventCenter from "../event/eventCenter";
import cs from "classnames";
import Selected from "../assets/images/common/selected.png";
import SelectedButton from "../assets/images/common/selected-button.png";
import { NftDetail } from "../components/nftDetail";
import { NftContext } from "./marketplace";
import { IProfileNft } from "../contexts/profileNftContext";
import { getParsedAccountByMint } from "@nfteyez/sol-rayz";
import { useNavigate } from "react-router-dom";
import Frame from "../assets/images/marketplace/frame.png";
import { Connection } from "@solana/web3.js";
import { TradeState } from "../prot/protocols/auction/Commodity";
import { CancelSell } from "../common/ServerAuction";

export const Profile: FC<INavigation> = (navigation: INavigation) => {
    const navigate = useNavigate();
    const { onConnectWallet, onNavigation } = navigation;
    const [currentToken, setCurrentToken] = useState(Define.TokenType[0]);
    const [netWorth, setNetWorth] = useState("0.00");
    const [USDC, setUSDC] = useState(0);
    const [SOL, setSOL] = useState(0);
    const [SYP, setSYP] = useState(0);
    const [MBTT, setMBTT] = useState(0);
    const [MITCOIN, setMITCOIN] = useState(0);
    const [isWait, setIsWait] = useState(false);
    const [isLoadOwnNfts, setIsLoadOwnNfts] = useState(false);
    const [isLoadTokens, setLoadTokens] = useState(false);
    const [selectedNftIndex, setSelectedNftIndex] = useState(Define.INVALID);
    const [ownSellNfts, setOwnSellNfts] = useState(new Array<ISellNft>());
    const [domNftArray, setDomNftArray] = useState(new Array<IProfileNft>());
    const [currentCategory, setCurrentCategory] = useState(Define.ProfileCategory.All);
    const [isShowNftDetail, setIsShowNftDetail] = useState(false);
    const [nft, setNft] = useState({
        id: 0,
        name: "",
        initial_nfts: 0,
        category: 0,
        rarity: 0,
        level: 0,
        price: 0,
        increase: 0,
        gender: 0,
        age: 0,
        length: 0,
        width: 0,
        height: 0,
        feature: "",
        skill: 0,
        space: 0,
        wartung_des_computers: 0,
        power_supply: 0,
        impulse_engine: 0,
        qpu: 0,
        shield_generator: 0,
        weapon_hardpoint: 0,
        energy_consumption: "",
        materials_consumption: "",
        output: "",
        human_commander: 0,
        working_robot: 0,
        image: "",
        atlas: "",
        description: "",
        quantity: 0
    });
    let tempCurrentCategory: number = Define.ProfileCategory.All;

    const onRefreshProfile = (isLoadNftConfig: boolean) => {
        setLoadTokens(Global.IsGetTokens);
        getOwnTokens();
    }

    const onShowProfile = (isShowProfile: boolean) => {
        setIsShowNftDetail(!isShowProfile);
    }

    useEffect(() => {
        EventCenter.addListener(Define.Event.RefreshProfile, onRefreshProfile);
        return () => {
            EventCenter.removeListener(Define.Event.RefreshProfile, onRefreshProfile);
        }
    }, [onRefreshProfile]);

    useEffect(() => {
        EventCenter.addListener(Define.Event.ShowProfile, onShowProfile);
        return () => {
            EventCenter.removeListener(Define.Event.ShowProfile, onShowProfile);
        }
    }, [onShowProfile]);

    useEffect(() => {
        onNavigation(Define.Page.Profile);
        if (!Global.WalletConnected("SOL")) {
            console.log("SOLANAAAA");

            navigate(Define.Page.Home);
        }
    }, []);

    useEffect(() => {
        getOwnTokens();
    }, []);

    const getOwnTokens = async () => {
        if (Global.IsInitGlobalConfig) {
            await Global.getTokens();
            await setTokens();
            let nfts = loadNfts();
            nfts = sortProfileNft(nfts);
            setDomNftArray(nfts);
            await getOwnSellNfts();
        }
    }

    const loadNfts = () => {
        let tempNfts = new Array<IProfileNft>();
        for (let i = 0; i < Global.OwnNfts.length; ++i) {
            let ownNft = Global.OwnNfts[i];
            let nft: IProfileNft = {
                id: ownNft.id,
                nftConfig: ownNft.config,
                mapConfig: null,
                mint_address: ownNft.mint_address
            }
            tempNfts.push(nft);
        }

        for (let i = 0; i < Global.OwnMaps.length; ++i) {
            let ownMap = Global.OwnMaps[i];
            let nft: IProfileNft = {
                id: ownMap.id,
                nftConfig: null,
                mapConfig: ownMap.config,
                mint_address: ownMap.mint_address
            }
            tempNfts.push(nft);
        }
        console.log(tempNfts);

        return tempNfts;
    }

    const setTokens = async () => {
        setUSDC(Global.USDC);
        setSOL(Global.SOL);
        setSYP(Global.SYP);
        setMBTT(Global.MBTT);
        setMITCOIN(Global.MITCOIN);
        setLoadTokens(true);
    }

    const getOwnSellNfts = async () => {
        let validSells = await getAllValidSells(Global.Connection);

        let tempSellNfts = new Array<ISellNft>();
        let index = 1;
        validSells = validSells.filter(v => v.order.tradeState == TradeState.OnSale)
        for (const sell of validSells) {
            // let nftInfo = await Metadata.load(Global.Connection, sell.listing.metadata);
            let strs = sell.uri.split("/");
            let json = strs[strs.length - 1];
            let jsonStrs = json.split(".");
            let nftId = Number(jsonStrs[0]);
            const commodity = sell.order;
            let sellerPublickey = commodity.seller;

            switch (sell.category) {
                case "item": {
                    let nftConfig = GlobalConfig.getNftConfig(nftId);
                    if (sellerPublickey === Global.WalletAdapter.publicKey.toString()) {
                        let sellNft: ISellNft = {
                            id: index,
                            nftConfig: nftConfig,
                            mapConfig: null,
                            owner_address: commodity.seller,
                            price: Number(commodity.price),//Number(sell.listing.price) / Global.AdminConfig.Decimals[sell.tokenType],
                            token: Define.Token.SOL,
                            address: sell.mintAddress,
                            sellerReceipt: sell
                        }
                        tempSellNfts.push(sellNft);
                        ++index;
                    }
                    break;
                }
                case "map": {
                    let mapConfig = GlobalConfig.getMapConfig(nftId);
                    if (sellerPublickey === Global.WalletAdapter.publicKey.toString()) {
                        let sellNft: ISellNft = {
                            id: index,
                            nftConfig: null,
                            mapConfig: mapConfig,
                            owner_address: commodity.seller,
                            price: Number(commodity.price), //Number(sell.listing.price) / Global.AdminConfig.Decimals[sell.tokenType],
                            token: Define.Token.SOL,
                            address: sell.mintAddress,
                            sellerReceipt: sell
                        }
                        tempSellNfts.push(sellNft);
                        ++index;
                    }
                    break;
                }
            }
        }
        console.log(tempSellNfts);

        setIsLoadOwnNfts(true);
        setOwnSellNfts(tempSellNfts);
    }

    const getAllValidSells = async (connection: Connection) => {
        const items = (await apiClient.callApi("auction/GetSells", {
            filter: {
                project: "Official",
                category: "item",
                chain: "SOL",
                spl: "SOL",
            },
            mustSell: false,
            needHistory: false,
        })).res.list;
        const maps = (await apiClient.callApi("auction/GetSells", {
            filter: {
                project: "Official",
                category: "map",
                chain: "SOL",
                spl: "SOL",
            },
            mustSell: false,
            needHistory: false,
        })).res.list;
        return [items, maps].flat();
        // // let usdcSells = (await GetSells(connection, Define.Token.USDC)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // let solSells = (await GetSells(connection, Define.Token.SOL)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // // let sypSells = (await GetSells(connection, Define.Token.SYP)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // // let mbttSells = (await GetSells(connection, Define.Token.MBTT)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // // let mitcoinSells = (await GetSells(connection, Define.Token.MITCOIN)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // // let usdcMapSells = (await GetSells(connection, Define.Token.USDC, "map")).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // let solMapSells = (await GetSells(connection, Define.Token.SOL, "map")).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // // let sypMapSells = (await GetSells(connection, Define.Token.SYP, "map")).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // // let mbttMapSells = (await GetSells(connection, Define.Token.MBTT, "map")).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // // let mitcoinMapSells = (await GetSells(connection, Define.Token.MITCOIN, "map")).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);

        // // let allSells = [...usdcSells, ...solSells, ...sypSells, ...usdcMapSells, ...solMapSells, ...sypMapSells];
        // let allSells = [...solSells, ...solMapSells];

        // // 已成交的订单
        // let tempAllPurchases = await Promise.all([
        //     GetPurchases(connection, Define.Token.USDC),
        //     GetPurchases(connection, Define.Token.SOL),
        //     GetPurchases(connection, Define.Token.SYP),
        //     GetPurchases(connection, Define.Token.MBTT),
        //     GetPurchases(connection, Define.Token.MITCOIN)
        // ]);

        // // 将已成交的订单转换为一维数组
        // let allPurchases = [...tempAllPurchases[0], ...tempAllPurchases[1], ...tempAllPurchases[2], ...tempAllPurchases[3], ...tempAllPurchases[4]];
        // // 获得有效订单
        // allSells = allSells.filter(v => {
        //     let purch = allPurchases.find(m => m.listing.seller.toString() == v.listing.seller.toString() && m.listing.metadata.toString() == v.listing.metadata.toString());
        //     return purch == null && v.listing.seller.toBase58() != Define.NFTAddress;
        // });

        // return allSells;
    }

    const onDisConnectClick = useCallback(() => {
        onConnectWallet(false);
        navigate(Define.Page.Home);
    }, []);

    const onSelectChange = useCallback((event) => {
        setCurrentToken(event.target.value);
    }, [currentToken]);

    useEffect(() => {
        let tempNewWorth = 0;
        switch (currentToken) {
            case Define.TokenType[0]:
                tempNewWorth = 0;
                break;
            case Define.TokenType[1]:
                tempNewWorth = 0;
                break;
            case Define.TokenType[2]:
                tempNewWorth = 0;
                break;
            case Define.TokenType[3]:
                tempNewWorth = 0;
                break;
            case Define.TokenType[4]:
                tempNewWorth = 0;
                break;
        }

        let priceString = Global.formatNumber(tempNewWorth);
        setNetWorth(priceString);
    }, [currentToken]);

    const onCancelClick = async (sell: ISellNft) => {
        if (isWait) {
            return;
        }

        setIsWait(true);
        let isSuccess = await CancelSell(sell.sellerReceipt);//await CancelSell(Global.Connection, Global.WalletAdapter, sell.address, sell.sellerReceipt, sell.token);
        setIsWait(false);
        if (isSuccess) {
            getOwnSellNfts();
        }
    }

    const onSolveBid = async (bids: any) => {
        if (isWait)
            return;

        setIsWait(true);
        let isSuccess = false//await CancelBid(Global.Connection, Global.WalletAdapter, bids.address, bids.sellerReceipt, bids.token);
        setIsWait(false);
        if (isSuccess) {
            setIsLoadOwnNfts(false);
            // const bids = await FindUnCompletedBids();
            setIsLoadOwnNfts(true);
        }
    }

    const onCategoryClick = (category: number) => {
        if (Define.Categories[category - 1].category == Define.Category.Resources) {
            return;
        }

        tempCurrentCategory = category;
        setCurrentCategory(tempCurrentCategory);
        setSelectedNftIndex(Define.INVALID);
        setNftList();
    }

    const setNftList = () => {
        if (tempCurrentCategory === Define.ProfileCategory.Lands) {
            let nftArray = Global.OwnMaps;
            let tempNft = new Array<IProfileNft>();
            for (let i = 0; i < nftArray.length; ++i) {
                let ownMap = nftArray[i];
                let nft: IProfileNft = {
                    id: ownMap.id,
                    nftConfig: null,
                    mapConfig: ownMap.config,
                    mint_address: ownMap.mint_address
                }
                tempNft.push(nft);
            }

            tempNft = sortProfileNft(tempNft);
            setDomNftArray(tempNft);
        } else if (tempCurrentCategory === Define.ProfileCategory.All) {
            let tempNft = new Array<IProfileNft>();

            let mapArray = Global.OwnMaps;
            for (let i = 0; i < mapArray.length; ++i) {
                let ownMap = mapArray[i];
                let nft: IProfileNft = {
                    id: ownMap.id,
                    nftConfig: null,
                    mapConfig: ownMap.config,
                    mint_address: ownMap.mint_address
                }
                tempNft.push(nft);
            }

            let nftArray = getCategory(Global.OwnNfts, tempCurrentCategory);

            for (let i = 0; i < nftArray.length; ++i) {
                let ownNft = nftArray[i];
                let nft: IProfileNft = {
                    id: ownNft.id,
                    nftConfig: ownNft.config,
                    mapConfig: null,
                    mint_address: ownNft.mint_address
                }
                tempNft.push(nft);
            }

            tempNft = sortProfileNft(tempNft);
            setDomNftArray(tempNft);

        } else {
            let nftArray = getCategory(Global.OwnNfts, tempCurrentCategory);

            let tempNft = new Array<IProfileNft>();
            for (let i = 0; i < nftArray.length; ++i) {
                let ownNft = nftArray[i];
                let nft: IProfileNft = {
                    id: ownNft.id,
                    nftConfig: ownNft.config,
                    mapConfig: null,
                    mint_address: ownNft.mint_address
                }
                tempNft.push(nft);
            }

            tempNft = sortProfileNft(tempNft);
            setDomNftArray(tempNft);
        }
    }

    const sortProfileNft = (iProfileNfts: Array<IProfileNft>) => {
        for (let i = 0; i < iProfileNfts.length - 1; i++) {
            for (let j = i; j < iProfileNfts.length; j++) {
                let iId = iProfileNfts[i].id;
                let jId = iProfileNfts[j].id;
                if (iId < jId) {
                    let temp = iProfileNfts[i];
                    iProfileNfts[i] = iProfileNfts[j];
                    iProfileNfts[j] = temp;
                }
            }
        }
        return iProfileNfts;
    }

    const getCategory = (nfts: Array<INftItem>, category: number) => {
        let tempNftArray = [];
        for (let i = 0; i < nfts.length; i++) {
            let nft = nfts[i];
            if (category === 1) {
                tempNftArray.push(nft);
            } else {
                if (nft.config.category === category) {
                    tempNftArray.push(nft);
                }
            }
        }
        return tempNftArray;
    }

    const onMouseOver = useCallback((index: number) => {
        setSelectedNftIndex(index);
    }, []);

    const onNftDetailClick = useCallback((iProfileNft: IProfileNft) => {
        if (iProfileNft.nftConfig != null) {
            setNft(iProfileNft.nftConfig);
            setIsShowNftDetail(true)
        }
    }, []);

    const onShowNftList = useCallback(() => {
        setIsShowNftDetail(false);
    }, []);

    const onShowWallets = useCallback(() => {

    }, []);

    const unCompleteBids = () => {
        if (Global.UnCompleteBids.length == 0)
            return <></>
        return <div>
            <div className="refound-title">REFUND</div>
            <p className="refound-description">Due to the congestion of Solana, the token was transferred to a
                third-party contract but the NFT transaction was not successful. User can cancel the following order and
                get a refund.</p>
            <section className="own-background-bid">

                <section className="own-sell-titles">
                    <span className="own-orders-title">BID ORDERS</span>
                    <span className="own-sell-price-title">BID PRICE</span>
                    <span className="own-sell-token">TOKEN</span>
                </section>
                <section className="own-sells">
                    {
                        Global.UnCompleteBids.map((listing: any, index: number) => {
                            console.log(listing);
                            if (listing.nftConfig) {
                                return (
                                    <section className="own-sell" key={index}>
                                        <img className="own-sell-image"
                                            src={require("../assets/images/icon/" + listing.nftConfig.image)}
                                            alt="-_-" draggable={false} />
                                        <section className="own-sell-name">
                                            <p>{listing.nftConfig.name}</p>
                                        </section>
                                        <img className="own-sell-rarity"
                                            src={require("../assets/images/rarity/" + Define.RarityType[listing.nftConfig.rarity].toLowerCase() + ".png")}
                                            alt="-_-"
                                            draggable={false} />
                                        <section className="own-sell-price">
                                            <p>{Global.formatNumber(listing.price)}</p>
                                        </section>
                                        <section className="own-sell-token">
                                            <p>{Define.TokenType[listing.token]}</p>
                                        </section>
                                        <button className="own-sell-cancel"
                                            onClick={() => onSolveBid(listing)}>
                                            {
                                                isWait
                                                    ?
                                                    <section className="loading" />
                                                    :
                                                    <p>{"Cancel"}</p>
                                            }
                                        </button>
                                        {
                                            ownSellNfts.length <= 3 || ownSellNfts.length > 3 && index < ownSellNfts.length - 1
                                                ?
                                                <section className="own-sell-line" />
                                                :
                                                null
                                        }
                                    </section>
                                );
                            }

                            if (listing.mapConfig) {
                                return (
                                    <section className="own-sell" key={index}>
                                        <img className="own-sell-image"
                                            src={require("../assets/images/icon/" + listing.mapConfig.image)}
                                            alt="-_-" draggable={false} />
                                        <section className="own-sell-name">
                                            <p>{listing.mapConfig.name}</p>
                                        </section>
                                        <section className="own-sell-level">
                                            <p>{"Tier" + listing.mapConfig.Level}</p>
                                        </section>
                                        <section className="own-sell-price">
                                            <p>{Global.formatNumber(listing.price)}</p>
                                        </section>
                                        <section className="own-sell-token">
                                            <p>{Define.TokenType[listing.token]}</p>
                                        </section>
                                        <button className="own-sell-cancel"
                                            onClick={() => onSolveBid(listing)}>
                                            {
                                                isWait
                                                    ?
                                                    <section className="loading" />
                                                    :
                                                    <p>{"Cancel"}</p>
                                            }
                                        </button>
                                        {
                                            ownSellNfts.length <= 3 || ownSellNfts.length > 3 && index < ownSellNfts.length - 1
                                                ?
                                                <section className="own-sell-line" />
                                                :
                                                null
                                        }
                                    </section>
                                );
                            }
                        })
                    }
                </section>
            </section>
        </div>

    }

    return (
        <section>
            {(() => {
                if (!isLoadTokens && !isLoadOwnNfts) {
                    return <Loading />
                }
            })()}
            {
                isShowNftDetail
                    ?
                    <section>
                        <img className="frame" src={Frame} alt="-_-" draggable={false} />
                        <NftContext.Provider value={nft}>
                            <NftDetail
                                currentCategory={nft.category}
                                enterType={Define.Enter.Profile}
                                onShowNftList={onShowNftList}
                                onShowWalletPanel={onShowWallets} />
                        </NftContext.Provider>
                    </section>
                    :
                    <section className="profile">
                        <p className="profile-title">PROFILE</p>
                        <section className="line" />
                        <section className="top">
                            <section className="wallet-address">
                                <section className="wallet-address-title">WALLET ADDRESS:</section>
                                <section className="wallet-address-text">
                                    {Global.WalletAdapter != null && Global.WalletAdapter.publicKey.toString().length > 10 ? Global.getEllipsis(Global.WalletAdapter.publicKey.toString()) : ""}
                                </section>
                                <button className="dis-connect-btn" onClick={onDisConnectClick}>DisConnect</button>
                            </section>
                            <section className="game-duration">
                                <section className="game-duration-title">GAME DURATION:</section>
                                <section className="game-duration-text">{"0 Days"}</section>
                            </section>
                            <section className="organization">
                                <section className="organization-title">ORGANIZATION:</section>
                                <section className="organization-text">{"NONE"}</section>
                            </section>
                            <section className="net-worth">
                                <section className="net-worth-title">NET WORTH:</section>
                                <section className="net-worth-text">{netWorth}</section>
                                <select className="options" value={currentToken}
                                    onChange={(event) => onSelectChange(event)}> {
                                        Define.TokenType.filter((v, i) => Global.AdminConfig.ShowCoin[i]).map((token, index) => {
                                            return (
                                                <option key={index} value={token} className="option">{token}</option>
                                            )
                                        })
                                    }
                                </select>
                            </section>
                            <section className="token-assets">
                                <section className="token-assets-title">TOKEN ASSETS:</section>
                                <section className="usdc">
                                    <section className="usdc-count">{Global.formatNumber(USDC)}</section>
                                </section>
                                <section className="sol">
                                    <section className="sol-count">{Global.formatNumber(SOL)}</section>
                                </section>
                                <section className="syp">
                                    <section className="syp-count">{Global.formatNumber(SYP)}</section>
                                </section>
                                {/*<section className="mbtt">*/}
                                {/*    <section className="mbtt-count">{Global.formatNumber(MBTT)}</section>*/}
                                {/*</section>*/}
                                {/*<section className="mitcoin">*/}
                                {/*    <section className="mitcoin-count">{Global.formatNumber(MITCOIN)}</section>*/}
                                {/*</section>*/}
                            </section>
                        </section>
                        <div className="nft-collection-title">
                            <p style={
                                ShowVersion >= OpenVersion.Market ?
                                    {
                                        color: "#70b9f5",
                                        textDecoration: "underline",
                                    } : {}
                            }>Meta2150s</p>
                            {
                                ShowVersion >= OpenVersion.Market ? <p onClick={
                                    () => {
                                        navigate(Define.Page.ProfileV2)
                                    }
                                }>Others</p> : null
                            }

                        </div>
                        <section className="nft-collection">
                            <section className="category">
                                <ul> {
                                    Define.ProfileCategories.map((category, index) => {
                                        if (index < 3) {
                                            return (
                                                <li
                                                    key={index}
                                                    className={cs({
                                                        "left-btn": true,
                                                        "left-btn-select": currentCategory === category.category,
                                                        "li-select": currentCategory === category.category
                                                    })}
                                                    onClick={() => onCategoryClick(category.category)}>
                                                    {category.title}
                                                </li>
                                            );
                                        } else if (index == 3) {
                                            return (
                                                <li
                                                    key={index}
                                                    className={cs({
                                                        "center-btn": true,
                                                        "center-btn-select": currentCategory === category.category,
                                                        "li-select": currentCategory === category.category
                                                    })}
                                                    onClick={() => onCategoryClick(category.category)}>
                                                    {category.title}
                                                </li>
                                            );
                                        } else if (index > 3) {
                                            if (category.category === Define.Category.Resources) {
                                                return (
                                                    <li
                                                        key={index}
                                                        className={cs({
                                                            "right-btn": true,
                                                            "right-btn-select": currentCategory === category.category,
                                                            "li-select": currentCategory === category.category
                                                        })}
                                                        onClick={() => onCategoryClick(category.category)}>
                                                        <p>{category.title}</p>
                                                    </li>
                                                );
                                            } else {
                                                return (
                                                    <li
                                                        key={index}
                                                        className={cs({
                                                            "right-btn": true,
                                                            "right-btn-select": currentCategory === category.category,
                                                            "li-select": currentCategory === category.category
                                                        })}
                                                        onClick={() => onCategoryClick(category.category)}>
                                                        {category.title}
                                                    </li>
                                                );
                                            }
                                        }
                                    })
                                }
                                </ul>
                            </section>
                            <section className="nfts">
                                <ul>
                                    {
                                        domNftArray.map((profileNft: IProfileNft, index: number) => {
                                            if (profileNft.nftConfig != null) {
                                                return (
                                                    <section className="nft" key={index}>
                                                        <section className="background"
                                                            onMouseOver={() => onMouseOver(index)}
                                                            onClick={() => onNftDetailClick(profileNft)}
                                                        />
                                                        {
                                                            selectedNftIndex === index
                                                                ? <section>
                                                                    <img className="selected" src={Selected} />
                                                                    <img className="selected-btn"
                                                                        src={SelectedButton} />
                                                                </section>
                                                                :
                                                                null
                                                        }
                                                        <li className="name"
                                                            onMouseOver={() => onMouseOver(index)}>{profileNft.nftConfig.name}</li>
                                                        <img className="icon"
                                                            src={require("../assets/images/icon/" + profileNft.nftConfig.image)}
                                                            alt="-_-"
                                                            draggable={false}
                                                            onMouseOver={() => onMouseOver(index)}
                                                            onClick={() => onNftDetailClick(profileNft)} />
                                                        <img className="rarity"
                                                            src={require("../assets/images/rarity/" + Define.RarityType[profileNft.nftConfig.rarity].toLowerCase() + ".png")}
                                                            alt="-_-"
                                                            draggable={false}
                                                            onMouseOver={() => onMouseOver(index)}
                                                            onClick={() => onNftDetailClick(profileNft)} />
                                                    </section>
                                                )
                                            }

                                            if (profileNft.mapConfig != null) {
                                                return (
                                                    <section className="nft" key={index}>
                                                        <section className="background"
                                                            onMouseOver={() => onMouseOver(index)}
                                                            onClick={() => onNftDetailClick(profileNft)}
                                                        />
                                                        {
                                                            selectedNftIndex === index
                                                                ? <section>
                                                                    <img className="selected" src={Selected} />
                                                                    <img className="selected-btn"
                                                                        src={SelectedButton} />
                                                                </section>
                                                                :
                                                                null
                                                        }
                                                        <li className="name"
                                                            onMouseOver={() => onMouseOver(index)}>{profileNft.mapConfig.name}</li>
                                                        <img className="icon"
                                                            src={require("../assets/images/icon/" + profileNft.mapConfig.image)}
                                                            alt="-_-"
                                                            draggable={false}
                                                            onMouseOver={() => onMouseOver(index)}
                                                            onClick={() => onNftDetailClick(profileNft)} />
                                                        <li className="level"
                                                            onMouseOver={() => onMouseOver(index)}>{"Tier" + profileNft.mapConfig.Level}</li>
                                                    </section>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </section>
                        </section>
                        <p className="own-title">TRADING</p>
                        <p className="trading-description"></p>
                        <section className="own-background">
                            <section className="own-sell-titles">
                                <span className="own-orders-title">SELL ORDERS</span>
                                <span className="own-sell-price-title">SELL PRICE</span>
                                <span className="own-sell-token">TOKEN</span>
                            </section>
                            <section className="own-sells">
                                {
                                    ownSellNfts.map((sell: ISellNft, index: number) => {
                                        if (sell.nftConfig !== null) {
                                            return (
                                                <section className="own-sell" key={index}>
                                                    <img className="own-sell-image"
                                                        src={require("../assets/images/icon/" + sell.nftConfig.image)}
                                                        alt="-_-" draggable={false} />
                                                    <section className="own-sell-name">
                                                        <p>{sell.nftConfig.name}</p>
                                                    </section>
                                                    <img className="own-sell-rarity"
                                                        src={require("../assets/images/rarity/" + Define.RarityType[sell.nftConfig.rarity].toLowerCase() + ".png")}
                                                        alt="-_-"
                                                        draggable={false} />
                                                    <section className="own-sell-price">
                                                        <p>{Global.formatNumber(sell.price)}</p>
                                                    </section>
                                                    <section className="own-sell-token">
                                                        <p>{Define.TokenType[sell.token]}</p>
                                                    </section>
                                                    <button className="own-sell-cancel"
                                                        onClick={() => onCancelClick(sell)}>
                                                        {
                                                            isWait
                                                                ?
                                                                <section className="loading" />
                                                                :
                                                                <p>Cancel</p>
                                                        }
                                                    </button>
                                                    {
                                                        ownSellNfts.length <= 3 || ownSellNfts.length > 3 && index < ownSellNfts.length - 1
                                                            ?
                                                            <section className="own-sell-line" />
                                                            :
                                                            null
                                                    }
                                                </section>
                                            );
                                        }

                                        if (sell.mapConfig != null) {
                                            return (
                                                <section className="own-sell" key={index}>
                                                    <img className="own-sell-image"
                                                        src={require("../assets/images/icon/" + sell.mapConfig.image)}
                                                        alt="-_-" draggable={false} />
                                                    <section className="own-sell-name">
                                                        <p>{sell.mapConfig.name}</p>
                                                    </section>
                                                    <section className="own-sell-level">
                                                        <p>{"Tier" + sell.mapConfig.Level}</p>
                                                    </section>
                                                    <section className="own-sell-price">
                                                        <p>{Global.formatNumber(sell.price)}</p>
                                                    </section>
                                                    <section className="own-sell-token">
                                                        <p>{Define.TokenType[sell.token]}</p>
                                                    </section>
                                                    <button className="own-sell-cancel"
                                                        onClick={() => onCancelClick(sell)}>
                                                        {
                                                            isWait
                                                                ?
                                                                <section className="loading" />
                                                                :
                                                                <p>Cancel</p>
                                                        }
                                                    </button>
                                                    {
                                                        ownSellNfts.length <= 3 || ownSellNfts.length > 3 && index < ownSellNfts.length - 1
                                                            ?
                                                            <section className="own-sell-line" />
                                                            :
                                                            null
                                                    }
                                                </section>
                                            );
                                        }
                                    })
                                }
                            </section>
                        </section>
                        {unCompleteBids()}
                        <section className="trading-bottom" />
                    </section>
            }
        </section>
    );
}
