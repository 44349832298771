import { ObjectId } from "mongodb";
import { ChainType } from "../types";
import {} from "web3"

export type StakeMapTag = "level" | "country" | "area";
export type StakeItemType = "computer" | "hyper_computer" | "land" | "partner" | "other" | "token";
//池子状态：准备，开启，关闭，废弃
export enum StakingPoolState {
    none = -1,
    closed,
    ready,
    open,
    deprecated,
};
//nft状态:维修，开启，关闭
export enum StakedNFTState {
    none = -1,
    closed,
    open,
    repair,
    // freeze,
};
export enum StakingGenerateType {
    none = -1,
    TypeA,
    TypeB,
};


//质押的nft
export interface IStakedNFT {
    _id: ObjectId;
    type: StakeItemType;//类型
    amount: bigint;//数量，spl-token/erc20使用,nft为1
    adjustedAmount: number;//兑换后
    state: StakedNFTState;//状态

    force: bigint;//算力,受耐久影响
    bonus: number;//加成,受耐久影响

    id?: string;//仅computer和land有效
    mintAddress: string;//nft地址，bsc为address_id

    stakeUTC: Date;//存入时间，用于计算总时长
    startUTC: Date;//有效开始时间,存入时间和维修结束时间

    harvestAuthority: number;//提取收益权限
    commissionAuthority: number;//获取佣金权限

    durability: number;//当前耐久度
    durabilityLimit: number;//最大耐久度
    recoverTime: Date;//触发修理时间
    toolCost: bigint;//维修消耗
    repairTime: number;//维修一点所需时间，单位为分

    isFreeze?: boolean;//是否冻结
}

//质押池中道具
export interface IStakingPoolItem {
    key: string;
    id?: string;//仅computer和land有效
    poolId: string;//池子id
    mintAddress?: string;//nft地址，bsc为address_id
    name?: string;//nft名称
    force?: bigint;//产能
    bonus?: number;//buff，万分比
    type: StakeItemType;//类型
    display: string;//前端筛选
    collection?: string;//分类 第三方时为collectionAddress/contractAddress,
    tag?: string;//标签，官方为level/country/area
    tagParam?: string;//标签参数
    toolCost?: bigint;//维修消耗
    repairTime?: number;//维修一点所需时间，单位为分
    harvestAuthority?: number;//提取收益权限
    commissionAuthority?: number;//获取佣金权限
    price: bigint;//质押费用，需要乘以池系数
    exchange?: number;
    durability?: number;
    durabilityLimit?: number;
}

export interface IStakingAreaReward {
    address: string;
    closedGenerated: bigint;
    totalGenerated: bigint;
}

//质押矿区
export interface IStakingArea {
    name: string;
    title: string;
    imgUrl: string;

    pool: number;
    landStaked: number;
    nftStaked: number;
    generator: IStakingAreaReward[];
}

export interface IStakingPoolReward {
    address: string;
    commissionAddress: string;//佣金地址
    generateType: StakingGenerateType;//产出计划，分为A和B
    produceLimit1: bigint;//产出阀门值1
    produceLimit2: bigint;//产出阀门值2
    totalGenerated: bigint;//总产量

    reward: bigint;//总获取的值
}
//质押矿池
export interface IStakingPool {
    poolId?: string;//池id，添加时生成
    area: string;//矿区
    isShow: boolean;
    iconUrl: string;//icon
    imgUrl: string;//图片
    name: string;//名称
    durabilityLoss: number;//每分钟消耗的耐久度
    supportChain: string;

    state: StakingPoolState;//矿池状态
    openUTC: Date;//开始时间
    closeUTC: Date;//结束时间
    rewardUTC: Date;//可取出开始日期
    landEffectEndUTC: Date;//土地质押截止日期
    officialRewardFee: number;//官方抽水比例
    commissionRate: number;//佣金比例，从官方抽水里抽取
    stakeFeeRate: number;//质押费用系数
    updateTime?: Date;//刷新时间
    totalForce: bigint;
    totalBonus: number;//总加成
    stakedCount: number;//质押的数量
    generator: IStakingPoolReward[];
    solWallet: string;
    bscWallet: string;

    computerStaked: number;
    landStaked: number;
    otherStaked: number;
    partnerStaked: number;
    tokenStaked: number;

    realForce?: bigint;
}

export interface IStakingAccountReward {
    address: string;//产出地址
    commissionAddress: string;//佣金地址

    totalGenerated: bigint;//总生产量
    rewardPerMinute: bigint;//每分钟获得的奖励，计算时需要四舍五入
    rewards: bigint;//已获取数量
}
// 质押账户，以池子划分
export interface IStakingAccount {
    address: string;//用户账户
    poolId: string;//所在池子id
    staked: IStakedNFT[];//质押的nft

    updateTime: Date;//刷新时间
    harvestAuthority: number;//提取收益权限
    commissionAuthority: number;//获取佣金权限
    totalForce: bigint;//总有效算力
    totalBonus: number;//总奖励
    lands: number;//质押地块数量
    quantumForce: number;//质押工厂
    hyperQuantumForce: number;//超级工厂
    nfts: number;//质押的其他nft

    generator: IStakingAccountReward[]
}