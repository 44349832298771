import { useContext, useEffect } from "react";
import CommodityDetail from "../components/commodityDetail";
import { CommodityContext } from "../contexts/commodityContext";
import { Define } from "../define/define";
import { INavigation } from "../event/navigation";
import "../assets/css/newMarketCommon.css"

function CommodityPage(navigation: INavigation) {
    const nft = useContext(CommodityContext);
    const { onShowWallet, onNavigation } = navigation;
    useEffect(() => {
        onNavigation(Define.Page.Commodity);
    }, []);
    return (
        <section className="market-container-common">
            <CommodityDetail commodityInfo={nft} setWalletShow={onShowWallet} />
        </section>
    );
}

export default CommodityPage;