import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/gameIconList.css";
import "../assets/css/market.css";
import { GameStoreContext } from "../contexts/gameContext";
import { Define } from "../define/define";
import { apiClient } from "../global/global";
import { GameIconIntro } from "../prot/protocols/market/PtlGetRecentListed";
import GameIcon from "./gameIcon";

function RecentProjects() {
    const [gameList, setGameList] = useState([] as GameIconIntro[]);
    const [ContainerRef, setContainerRef] = useState(null as HTMLDivElement);
    const [ContainerWidth, setContainerWidth] = useState("100%")
    const navigate = useNavigate();
    const ctx = useContext(GameStoreContext);
    const onClick = (info: GameIconIntro) => {
        if (info.project == "Official") {
            navigate(Define.Page.Marketplace);
            return;
        }
        ctx.project = info.project;
        navigate(Define.Page.GameStore);
    }
    const getList = async () => {
        const res = await apiClient.callApi("market/GetRecentListed", {});
        if (res.isSucc) {
            setGameList(res.res.list)
        }
    }
    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        const onResize = () => {
            setContainerWidth(getWidth());
        }
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])


    const getWidth = () => {
        console.log("width");

        if (!ContainerRef)
            return "100%"

        const maxColumn = 6;
        const curWidth = ContainerRef.clientWidth - 36 * 2;
        const minColumn = 1;
        let column = maxColumn;
        let iconWidth = 1200;
        for (; column >= minColumn; column--) {
            iconWidth = (curWidth - (column - 1) * 24) / column - 10;
            console.log(iconWidth);

            if (iconWidth > 180)
                break;
        }
        const offset = column - gameList.length;
        if (offset < 0)
            return "100%";

        const w = curWidth - offset * iconWidth - 24 * (offset - 1);

        return w + "px";
    }

    return (<div id="recent-listed" ref={setContainerRef}>
        <h1>Recently Listed</h1>
        <div id="title-line" />
        <div className="market-game-icon-container small-icon" style={{ width: ContainerWidth }}>
            {gameList.map((v, i) => <GameIcon key={i} info={v} shadow onClick={onClick} />)}
        </div>
    </div>);
}

export default RecentProjects;