import bgu from "../assets/images/staking/BG_up.jpg";
import bgd from "../assets/images/staking/BG_down.jpg";
import { useState, useEffect, ReactNode } from "react";
import "../assets/css/market.css";
import "../assets/css/staking.css";

function StakingContainer(props: { children: ReactNode }) {
    const [Container, setContainer] = useState(null as HTMLElement);
    const [BackgroundTop, setBackgroundTop] = useState(0);
    const onScroll = () => {
        const offset = 2160 - Container.scrollTop;
        setBackgroundTop(Math.max(0, Container.clientHeight - offset))
    }
    useEffect(() => {
        if (Container)
            Container.addEventListener("scroll", onScroll)

        return () => {
            Container?.removeEventListener("scroll", onScroll);
        }
    }, [Container])
    return (<section className="market-container-common" id="need-scroll" ref={setContainer}>
        <div className="staking-container" >
            <div className="staking-bg" style={{ top: BackgroundTop }}>
                <img src={bgu} alt="" />
                <img src={bgd} alt="" />
            </div>
            {props.children}
        </div>
    </section >);
}

export default StakingContainer;