import React, { createContext, FC, useCallback, useEffect, useState } from "react";
import "../assets/scss/marketplace.scss";
import cs from "classnames";
import { Loading } from "../components/loading";
import { Define } from "../define/define";
import { GlobalConfig } from "../global/globalConfig";
import { NftDetail } from "../components/nftDetail";
import { INavigation } from "../event/navigation";
import { INft } from "../contexts/nftContext";
import EventCenter from "../event/eventCenter";
import Line from "../assets/images/marketplace/line.png";
import ExtendedLine from "../assets/images/marketplace/extended-line.png";
import Selected from "../assets/images/common/selected.png";
import SelectedButton from "../assets/images/common/selected-button.png";
import { apiClient } from "../global/global";
import { OfficialItem } from "../prot/protocols/base";
import { useNavigate } from "react-router-dom";

export const NftContext = createContext({
    id: 0,
    name: "",
    initial_nfts: 0,
    category: 0,
    rarity: 0,
    level: 0,
    price: 0,
    increase: 0,
    gender: 0,
    age: 0,
    length: 0,
    width: 0,
    height: 0,
    feature: "",
    skill: 0,
    space: 0,
    wartung_des_computers: 0,
    power_supply: 0,
    impulse_engine: 0,
    qpu: 0,
    shield_generator: 0,
    weapon_hardpoint: 0,
    energy_consumption: "",
    materials_consumption: "",
    output: "",
    human_commander: 0,
    working_robot: 0,
    image: "",
    atlas: "",
    description: "",
    quantity: 0
});

export const Marketplace: FC<INavigation> = (navigation: INavigation) => {
    const { onShowWallet, onNavigation } = navigation;
    const navigate = useNavigate();
    let categoryNftArray = new Array<INft>();
    let nftArray = new Array<INft>();
    let tempSearchString: string = "";
    let tempCurrentCategory: number = Define.Category.InitialNFTs;
    let tempSortBy = Define.Sort.Select;

    const [isShowNftList, setIsShowNftList] = useState(true);
    const [nfts, setNfts] = useState(new Array<INft>());
    const [currentCategory, setCurrentCategory] = useState(Define.Category.InitialNFTs);
    const [searchString, setSearchString] = useState("");
    const [nft, setNft] = useState({
        id: 0,
        name: "",
        initial_nfts: 0,
        category: 0,
        rarity: 0,
        level: 0,
        price: 0,
        increase: 0,
        gender: 0,
        age: 0,
        length: 0,
        width: 0,
        height: 0,
        feature: "",
        skill: 0,
        space: 0,
        wartung_des_computers: 0,
        power_supply: 0,
        impulse_engine: 0,
        qpu: 0,
        shield_generator: 0,
        weapon_hardpoint: 0,
        energy_consumption: "",
        materials_consumption: "",
        output: "",
        human_commander: 0,
        working_robot: 0,
        image: "",
        atlas: "",
        description: "",
        quantity: 0
    });

    const [selectedNftId, setSelectedNftId] = useState(Define.INVALID);
    const [domNftArray, setDomNftArray] = useState(new Array<INft>());
    const [sortBy, setSortBy] = useState(Define.Sort.Select);
    const [isInitNfts, setIsInitNfts] = useState(true);

    useEffect(() => {
        onNavigation(Define.Page.Marketplace);
        window.scrollTo(0, 0);
    }, []);

    const onRefreshMarketplace = async (isLoadNftConfig: boolean) => {
        const items = await apiClient.callApi("auction/GetOfficialMarketplace", {
            chain: "SOL",
            category: "item",
            needNft: true,
        })
        setIsInitNfts(!isLoadNftConfig);
        let nftList = GlobalConfig.NftConfigList;
        nftArray = nftList;
        nftArray = getCategory(nftArray, currentCategory, items.res.items);
        categoryNftArray = nftArray;
        setNfts(nftList);
        setDomNftArray(nftArray);
    }

    useEffect(() => {
        EventCenter.addListener(Define.Event.RefreshMarketplace, onRefreshMarketplace);
        return () => {
            EventCenter.removeListener(Define.Event.RefreshMarketplace, onRefreshMarketplace);
        }
    }, [onRefreshMarketplace]);

    useEffect(() => {
        loadNftConfig();
    }, []);

    const loadNftConfig = async () => {
        const items = await apiClient.callApi("auction/GetOfficialMarketplace", {
            chain: "SOL",
            category: "item",
            needNft: true,
        })
        let nftList = GlobalConfig.NftConfigList;
        if (nftList.length > 0) {
            setIsInitNfts(false);
        }
        nftArray = nftList;
        nftArray = getCategory(nftArray, tempCurrentCategory, items.res.items);
        categoryNftArray = nftArray;
        setNfts(nftList);
        setDomNftArray(nftArray);
    }

    const onCategoryClick = (category: number) => {
        if (Define.Categories[category - 1].category == Define.Category.Resources) {
            return;
        }

        if (Define.Categories[category - 1].category === Define.Category.Lands) {
            navigate(Define.Page.Map);
        } else {
            tempCurrentCategory = category;
            setCurrentCategory(tempCurrentCategory);
            tempSearchString = "";
            setSearchString(tempSearchString);
            setSelectedNftId(Define.INVALID);
            setNftList();
        }
    }

    const getCategory = (nfts: Array<INft>, category: number, items: OfficialItem[]) => {
        let tempNftArray: INft[] = [];

        nfts.filter(v => items.findIndex((a) => a.id == v.id.toString()) != -1)
            .forEach(nft => {
                if (category === 1) {
                    if (nft.quantity > 0 && nft.initial_nfts === 1) {
                        tempNftArray.push(nft);
                    }
                } else {
                    if (nft.quantity > 0 && nft.category === category) {
                        tempNftArray.push(nft);
                    }
                }
            })

        return tempNftArray;
    }

    const onSearchChange = useCallback((event) => {
        tempSearchString = event.target.value;
        setSearchString(tempSearchString);
        setNftList();
    }, [nfts]);

    const setNftList = async () => {
        const items = await apiClient.callApi("auction/GetOfficialMarketplace", {
            chain: "SOL",
            category: "item",
            needNft: true,
        })
        setIsShowNftList(true);
        let nftArray = [];
        let tempCategoryNftArray = getCategory(nfts, tempCurrentCategory, items.res.items);

        for (let i = 0; i < tempCategoryNftArray.length; i++) {
            let nft = tempCategoryNftArray[i];
            if (nft.name.toLowerCase().includes(tempSearchString.toLowerCase())) {
                nftArray.push(nft);
            }
        }

        switch (tempSortBy) {
            case Define.Sort.NameAtoZ:
                nftArray = sortByNameAtoZ(nftArray);
                break;
            case Define.Sort.NameZtoA:
                nftArray = sortByNameZtoA(nftArray);
                break;
            case Define.Sort.Newest:
                nftArray = sortByNewest(nftArray);
                break;
            case Define.Sort.Oldest:
                nftArray = sortByOldest(nftArray);
                break;
            case Define.Sort.LowPrice:
                nftArray = sortByLowPrice(nftArray);
                break;
            case Define.Sort.HighPrice:
                nftArray = sortByHighPrice(nftArray);
                break;
        }

        setDomNftArray(nftArray);
    };

    const sortByNameAtoZ = (nfts: Array<INft>) => {
        return nfts.sort((a, b) => {
            return a["name"].localeCompare(b["name"]);
        });
    }

    const sortByNameZtoA = (nfts: Array<INft>) => {
        return nfts.sort((a, b) => {
            return b["name"].localeCompare(a["name"]);
        });
    }

    const sortByNewest = (nfts: Array<INft>) => {
        for (let i = 0; i < nfts.length - 1; i++) {
            for (let j = i; j < nfts.length; j++) {
                let iId = nfts[i].id;
                let jId = nfts[j].id;
                if (iId < jId) {
                    let temp = nfts[i];
                    nfts[i] = nfts[j];
                    nfts[j] = temp;
                }
            }
        }
        return nfts;
    }

    const sortByOldest = (nfts: Array<INft>) => {
        for (let i = 0; i < nfts.length - 1; i++) {
            for (let j = i; j < nfts.length; j++) {
                let iId = nfts[i].id;
                let jId = nfts[j].id;
                if (iId > jId) {
                    let temp = nfts[i];
                    nfts[i] = nfts[j];
                    nfts[j] = temp;
                }
            }
        }
        return nfts;
    }

    const sortByLowPrice = (nfts: Array<INft>) => {
        for (let i = 0; i < nfts.length - 1; i++) {
            for (let j = i; j < nfts.length; j++) {
                let iPrice = nfts[i].price;
                let jPrice = nfts[j].price;
                if (iPrice > jPrice) {
                    let temp = nfts[i];
                    nfts[i] = nfts[j];
                    nfts[j] = temp;
                }
            }
        }
        return nfts;
    }

    const sortByHighPrice = (nfts: Array<INft>) => {
        for (let i = 0; i < nfts.length - 1; i++) {
            for (let j = i; j < nfts.length; j++) {
                let iPrice = nfts[i].price;
                let jPrice = nfts[j].price;
                if (iPrice < jPrice) {
                    let temp = nfts[i];
                    nfts[i] = nfts[j];
                    nfts[j] = temp;
                }
            }
        }
        return nfts;
    }

    const onNftDetailMouseOver = useCallback((iNft: INft) => {
        setSelectedNftId(iNft.id);
    }, []);

    const onNftDetailClick = useCallback(async (iNft: INft) => {
        setNft(iNft);
        setIsShowNftList(false);
        nftArray = new Array<INft>();
        setDomNftArray(nftArray);
        setSelectedNftId(Define.INVALID);
    }, []);

    const onShowNftList = useCallback(() => {
        tempCurrentCategory = currentCategory;
        setNftList();
    }, [currentCategory, nfts]);

    const onShowWallets = useCallback(() => {
        onShowWallet("SOL");
    }, []);

    const onSelectChange = useCallback((event) => {
        tempSortBy = event.target.selectedIndex;
        setSortBy(tempSortBy);
        setNftList();
    }, [nfts]);

    return (
        <section>
            {
                isInitNfts
                    ?
                    <Loading />
                    :
                    null
            }
            {
                isShowNftList ?
                    <section className="marketplace">
                        <section className="content">
                            <img className="line" src={Line} alt="-_-" draggable={false} />
                            <img className="left-line" src={ExtendedLine} alt="-_-" draggable={false} />
                            <img className="right-line" src={ExtendedLine} alt="-_-" draggable={false} />
                            <section className="category">
                                <ul> {
                                    Define.Categories.map((category, index) => {
                                        if (category.category === Define.Category.Resources) {
                                            return <li
                                                key={index}
                                                className={cs({
                                                    btn: true,
                                                    "btn-select": currentCategory === category.category,
                                                    "li-select": currentCategory === category.category
                                                })}
                                                onClick={() => onCategoryClick(category.category)}>
                                                <p>{category.title}</p>
                                            </li>
                                        } else {
                                            return <li
                                                key={index}
                                                className={cs({
                                                    btn: true,
                                                    "btn-select": currentCategory === category.category,
                                                    "li-select": currentCategory === category.category
                                                })}
                                                onClick={() => onCategoryClick(category.category)}>
                                                {category.title}
                                            </li>
                                        }
                                    })
                                }
                                </ul>
                            </section>
                        </section>
                        <section className="frame" />
                        <section className="select">
                            <select className="options" value={Define.SortType[sortBy]}
                                onChange={(event) => onSelectChange(event)}> {
                                    Define.SortType.filter(v => {
                                        if (currentCategory != Define.Category.InitialNFTs) {
                                            if (v == Define.SortType[Define.Sort.HighPrice] || v == Define.SortType[Define.Sort.LowPrice])
                                                return false;
                                        }
                                        return true;
                                    }).map((sort, index) => {
                                        return (
                                            <option key={index} value={sort} className="option">{sort}</option>
                                        )
                                    })
                                }
                            </select>
                            <h1 className="sort-by">Sort by</h1>
                        </section>
                        <section className="search">
                            <input className="placeholder"
                                value={searchString}
                                placeholder="Search Marketplace"
                                onChange={onSearchChange} />
                        </section>
                        <section className="nfts">
                            <ul>
                                {
                                    domNftArray.map((n, i) => {
                                        return (
                                            <section className="nft" key={i}>
                                                <section className="background"
                                                    onMouseOver={() => onNftDetailMouseOver(n)}
                                                    onClick={() => onNftDetailClick(n)} />
                                                {
                                                    selectedNftId === n.id
                                                        ? <section>
                                                            <img className="selected" src={Selected} />
                                                            <img className="selected-btn"
                                                                src={SelectedButton} />
                                                        </section>
                                                        :
                                                        null
                                                }
                                                <li className="name" onMouseOver={() => onNftDetailMouseOver(n)}>
                                                    {n.name}
                                                </li>
                                                <img className="icon"
                                                    src={require("../assets/images/icon/" + n.image)}
                                                    onMouseOver={() => onNftDetailMouseOver(n)}
                                                    onClick={() => onNftDetailClick(n)}
                                                    alt="-_-"
                                                    draggable={false} />
                                                <img className="rarity"
                                                    src={require("../assets/images/rarity/" + Define.RarityType[n.rarity].toLowerCase() + ".png")}
                                                    onMouseOver={() => onNftDetailMouseOver(n)}
                                                    onClick={() => onNftDetailClick(n)}
                                                    alt="-_-"
                                                    draggable={false} />
                                            </section>
                                        )
                                    })
                                }
                            </ul>
                        </section>
                    </section>
                    :
                    <section className="marketplace">
                        {
                            isInitNfts
                                ?
                                <Loading />
                                :
                                null
                        }
                        <section className="content">
                            <img className="line" src={Line} alt="-_-" draggable={false} />
                            <img className="left-line" src={ExtendedLine} alt="-_-" draggable={false} />
                            <img className="right-line" src={ExtendedLine} alt="-_-" draggable={false} />
                            <section className="category">
                                <ul> {
                                    Define.Categories.map((category, index) => {
                                        if (category.category === Define.Category.Resources) {
                                            return <li
                                                key={index}
                                                className={cs({
                                                    btn: true,
                                                    "btn-select": currentCategory === category.category,
                                                    "li-select": currentCategory === category.category
                                                })}
                                                onClick={() => onCategoryClick(category.category)}>
                                                <p>{category.title}</p>
                                            </li>
                                        } else {
                                            return <li
                                                key={index}
                                                className={cs({
                                                    btn: true,
                                                    "btn-select": currentCategory === category.category,
                                                    "li-select": currentCategory === category.category
                                                })}
                                                onClick={() => onCategoryClick(category.category)}>
                                                {category.title}
                                            </li>
                                        }
                                    })
                                }
                                </ul>
                            </section>
                        </section>
                        <section className="frame" />
                    </section>
            }{
                isShowNftList
                    ?
                    null
                    :
                    <NftContext.Provider value={nft}>
                        <NftDetail
                            currentCategory={currentCategory}
                            enterType={Define.Enter.Marketplace}
                            onShowNftList={onShowNftList}
                            onShowWalletPanel={onShowWallets}
                        />
                    </NftContext.Provider>
            }
        </section>
    );
}
