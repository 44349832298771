import { ObjectId, Decimal128 } from "mongodb";
import { IStakedNFT, IStakingPoolItem, IStakingArea, IStakingPool, StakingGenerateType, IStakingAccount } from "../staking/Staking";
import { ChainType } from "../types";


// export const MitCoinSOL = "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr";
export const MitCoinSOL = "4SDRbosn3cyRVVpBtbpi7GHZ1XrR1dK7umfj7CvfGca9";
export const MitCoinBSC = "0x40406bEa2903DCBFF3d51f2d83e54437feBdf475";
// export const MitCoinBSC = "0x4F98fE05961d8589F722AeD4fB21E3b0e4C4A6E7";

export const MitCoinDecimalSOL = 8;
export const MitCoinDecimalBSC = 9;
// export const MitCOinBSC = "0x16617E328e85b0074770bc709777044415ea57ad";
//产出刷新频率：5分一次
//耐久刷新频率：根据矿池刷新

//质押的nft
export interface DbStakedNFT extends Omit<IStakedNFT, "force" | "toolCost"|"amount"> {
    force: Decimal128;//算力,受耐久影响
    toolCost: Decimal128;//维修消耗
    amount: Decimal128;
}

//质押池中道具
export interface DbStakingPoolItem extends Omit<IStakingPoolItem, "force" | "toolCost" | "price"> {
    _id?: ObjectId;
    force: Decimal128;//产能
    toolCost: Decimal128;//维修消耗
    price: Decimal128;//质押费用，需要乘以池系数
    open: boolean;
}
//质押矿区
export interface DbStakingArea extends Omit<IStakingArea, "generator"> {
    _id?: ObjectId;
    generator: {
        address: string,
        closedGenerated: Decimal128;
        totalGenerated: Decimal128;
    }[];
}
//质押矿池
export interface DbStakingPool extends Omit<IStakingPool, "generator" | "totalForce"> {
    _id?: ObjectId;
    generator: {
        address: string;
        commissionAddress: string;
        generateType: StakingGenerateType;//产出计划，分为A和B
        produceLimit1: Decimal128;//产出阀门值1
        produceLimit2: Decimal128;//产出阀门值2
        totalGenerated: Decimal128;//总产量
        reward: Decimal128;//获取量
    }[];

    totalForce: Decimal128;//总生产力
}
// 质押账户，以池子划分
export interface DbStakingAccount extends Omit<IStakingAccount, "staked" | "totalForce" | "generator"> {
    _id?: ObjectId;
    staked: DbStakedNFT[];//质押的nft
    totalForce: Decimal128;//总有效算力
    generator: {
        address: string;//产出地址
        commissionAddress: string;
        totalGenerated: Decimal128;//总生产量
        rewardPerMinute: Decimal128;//每分钟获得的奖励，计算时需要四舍五入
        rewards: Decimal128;//已获取数量
    }[];
}

export interface DbStakeCoin {
    _id?: ObjectId;
    name: string;
    symbol: string;
    imgUrl: string;
    address: string;
    chain: ChainType;
    decimal: number;
}