import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import "./App.css";
import { Header } from "./components/header";
import { GlobalConfigContext } from "./contexts/GlobalConfigContext";
import { Define } from "./define/define";
import EventCenter from "./event/eventCenter";
import { apiClient, Global, RefreshWebsite } from "./global/global";
import { GlobalConfig } from "./global/globalConfig";
// import { jsonInfo2PoolKeys, Liquidity, LiquidityPoolKeys, poolKeys2JsonInfo, Token } from "@raydium-io/raydium-sdk";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { getMetamask } from "./common/EvmAuction";
import FloatMessage from "./components/floatMessage";
import LoadingMask from "./components/loadingMask";
import PopWindow from "./components/popWindow";
import StakeTokenWindow, { ITokenDetail } from "./components/stakeTokenWindow";

export const App: FC = () => {
    const [context, setContext] = useState(Global.AdminConfig);
    const [messages, setMessages] = useState([]);
    const [ShowLoading, setShowLoading] = useState(false);
    const [ShowPop, setShowPop] = useState(false);
    const [PopState, setPopState] = useState({ msg: "string", onClick: (isOk: boolean) => { } });
    const [ShowTokenPop, setShowTokenPop] = useState(false)
    const [TokenPop, setTokenPop] = useState({ onClick: (isOk: boolean) => { }, list: [], msg: "", certain: "" });
    const [OverlayUI, setOverlayUI] = useState({} as { [key: string]: JSX.Element })
    Global.ShowLoading = setShowLoading;
    Global.ShowPop = (msg: string, onClick: (isOk: boolean) => void) => {
        setShowPop(true);
        setPopState({
            msg,
            onClick,
        });
    }
    Global.HidePop = () => {
        setShowPop(false);
    }
    Global.ShowTokenPop = (list: ITokenDetail[], msg: string, type: string, onClick: (isOk: boolean) => void) => {
        setShowTokenPop(true);
        setTokenPop({
            list,
            msg,
            onClick,
            certain: type,
        });
    }
    Global.HideTokenPop = () => {
        setShowTokenPop(false);
        setTokenPop({
            list: [],
            msg: "",
            onClick: () => { },
            certain: "",
        });
    }

    Global.AddOverlay = (key: string, element: JSX.Element) => {
        setOverlayUI(pre => {
            pre[key] = element;
            return { ...pre };
        });
    }
    Global.RemoveOverlay = (key: string) => {
        setOverlayUI(pre => {
            pre[key] = undefined;
            return { ...pre };
        });
    }

    try {
        getMetamask().on('disconnect', (err: any) => {
            console.log(err);
            Global.EthProvider = null;
        });
    } catch (error) {
        console.error(error);

    }


    if (process.env.NODE_ENV === "production")
        console.log = () => { };

    Global.AddMessage = (message: string, txId?: string, subMessage?: string, displayTime?: number, callback?: Function) => {
        let key = Date.now().toString()
        setMessages(list => {
            return [...list,
            <FloatMessage
                key={key}
                keyId={key}
                message={message}
                txId={txId}
                sub={subMessage || "(View on Chain Explorer)"}
                displayTime={displayTime || 6 * 1000}
                callback={callback}
            />];
        });
        return key;
    }
    Global.RemoveMessage = (key: string) => {
        setMessages(list => list.splice(list.findIndex(v => v.props.keyId == key)));
    }
    /**
     * 获取网页配置
     * @returns 
     */
    async function GetGlobalConfig() {
        console.debug('get start');
        //连接服务器
        const settingsRes = await apiClient.callApi("settings/GetSettings", { address: "" });
        if (settingsRes.err) {
            console.error(settingsRes.err);
            RefreshWebsite();
            return;
        }
        console.log(settingsRes.res.Configs);
        const { WhiteList, WhiteListOnly } = settingsRes.res;
        if (WhiteListOnly) {
            Global.WhiteList = new Set();
            WhiteList.forEach(v => Global.WhiteList.add(v));
        }
        else
            Global.WhiteList = null;
        const { MapConfigURL, ItemConfigURL, ConfigTypeURL, CityTagURL, ProvinceTagURL, CountryTagURL } = settingsRes.res.Configs;
        console.log(ItemConfigURL);

        Global.ConfigAsync["config_item"] = axios.get(ItemConfigURL).then(res => res.data);
        Global.ConfigAsync["config_map"] = axios.get(MapConfigURL).then(res => res.data);
        Global.ConfigAsync["config_type"] = axios.get(ConfigTypeURL).then(res => res.data);
        Global.MapGeoURL = [CityTagURL, CountryTagURL, ProvinceTagURL,];

        Global.Contract = settingsRes.res;

        Global.AdminConfig.ShowCoin[Define.Token.SOL] = true;//OpenSPLToken.findIndex(v => v == "SOL") != -1;
        Global.AdminConfig.ShowCoin[Define.Token.USDC] = false;//OpenSPLToken.findIndex(v => v == "USDC") != -1;
        Global.AdminConfig.ShowCoin[Define.Token.SYP] = false;//OpenSPLToken.findIndex(v => v == "SYP") != -1;
        Global.AdminConfig.ShowCoin[Define.Token.MBTT] = false;//OpenSPLToken.findIndex(v => v == "MBTT") != -1;
        Global.AdminConfig.ShowCoin[Define.Token.MITCOIN] = false;//OpenSPLToken.findIndex(v => v == "MITCOIN") != -1;

        //获取tokenMint
        Global.AdminConfig.Decimals = await Promise.all([
            Global.getTokenDecimal(Global.Connection, new PublicKey(Global.AdminConfig.TokenAddress[Define.Token.USDC])),
            Global.getTokenDecimal(Global.Connection, new PublicKey(Global.AdminConfig.TokenAddress[Define.Token.SOL])),
            Global.getTokenDecimal(Global.Connection, new PublicKey(Global.AdminConfig.TokenAddress[Define.Token.SYP])),
            Global.getTokenDecimal(Global.Connection, new PublicKey(Global.AdminConfig.TokenAddress[Define.Token.MBTT])),
            Global.getTokenDecimal(Global.Connection, new PublicKey(Global.AdminConfig.TokenAddress[Define.Token.MITCOIN]))
        ]);
        setContext(Global.AdminConfig);
        console.log("设置");

        await GlobalConfig.InitConfigs();
        await loadTokenPrice();

        Global.IsInitGlobalConfig = true;
        EventCenter.emit(Define.Event.RefreshMarketplace, true);
        EventCenter.emit(Define.Event.RefreshProfile, true);
        console.debug('get complete');
    }

    const loadTokenPrice = async () => {
        console.log("fetching price");
        const connection = new Connection(clusterApiUrl(WalletAdapterNetwork.Mainnet), "confirmed");
        const poolsJson: any[] = (await axios.get("https://api.raydium.io/v2/sdk/liquidity/mainnet.json")).data.official;
        const fromTokens = new Set([
            Define.SOLAddress,
            Define.SYPAddress,
            Define.MBTTAddress,
            Define.MITCOINAddress,
        ]);

        const toToken = Define.USDCAddress;
        const pools = poolsJson.filter(v => (fromTokens.has(v.baseMint) && v.quoteMint === toToken));
        console.log(pools);


        // const infos = await Liquidity.fetchMultipleInfo({ connection, pools });
        // for (let index = 0; index < pools.length; index++) {
        //     const pool = pools[index];
        //     const info = infos[index];
        //     const price = Liquidity.getRate(info);
        //     const rate = parseFloat(price.toFixed());
        //     const json = poolKeys2JsonInfo(pool);
        //     if (json.baseMint == Define.SOLAddress)
        //         Global.USDCRate[Define.Token.SOL] = rate;
        //     else if (json.baseMint == Define.SYPAddress)
        //         Global.USDCRate[Define.Token.SYP] = rate;
        //     else if (json.baseMint == Define.MITCOINAddress)
        //         Global.USDCRate[Define.Token.MITCOIN] = rate;
        //     else if (json.baseMint == Define.MBTTAddress)
        //         Global.USDCRate[Define.Token.SYP] = rate;
        // }
        // console.log("price updated", Global.USDCRate);
    }

    useEffect(() => {
        GetGlobalConfig();

        //每五分钟刷新币种价格
        // setInterval(() => {
        //     loadTokenPrice();
        // }, 5 * 60 * 1000);
    }, []);
    return (
        <GlobalConfigContext.Provider value={context}>
            <div>
                <Header />
                <LoadingMask display={ShowLoading} />
                <div className="float-message-container">
                    {messages}
                </div>
                <PopWindow msg={PopState.msg} onClick={PopState.onClick} display={ShowPop} />
                <StakeTokenWindow message={TokenPop.msg}
                    certain={TokenPop.certain}
                    tokens={TokenPop.list}
                    display={ShowTokenPop}
                    onClick={TokenPop.onClick} />
                <div className="overlay-all">{
                    Object.values(OverlayUI)
                }</div>
            </div>
        </GlobalConfigContext.Provider>
    );
}
