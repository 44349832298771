

export interface IToolTipsProperty {
    left: number;
    top: number;
    lines: string[];
}

function ToolTips(props: IToolTipsProperty) {
    return (<div className="tool-tips" style={{
        left: props.left,
        top: props.top,
    }}>
        {props.lines.map((v, i) => <h1 key={i}>{v}</h1>)}
    </div>);
}

export default ToolTips;