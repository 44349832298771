import { useEffect, useState } from "react";
import "../assets/css/stakeTokenWindow.css"
import under from "../assets/images/staking/window_under.png"
import { Global, LimitInputNumber, } from "../global/global";

export interface ITokenDetail {
    _id: string;
    address: string;
    amount: number;
    isNft: boolean;
    decimal: number;
    //只有token才有
    exchange: number;
    icon: string;
    limit: number;
    force: number;
    bonus: number;
}

function StakeTokenWindow(props: {
    message: string,
    certain?: string,
    cancel?: string,
    tokens: ITokenDetail[], display: boolean, onClick: (isOk: boolean) => void
}) {

    const [Force, setForce] = useState(0);
    const [Bonus, setBonus] = useState(0);

    useEffect(() => {
        setForce(0);
        setBonus(0);
    }, [props.display])


    const onCancelClick = () => {
        props.onClick(false);
        Global.HideTokenPop();
    }
    const onCertainClick = () => {
        props.tokens.filter(v => !v.isNft).map((v, i) => {
            v.amount = v.amount * Math.pow(10, v.decimal);
        })
        props.onClick(true);
        Global.HideTokenPop();
    }
    const calc = () => {
        let force = 0;
        let bonus = 0;
        props.tokens.filter(v => !v.isNft).forEach(element => {
            force += element.force * Math.floor(element.amount / element.exchange);
            bonus += element.bonus * Math.floor(element.amount / element.exchange);
        });

        setForce(isNaN(force) ? 0 : force);
        setBonus(isNaN(bonus) ? 0 : bonus);
    }

    return (<div id="stake-pop-mask" style={{ display: props.display ? "block" : "none" }}>
        <div id="stake-pop">
            <div id="stake-pop-bg">
                <p>{props.message}</p>
                <div className="token-list">
                    {props.tokens.filter(v => !v.isNft).map((v, i) => {
                        const onChange = (e: any) => {
                            const val = LimitInputNumber(e);
                            console.log(val, v.limit);

                            if (isNaN(val)) {
                                console.log(val);
                                e.target.value = "";
                            }
                            if (val > v.limit) {
                                v.amount = v.limit;
                                e.target.value = v.limit;
                            }
                            else
                                v.amount = val;
                            calc();
                        }
                        return <span key={i} className="token-input">
                            <img src={v.icon} alt="" />
                            <input type="text" placeholder="" onChange={onChange} />
                        </span>
                    })}
                </div>
                <div className="summary">
                    <label >Total Force:{Force}</label>
                    <label >Bonus:{`${(Bonus / 100).toFixed(2)}%`}</label>
                </div>
                <div>
                    <button className="stake-pop-button" onClick={onCertainClick}>{props.certain || "Certain"}</button>
                    <button className="stake-pop-button" onClick={onCancelClick}>{props.cancel || "Cancel"}</button>
                </div>
                <section className="pop-underline">
                    <img src={under} />
                </section>
            </div>
        </div>
    </div>);
}

export default StakeTokenWindow;