import { useContext, useEffect, useState } from "react";
import StakingContainer from "../components/stakingContainer";
import StakingHeader from "../components/stakingHeader";
import { Define } from "../define/define";
import { INavigation } from "../event/navigation";
import "../assets/css/stakingPool.css"
import StakingFolder from "../components/stakingFolder";
import asia from "../assets/images/staking/Txt_Asia.png"
import europe from "../assets/images/staking/Txt_Europe.png"
import africa from "../assets/images/staking/Txt_Africa.png"
import america from "../assets/images/staking/Txt_America.png"
import oceania from "../assets/images/staking/Txt_Oceania.png"
import PoolCard from "../components/poolCard";
import { IStakingPoolSummary } from "../prot/protocols/staking/PtlGetPoolList";
import { StakingNavigateContext } from "../contexts/stakingNavigateContext";
import { StakingGenerateType, StakingPoolState } from "../prot/protocols/staking/Staking";
// import { dummyStakingPool } from "../define/dummyInfo";
import { apiClient, Global } from "../global/global";
import { IStakingAccountSummary } from "../prot/protocols/staking/PtlGetStakeAccounts";

function StakingPool(navigation: INavigation) {
    const { onShowWallet, onNavigation } = navigation;
    const [Folders, setFolders] = useState([true, false, false, false, false])
    const [CardScale, setCardScale] = useState(1);
    const [Asia, setAsia] = useState([] as IStakingPoolSummary[]);
    const [Europe, setEurope] = useState([] as IStakingPoolSummary[]);
    const [Oceania, setOceania] = useState([] as IStakingPoolSummary[]);
    const [Africa, setAfrica] = useState([] as IStakingPoolSummary[]);
    const [America, setAmerica] = useState([] as IStakingPoolSummary[]);
    const areaList = ["asia", "europe", "oceania", "africa", "america"];
    const ctx = useContext(StakingNavigateContext);
    const [TotalGenerateIcon, setTotalGenerateIcon] = useState([])
    const [RefreshCounter, setRefreshCounter] = useState(0);
    const [Accounts, setAccounts] = useState({} as { [key: string]: IStakingAccountSummary })

    const fetchIcon = async () => {
        const call = await apiClient.callApi("staking/GetStakeCoin", { addresses: [] })
        if (call.isSucc) {
            setTotalGenerateIcon(call.res.list);
        }
    }

    const fetchAccounts = async () => {

        if (!Global.WalletConnected("SOL") && !Global.WalletConnected("BNB"))
            return;

        const call = await apiClient.callApi("staking/GetStakeAccounts", { address: Global.WalletAddress });
        if (call.isSucc) {
            const acc: any = {};
            console.log(call);

            for (const a of call.res.list) {
                acc[a.poolId] = a;
            }
            setAccounts(acc);
        }
    }

    const fetchPools = async () => {
        const sets = [setAsia, setEurope, setOceania, setAfrica, setAmerica];
        let plans = []
        for (let index = 0; index < areaList.length; index++) {
            const area = areaList[index];
            plans.push(apiClient.callApi("staking/GetPoolList", { area }).then(call => {
                if (call.isSucc && call.res.list.length > 0) {
                    sets[index](call.res.list.filter(v => v.isShow));
                    console.log(call.res.list);

                }
            }))
        }
        await Promise.all(plans)
        fetchAccounts();
        setRefreshCounter(pre => pre + 1);
    }

    useEffect(() => {
        const folders = [];
        for (let index = 0; index < areaList.length; index++) {
            if (ctx.area == areaList[index])
                folders.push(true);
            else
                folders.push(false);
        }
        setFolders(folders);
        onNavigation(Define.Page.Staking);
        fetchPools();
        fetchIcon();

    }, []);
    useEffect(() => {
        const onResize = () => {
            if (window.innerWidth <= 660) {
                setCardScale(window.innerWidth / 660);
            }
            else
                setCardScale(1);
        }
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, [])

    const onFolderClicked = (index: number, isUp: boolean) => {
        console.log(index, isUp);
        let idx = [false, false, false, false, false];
        for (let i = 0; i < 5; i++) {
            if (index == i)
                idx[i] = isUp;
        }
        setFolders(idx);
    }
    const filterChain = (pool: IStakingPoolSummary) => {
        if (Global.WalletConnected("SOL"))
            return pool.supportChain.indexOf("SOL") != -1;
        if (Global.WalletConnected("BNB"))
            return pool.supportChain.indexOf("BSC") != -1;
        return true;
    }

    return (<StakingContainer>
        <StakingHeader />
        <div id="pool">
            <div id="left-bar"></div>
            <div id="folder">
                <StakingFolder index={0} coinIcon={TotalGenerateIcon} title={asia} onClick={onFolderClicked} refreshCounter={RefreshCounter} isOpen={Folders[0]} folderStyle={{ padding: 15 }} >
                    {Asia.filter(v => filterChain(v)).map((v, i) => <PoolCard zoom={CardScale} info={v} key={i} account={Accounts[v.poolId]} />)}
                </StakingFolder>
                <StakingFolder index={1} coinIcon={TotalGenerateIcon} title={europe} onClick={onFolderClicked} refreshCounter={RefreshCounter} isOpen={Folders[1]} folderStyle={{ padding: 15 }} >
                    {Europe.filter(v => filterChain(v)).map((v, i) => <PoolCard zoom={CardScale} info={v} key={i} account={Accounts[v.poolId]} />)}
                </StakingFolder>
                <StakingFolder index={2} coinIcon={TotalGenerateIcon} title={oceania} onClick={onFolderClicked} refreshCounter={RefreshCounter} isOpen={Folders[2]} folderStyle={{ padding: 15 }} >
                    {Oceania.filter(v => filterChain(v)).map((v, i) => <PoolCard zoom={CardScale} info={v} key={i} account={Accounts[v.poolId]} />)}
                </StakingFolder>
                <StakingFolder index={3} coinIcon={TotalGenerateIcon} title={africa} onClick={onFolderClicked} refreshCounter={RefreshCounter} isOpen={Folders[3]} folderStyle={{ padding: 15 }}>
                    {Africa.filter(v => filterChain(v)).map((v, i) => <PoolCard zoom={CardScale} info={v} key={i} account={Accounts[v.poolId]} />)}
                </StakingFolder>
                <StakingFolder index={4} coinIcon={TotalGenerateIcon} title={america} onClick={onFolderClicked} refreshCounter={RefreshCounter} isOpen={Folders[4]} folderStyle={{ padding: 15 }} >
                    {America.filter(v => filterChain(v)).map((v, i) => <PoolCard zoom={CardScale} info={v} key={i} account={Accounts[v.poolId]} />)}
                </StakingFolder>
            </div>
            <div id="right-bar"></div>
        </div>
    </StakingContainer>);
}

export default StakingPool;