import mapboxgl from 'mapbox-gl'
import { layerConfig } from './layer';
import label_back from '../assets/images/map/table.svg';
import { Define } from '../define/define';
import { GetOfficialItemPrice } from '../global/global';
import { TradeState } from '../prot/protocols/auction/Commodity';

mapboxgl.accessToken = "pk.eyJ1Ijoic2h1bmh1YWwiLCJhIjoiY2t6eHF2anlzMDQxbDJvcXZ1bTdkNWMwNCJ9.chmQ1Z_Ack71P2G_uyo4Vw";

let hoveredStateIds = [];
let selected = null;

let deviceEventType = "pc";
let documentClick = false;
let isMoving = false;
let isOut = true;
let clickOneLayer = false;
let removed = false;
let map = null;
let flying = false;
let flyCoord = null;
let flyId = null;
let zooming = false;
let selectLand = (param) => { };
const mapData = {};
const mapIDList = [];
let countrySet = {};
let countryTokenSet = {};
let provinceSet = {};
let provinceTokenSet = {};
let city_tag_g = null;
let country_tag_g = null;
let province_tag_g = null;
let map_json_g = null;
let sell_lands_g = null;
const citySet = {};
if (
    window.navigator &&
    /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent)
) {
    deviceEventType = "phone";
}

function isPC() {
    return deviceEventType === "pc";
}

function onZoomClicked(zoom, srcLayer, e) {
    console.log(map.getZoom());

    if (e.features.length > 0) {
        if (hoveredStateIds.length > 0) {
            hoveredStateIds.forEach((element) => {
                map.setFeatureState(
                    { sourceLayer: element.layer, source: "states", id: element.id },
                    { hover: false });
            });
            hoveredStateIds = [];
        }
        map.setFeatureState(
            {
                sourceLayer: srcLayer,
                source: "states",
                id: e.features[0].id,
            },
            { hover: true }
        );
        hoveredStateIds.push({ id: e.features[0].id, layer: srcLayer });

        map.flyTo({
            center: e.lngLat,
            zoom: zoom,
        });
    }
}

function onMouseMove(srcLayer, e) {
    isOut = false;
    if (e.features.length > 0) {
        isOut = false;

        hoveredStateIds.forEach((element) => {
            if (element.id === selected) return;
            map.setFeatureState(
                { sourceLayer: element.layer, source: "states", id: element.id },
                { hover: false }
            );
        });

        hoveredStateIds = [];
        if (isMoving) return;
        hoveredStateIds.push({ id: e.features[0].id, layer: srcLayer });
        map.setFeatureState(
            {
                sourceLayer: srcLayer,
                source: "states",
                id: hoveredStateIds[0].id,
            },
            { hover: true }
        );
    }
}

function onMouseLeave(srcLayer, e) {
    isOut = true;
    if (hoveredStateIds.length > 0) {
        hoveredStateIds.forEach((element) => {
            if (element.id === selected) return;
            map.setFeatureState(
                { sourceLayer: element.layer, source: "states", id: element.id },
                { hover: false }
            );
        });
    }

    hoveredStateIds = [];
}

function onClicked(srcLayer, e) {
    // console.log(map.getZoom(), clickOneLayer, e, e.features);

    if (hoveredStateIds.length > 0) {
        hoveredStateIds.forEach((element) => {
            map.setFeatureState(
                { sourceLayer: element.layer, source: "states", id: element.id },
                { hover: false }
            );
        });
    }

    if (selected) {
        map.setFeatureState(
            { sourceLayer: srcLayer, source: "states", id: selected },
            { select: false }
        );
        map.setFeatureState(
            { sourceLayer: srcLayer, source: "states", id: selected },
            { hover: false }
        );
    }
    if (clickOneLayer) return;

    if (e.features && e.features.length > 0) {
        let sf = e.features[0];
        if (flyId) {
            sf = e.features.find((f) => f.properties.FieldID == flyId);
            flyId = null;
        }
        let sd = mapData[sf.properties.FieldID];
        console.log(sell_lands_g, sf.properties.FieldID);
        selectLand(sell_lands_g[sf.properties.FieldID]);

        if (selected && selected === sf.id) {
            map.setFeatureState(
                { sourceLayer: srcLayer, source: "states", id: selected },
                { select: false });
            map.setFeatureState(
                { sourceLayer: srcLayer, source: "states", id: selected },
                { hover: false });
            selected = null;
            return;
        }

        map.flyTo({
            center: e.lngLat,
            zoom: 8.5,
        });
        // const bound = getBoundingBox(sf)
        // map.fitBounds(bound, {
        //     padding: { top: 100, bottom: 100, left: 100, right: 100 }
        // });


        selected = sf.id;
        map.setFeatureState(
            { sourceLayer: srcLayer, source: "states", id: selected },
            { select: true }
        );
        map.setFeatureState(
            { sourceLayer: srcLayer, source: "states", id: selected },
            { hover: true }
        );
        removed = false;
    }
}

export async function updateMapFilter(sellLands) {
    if (!map_json_g)
        return;
    console.log("update sell lands", Object.keys(sellLands).length);
    sell_lands_g = sellLands;
    //获取id列表
    //获取id列表map
    for (const iterator of map_json_g) {
        if (!sell_lands_g[iterator.FieldID])
            continue;
        mapData[iterator.FieldID] = iterator;
        mapIDList.push(iterator.FieldID);
        countrySet[iterator.Country] = Infinity;
        countryTokenSet[iterator.Country] = Define.Token.SOL;
        provinceSet[iterator.Province] = Infinity;
        provinceTokenSet[iterator.Country] = Define.Token.SOL;
    }
    // console.log(map_json_g)
    // console.log(mapData);
    //筛取标签
    city_tag_g.features = city_tag_g.features.filter(a => mapData[a.properties.FieldID] != undefined);
    //获取
    for (let index = 0; index < city_tag_g.features.length; index++) {
        const element = city_tag_g.features[index];

        const official = sell_lands_g[element.properties.FieldID].official;
        element.properties.Price = -1;
        // element.properties.TokenType = Define.Token.USDC;
        if (official) {
            // if (official.soldNfts.length > 0) {
            //     element.properties.Price = parseFloat(official.nft.commodity[0].price)
            //     // element.properties.Price = parseFloat((p1 * Global.USDCRate[order.tokenType]).toFixed(4));//Global.getExchangedCoinPrice(order.listing.price.toNumber(), Define.Token.USDC, order.tokenType);
            //     // element.properties.TokenType = Define.TokenType[order.tokenType]
            // }
            // else
            //     element.properties.Price = parseFloat(official.price);
            if (!official.nft || official.nft.state == TradeState.OnSale)
                element.properties.Price = GetOfficialItemPrice(official)
        }
        element.properties.Name = mapData[element.properties.FieldID].name;
        if (element.properties.Price > 0) {
            countrySet[element.properties.Country] = Math.min(countrySet[element.properties.Country], element.properties.Price);
            provinceSet[element.properties.Province] = Math.min(provinceSet[element.properties.Province], element.properties.Price);
        }
    }
    // console.log(city_tag_g);
    country_tag_g.features = country_tag_g.features.filter(f => countrySet[f.properties.Country]);
    province_tag_g.features = province_tag_g.features.filter(f => provinceSet[f.properties.Province]);
    for (let index = 0; index < country_tag_g.features.length; index++) {
        const element = country_tag_g.features[index];
        element.properties.Price = countrySet[element.properties.Country];
        // element.properties.TokenType = countryTokenSet[element.properties.Country];
    }
    for (let index = 0; index < province_tag_g.features.length; index++) {
        const element = province_tag_g.features[index];
        element.properties.Price = provinceSet[element.properties.Province];
        // element.properties.TokenType = provinceTokenSet[element.properties.Province];
    }
}

export async function setMap(configs, selectLandCallback) {
    console.log("stage 1");
    selectLand = selectLandCallback;
    const { city_tag, country_tag, province_tag, mapJson, sellLands } = configs;
    if (map.getSource("country-tag")) {
        return;
    }
    city_tag_g = city_tag;
    country_tag_g = country_tag;
    province_tag_g = province_tag;
    map_json_g = mapJson;

    updateMapFilter(sellLands)

    console.log('stage 2');
    // map.on("load", () => {

    map.addSource("states", {
        type: "vector",
        url: "mapbox://ssk14.8mwor7ad",
    });
    map.addSource('country-tag', {
        type: 'geojson',
        data: country_tag_g,
    })
    map.addSource('province-tag', {
        type: 'geojson',
        data: province_tag_g,
    })
    map.addSource('city-tag', {
        type: 'geojson',
        data: city_tag_g,
    })


    //添加层级
    for (const key in layerConfig) {
        const element = layerConfig[key];
        for (const key in element) {
            if (Object.hasOwnProperty.call(element, key)) {
                const layer = element[key];
                map.addLayer(layer);
            }
        }
    }

    //添加源
    const image = new Image(130, 60);
    image.src = label_back;
    image.onload = () => {


        map.addImage('label-back', image);
        //添加tag

        map.addLayer({
            'id': 'country-price',
            'type': 'symbol',
            'source': 'country-tag', // reference the data source
            'layout': {
                'icon-image': 'label-back',
                'icon-size': 1,
                'icon-text-fit': 'both',
                'text-size': 12,
                'icon-text-fit-padding': [5, 5, 15, 5],
                'text-field': ['concat', ['get', 'Country'], "\n", ['get', 'Price'], " SOL"],
            },
            // minzoom: 0,
            maxzoom: 3,
        });
        map.addLayer({
            'id': 'province-price',
            'type': 'symbol',
            'source': 'province-tag', // reference the data source
            'layout': {
                'icon-image': 'label-back',
                'icon-size': 1,
                'icon-text-fit': 'both',
                'icon-text-fit-padding': [5, 5, 15, 5],
                'text-size': 12,
                'text-field': ['concat', ['get', 'Province'], "\n", ['get', 'Price'], " SOL"],
            },
            minzoom: 3,
            maxzoom: 5,
        });
        map.addLayer({
            'id': 'city-price',
            'type': 'symbol',
            'source': 'city-tag', // reference the data source
            'layout': {
                'icon-image': 'label-back',
                'icon-size': 1,
                'icon-text-fit': 'both',
                'icon-text-fit-padding': [5, 5, 15, 5],
                'text-size': 12,
                'text-field': ['concat', ['get', 'Name'], "\n", ['get', 'Price'], " SOL"],
            },
            minzoom: 5,
        });
        //
        map.setLayoutProperty('country-price', 'text-field', ['concat', ['get', 'Country']]);
        map.setLayoutProperty('province-price', 'text-field', ['concat', ['get', 'Province']]);
        map.setLayoutProperty('city-price', 'text-field', ['concat', ['get', 'Name']]);

    }

    map.on("movestart", () => {
        isMoving = true;
    });
    map.on("moveend", async () => {
        isMoving = false;
        if (flying) {
            // tooltip or overlay here
            waitLoaded(map);
            map.fire('flyend');
        }
    });

    map.on('flystart', function () {
        flying = true;
    });
    map.on('flyend', function () {
        flying = false;
        console.log(flyCoord)
        map.fire('click', flyCoord);
    });

    map.on('drag', () => { selectLand(null) });
    map.on('wheel', () => { selectLand(null) });

    if (isPC()) {
        map.on("mousemove", "country-fills", (e) => {
            documentClick = false;
            onMouseMove("country", e);
        });
        map.on("mouseleave", "country-fills", (e) => {
            onMouseLeave("country", e);
        });
        map.on("mousemove", "province-fills", (e) => {
            onMouseMove("province", e);
        });
        map.on("mouseleave", "province-fills", (e) => {
            onMouseLeave("province", e);
        });
        map.on("mousemove", "city-fills", (e) => {
            onMouseMove("city", e);
        });
        map.on("mouseleave", "city-fills", (e) => {
            onMouseLeave("city", e);
        });
    }
    let mapDom = document.getElementById("map");

    const zoomCountry = function (e) {
        if (zooming)
            return;
        zooming = true;
        mapDom.style.pointerEvents = 'none';
        setTimeout(() => {
            zooming = false;
            mapDom.style.pointerEvents = 'auto';
        }, 1000);
        onZoomClicked(4, "country", e);
    };
    const zoomProvince = function (e) {
        if (zooming)
            return;
        zooming = true;
        mapDom.style.pointerEvents = 'none';
        setTimeout(() => {
            zooming = false;
            mapDom.style.pointerEvents = 'auto';
        }, 1000);
        onZoomClicked(7.5, "province", e);
    }

    map.on("click", "country-fills", zoomCountry);
    map.on("click", "province-fills", zoomProvince);

    map.on("click", "city-fills", (e) => {
        if (zooming)
            return;
        zooming = true;
        mapDom.style.pointerEvents = 'none';
        setTimeout(() => {
            zooming = false;
            mapDom.style.pointerEvents = 'auto';
        }, 600);
        onClicked("city", e);
    });
    map.setFilter('city-fills', ["in", "FieldID", ...mapIDList]);
    map.setFilter('city-borders', ["in", "FieldID", ...mapIDList]);

    map.setFilter('country-fills', ["in", "Country", ...Object.keys(countrySet)]);
    map.setFilter('province-fills', ["in", "Province", ...Object.keys(provinceSet)]);


    map.doubleClickZoom.disable();

    // });
}

export function createMap(domId) {
    map = new mapboxgl.Map({
        container: domId,
        style: "mapbox://styles/shunhual/cl07mx4jx006814ro92sbev55",
        center: [100.486052, 37.830348],
        zoom: 2,
    });
    map.on('load', () => {
        const country_filter = map.getFilter('country-label');
        const state_filter = map.getFilter('state-label');
        map.setFilter('country-label', ["all", ["!=", ["get", "name_en"], "Taiwan"], country_filter]);
        map.setFilter('state-label', ["any", ["==", ["get", "name_en"], "Taiwan"], state_filter]);
        // console.log(map.getLayer("country-label"));
        // console.log(map.getLayer("state-label"));
    })
    // map.setFilter('country-label', ["!=", "name_en", "Taiwan"]);
    console.log("create");
    // countrySet = null;
    // provinceSet = null;

    // map.addControl(new mapboxgl.NavigationControl());

}


export function showMapForSale(show) {
    if (!show) {
        map.setLayoutProperty('country-price', 'text-field', ['concat', ['get', 'Country'], "\n", ['get', 'Price'], " SOL"]);
        map.setLayoutProperty('province-price', 'text-field', ['concat', ['get', 'Province'], "\n", ['get', 'Price'], " SOL"]);
        map.setLayoutProperty('city-price', 'text-field', ['concat', ['get', 'Name'], "\n", ['get', 'Price'], " SOL"]);

        map.setFilter('country-price', [">", "Price", 0]);
        map.setFilter('province-price', [">", "Price", 0]);
        map.setFilter('city-price', [">", "Price", 0]);

    }
    else {
        map.setLayoutProperty('country-price', 'text-field', ['concat', ['get', 'Country']]);
        map.setLayoutProperty('province-price', 'text-field', ['concat', ['get', 'Province']]);
        map.setLayoutProperty('city-price', 'text-field', ['concat', ['get', 'Name']]);

        map.setFilter('country-price', null);
        map.setFilter('province-price', null);
        map.setFilter('city-price', null);
    }
}

export function moveToField(fieldId) {
    let res = city_tag_g.features.filter(a => a.properties.FieldID == fieldId);
    map.flyTo(
        {
            center: res[0].geometry.coordinates,
            zoom: 8,
        }
    );
    flyCoord = res[0].geometry.coordinates;
    flyId = fieldId;
    map.fire('flystart');
}

export function closeMapDetail() {
    if (!map.isStyleLoaded())
        return;

    if (selected) {
        // console.log("close", selected)
        map.setFeatureState(
            { sourceLayer: 'city', source: "states", id: selected },
            { select: false }
        );
        map.setFeatureState(
            { sourceLayer: 'city', source: "states", id: selected },
            { hover: false }
        );
        selected = null;
    }
}

async function waitLoaded(map) {
    return new Promise(async (resolve) => {
        while (true) {
            await wait(1);
            let pass = true;
            if (!map.loaded()) {
                pass = false;
                break;
            }
            if (pass)
                break;
        }
        resolve();
    });
}
async function wait(second) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, second * 1000);
    })
}

function getBoundingBox(data) {
    var bounds = {}, coords, point, latitude, longitude;

    coords = data.geometry.coordinates;
    console.log(data.geometry.type);
    if (data.geometry.type == 'MultiPolygon') {
        coords.forEach(coord => {
            for (var j = 0; j < coord[0].length; j++) {
                longitude = coord[0][j][0];
                latitude = coord[0][j][1];
                bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
                bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
                bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
                bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
            }
        });

    }
    else {
        for (var j = 0; j < coords[0].length; j++) {

            longitude = coords[0][j][0];
            latitude = coords[0][j][1];
            bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
            bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
            bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
            bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
        }
    }

    return [[bounds.xMin, bounds.yMin], [bounds.xMax, bounds.yMax]];
}