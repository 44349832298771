import "../assets/css/staking.css"
import "../assets/css/market.css"
import { INavigation } from "../event/navigation";
import { useContext, useEffect, useState } from "react";
import { Define } from "../define/define";
import bgu from "../assets/images/staking/BG_up.jpg";
import bgd from "../assets/images/staking/BG_down.jpg";
import logo from "../assets/images/staking/Logo.png";
import detailButton from "../assets/images/staking/Button_VIEAW-DETAILS.png";
import myButton from "../assets/images/staking/Button_MY-STAKED.png";
import StakeOverview from "../components/stakeOverview";
import StakingContainer from "../components/stakingContainer";
import { useNavigate } from "react-router-dom";
import { StakingNavigateContext } from "../contexts/stakingNavigateContext";

function Staking(navigation: INavigation) {
    const navigate = useNavigate();
    const ctx = useContext(StakingNavigateContext);

    const { onShowWallet, onNavigation } = navigation;
    const [Container, setContainer] = useState(null as HTMLElement);
    const [BackgroundTop, setBackgroundTop] = useState(0)
    useEffect(() => {
        onNavigation(Define.Page.Staking);
    }, []);
    const onScroll = () => {
        const offset = 2160 - Container.scrollTop;
        setBackgroundTop(Math.max(0, Container.clientHeight - offset))
    }
    useEffect(() => {
        if (Container)
            Container.addEventListener("scroll", onScroll)

        return () => {
            Container?.removeEventListener("scroll", onScroll);
        }
    }, [Container])

    const onViewDetailClick = () => {
        //TODO跳转质押规则
    }
    const onMyStakedClick = () => {
        ctx.poolId = "default";
        navigate(Define.Page.MyStake);
    }


    return (<StakingContainer>
        <img src={logo} id="logo" alt="" />
        <img src={detailButton} id="detailbtn" alt="" onClick={onViewDetailClick} />
        <hr className="hr-edge-weak"></hr>
        <div className="stake-intro">
            <h1><div></div> STAKING NFT</h1>
            <p>
                Select the region to choose the pools you want to stake. Each region has different pools. Please note that land owners can only stake the land in the located region.
            </p>
        </div>
        <StakeOverview />
        <img src={myButton} id="mybtn" alt="" onClick={onMyStakedClick} />
    </StakingContainer >);
}

export default Staking;