import { createContext } from "react";

export interface IStakingNavigateContext {
    area: string;
    poolId: string;
}

const defaultContext: IStakingNavigateContext = {
    area: "default",
    poolId: "default",
};

export const StakingNavigateContext = createContext(defaultContext);