import "../assets/css/marketProjectTitle.css"
import { DbProject } from "../prot/protocols/db/DbProject";
import { useNavigate } from "react-router-dom";
import { Define } from "../define/define";
import { useEffect, useState } from "react";
function MarketProjectTitle(props: { game: DbProject, index?: number, selected: number, onIndexChange?: (index: number) => void }) {
    const navigate = useNavigate();
    const [Checked, setChecked] = useState(false)

    useEffect(() => {

        setChecked(props.selected == props.index)

    }, [props.selected])


    // const onChange = (event: any) => {

    //     if (props.game.name == "Official") {
    //         navigate(Define.Page.Marketplace);
    //         return;
    //     }
    //     if (props.onIndexChange) {
    //         props.onIndexChange()
    //     };
    // }
    const onClick = () => {
        if (props.game.name == "Official") {
            navigate(Define.Page.Marketplace);
            return;
        }
        if (props.onIndexChange) {

            props.onIndexChange(Checked ? -1 : props.index)
        };

    }

    return (<label id="market-project-title"  >
        <input type="radio" id={`game-title-${props.index}`} name="game-title" value={props.index} checked={Checked} onClick={onClick} onChange={() => { }} />
        <div>
            <img id="market-project-title-icon" src={props.game?.gameIconUrl} />
            <label htmlFor={`game-title-${props.index}`}>{props.game?.title}</label>
        </div>

    </label>);
}

export default MarketProjectTitle;