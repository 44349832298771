import { useEffect, useState } from "react";
import { Define } from "../define/define";
import { INavigation } from "../event/navigation";
import "../assets/css/market.css"
import GameIconList from "../components/gameIconList";
import "../assets/css/newMarketCommon.css"
import GameIconDetail from "../components/gameIconDetail";
import { GameIconIntro } from "../prot/protocols/market/PtlGetRecentListed";
import { waitSeconds } from "../utils";

function Games(navigation: INavigation) {
    const { onShowWallet, onNavigation } = navigation;
    const [selectedGame, setSelectedGame] = useState(null);
    const [IconPosition, setIconPosition] = useState({ left: 0, top: 0 })
    useEffect(() => {
        onNavigation(Define.Page.Games);
    }, []);

    return (<section className="market-container-common" onClick={(e) => {
        const target = e.target as HTMLDivElement;
        if (target.className == "market-container-common") {
            setSelectedGame(null);
        }
    }}>
        <GameIconList onSelectGame={async (info: GameIconIntro, pos: { left: number; top: number; }) => {
            if (selectedGame) {
                setSelectedGame(null);
                await waitSeconds(0.01);
                setSelectedGame(info);
            }
            else
                setSelectedGame(info);
            setIconPosition(pos)
        }} />
        {selectedGame ?
            <GameIconDetail info={selectedGame} targetRect={IconPosition}></GameIconDetail>
            : <></>}
    </section>);
}

export default Games;