import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import '../assets/css/map.css';
import { closeMapDetail, createMap, setMap, showMapForSale, updateMapFilter } from '../map/map';
import marketImg from '../assets/images/map/marketlist.png';
import saleSwitchOn from '../assets/images/map/forsale_b.png';
import saleSwitchOff from '../assets/images/map/forsale.png';
import myLandImg from '../assets/images/map/mylands.png';
import LandList from '../components/landList';
import { INavigation } from "../event/navigation";
import { Define } from '../define/define';
import { defaultLandMarket, ILandDetail, ISelectedLand, LandMarketContext, SELECTED_LAND_DEFAULT } from '../contexts/LandMarketContext';
import { apiClient, Global, } from '../global/global';
import LandDetail from '../components/landDetail';
import axios from "axios";
import ManageLand from '../components/manageLand';
import { getParsedAccountByMint, getParsedNftAccountsByOwner } from '@nfteyez/sol-rayz';
import { GlobalConfig } from '../global/globalConfig';
import { Loading1 } from "../components/loading1";
import { OfficialItem } from '../prot/protocols/base';
import { ParsedAccountData, PublicKey } from '@solana/web3.js';
import { TradeState } from '../prot/protocols/auction/Commodity';
import { retryPromise, waitSeconds } from '../utils';


export const Map: FC<INavigation> = (navigation: INavigation) => {
    const { onShowWallet, onNavigation } = navigation;
    const [forSaleOn, SetForSaleOn] = useState(false);
    const [showCoin, setShowCoin] = useState(Define.Token.SOL);
    const [detailShow, setDetailShow] = useState(false);
    const [landMarketShow, setLandMarketShow] = useState(false);
    const [ownedListShow, setOwnedListShow] = useState(false);
    const [selectedLand, setSelectedLand] = useState(SELECTED_LAND_DEFAULT);
    const [mapList, setMapList] = useState([]);
    const [loadMarketList, setLoadMarketList] = useState(false);
    const [loadOwnedList, setLoadOwnedList] = useState(false);
    const [detailTag, setDetailTag] = useState(null);
    const [sellLands, setSellLands] = useState({});
    const [owned, setOwned] = useState([]);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [buyComplete, setBuyComplete] = useState(true);
    useEffect(() => {
        onNavigation(Define.Page.Map);
    }, []);

    useEffect(() => {
        createMap("map");
        load();
    }, []);

    const fetchOwnedLands = async () => {
        if (!Global.WalletConnected())
            return;
        console.log("GET NFTS");

        const nfts = await getParsedNftAccountsByOwner({ publicAddress: Global.WalletAdapter.publicKey.toBase58(), connection: Global.Connection });
        const list = nfts.filter(v => GlobalConfig.NFTMapDic.has(v.mint)).map(v => {
            return {
                ...GlobalConfig.NFTMapDic.get(v.mint),
                owner: Global.WalletAdapter.publicKey.toBase58(),
            }
        });
        // console.log("NFTS", list);
        setOwned(list);
    }

    const fetchSellLands = async () => {
        await retryPromise(async () => new Promise((resolve, reject) => {
            if (!Global.ConfigAsync["config_map"])
                reject("map not set");
            else
                resolve("map set");
        }), 1, 1000)
        const maplist: any[] = await Global.ConfigAsync["config_map"];
        let lands: {
            [key: string]: {
                config?: any,
                official?: OfficialItem,
                owner?: string,
            }
        } = {};

        const res = apiClient.callApi("auction/GetOfficialMarketplace", {
            chain: "SOL",
            category: "map",
            needNft: true,
        });
        const { items } = (await res).res;
        items.forEach(item => {
            let owner = undefined;
            if (item?.nft?.order.tradeState == TradeState.OnSale) {
                owner = item?.nft?.order.seller;
            }
            // if (item?.nft?.state == TradeState.CanSell) {
            //     owner = item.nft.commodity[item.nft.commodity.length - 1].buyer;
            // }
            lands[item.id] = {
                official: item,
                owner,
            };

        })
        // console.log(lands);

        for (const cfg of maplist) {
            if (lands[cfg.FieldID]) {
                if (lands[cfg.FieldID])
                    lands[cfg.FieldID].config = cfg;
                else
                    lands[cfg.FieldID] = { config: cfg };
            }
        }
        setSellLands(lands);
        updateMapFilter(lands);
        return { mapJson: maplist, sellLands: lands };
    }

    const load = async () => {
        console.log("LOAD MAP");
        await GlobalConfig.loadNftMapConfigs();
        setMapLoaded(false);
        // const planes = [
        //     axios.get("./city_tag_v1.json"),
        //     axios.get("./country_tag.json"),
        //     axios.get("./province_tag_v1.json")];
        const planes = Global.MapGeoURL.map(v => axios.get(v));

        const list = await Promise.all(planes);
        const { mapJson, sellLands } = await fetchSellLands();
        setMap({
            city_tag: list[0].data,
            country_tag: list[1].data,
            province_tag: list[2].data,
            mapJson,
            sellLands,
        }, OnSelected);
        fetchOwnedLands();
        setMapLoaded(true);
        console.log("发送刷新消息");
        window.dispatchEvent(new CustomEvent("show-landlist"));
    }

    const OnSelected = async (selected: ISelectedLand) => {
        if (!selected) {
            setDetailShow(false);
            setSelectedLand({ official: null, config: { FieldID: -1 }, owner: "" });
            return;
        }
        console.log(selected.owner);

        if (selected.official.nft && !selected.owner) {
            setBuyComplete(false)
            if (selected.official.nft.order.tradeState == TradeState.OnSale) {
                selected.owner = selected.official.nft.order.seller;
            }
            else {
                console.log(selected.official);
                try {
                    const accounts = await Global.Connection.getTokenLargestAccounts(new PublicKey(selected.official.nft.mintAddress));
                    const accountInfo = await Global.Connection.getParsedAccountInfo(accounts.value[0].address)
                    console.log(accounts.value[0].address.toBase58());

                    selected.owner = (accountInfo.value.data as ParsedAccountData).parsed.info.owner;
                } catch (error) {
                    console.error(error)
                    console.log("FIND OWNER FAILED");

                }

            }
            setBuyComplete(true)
        }
        setSelectedLand((preValue) => {
            if (!detailTag) {
                setDetailTag(<LandDetail onRefresh={load} setWalletShow={onShowWallet} setBuyComplete={setBuyComplete}></LandDetail>);
            }
            setDetailShow(isShow => {
                if (!selected)
                    return false;

                if (isShow && preValue.config.FieldID == selected.config.FieldID)
                    return false;
                else {
                    window.dispatchEvent(new CustomEvent("show-detail", { detail: selected }))
                    return true;
                }
            });

            return selected;
        });
    }

    useEffect(() => {
        if (!detailShow)
            closeMapDetail();
    }, [detailShow]);

    async function switchMarket() {
        if (!Global.OpenSell) {
            Global.AddMessage("Coming soon.", undefined, " ");
            return;
        }

        setLoadMarketList(true);
        setLandMarketShow(iShow => {
            const show = !iShow;
            if (show)
                window.dispatchEvent(new CustomEvent("show-landlist"))
            return show;
        });
        setOwnedListShow(false);
    }

    function switchForSale() {
        SetForSaleOn((prVal) => {
            showMapForSale(prVal);
            return !prVal;
        });
    }

    async function switchOwnedList() {
        if (!Global.OpenSell) {
            Global.AddMessage("Coming soon.", undefined, " ");
            return;
        }

        if (!Global.WalletConnected()) {
            onShowWallet("SOL");
            return;
        }
        fetchOwnedLands();
        setBuyComplete(false);
        setLoadOwnedList(true);
        setBuyComplete(true);
        setLandMarketShow(false);
        setOwnedListShow(!ownedListShow);
    }

    const mapButtons = (<div id='map-buttons' style={{ marginTop: 10 }}>
        <button className='trans-button' onClick={switchForSale}>
            <img src={forSaleOn ? saleSwitchOn : saleSwitchOff} />
        </button>
        <button className='trans-button' onClick={switchMarket}>
            <img src={marketImg} />
        </button>
        <button className='trans-button' onClick={switchOwnedList}>
            <img src={myLandImg} />
        </button>
    </div>);

    const mapLoading = (
        <div style={{ position: "absolute", width: 50, height: 50, left: "50vw", top: "40vh" }}>
            <section className="loading-map" />
        </div>);
    const buyLoading = (
        <div style={{ position: "absolute", width: "100%", height: "100%", left: 0, top: 0, zIndex: 800 }}>
            <section className="loading-map" />
        </div>
    )

    return (
        <section className="map-view">
            <div id="map" />

            <LandMarketContext.Provider value={{
                showCoin,
                setCoin: setShowCoin,
                selected: selectedLand,
                setSelected: setSelectedLand,
                detailShow,
                setDetailShow,
                landMarketShow,
                setLandMarketShow,
                sellLands,
                ownedListShow,
                setOwnedListShow,
                reloadMap: load,
            }}>
                {mapLoaded ? mapButtons : <></>}
                {loadMarketList ? <LandList refreshMap={load} setWalletShow={onShowWallet} setBuyComplete={setBuyComplete}></LandList> : <></>}
                {detailTag}
                {loadOwnedList ? <ManageLand landList={owned} refreshMap={fetchOwnedLands} setWalletShow={onShowWallet}></ManageLand> : <></>}
                {!mapLoaded ? mapLoading : <></>}
                {!buyComplete ? buyLoading : <></>}
            </LandMarketContext.Provider>

        </section>
    );
}
