import homeIcon from "../assets/images/icon/website.png";
import twitterIcon from "../assets/images/icon/twitter.png";
import discordIcon from "../assets/images/icon/discord.png";
import telegramIcon from "../assets/images/icon/telegram.png";
import "../assets/css/socialIcons.css"
import { Global } from "../global/global";

function SocialIcons(props: {
    direction: "ltr" | "rtl",
    home?: string,
    twitter?: string,
    discord?: string,
    telegram?: string,
}) {



    const onHomeClick = () => {
        Global.Open(props.home);
    }
    const onTwitterClick = () => {
        Global.Open(props.twitter);
    }
    const onDiscordClick = () => {
        Global.Open(props.discord);
    }
    const onTelegramClick = () => {
        Global.Open(props.telegram);
    }

    return (<div className="social-icon" style={{ justifyContent: props.direction == "ltr" ? "flex-start" : "flex-end" }}>
        {props.home ? <img src={homeIcon} alt="" onClick={onHomeClick} /> : <></>}
        {props.twitter ? <img src={twitterIcon} alt="" onClick={onTwitterClick} /> : <></>}
        {props.discord ? <img src={discordIcon} alt="" onClick={onDiscordClick} /> : <></>}
        {props.telegram ? <img src={telegramIcon} alt="" onClick={onTelegramClick} /> : <></>}
    </div>);
}

export default SocialIcons;