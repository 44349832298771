import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from "react";
import { Metadata } from '@metaplex-foundation/mpl-token-metadata';
import "../assets/scss/nftDetail.scss"
import "swiper/css";
import { apiClient, copyTextToClipboard, Global, LimitInputNumber, RefreshWebsite } from "../global/global";
import { Define } from "../define/define";
import { GlobalConfig } from "../global/globalConfig";
import { Loading } from "../components/loading";
import { NftContext } from "../pages/marketplace";
import { GlobalConfigContext } from "../contexts/GlobalConfigContext";
import { ISellNft } from "../contexts/sellNftContext";
import { INftItem } from "../contexts/nftItemContext";
import Buy1 from "../assets/images/detail/buy-1.png";
import Buy2 from "../assets/images/detail/buy-2.png";
import Sell1 from "../assets/images/detail/sell-1.png";
import Sell2 from "../assets/images/detail/sell-2.png";
import Line from "../assets/images/detail/line.png";
import cs from "classnames";
import { getParsedAccountByMint } from "@nfteyez/sol-rayz";
import BN from "bn.js"
import { Connection } from "@solana/web3.js";
import { OfficialItem } from "../prot/protocols/base";
import { Buy, BuyOfficialItem, CancelSell, Sell } from "../common/ServerAuction";
import { TradeState } from "../prot/protocols/auction/Commodity";
import { DbNFT } from "../prot/protocols/db/DbNFT";

interface IShowNftList {
    currentCategory: number;
    enterType: number;
    onShowNftList: Function;
    onShowWalletPanel: Function;
}

export const NftDetail: FC<IShowNftList> = (showNftList: IShowNftList) => {
    const nft = useContext(NftContext);
    const globalConfig = useContext(GlobalConfigContext);
    const { currentCategory, onShowWalletPanel, onShowNftList, enterType } = showNftList;
    const [official, setOfficial] = useState(null as OfficialItem);
    const [currentIndexs, setCurrentIndexs] = useState([4, 3, 2, 1, 0]);
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [originantionPrice, setOriginantionPrice] = useState("0");
    const atlas = new Array<string>();
    const [currentAtlas, setCurrentAtlas] = useState(new Array<string>());
    const [isInitialNfts, setIsInitialNfts] = useState(false);
    // const [availableSupply, setAvailableSupply] = useState(0);
    const [buyType, setBuyType] = useState(Define.Buy.Buy);
    const [price, setPrice] = useState("");
    // const [officalSellNfts, setOfficalSellNfts] = useState(new Array<ISellNft>());
    const [sellNfts, setSellNfts] = useState(new Array<ISellNft>());
    const [ownNfts, setOwnNfts] = useState(new Array<INftItem>());
    const [ownSelectedIndex, setOwnSelectedIndex] = useState(0);
    const [ownSellNfts, setOwnSellNfts] = useState(new Array<ISellNft>());
    const [selectNftMintAddress, setSelectNftMintAddress] = useState(null as DbNFT);
    const [sellSelectedIndex, setSellSelectedIndex] = useState(0);
    const [isWait, setIsWait] = useState(false);
    const [isLoadOfficalSellNfts, setIsLoadOfficalSellNfts] = useState(false);
    const [isLoadSellNfts, setIsLoadSellNfts] = useState(false);
    const [isLoadOwnNfts, setIsLoadOwnNfts] = useState(false);
    const [isLoadOwnSellNfts, setIsLoadOwnSellNfts] = useState(false);
    const [currentToken, setCurrentToken] = useState(Define.TokenType[1]);
    const [currentBuyToken, setCurrentBuyToken] = useState(Define.TokenType[1]);
    const [currentSellToken, setCurrentSellToken] = useState(Define.TokenType[1]);
    let tempIndex = 0;

    const getValidSells = async (connection: Connection) => {
        return (await apiClient.callApi("auction/GetSells", {
            filter: {
                project: "Official",
                id: nft.id.toString(),
                category: "item",
                chain: "SOL",
                spl: "SOL",
            },
            mustSell: true,
            needHistory: false,
        })).res.list;
        // let tempBuyToken = Define.Token.USDC;
        // switch (currentBuyToken) {
        //     case Define.TokenType[0]:
        //         tempBuyToken = Define.Token.USDC;
        //         break;
        //     case Define.TokenType[1]:
        //         tempBuyToken = Define.Token.SOL;
        //         break;
        //     case Define.TokenType[2]:
        //         tempBuyToken = Define.Token.SYP;
        //         break;
        //     case Define.TokenType[3]:
        //         tempBuyToken = Define.Token.MBTT;
        //         break;
        //     case Define.TokenType[4]:
        //         tempBuyToken = Define.Token.MITCOIN;
        //         break;
        // }

        // console.log("tempBuyToken");
        // console.log(tempBuyToken);
        // // 卖单
        // // let allSells = (await GetSells(connection, tempBuyToken)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // // 已成交的订单
        // let tempAllPurchases = await Promise.all([
        //     // GetPurchases(connection, Define.Token.USDC),
        //     // GetPurchases(connection, Define.Token.SOL),
        //     // GetPurchases(connection, Define.Token.SYP),
        //     // GetPurchases(connection, Define.Token.MBTT),
        //     // GetPurchases(connection, Define.Token.MITCOIN)
        // ]);
        // // 将已成交的订单转换为一维数组
        // let allPurchases = tempAllPurchases.flat();


        // // 获得有效订单
        // // allSells = allSells.filter(v => {
        // //     let purch = allPurchases.find(m =>
        // //         v.tokenType == m.tokenType &&
        // //         (m.listing.createdAt as BN).gt(v.listing.createdAt as BN) &&
        // //         m.listing.seller.toBase58() == v.listing.seller.toBase58() &&
        // //         m.listing.metadata.toBase58() == v.listing.metadata.toBase58());

        // //     console.log(purch, v.listing.seller.toBase58());

        // //     return purch == undefined && v.listing.seller.toBase58() != Define.NFTAddress;
        // // });

        // // return allSells;
        // return [];
    }

    const getAllValidSells = async (connection: Connection) => {
        return (await apiClient.callApi("auction/GetSells", {
            filter: {
                project: "Official",
                id: nft.id.toString(),
                category: "item",
                chain: "SOL",
                spl: "SOL",
            },
            mustSell: true,
            needHistory: false,
        })).res.list;
        // let usdcSells = (await GetSells(connection, Define.Token.USDC)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // let solSells = (await GetSells(connection, Define.Token.SOL)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // let sypSells = (await GetSells(connection, Define.Token.SYP)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // let mbttSells = (await GetSells(connection, Define.Token.MBTT)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);
        // let mitcoinSells = (await GetSells(connection, Define.Token.MITCOIN)).filter(v => v.listing.canceledAt == null && v.listing.purchaseReceipt == null);

        let allSells = [
            // ...usdcSells,
            // ...solSells,
            // ...sypSells
        ];

        // 已成交的订单
        // let tempAllPurchases = await Promise.all([
        //     GetPurchases(connection, Define.Token.USDC),
        //     GetPurchases(connection, Define.Token.SOL),
        //     GetPurchases(connection, Define.Token.SYP),
        //     GetPurchases(connection, Define.Token.MBTT),
        //     GetPurchases(connection, Define.Token.MITCOIN)
        // ]);

        // // 将已成交的订单转换为一维数组
        // let allPurchases = [...tempAllPurchases[0], ...tempAllPurchases[1], ...tempAllPurchases[2], ...tempAllPurchases[3], ...tempAllPurchases[4]];
        // // 获得有效订单
        // allSells = allSells.filter(v => {
        //     let purch = allPurchases.find(m =>
        //         v.tokenType == m.tokenType &&
        //         (m.listing.createdAt as BN).gte(v.listing.createdAt as BN) &&
        //         m.listing.seller.toString() == v.listing.seller.toString() &&
        //         m.listing.metadata.toString() == v.listing.metadata.toString());
        //     return purch == undefined && v.listing.seller.toBase58() != Define.NFTAddress;
        // });

        // return allSells;
        return [];
    }

    const getOfficalSells = async () => {
        let tempToken = Define.Token.SOL;
        // switch (currentToken) {
        //     case Define.TokenType[0]:
        //         tempToken = Define.Token.USDC;
        //         break;
        //     case Define.TokenType[1]:
        //         tempToken = Define.Token.SOL;
        //         break;
        //     case Define.TokenType[2]:
        //         tempToken = Define.Token.SYP;
        //         break;
        //     case Define.TokenType[3]:
        //         tempToken = Define.Token.MBTT;
        //         break;
        //     case Define.TokenType[4]:
        //         tempToken = Define.Token.MITCOIN;
        //         break;
        // }

        setIsLoadOfficalSellNfts(false);
        const result = await apiClient.callApi("auction/GetOfficialMarketplace", {
            chain: "SOL",
            category: "item",
            needNft: true,
            id: nft.id.toString(),
        });
        if (!result.isSucc) {
            RefreshWebsite();
            return;
        }
        setOfficial(result.res.items[0]);

        // let validSells = await getOfficalValidSells(Global.Connection);
        // let tempSellNfts = new Array<ISellNft>();
        // let index = 1;
        // for (const sell of validSells) {
        //     // let nftInfo = await Metadata.load(Global.Connection, sell.listing.metadata);
        //     // let strs = nftInfo.data.data.uri.split("/");
        //     // let json = strs[strs.length - 1];
        //     // let jsonStrs = json.split(".");
        //     // let nftId = Number(jsonStrs[0]);
        //     // if (nftId === nft.id) {
        //     //     let sellNft: ISellNft = {
        //     //         id: index,
        //     //         nftConfig: nft,
        //     //         mapConfig: null,
        //     //         owner_address: sell.listing.seller.toBase58(),
        //     //         price: Number(sell.listing.price) / Global.AdminConfig.Decimals[tempToken],
        //     //         token: tempToken,
        //     //         address: sell.address.toBase58(),
        //     //         sellerReceipt: sell.listing
        //     //     }
        //     //     tempSellNfts.push(sellNft);
        //     //     ++index;
        //     // }
        // }

        setIsLoadOfficalSellNfts(true);
        // setOfficalSellNfts(tempSellNfts);
    }

    useEffect(() => {
        console.log("currentBuyToken");
        console.log(currentBuyToken);
        getSellNfts();
    }, [currentBuyToken]);

    const getSellNfts = async () => {
        let tempBuyToken = Define.Token.SOL;
        switch (currentBuyToken) {
            case Define.TokenType[0]:
                tempBuyToken = Define.Token.USDC;
                break;
            case Define.TokenType[1]:
                tempBuyToken = Define.Token.SOL;
                break;
            case Define.TokenType[2]:
                tempBuyToken = Define.Token.SYP;
                break;
            case Define.TokenType[3]:
                tempBuyToken = Define.Token.MBTT;
                break;
            case Define.TokenType[4]:
                tempBuyToken = Define.Token.MITCOIN;
                break;
        }

        setIsLoadSellNfts(false);
        let tempSellNfts = await getValidSells(Global.Connection);

        // let tempSellNfts = new Array<ISellNft>();
        // let index = 1;
        // console.log(validSells);

        // for (const sell of validSells) {
        //     // let nftInfo = await Metadata.load(Global.Connection, sell.listing.metadata);
        //     // let strs = nftInfo.data.data.uri.split("/");
        //     // let json = strs[strs.length - 1];
        //     // let jsonStrs = json.split(".");
        //     // let nftId = Number(jsonStrs[0]);
        //     // if (nftId === nft.id) {
        //     //     console.log(nftId);

        //     //     const nft = await getParsedAccountByMint({
        //     //         mintAddress: nftInfo.data.mint,
        //     //         connection: Global.Connection
        //     //     });
        //     //     const owner = nft.account.data.parsed.info.owner;

        //     //     if (owner != sell.listing.seller.toBase58()) {
        //     //         console.log("invalid sell");
        //     //         continue;
        //     //     }
        //     //     console.log(owner, Global.WalletAdapter);

        //     //     if (Global.WalletConnected() && owner == Global.WalletAdapter.publicKey.toBase58())
        //     //         continue;
        //     //     let sellNft: ISellNft = {
        //     //         id: index,
        //     //         nftConfig: nft,
        //     //         mapConfig: null,
        //     //         owner_address: owner,//sell.listing.seller.toBase58(),
        //     //         price: Number(sell.listing.price) / Global.AdminConfig.Decimals[tempBuyToken],
        //     //         token: tempBuyToken,
        //     //         address: sell.address.toBase58(),
        //     //         sellerReceipt: sell.listing
        //     //     }
        //     //     tempSellNfts.push(sellNft);
        //     //     ++index;
        //     // }
        // }
        tempSellNfts = tempSellNfts.filter(v => {
            
            if (v.name != nft.name)
                return false;
            if (!Global.WalletConnected())
                return true;
            return v.order.seller != Global.WalletAdapter.publicKey.toBase58()
        })
        console.log(nft,tempSellNfts);

        if (tempSellNfts.length < 0) {
            setOwnSelectedIndex(Define.INVALID);
        }

        setIsLoadSellNfts(true);
        setSellNfts(tempSellNfts.map((v, index) => {
            return {
                id: index,
                nftConfig: nft,
                mapConfig: null,
                owner_address: v.order.seller,//sell.listing.seller.toBase58(),
                price: Number(v.order.price), // Global.AdminConfig.Decimals[tempBuyToken],
                token: tempBuyToken,
                address: v.mintAddress,
                sellerReceipt: v
            }
        }));
    }

    const getOwnNfts = async () => {
        setIsLoadOwnNfts(false);

        const validSells = (await getAllValidSells(Global.Connection));
        // console.log("sells");
        // console.log(sells);
        // let validSells = sells.filter(v => v.listing.bookkeeper.toBase58() == Global.WalletAdapter.publicKey.toBase58());
        // console.log("validSells");
        // console.log(validSells);
        let tempOwnNfts = new Array<INftItem>();
        console.log(Global.OwnNfts);

        for (let i = 0; i < Global.OwnNfts.length; ++i) {
            let ownNft = Global.OwnNfts[i];
            let isHaveParchase: boolean = false;
            // for (const sell of validSells) {
            //     // let nftInfo = await Metadata.load(Global.Connection, sell.listing.metadata);
            //     let strs = sell.uri.split("/");
            //     let json = strs[strs.length - 1];
            //     let jsonStrs = json.split(".");
            //     let nftId = Number(jsonStrs[0]);
            //     let mintAddress = sell.mintAddress;
            //     if (ownNft.id === nftId && ownNft.mint_address === mintAddress) {
            //         isHaveParchase = true;
            //         break;
            //     }
            // }

            if (!isHaveParchase && ownNft.id === nft.id) {
                let tempNft: INftItem = {
                    id: ownNft.id,
                    config: ownNft.config,
                    mint_address: ownNft.mint_address,
                    item: ownNft.item
                }
                tempOwnNfts.push(tempNft);
            }
        }

        if (tempOwnNfts.length > 0) {
            setSelectNftMintAddress(tempOwnNfts[0].item);
        }

        setIsLoadOwnNfts(true);
        console.log("OwnNfts");
        console.log(tempOwnNfts);
        setOwnNfts(tempOwnNfts);
    }

    const getOwnSellNfts = async () => {
        setIsLoadOwnSellNfts(false);
        let validSells = await getAllValidSells(Global.Connection);
        let tempSellNfts = new Array<ISellNft>();
        let index = 1;
        for (const sell of validSells) {
            // let nftInfo = await Metadata.load(Global.Connection, sell.listing.metadata);
            const commodity = sell.order;
            let strs = sell.uri.split("/");
            let json = strs[strs.length - 1];
            let jsonStrs = json.split(".");
            let nftId = Number(jsonStrs[0]);
            let sellerPublickey = commodity.seller;
            if (nftId === nft.id && sellerPublickey === Global.WalletAdapter.publicKey.toString()) {
                let sellNft: ISellNft = {
                    id: index,
                    nftConfig: nft,
                    mapConfig: null,
                    owner_address: commodity.seller,
                    price: Number(commodity.price), // Global.AdminConfig.Decimals[sell.tokenType],
                    token: Define.Token.SOL,
                    address: sell.mintAddress,
                    sellerReceipt: sell,
                }
                tempSellNfts.push(sellNft);
                ++index;
            }
        }

        setIsLoadOwnSellNfts(true);
        console.log("SellNfts");
        console.log(tempSellNfts);
        setOwnSellNfts(tempSellNfts);
    }

    useEffect(() => {
        let nftConfig = GlobalConfig.getNftConfig(nft.id);
        for (let i = 1; i <= 5; ++i) {
            let png = require("../assets/images/atlases/" + nftConfig.atlas + "/" + i + ".png");
            atlas.push(png);
        }
        setCurrentAtlas(atlas);

        let index = 0;
        let lastIndex = 0;
        let tempIndexs = currentIndexs;
        let tempAtlas = [];
        for (let i = currentIndex; i < atlas.length; ++i) {
            lastIndex = atlas.length - 1 - index;
            tempIndexs[i] = lastIndex;
            tempAtlas.push(atlas[i]);
            ++index;
        }

        for (let i = 0; i < atlas.length - index; ++i) {
            tempIndexs[i] = lastIndex - 1 - i;
            tempAtlas.push(atlas[i]);
        }

        setCurrentIndexs(tempIndexs);
        setCurrentAtlas(tempAtlas);
    }, [currentIndex]);

    // useEffect(() => {
    //     setAvailableSupply(Global.AllNfts.hasOwnProperty(nft.id) ? Global.AllNfts[nft.id].length : 0);
    // }, []);

    useEffect(() => {
        setIsInitialNfts(currentCategory === Define.Category.InitialNFTs);
        if (currentCategory === Define.Category.InitialNFTs) {
            getOfficalSells();
        } else {
            getSellNfts();
        }
    }, [currentToken]);

    const onBackClick = useCallback(() => {
        // setAvailableSupply(0);
        onShowNftList();
    }, []);

    const onBuyClick = async () => {
        console.log(official);

        if (isWait) {
            return;
        }

        if (!isLoadOfficalSellNfts || !official) {
            return;
        }

        if (!Global.WalletConnected()) {
            onShowWalletPanel(true);
        } else {

            if (official.supply[0] > 0) {
                let price = 0;
                let isBuy = false;
                let tempToken = Define.Token.USDC;
                switch (currentToken) {
                    case Define.TokenType[0]:
                        price = Global.getExchangedCoinPrice(nft.price, Define.Token.USDC);
                        tempToken = Define.Token.USDC;
                        isBuy = true;
                        break;
                    case Define.TokenType[1]:
                        price = Global.getExchangedCoinPrice(nft.price, Define.Token.SOL);
                        tempToken = Define.Token.SOL;
                        isBuy = true;
                        break;
                    case Define.TokenType[2]:
                        price = Global.getExchangedCoinPrice(nft.price, Define.Token.SYP);
                        tempToken = Define.Token.SYP;
                        isBuy = true;
                        break;
                    case Define.TokenType[3]:
                        // price = Global.getExchangedCoinPrice(nft.price , Define.Token.MBTT);
                        tempToken = Define.Token.MBTT;
                        break;
                    case Define.TokenType[4]:
                        // price = Global.getExchangedCoinPrice(nft.price, Define.Token.MITCOIN);
                        tempToken = Define.Token.MITCOIN;
                        break;
                }

                if (isBuy) {
                    if (official) {
                        setIsWait(true);
                        // let random = Global.getRandom(0, officalSellNfts.length - 1);
                        // let officalSellNft = officalSellNfts[random];
                        console.log("buy official");

                        let isSuccess = await BuyOfficialItem(official);//await BuyNFT(Global.Connection, Global.WalletAdapter, officalSellNft.address, officalSellNft.sellerReceipt, tempToken);
                        setIsWait(false);
                        if (isSuccess) {
                            await getOfficalSells();
                            await Global.getTokens();
                            await getOwnNfts();
                            await getOwnSellNfts();
                        }
                    }
                }
            }
        }
    }

    const onLeftPageClick = useCallback(() => {
        --tempIndex;
        if (tempIndex < 0) {
            tempIndex = atlas.length - 1;
        }

        setCurrentIndex(tempIndex);
    }, []);

    const onRightPageClick = useCallback(() => {
        ++tempIndex;
        if (tempIndex >= atlas.length) {
            tempIndex = 0;
        }

        setCurrentIndex(tempIndex);
    }, []);

    const onSelectChange = useCallback((event) => {
        setCurrentToken(event.target.value);
    }, []);

    const onBuyTokenChange = useCallback((event) => {
        setSellNfts(new Array<ISellNft>());
        setCurrentBuyToken(event.target.value);
    }, []);

    const onSellTokenChange = useCallback((event) => {
        setCurrentSellToken(event.target.value);
    }, []);

    const onBuyOtherPlayerNftClick = async () => {
        if (isWait) {
            return;
        }

        if (!isLoadSellNfts || sellNfts.length <= 0) {
            return;
        }

        setIsWait(true);

        let tempBuyToken = Define.Token.USDC;
        switch (currentBuyToken) {
            case Define.TokenType[0]:
                tempBuyToken = Define.Token.USDC;
                break;
            case Define.TokenType[1]:
                tempBuyToken = Define.Token.SOL;
                break;
            case Define.TokenType[2]:
                tempBuyToken = Define.Token.SYP;
                break;
            case Define.TokenType[3]:
                tempBuyToken = Define.Token.MBTT;
                break;
            case Define.TokenType[4]:
                tempBuyToken = Define.Token.MITCOIN;
                break;
        }

        let sellNft = sellNfts[sellSelectedIndex];
        console.log(sellNft);

        let isSuccess = await Buy(sellNft.sellerReceipt);//await BuyNFT(Global.Connection, Global.WalletAdapter, sellNft.address, sellNft.sellerReceipt, tempBuyToken);
        if (isSuccess) {
            await Global.getTokens();
            await getOwnSellNfts();
            await getOwnNfts();
            await getSellNfts();
        }
        setIsWait(false);
    }

    const onPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
        // let length = event.target.value.split(".").length - 1;

        // if (length > 1) {
        //     return;
        // }

        // let priceArray = event.target.value.split(".");
        // if (priceArray.length >= 2) {
        //     if (priceArray[1].length > 18) {
        //         return;
        //     }
        // }

        // let value = event.target.value.replace(/\+|-/ig, "");
        // value = value.replace("-", "");
        // value = value.replace("+", "");
        // value = value.replace("e", "");
        // value = value.replace("E", "");
        // value = value.replace(",", "");

        // if (!(/[^0]/gi.test(value))) {
        //     value = "0";
        // }

        // let priceNumber = Number(value);
        // if (!isNaN(priceNumber)) {
        //     if (priceNumber >= 1) {
        //         value = value.replace(/^[0]+/, "");
        //     }
        // };
        const value = LimitInputNumber(event);
        if (!isNaN(value) && value > 0)
            setPrice(value.toString());

    }

    const onCancelClick = async (sell: ISellNft) => {
        if (isWait) {
            return;
        }

        setIsWait(true);
        let isSuccess = await CancelSell(sell.sellerReceipt);//await CancelSell(Global.Connection, Global.WalletAdapter, sell.address, sell.sellerReceipt, sell.token);
        setIsWait(false);
        if (isSuccess) {
            await Global.getTokens();
            await getOwnSellNfts();
            await getOwnNfts();
        }
    }

    // useEffect(() => {
    //     // let tempPrice = 0;
    //     // let priceString = official.price;
    //     console.log(nft)
    //     // switch (currentToken) {
    //     //     case Define.TokenType[0]:
    //     //         tempPrice = Global.getExchangedCoinPrice(nft.price * Global.AdminConfig.Decimals[Define.Token.USDC], Define.Token.USDC);
    //     //         if (globalConfig.ShowCoin[Define.Token.USDC]) {
    //     //             priceString = Global.formatNumber(tempPrice);
    //     //         } else {
    //     //             priceString = "--";
    //     //         }
    //     //         break;
    //     //     case Define.TokenType[1]:
    //     //         tempPrice = Global.getExchangedCoinPrice(nft.price * Global.AdminConfig.Decimals[Define.Token.USDC], Define.Token.SOL);
    //     //         if (globalConfig.ShowCoin[Define.Token.SOL]) {
    //     //             priceString = Global.formatNumber(tempPrice);
    //     //         } else {
    //     //             priceString = "--";
    //     //         }
    //     //         break;
    //     //     case Define.TokenType[2]:
    //     //         tempPrice = Global.getExchangedCoinPrice(nft.price * Global.AdminConfig.Decimals[Define.Token.USDC], Define.Token.SYP);
    //     //         if (globalConfig.ShowCoin[Define.Token.SYP]) {
    //     //             priceString = Global.formatNumber(tempPrice);
    //     //         } else {
    //     //             priceString = "--";
    //     //         }
    //     //         break;
    //     //     case Define.TokenType[3]:
    //     //         tempPrice = Global.getExchangedCoinPrice(nft.price * Global.AdminConfig.Decimals[Define.Token.USDC], Define.Token.MBTT);
    //     //         if (globalConfig.ShowCoin[Define.Token.MBTT]) {
    //     //             priceString = Global.formatNumber(tempPrice);
    //     //         } else {
    //     //             priceString = "--";
    //     //         }
    //     //         break;
    //     //     case Define.TokenType[4]:
    //     //         tempPrice = Global.getExchangedCoinPrice(nft.price * Global.AdminConfig.Decimals[Define.Token.USDC], Define.Token.MITCOIN);
    //     //         if (globalConfig.ShowCoin[Define.Token.MITCOIN]) {
    //     //             priceString = Global.formatNumber(tempPrice);
    //     //         } else {
    //     //             priceString = "--";
    //     //         }
    //     //         break;
    //     // }
    //     if (official)
    //         setOriginantionPrice(official.price);
    //     else
    //         setOriginantionPrice("--");
    // }, [currentToken]);

    const onSelectSellNftClick = (sell: ISellNft, index: number) => {
        setSellSelectedIndex(index);
    }

    const onBuyIconClick = async () => {
        setSellNfts(new Array<ISellNft>());
        setBuyType(Define.Buy.Buy);
        await getSellNfts();
    }

    const onSellIconClick = async () => {
        if (Global.WalletAdapter === null) {
            onShowWalletPanel(true);
        } else {
            setBuyType(Define.Buy.Sell);
            await getOwnSellNfts();
            await getOwnNfts();
        }
    }

    const onOwnNftClick = (nft: INftItem, index: number) => {
        setSelectNftMintAddress(nft.item);
        setOwnSelectedIndex(index);
    };

    const onSellClick = async () => {
        if (isWait) {
            return;
        }

        if (!isLoadOwnNfts || ownNfts.length <= 0) {
            return;
        }
        console.log(price);

        if (price == "") {
            Global.AddMessage("Please enter the correct price!", undefined, " ");
        } else {
            let p = Number(price);
            if (p <= 0 || isNaN(p)) {
                Global.AddMessage("Please enter the correct price!", undefined, " ");
            } else {
                setIsWait(true);
                if (selectNftMintAddress) {
                    let tempSellToken = Define.Token.USDC;
                    switch (currentSellToken) {
                        case Define.TokenType[0]:
                            tempSellToken = Define.Token.USDC;
                            break;
                        case Define.TokenType[1]:
                            tempSellToken = Define.Token.SOL;
                            break;
                        case Define.TokenType[2]:
                            tempSellToken = Define.Token.SYP;
                            break;
                        case Define.TokenType[3]:
                            tempSellToken = Define.Token.MBTT;
                            break;
                        case Define.TokenType[4]:
                            tempSellToken = Define.Token.MITCOIN;
                            break;
                    }

                    let isSuccess = await Sell(selectNftMintAddress, price); //await SellNFT(Global.Connection, Global.WalletAdapter, selectNftMintAddress, Number(price), tempSellToken);
                    setIsWait(false);
                    if (isSuccess) {
                        await Global.getTokens();
                        await getOwnSellNfts();
                        await getOwnNfts();
                    }
                }
            }
        }
    }

    return (
        <section>
            {(() => {
                if (isInitialNfts) {
                    if (!isLoadOfficalSellNfts) {
                        return <Loading />
                    }
                } else {
                    switch (buyType) {
                        case Define.Buy.Buy:
                            if (!isLoadSellNfts) {
                                return <Loading />
                            }
                            break;
                        case Define.Buy.Sell:
                            if (!isLoadOwnNfts || !isLoadOwnSellNfts) {
                                return <Loading />
                            }
                            break;
                    }
                }
            })()}
            <section className="nft-detail">
                <section className={cs({
                    "detail": enterType === Define.Enter.Marketplace,
                    "detail1": enterType === Define.Enter.Profile
                })}>
                    <section className="left">
                        <section className="return" onClick={onBackClick} />
                        {(() => {
                            if (isInitialNfts) {
                                return (
                                    <section>
                                        <section className="swiper-body">
                                            {
                                                currentAtlas.map((atlas, index) => {
                                                    return (
                                                        <section className={"swiper-slide" + index} key={index}>
                                                            <img src={atlas} alt="-_-" draggable={false} />
                                                        </section>
                                                    );
                                                })
                                            }
                                        </section>
                                        <section className="page-btn">
                                            <section className="left-page-btn" onClick={onLeftPageClick} />
                                            <section className="right-page-btn" onClick={onRightPageClick} />
                                        </section>
                                        <section className="descriptions">
                                            <section className="name">{nft.name}</section>
                                            <img className="rarity"
                                                src={require("../assets/images/rarity/" + Define.RarityType[nft.rarity].toLowerCase() + ".png")}
                                                alt="-_-"
                                                draggable={false} />
                                            <section className="description">{nft.description}</section>
                                        </section>
                                    </section>
                                );
                            } else {
                                switch (buyType) {
                                    case Define.Buy.Buy:
                                        return (
                                            <section>
                                                <img src={currentAtlas.length > 0 ? currentAtlas[0] : null}
                                                    className="buy-icon"
                                                    alt="-_-"
                                                    draggable={false} />
                                                <section className="descriptions">
                                                    <section className="name">{nft.name}</section>
                                                    <img className="rarity"
                                                        src={require("../assets/images/rarity/" + Define.RarityType[nft.rarity].toLowerCase() + ".png")}
                                                        alt="-_-"
                                                        draggable={false} />
                                                    <section className="description">{nft.description}</section>
                                                </section>
                                            </section>
                                        );
                                        break;
                                    case Define.Buy.Sell:
                                        return (
                                            <section>
                                                <img src={currentAtlas.length > 0 ? currentAtlas[0] : null}
                                                    className="sell-icon" alt="-_-"
                                                    draggable={false} />
                                                <section className="sell-descriptions">
                                                    <section className="sell-name">{nft.name}</section>
                                                    <img className="sell-rarity"
                                                        src={require("../assets/images/rarity/" + Define.RarityType[nft.rarity].toLowerCase() + ".png")}
                                                        alt="-_-"
                                                        draggable={false} />
                                                </section>
                                                <section className="wallet-owner">
                                                    <section className="wallet-owner-name">WALLET OWNER</section>
                                                    {/* <p className="wallet-description"></p> */}
                                                    <section className="wallet-owner-region">
                                                        {
                                                            ownNfts.map((nft: INftItem, index: number) => {
                                                                return (
                                                                    <section className="owner" key={index}
                                                                        onClick={() => onOwnNftClick(nft, index)}>
                                                                        {
                                                                            ownSelectedIndex == index ?
                                                                                <section className="background" />
                                                                                :
                                                                                null
                                                                        }
                                                                        <img className="image"
                                                                            src={require("../assets/images/icon/" + nft.config.image)}
                                                                            alt="-_-"
                                                                            draggable={false} />
                                                                        <section className="name">
                                                                            <p>{nft.config.name}</p>
                                                                        </section>
                                                                        <section className="id">
                                                                            <p>{"ID:" + nft.id}</p>
                                                                        </section>
                                                                        {
                                                                            ownNfts.length <= 4 || ownNfts.length > 4 && index < ownNfts.length - 1
                                                                                ?
                                                                                <img className="line" src={Line}
                                                                                    alt="-_-"
                                                                                    draggable={false} />
                                                                                :
                                                                                null
                                                                        }
                                                                    </section>
                                                                );
                                                            })
                                                        }
                                                    </section>
                                                </section>
                                            </section>
                                        );
                                        break;
                                }
                            }
                        })()}
                    </section>
                    {
                        isInitialNfts
                            ?
                            <section className="right-top">
                                {(() => {
                                    switch (nft.category) {
                                        case Define.Category.Architectures:
                                            return <section>
                                                <section className="architecture-top-line" />
                                                <section className="architecture-middle-line" />
                                                <section className="architecture-third-line" />
                                                <section className="architecture-detail">
                                                    <p>Detail</p>
                                                </section>
                                                <section className="architecture-category">
                                                    <h1>Category</h1>
                                                    <p>{Define.DetailCategoryType[nft.category - 3]}</p>
                                                </section>
                                                <section className="architecture-rarity">
                                                    <h1>Rarity</h1>
                                                    <p>{Define.RarityType[nft.rarity]}</p>
                                                </section>
                                                <section className="architecture-level">
                                                    <h1>Level</h1>
                                                    <p>{nft.level}</p>
                                                </section>
                                                <section className="architecture-increase">
                                                    <h1>Increase</h1>
                                                    <p>{"+" + Global.formatNumber(nft.increase / Define.IncreaseCoefficient * 100) + "%"}</p>
                                                </section>
                                                <section className="architecture-length">
                                                    <h1>Length</h1>
                                                    <p>{nft.length}</p>
                                                </section>
                                                <section className="architecture-width">
                                                    <h1>Width</h1>
                                                    <p>{nft.width}</p>
                                                </section>
                                                <section className="architecture-height">
                                                    <h1>Height</h1>
                                                    <p>{nft.height}</p>
                                                </section>
                                                <section className="architecture-deploy">
                                                    <p>Deploy</p>
                                                </section>
                                                <section className="architecture-human-commander">
                                                    <h1>Human Commander</h1>
                                                    <p>{nft.human_commander}</p>
                                                </section>
                                                <section className="architecture-working-robot">
                                                    <h1>Working Robot</h1>
                                                    <p>{nft.working_robot}</p>
                                                </section>
                                                <section className="architecture-consume-output">
                                                    <p>Consume Output</p>
                                                </section>
                                                <section className="architecture-energy-consumption">
                                                    <h1>Energy Consumption</h1>
                                                    {(() => {
                                                        if (nft.energy_consumption != null) {
                                                            let energyArray = typeof nft.energy_consumption == "number" ? [nft.energy_consumption] : nft.energy_consumption.split(",");
                                                            let energyStr = "";
                                                            for (let i = 0; i < energyArray.length; ++i) {
                                                                let energy = energyArray[i];
                                                                let id = Number(energy);
                                                                let typeConfig = GlobalConfig.getTypeConfig(id);
                                                                if (typeConfig != null) {
                                                                    if (i == energyArray.length - 1) {
                                                                        energyStr += typeConfig.name;
                                                                    } else {
                                                                        energyStr += typeConfig.name + ",";
                                                                    }
                                                                }
                                                            }
                                                            return <p>{energyStr}</p>
                                                        } else {
                                                            return <p>--</p>
                                                        }
                                                    })()}
                                                </section>
                                                <section className="architecture-materials-consumption">
                                                    <h1>Materials Consumption</h1>
                                                    {(() => {
                                                        if (nft.materials_consumption != null) {

                                                            let materialsArray = typeof nft.materials_consumption == "number" ? [nft.materials_consumption] : nft.materials_consumption.split(",");
                                                            let materialsStr = "";
                                                            for (let i = 0; i < materialsArray.length; ++i) {
                                                                let materials = materialsArray[i];
                                                                let id = Number(materials);
                                                                let typeConfig = GlobalConfig.getTypeConfig(id);
                                                                if (typeConfig != null) {
                                                                    materialsStr += typeConfig.name + "\r\n";
                                                                }
                                                            }
                                                            return <p>{materialsStr}</p>
                                                        } else {
                                                            return <p>--</p>
                                                        }
                                                    })()}
                                                </section>
                                                <section className="architecture-output">
                                                    <h1>Output</h1>
                                                    {(() => {
                                                        if (nft.output != null) {
                                                            let outputArray = typeof nft.output == "number" ? [nft.output] : nft.output.split(",");
                                                            let outputStr = "";
                                                            for (let i = 0; i < outputArray.length; ++i) {
                                                                let output = outputArray[i];
                                                                let id = Number(output);
                                                                let typeConfig = GlobalConfig.getTypeConfig(id);
                                                                if (typeConfig != null) {
                                                                    outputStr += typeConfig.name + "\r\n";
                                                                }
                                                            }
                                                            return <p>{outputStr}</p>
                                                        } else {
                                                            return <p>--</p>
                                                        }
                                                    })()}
                                                </section>
                                            </section>
                                        case Define.Category.Robots:
                                            return <section>
                                                <section className="robot-top-line" />
                                                <section className="robot-middle-line" />
                                                <section className="robot-third-line" />
                                                <section className="robot-detail">
                                                    <p>Detail</p>
                                                </section>
                                                <section className="robot-category">
                                                    <h1>Category</h1>
                                                    <p>{Define.DetailCategoryType[nft.category - 3]}</p>
                                                </section>
                                                <section className="robot-rarity">
                                                    <h1>Rarity</h1>
                                                    <p>{Define.RarityType[nft.rarity]}</p>
                                                </section>
                                                <section className="robot-increase">
                                                    <h1>Increase</h1>
                                                    <p>{"+" + Global.formatNumber(nft.increase / Define.IncreaseCoefficient * 100) + "%"}</p>
                                                </section>
                                                <section className="robot-deploy">
                                                    <p>Deploy</p>
                                                </section>
                                                <section className="robot-power-supply">
                                                    <h1>Power Supply</h1>
                                                    <p>{nft.power_supply}</p>
                                                </section>
                                                <section className="robot-impulse-engine">
                                                    <h1>Impulse Engine</h1>
                                                    <p>{nft.impulse_engine}</p>
                                                </section>
                                                <section className="robot-qpu">
                                                    <h1>QPU</h1>
                                                    <p>{nft.qpu}</p>
                                                </section>
                                                <section className="robot-shield-generator">
                                                    <h1>Shield Generator</h1>
                                                    <p>{nft.shield_generator}</p>
                                                </section>
                                                <section className="robot-weapon-hardpoint">
                                                    <h1>Weapon Hardpoint</h1>
                                                    <p>{nft.weapon_hardpoint}</p>
                                                </section>
                                                <section className="robot-consume-output">
                                                    <p>Consume Output</p>
                                                </section>
                                                <section className="robot-energy-consumption">
                                                    <h1>Energy Consumption</h1>
                                                    {(() => {
                                                        if (nft.energy_consumption != null) {
                                                            let energyArray = typeof nft.energy_consumption == "number" ? [nft.energy_consumption] : nft.energy_consumption.split(",");
                                                            let energyStr = "";
                                                            for (let i = 0; i < energyArray.length; ++i) {
                                                                let energy = energyArray[i];
                                                                let id = Number(energy);
                                                                let typeConfig = GlobalConfig.getTypeConfig(id);
                                                                if (typeConfig != null) {
                                                                    if (i == energyArray.length - 1) {
                                                                        energyStr += typeConfig.name;
                                                                    } else {
                                                                        energyStr += typeConfig.name + ",";
                                                                    }
                                                                }
                                                            }
                                                            return <p>{energyStr}</p>
                                                        } else {
                                                            return <p>--</p>
                                                        }
                                                    })()}
                                                </section>
                                            </section>
                                        case Define.Category.Human:
                                            return <section>
                                                <section className="human-top-line" />
                                                <section className="human-middle-line" />
                                                <section className="human-third-line" />
                                                <section className="human-detail">
                                                    <p>Detail</p>
                                                </section>
                                                <section className="human-category">
                                                    <h1>Category</h1>
                                                    <p>{Define.DetailCategoryType[nft.category - 3]}</p>
                                                </section>
                                                <section className="human-gender">
                                                    <h1>Gender</h1>
                                                    <p>{Define.GenderType[nft.gender]}</p>
                                                </section>
                                                <section className="human-rarity">
                                                    <h1>Rarity</h1>
                                                    <p>{Define.RarityType[nft.rarity]}</p>
                                                </section>
                                                <section className="human-increase">
                                                    <h1>Increase</h1>
                                                    <p>{"+" + Global.formatNumber(nft.increase / Define.IncreaseCoefficient * 100) + "%"}</p>
                                                </section>
                                                <section className="human-length">
                                                    <h1>Height(CM)</h1>
                                                    <p>{nft.height}</p>
                                                </section>
                                                <section className="age-length">
                                                    <h1>Age</h1>
                                                    <p>{nft.age}</p>
                                                </section>
                                                <section className="human-feature">
                                                    <p>Feature</p>
                                                </section>
                                                <section className="human-feature-text">
                                                    <p>{nft.feature}</p>
                                                </section>
                                                <section className="human-skill">
                                                    <p>Skill</p>
                                                </section>
                                                <section className="human-skill-text">
                                                    <p>--</p>
                                                </section>
                                            </section>
                                        case Define.Category.Resources:
                                        case Define.Category.Equipments:
                                            return <section>
                                                <section className="collection-top-line" />
                                                <section className="collection-middle-line" />
                                                <section className="collection-third-line" />
                                                <section className="collection-detail">
                                                    <p>Detail</p>
                                                </section>
                                                <section className="collection-category">
                                                    <h1>Category</h1>
                                                    <p>{Define.DetailCategoryType[nft.category - 3]}</p>
                                                </section>
                                                <section className="collection-rarity">
                                                    <h1>Rarity</h1>
                                                    <p>{Define.RarityType[nft.rarity]}</p>
                                                </section>
                                                <section className="collection-level">
                                                    <h1>Level</h1>
                                                    <p>{nft.level}</p>
                                                </section>
                                                <section className="collection-increase">
                                                    <h1>Increase</h1>
                                                    <p>{"+" + Global.formatNumber(nft.increase / Define.IncreaseCoefficient * 100) + "%"}</p>
                                                </section>
                                                <section className="collection-length">
                                                    <h1>Length</h1>
                                                    <p>{nft.length}</p>
                                                </section>
                                                <section className="collection-width">
                                                    <h1>Width</h1>
                                                    <p>{nft.width}</p>
                                                </section>
                                                <section className="collection-height">
                                                    <h1>Height</h1>
                                                    <p>{nft.height}</p>
                                                </section>
                                                <section className="collection-deploy">
                                                    <p>Deploy</p>
                                                </section>
                                                <section className="collection-consume-output">
                                                    <p>Consume Output</p>
                                                </section>
                                                <section className="collection-space">
                                                    <h1>Space</h1>
                                                    <p>{nft.space}</p>
                                                </section>
                                                <section className="collection-wartung-des-computers">
                                                    <h1>Wartung Des Computers</h1>
                                                    <p>{nft.wartung_des_computers}</p>
                                                </section>
                                                <section className="collection-energy-consumption">
                                                    <h1>Energy Consumption</h1>
                                                    {(() => {
                                                        if (nft.energy_consumption != null) {
                                                            let energyArray = typeof nft.energy_consumption == "number" ? [nft.energy_consumption] : nft.energy_consumption.split(",");
                                                            let energyStr = "";
                                                            for (let i = 0; i < energyArray.length; ++i) {
                                                                let energy = energyArray[i];
                                                                let id = Number(energy);
                                                                let typeConfig = GlobalConfig.getTypeConfig(id);
                                                                if (typeConfig != null) {
                                                                    if (i == energyArray.length - 1) {
                                                                        energyStr += typeConfig.name;
                                                                    } else {
                                                                        energyStr += typeConfig.name + ",";
                                                                    }
                                                                }
                                                            }
                                                            return <p>{energyStr}</p>
                                                        } else {
                                                            return <p>--</p>
                                                        }
                                                    })()}
                                                </section>
                                                <section className="collection-output">
                                                    <h1>Output</h1>
                                                    {(() => {
                                                        if (nft.output != null) {
                                                            let outputArray = typeof nft.output == "number" ? [nft.output] : nft.output.split(",");
                                                            let outputStr = "";
                                                            for (let i = 0; i < outputArray.length; ++i) {
                                                                let output = outputArray[i];
                                                                let id = Number(output);
                                                                let typeConfig = GlobalConfig.getTypeConfig(id);
                                                                if (typeConfig != null) {
                                                                    outputStr += typeConfig.name + "\r\n";
                                                                }
                                                            }
                                                            return <p>{outputStr}</p>
                                                        } else {
                                                            return <p>--</p>
                                                        }
                                                    })()}
                                                </section>
                                            </section>
                                    }
                                }
                                )()}
                            </section>
                            :
                            <section>
                                {(() => {
                                    switch (buyType) {
                                        case Define.Buy.Buy:
                                            return (
                                                <section className="buy-right-top">
                                                    <img src={Buy1} className="buy" onClick={onBuyIconClick}
                                                        alt="-_-"
                                                        draggable={false} />
                                                    <img src={Sell2} className="sell" onClick={onSellIconClick}
                                                        alt="-_-"
                                                        draggable={false} />
                                                    <section className="sell-titles">
                                                        <span className="idx-title">IDX</span>
                                                        <span className="owner-title">OWNER</span>
                                                        <span className="sell-price-title">SELL PRICE</span>
                                                        <select className="options" value={currentBuyToken}
                                                            onChange={(event) => onBuyTokenChange(event)}> {
                                                                Define.TokenType.filter((v, i) => Global.AdminConfig.ShowCoin[i]).map((token, index) => {
                                                                    return (
                                                                        <option key={index} value={token}
                                                                            className="option">{token}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </section>
                                                    <section className="sells">
                                                        {
                                                            sellNfts.map((sell: ISellNft, index: number) => {
                                                                return (
                                                                    <section className="sell-order" key={index}
                                                                        onClick={() => onSelectSellNftClick(sell, index)}>
                                                                        {
                                                                            sellSelectedIndex == index
                                                                                ?
                                                                                <section className="select" />
                                                                                :
                                                                                null
                                                                        }
                                                                        <section className="id">
                                                                            <p>{sell.id}</p>
                                                                        </section>
                                                                        <section className="owner">
                                                                            <p onClick={() => copyTextToClipboard(sell.owner_address)} style={{ cursor: "pointer" }}>{Global.getEllipsis2(sell.owner_address)}</p>
                                                                        </section>
                                                                        <section className="sell-price">
                                                                            <p>{Global.formatNumber(Number(sell.price))}</p>
                                                                        </section>
                                                                        <section className="current-token">
                                                                            <p>{currentBuyToken}</p>
                                                                        </section>
                                                                    </section>
                                                                );
                                                            })
                                                        }
                                                    </section>
                                                </section>
                                            )
                                            break;
                                        case Define.Buy.Sell:
                                            return (
                                                <section className="sell-right-top">
                                                    <img src={Buy2} className="buy" onClick={onBuyIconClick}
                                                        alt="-_-"
                                                        draggable={false} />
                                                    <img src={Sell1} className="sell" onClick={onSellIconClick}
                                                        alt="-_-"
                                                        draggable={false} />
                                                    <section className="own-sell-titles">
                                                        <span className="own-orders-title">MY ORDERS</span>
                                                        <span className="own-sell-price-title">SELL PRICE</span>
                                                        <span className="own-sell-token">TOKEN</span>
                                                    </section>
                                                    <section className="own-sells">
                                                        {
                                                            ownSellNfts.map((sell: ISellNft, index: number) => {
                                                                return (
                                                                    <section className="own-sell" key={index}>
                                                                        <img className="own-sell-image"
                                                                            src={require("../assets/images/icon/" + nft.image)}
                                                                            alt="-_-" draggable={false} />
                                                                        <section className="own-sell-name">
                                                                            <p>{nft.name}</p>
                                                                        </section>
                                                                        <img className="own-sell-rarity"
                                                                            src={require("../assets/images/rarity/" + Define.RarityType[nft.rarity].toLowerCase() + ".png")}
                                                                            alt="-_-"
                                                                            draggable={false} />
                                                                        <section className="own-sell-price">
                                                                            <p>{Global.formatNumber(sell.price)}</p>
                                                                        </section>
                                                                        <section className="own-sell-token">
                                                                            <p>{Define.TokenType[sell.token]}</p>
                                                                        </section>
                                                                        <button className="own-sell-cancel"
                                                                            onClick={() => onCancelClick(sell)}>
                                                                            {
                                                                                isWait
                                                                                    ?
                                                                                    <section className="loading" />
                                                                                    :
                                                                                    <p>CANCEL</p>
                                                                            }
                                                                        </button>
                                                                        {
                                                                            ownSellNfts.length <= 2 || ownSellNfts.length > 2 && index < ownSellNfts.length - 1
                                                                                ?
                                                                                <img className="own-sell-line"
                                                                                    src={Line}
                                                                                    alt="-_-"
                                                                                    draggable={false} />
                                                                                :
                                                                                null
                                                                        }
                                                                    </section>
                                                                );
                                                            })
                                                        }
                                                    </section>
                                                </section>
                                            )
                                            break;
                                    }
                                }
                                )()
                                }
                            </section>
                    }
                    {
                        isInitialNfts
                            ?
                            <section className="right-bottom">
                                <section className="available-supply">
                                    <section className="available-supply-img" />
                                    <h1>AVAILABLE SUPPLY</h1>
                                    {
                                        official ?
                                            <p> {official.supply[0] + "/" + official.supply[1]}</p>
                                            :
                                            <p>-/-</p>
                                    }

                                </section>
                                <section className="origination-price">
                                    <section className="origination-price-img" />
                                    <h1>ORIGINATION PRICE</h1>
                                    <p>{official ? official.price : "--"}</p>
                                    <select className="options" value={currentToken}
                                        onChange={(event) => onSelectChange(event)}> {
                                            Define.TokenType.filter((v, i) => Global.AdminConfig.ShowCoin[i]).map((token, index) => {
                                                return (
                                                    <option key={index} value={token}
                                                        className="option">{token}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </section>
                                <section className="last-line" />
                                {
                                    Global.HasTradePermission() ?
                                        <button
                                            className={isLoadOfficalSellNfts && official ? "buy-btn" : "buy-btn-gray"}
                                            onClick={onBuyClick}>
                                            {
                                                isWait
                                                    ?
                                                    <section className="loading" />
                                                    :
                                                    <p>BUY</p>
                                            }
                                        </button>
                                        :
                                        <></>}
                            </section>
                            :
                            <section className="right-bottom1">
                                {(() => {
                                    switch (nft.category) {
                                        case Define.Category.Architectures:
                                            return <section>
                                                <section className="architecture-top-line" />
                                                <section className="architecture-middle-line" />
                                                <section className="architecture-basic">
                                                    <p>Basic</p>
                                                </section>
                                                <section className="architecture-category">
                                                    <h1>Category</h1>
                                                    <p>{Define.DetailCategoryType[nft.category - 3]}</p>
                                                </section>
                                                <section className="architecture-rarity">
                                                    <h1>Rarity</h1>
                                                    <p>{Define.RarityType[nft.rarity]}</p>
                                                </section>
                                                <section className="architecture-length">
                                                    <h1>Length</h1>
                                                    <p>{nft.length}</p>
                                                </section>
                                                <section className="architecture-width">
                                                    <h1>Width</h1>
                                                    <p>{nft.width}</p>
                                                </section>
                                                <section className="architecture-height">
                                                    <h1>Height</h1>
                                                    <p>{nft.height}</p>
                                                </section>
                                                <section className="architecture-special">
                                                    <p>Special</p>
                                                </section>
                                                <section className="architecture-level">
                                                    <h1>Level</h1>
                                                    <p>{nft.level}</p>
                                                </section>
                                                <section className="architecture-increase">
                                                    <h1>Increase</h1>
                                                    <p>{"+" + Global.formatNumber(nft.increase / Define.IncreaseCoefficient * 100) + "%"}</p>
                                                </section>
                                            </section>
                                        case Define.Category.Robots:
                                            return <section>
                                                <section className="robot-top-line" />
                                                <section className="robot-middle-line" />
                                                <section className="robot-basic">
                                                    <p>Basic</p>
                                                </section>
                                                <section className="robot-category">
                                                    <h1>Category</h1>
                                                    <p>{Define.DetailCategoryType[nft.category - 3]}</p>
                                                </section>
                                                <section className="robot-rarity">
                                                    <h1>Rarity</h1>
                                                    <p>{Define.RarityType[nft.rarity]}</p>
                                                </section>
                                                <section className="robot-power-supply">
                                                    <h1>Power Supply</h1>
                                                    <p>{nft.power_supply}</p>
                                                </section>
                                                <section className="robot-impulse-engine">
                                                    <h1>Impulse Engine</h1>
                                                    <p>{nft.impulse_engine}</p>
                                                </section>
                                                <section className="robot-qpu">
                                                    <h1>QPU</h1>
                                                    <p>{nft.qpu}</p>
                                                </section>
                                                <section className="robot-shield-generator">
                                                    <h1>Shield Generator</h1>
                                                    <p>{nft.shield_generator}</p>
                                                </section>
                                                <section className="robot-weapon-hardpoint">
                                                    <h1>Weapon Hardpoint</h1>
                                                    <p>{nft.weapon_hardpoint}</p>
                                                </section>
                                                <section className="robot-special">
                                                    <p>Special</p>
                                                </section>
                                                <section className="robot-level">
                                                    <h1>Level</h1>
                                                    <p>{nft.level}</p>
                                                </section>
                                                <section className="robot-increase">
                                                    <h1>Increase</h1>
                                                    <p>{"+" + Global.formatNumber(nft.increase / Define.IncreaseCoefficient * 100) + "%"}</p>
                                                </section>
                                            </section>
                                        case Define.Category.Human:
                                            return <section>
                                                <section className="human-top-line" />
                                                <section className="human-middle-line" />
                                                <section className="human-basic">
                                                    <p>Basic</p>
                                                </section>
                                                <section className="human-category">
                                                    <h1>Category</h1>
                                                    <p>{Define.DetailCategoryType[nft.category - 3]}</p>
                                                </section>
                                                <section className="human-gender">
                                                    <h1>Gender</h1>
                                                    <p>{Define.GenderType[nft.gender]}</p>
                                                </section>
                                                <section className="human-rarity">
                                                    <h1>Rarity</h1>
                                                    <p>{Define.RarityType[nft.rarity]}</p>
                                                </section>
                                                <section className="human-length">
                                                    <h1>Height(CM)</h1>
                                                    <p>{nft.height}</p>
                                                </section>
                                                <section className="age-length">
                                                    <h1>Age</h1>
                                                    <p>{nft.age}</p>
                                                </section>
                                                <section className="human-special">
                                                    <p>Special</p>
                                                </section>
                                                <section className="human-increase">
                                                    <h1>Increase</h1>
                                                    <p>{"+" + Global.formatNumber(nft.increase / Define.IncreaseCoefficient * 100) + "%"}</p>
                                                </section>
                                            </section>
                                        case Define.Category.Resources:
                                        case Define.Category.Equipments:
                                            return <section>
                                                <section className="collection-top-line" />
                                                <section className="collection-middle-line" />
                                                <section className="collection-basic">
                                                    <p>Basic</p>
                                                </section>
                                                <section className="collection-category">
                                                    <h1>Category</h1>
                                                    <p>{Define.DetailCategoryType[nft.category - 3]}</p>
                                                </section>
                                                <section className="collection-rarity">
                                                    <h1>Rarity</h1>
                                                    <p>{Define.RarityType[nft.rarity]}</p>
                                                </section>
                                                <section className="collection-length">
                                                    <h1>Length</h1>
                                                    <p>{nft.length}</p>
                                                </section>
                                                <section className="collection-width">
                                                    <h1>Width</h1>
                                                    <p>{nft.width}</p>
                                                </section>
                                                <section className="collection-height">
                                                    <h1>Height</h1>
                                                    <p>{nft.height}</p>
                                                </section>
                                                <section className="collection-space">
                                                    <h1>Space</h1>
                                                    <p>{nft.space}</p>
                                                </section>
                                                <section className="collection-wartung-des-computers">
                                                    <h1>Wartung Des Computers</h1>
                                                    <p>{nft.wartung_des_computers}</p>
                                                </section>
                                                <section className="collection-special">
                                                    <p>Special</p>
                                                </section>
                                                <section className="collection-level">
                                                    <h1>Level</h1>
                                                    <p>{nft.level}</p>
                                                </section>
                                                <section className="collection-increase">
                                                    <h1>Increase</h1>
                                                    <p>{"+" + Global.formatNumber(nft.increase / Define.IncreaseCoefficient * 100) + "%"}</p>
                                                </section>
                                            </section>
                                    }
                                }
                                )()}
                                {(() => {
                                    switch (buyType) {
                                        case Define.Buy.Buy:
                                            return (
                                                <section className="bottom">
                                                    {
                                                        Global.HasTradePermission() ?
                                                            <button
                                                                className={isLoadSellNfts && sellNfts.length > 0 ? "buy-exchange-btn" : "buy-exchange-btn-gray"}
                                                                onClick={onBuyOtherPlayerNftClick}>
                                                                {
                                                                    isWait
                                                                        ?
                                                                        <section className="loading" />
                                                                        :
                                                                        <p className="token-buy">BUY</p>
                                                                }
                                                            </button>
                                                            :
                                                            <></>
                                                    }

                                                    {/* <button
                                                        className={isLoadSellNfts && sellNfts.length > 0 ? "buy-exchange-btn" : "buy-exchange-btn-gray"}
                                                        onClick={onBuyOtherPlayerNftClick}>
                                                        {
                                                            isWait
                                                                ?
                                                                <section className="loading" />
                                                                :
                                                                <p className="token-buy">BUY</p>
                                                        }
                                                    </button> */}
                                                    {/*<p className="buy-fee">FEE:0.00001SOL</p>*/}
                                                </section>
                                            );
                                            break;
                                        case Define.Buy.Sell:
                                            return (
                                                <section className="bottom">
                                                    <p className="token">TOKEN</p>
                                                    <select className="options" value={currentSellToken}
                                                        onChange={(event) => onSellTokenChange(event)}> {
                                                            Define.TokenType.filter((v, i) => Global.AdminConfig.ShowCoin[i]).map((token, index) => {
                                                                return (
                                                                    <option key={index} value={token}
                                                                        className="option">{token}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="price">PRICE</p>
                                                    <section className="price-input">
                                                        <input className="price-placeholder"
                                                            id="price-input"
                                                            onChange={(event) => onPriceChange(event)} />
                                                    </section>
                                                    {/*<p className="sell-fee">FEE:0.00001SOL</p>*/}
                                                    <button
                                                        className={isLoadOwnNfts && ownNfts.length > 0 ? "sell-exchange-btn" : "sell-exchange-btn-gray"}
                                                        onClick={onSellClick}>
                                                        {
                                                            isWait
                                                                ?
                                                                <section className="loading" />
                                                                :
                                                                <p className="token-sell">SELL</p>
                                                        }
                                                    </button>
                                                </section>
                                            );
                                            break;
                                    }
                                })()}
                            </section>
                    }
                </section>
            </section>
        </section>
    );
}
