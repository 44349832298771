import React, {FC} from "react";
import "../assets/scss/loading.scss"

export const Loading: FC = () => {
    return (
        <section className="loading-background">
            <section className="loading">
            </section>
        </section>
    );
}
