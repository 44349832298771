import { useEffect, useState } from "react";
import "../assets/css/collections.css";
import { apiClient } from "../global/global";
import { CollectionIntro } from "../prot/protocols/market/PtlGetCollections";
import CollectionIcon from "./collectionIcon";

function RecommendCollection(props: { onViewAllClicked: () => void }) {
    const defaultCollections: CollectionIntro[] = [
        { project: "", title: "", imageUrl: "", developer: "", },
        { project: "", title: "", imageUrl: "", developer: "", },
        { project: "", title: "", imageUrl: "", developer: "", },
        { project: "", title: "", imageUrl: "", developer: "", },
        { project: "", title: "", imageUrl: "", developer: "", },
        { project: "", title: "", imageUrl: "", developer: "", },
    ];
    const MAX_COUNT = 6;
    const [collections, setCollections] = useState([]);

    const getCollections = async () => {
        const res = await apiClient.callApi("market/GetCollections", { type: "recommend", count: 6 });
        if (res) {
            res.res.list.forEach((v, i) => {
                defaultCollections[i] = v;
            })
        }
        setCollections(
            defaultCollections.filter((v, i) => i < MAX_COUNT).map((v, i) => {
                return <CollectionIcon key={i} collection={v} />
            })
        );
    }

    useEffect(() => {
        getCollections();
    }, [])

    const onClickAll = () => {

        props.onViewAllClicked();
    }

    return (<div id="recommend-collection-container">
        <h1>ALL-Time Best Collections</h1>
        <div id="title-line" />
        <div className="collection-container">
            {collections}
        </div>
        <div id="recommend-all-button">
            <button onClick={onClickAll}>Explore all collections</button>
        </div>
    </div>);
}

export default RecommendCollection;