import { useEffect, useState } from "react";
import { dummyGames } from "../define/dummyInfo";
import CollectionIcon from "./collectionIcon";
import "../assets/css/collections.css"
import "../assets/css/newMarketCommon.css"
import { apiClient } from "../global/global";

function AllCollections(props: { onReturnClick: () => void }) {
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        fetchCollections();
    }, [])
    const fetchCollections = async () => {
        const res = await apiClient.callApi("market/GetCollections", { type: "all" });
        if (res) {
            setCollections(res.res.list);
        }
    }

    return (
        <div className="market-container-common-bg rect-corner" style={{ minHeight: 900 }}>
            <div id="collection-all-container">
                <div id="title-container">
                    <div id="return-button">
                        <button className="return-button-common" onClick={props.onReturnClick}>Return</button>
                    </div>
                    <label>All Collections</label>
                </div>
                <div id="title-line"></div>
                <div className="collection-container">
                    {collections.map((v, i) => <CollectionIcon key={i} collection={v} />)}
                </div>
            </div>
        </div>
    );
}

export default AllCollections;