import { Swiper, SwiperSlide } from 'swiper/react';
import "../assets/css/stakingDetail.css"
import "../assets/css/stakeGenerated.css"
import { Scrollbar } from 'swiper';
import { useEffect, useState } from 'react';
import MyStakingControl from './myStakingControl';
import StakeItem from './stakeItem';
import MyStakeInfo from './myStakeInfo';
import StakePoolDetail from './stakePoolDetail';
import { IStakingPoolSummary } from '../prot/protocols/staking/PtlGetPoolList';
import { IStakingAccountSummary } from '../prot/protocols/staking/PtlGetStakeAccounts';
import { DbStakeCoin, MitCoinBSC, MitCoinDecimalSOL, MitCoinSOL } from '../prot/protocols/db/DbStaking';
import { apiClient, bigintToFloat, Global, hasReceive, } from '../global/global';
import { DbNFT } from '../prot/protocols/db/DbNFT';
import { IStakeNFTItemSummary } from '../prot/protocols/staking/PtlGetStakingNFT';
import { Harvest, RepairNft, StakeNFT, UnStakeNFT } from '../common/ServerAuction';
import { StakedNFTState, StakeItemType } from '../prot/protocols/staking/Staking';
import { getTokensByOwner, TokenGet } from '../common/tokenGet';
import { PublicKey } from '@solana/web3.js';
import * as SPLToken from "@solana/spl-token";
import { actions, programs, transactions, utils, } from "@metaplex/js"
import { GetToken } from '../common/EvmAuction';
import { waitSeconds } from '../utils';
import LoadingMask from './loadingMask';
import icon from "../assets/images/loading/loading.png"
import StakeTokenWindow, { ITokenDetail } from './stakeTokenWindow';
import { BigNumber } from 'ethers';
import EventCenter from "../event/eventCenter";
import { Define } from '../define/define';
function ValidNumber(num: number) {
    if (isNaN(num))
        return 0;
    return num;
}

function StakingDetail(props: {
    id: string,
    zoom: number,
    isOpen?: boolean,
    info: IStakingPoolSummary,
    coinIcon: DbStakeCoin[],
    account?: IStakingAccountSummary,
    ownedNfts: string[],
    onRefresh: () => Promise<void>,
    onFoldClick?: (isOpen: boolean) => void
}) {
    const [SelectedFilter, setSelectedFilter] = useState("computer");
    const [IsFold, setIsFold] = useState(false);
    const [Folder, setFolder] = useState(null as HTMLDivElement);
    const [InnerContent, setInnerContent] = useState(null as HTMLDivElement);
    const [PageColumn, setPageColumn] = useState(6);
    const [PageSelected, setPageSelected] = useState([] as boolean[]);
    const [Filtered, setFiltered] = useState([] as IStakeNFTItemSummary[]);
    const [CanSelect, setCanSelect] = useState(false);
    const [CurrentPage, setCurrentPage] = useState(0);
    const [PageDisable, setPageDisable] = useState([]);
    const [SelectedForce, setSelectedForce] = useState(0);
    const [AccountSelected, setAccountSelected] = useState("start-earn");
    const [SelectedBonus, setSelectedBonus] = useState(0);
    const [AccountNfts, setAccountNfts] = useState([] as IStakeNFTItemSummary[]);
    const [Ref, setRef] = useState(null as HTMLElement)
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        const onClick = (id: string, isOpen: boolean) => {
            console.log(id, isOpen);

            if (isOpen && id != props.id)
                setIsFold(false);
        }
        EventCenter.addListener("onStakingDetailClick", onClick);
        return () => {
            EventCenter.removeListener("onStakingDetailClick", onClick);
        }
    }, [])


    const setFoldHeight = (isOpen: boolean) => {
        if (isOpen && InnerContent != null) {
            console.log(InnerContent.clientHeight);
            Folder.style.height = InnerContent.clientHeight + 6 + "px";
        }
        else {
            if (Folder)
                Folder.style.height = "0px";
        }
    }
    useEffect(() => {
        if (!Folder)
            return;
        // console.log(props.account);

        if (IsFold) {
            fetchAccountNFTS();
        }
        setFoldHeight(IsFold);
        props.onFoldClick?.(IsFold);
    }, [Folder, IsFold, props.account, props.ownedNfts]);

    const fetchAccountNFTS = async () => {
        console.log("FETCH STAKING");
        setLoading(true);
        const call = await apiClient.callApi("staking/GetStakingNFT", {
            account: Global.WalletAddress,
            tokens: props.ownedNfts,
            poolId: props.info.poolId,
        })

        if (call.isSucc) {
            let unstaked = 0;
            let staked = 0;
            call.res.items.forEach(element => {
                if (element.staked)
                    staked++;
                else
                    unstaked++;
            });
            console.error("STAKE:" + staked + "/" + (staked + unstaked));
            console.log(call.res.items);

            setAccountNfts(call.res.items);
        }
        else {
            console.error(call.err);

        }
        setLoading(false);

        setTimeout(() => {
            setFoldHeight(true);
            props.onFoldClick?.(true);
        }, 10);
    }

    useEffect(() => {
        if (!Folder)
            return;
        const onResize = () => {
            const column = Math.floor(Folder.clientWidth / 200);
            if (window.innerWidth <= 875)
                setPageColumn(3)
            else
                setPageColumn(column);
        }
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, [Folder])


    const onFoldClick = () => {

        if (!Global.WalletConnected("")) {
            Folder.style.height = "0px";
            return;
        }
        console.log(Ref.offsetTop);
        setTimeout(() => {
            document.getElementById("need-scroll").scroll({ top: Ref.offsetTop, behavior: "smooth" })
        }, 10);

        setIsFold(pre => {
            EventCenter.emit("onStakingDetailClick", props.id, !pre)
            return !pre;
        });
    }

    useEffect(() => {
        setControlFilter(AccountSelected);
        setPageSelected([].fill(false, 0, Filtered.length));
        setPageDisable([].fill(false, 0, Filtered.length));

    }, [SelectedFilter]);
    useEffect(() => {
        setControlFilter(AccountSelected);

    }, [props, AccountNfts])

    const setControlFilter = (value: string) => {
        const filter = (selected: string, type: string) => {
            // export type StakeItemType = "computer" | "hyper_computer" | "land" | "partner" | "other" | "token";
            if (type == "computer" || type == "hyper_computer")
                return selected == "computer";
            else if (type == "land")
                return selected == "land";
            else if (type == "other")
                return selected == "other";
            else if (type == "partner" || type == "token")
                return selected == "partner";
        };
        if (value == "start-earn") {
            setFiltered(AccountNfts.filter(v => !v.staked && filter(SelectedFilter, v.item.display)));
        }
        else if (value == "my-staked") {
            setFiltered(AccountNfts.filter(v => v.staked && filter(SelectedFilter, v.item.display)));
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setFoldHeight(true)
        }, 10);
    }, [SelectedFilter])


    const filters = <div className="filters">
        <div>
            <label className={SelectedFilter == "computer" ? "filter-checked" : "filter-unchecked"} onClick={() => { setSelectedFilter("computer") }} >Computer</label>
        </div>
        <div className="divide-line"></div>
        <div>
            <label className={SelectedFilter == "land" ? "filter-checked" : "filter-unchecked"} onClick={() => { setSelectedFilter("land") }}>Lands</label>
        </div>
        <div className="divide-line"></div>
        <div>
            <label className={SelectedFilter == "other" ? "filter-checked" : "filter-unchecked"} onClick={() => { setSelectedFilter("other") }}>Other NFTs</label>
        </div>
        <div className="divide-line"></div>
        <div>
            <label className={SelectedFilter == "partner" ? "filter-checked" : "filter-unchecked"} onClick={() => { setSelectedFilter("partner") }}>NFTS</label>
        </div>
    </div>

    const onSelected = (id: number, selected: boolean) => {
        setCanSelect(true);
        const select = [...PageSelected];
        select[id] = selected;
        let selectedCount = 0;
        setPageSelected(select);
        select.forEach(element => {
            element && selectedCount++;
        });
        const start = CurrentPage * PageColumn * 2;
        const end = PageColumn * 2 + start;
        const limit = (Global.WalletConnected("SOL") ? 6 : 12)//AccountSelected == "start-earn" ? (Global.WalletConnected("SOL") ? 6 : 12) : Filtered.length;
        if (selectedCount >= limit)
            setPageDisable(pre => {
                const res = [...pre];
                for (let index = start; index < end; index++) {
                    res[index] = !select[index];
                }
                return res;
            });
        else
            setPageDisable([].fill(false, 0, select.length));
    }

    const onAllClick = () => {
        setCanSelect(true);
        const start = CurrentPage * PageColumn * 2//AccountSelected == "start-earn" ? CurrentPage * PageColumn * 2 : 0;
        setPageSelected([].fill(false, 0, Filtered.length));
        if (Global.WalletConnected("SOL")) {
            const end = start + 6//AccountSelected == "start-earn" ? start + 6 : Filtered.length;

            setPageSelected(pre => {
                const res = [].fill(false, 0, Filtered.length);
                for (let index = start; index < end; index++) {
                    res[index] = true;
                }
                return res;
            });
            // if (AccountSelected == "start-earn")
            setPageDisable(() => {
                const res = [].fill(false, 0, Filtered.length);
                for (let index = start + 6; index < end + 6; index++) {
                    res[index] = true;
                }
                return res;
            });
            // else
            //     setPageDisable([].fill(false, 0, Filtered.length));
        }
        else {
            const end = PageColumn * 2 + start//AccountSelected == "start-earn" ? PageColumn * 2 + start : Filtered.length;

            setPageSelected(pre => {
                const res = [...pre];
                for (let index = start; index < end; index++) {
                    res[index] = true;
                }
                return res;
            });
            setPageDisable([].fill(false, 0, Filtered.length));
        }
    }

    useEffect(() => {
        let force = 0;
        let bonus = 0;
        for (let index = 0; index < Filtered.length; index++) {
            const item = Filtered[index];
            if (PageSelected[index]) {
                force += ValidNumber(item.item.force);
                bonus += ValidNumber(item.item.bonus);
            }

        }

        setSelectedBonus(bonus);
        setSelectedForce(force);
    }, [PageSelected, Filtered])

    const showTokenPop = async (list: ITokenDetail[], isTake: boolean) => {
        let show = true;
        let ok = false;
        Global.ShowTokenPop(list, "Please enter the quantity you want to " + (isTake ? "stake" : "unstake"), isTake ? "Stake" : "Unstake", (isOk) => {
            ok = isOk;
            show = false;
        })
        while (show) {
            //TODO等待pop返回
            await waitSeconds(0.1);
        }
        return ok;
    }

    const onStakeClick = async () => {
        const start = CurrentPage * PageColumn * 2;
        //检查token
        const stakeList: ITokenDetail[] = [];
        let hasToken = false;
        const fted = Filtered.filter((v, i) => PageSelected[i + start]);
        if (fted.length <= 0)
            return;
        Global.ShowLoading(true);

        for (let index = 0; index < fted.length; index++) {
            const token = fted[index];
            if (token.isNft)
                stakeList.push({
                    _id: token._id,
                    address: token.address,
                    amount: 1,
                    decimal: 0,
                    isNft: true,
                    icon: "",

                    exchange: 0,
                    limit: 0,
                    force: 0,
                    bonus: 0,
                });
            else {
                hasToken = true;
                const icon = props.coinIcon.find(v => v.address == token.address);
                console.log("LIMIT", await getTokenAmount(token.address));

                stakeList.push({
                    _id: token._id,
                    address: token.address,
                    amount: 0,
                    decimal: icon.decimal,
                    isNft: false,
                    icon: icon.imgUrl,

                    exchange: token.item.exchange,
                    limit: await getTokenAmount(token.address),
                    force: token.item.force,
                    bonus: token.item.bonus,
                });
            }
        }
        Global.ShowLoading(false);

        //等待弹窗结果
        if (hasToken) {
            const ok = await showTokenPop(stakeList, true);
            if (!ok)
                return;
        }

        Global.ShowLoading(true);
        //开始质押
        await StakeNFT(props.info.poolId, stakeList.map(v => ({
            _id: v._id,
            address: v.address,
            amount: v.amount + "",
            isNft: v.isNft,
            decimal: v.decimal,
        })), props.coinIcon);
        await RefreshAccounts();
        Global.ShowLoading(false);
    }
    const RefreshAccounts = async () => {
        await props.onRefresh();
        // console.log(22222);
        // await waitSeconds(2);
        // await fetchAccountNFTS();
        setPageSelected([].fill(false, 0, Filtered.length));
        setPageDisable([].fill(false, 0, Filtered.length));
        setCanSelect(false);
    }
    const unStakeNfts = async (selected: IStakeNFTItemSummary[]) => {
        Global.ShowLoading(true);

        //token弹窗
        const stakeList: ITokenDetail[] = [];
        let hasToken = false;
        const fted = Filtered.filter((v, i) => PageSelected[i]);
        Global.ShowLoading(true);

        for (let index = 0; index < fted.length; index++) {
            const token = fted[index];
            if (token.isNft)
                stakeList.push({
                    _id: token._id,
                    address: token.address,
                    amount: 1,
                    decimal: 0,
                    isNft: true,
                    icon: "",

                    exchange: 0,
                    limit: 0,
                    force: 0,
                    bonus: 0,
                });
            else {
                hasToken = true;
                const icon = props.coinIcon.find(v => v.address == token.address);

                stakeList.push({
                    _id: token._id,
                    address: token.address,
                    amount: 0,
                    decimal: icon.decimal,
                    isNft: false,
                    icon: icon.imgUrl,

                    exchange: token.item.exchange,
                    limit: bigintToFloat(BigInt(token.staked.amount), icon.decimal),//Number(BigInt(token.staked.amount) / ((BigInt(10) ** BigInt(icon.decimal)))),
                    force: token.item.force,
                    bonus: token.item.bonus,
                });
            }
        }
        Global.ShowLoading(false);

        //等待弹窗结果
        if (hasToken) {
            const ok = await showTokenPop(stakeList, false);
            if (!ok)
                return;
        }

        Global.ShowLoading(true);
        console.log(stakeList);

        await UnStakeNFT(props.info.poolId, stakeList.map(v => ({
            _id: v._id,
            address: v.address,
            amount: v.amount + "",
            isNft: v.isNft,
            decimal: v.decimal,
        })));
        await RefreshAccounts();
        Global.ShowLoading(false);
    }
    const onUnStakeClick = () => {
        const selected = Filtered.filter((v, i) => {
            return v.staked && PageSelected[i]
        });
        if (selected.length == 0)
            return;

        Global.ShowPop("Are you sure to unstake these NFTs?", (isOk) => isOk && unStakeNfts(selected));
    }

    const getTokenAmount = async (address: string) => {
        if (Global.WalletConnected("SOL")) {

            if (address == Global.AdminConfig.TokenAddress[Define.Token.SOL]) {
                const sol = await Global.Connection.getBalance(Global.WalletAdapter.publicKey);
                console.log("SOL", sol);

                return sol / Math.pow(10, 9);
            }
            const accounts = await TokenGet.getTokenAccountsByOwner(Global.Connection, Global.WalletAdapter.publicKey);

            for (const e of accounts.value) {
                const accountInfo = SPLToken.AccountLayout.decode(e.account.data);
                const publicKey = new PublicKey(accountInfo.mint).toString();
                console.log(accountInfo, publicKey);
                if (publicKey == address) {
                    // const amount = Buffer.from(accountInfo.amount).readBigInt64LE();
                    const coin = props.coinIcon.find(a => a.address == address);
                    console.log(BigNumber.from(accountInfo.amount));

                    const num = BigNumber.from(accountInfo.amount).div(100000).toNumber() / Math.pow(10, coin.decimal) * 100000;
                    console.log(num);
                    return num;
                }
            }

        }
        else if (Global.WalletConnected("BNB")) {
            return Number(await GetToken(address))
        }
        return 0;
    }

    const onRepairClick = async () => {

        //计算价格
        const tokens = Filtered.filter((v, i) => {
            return PageSelected[i] && v.staked && v.staked.state != StakedNFTState.repair && v.staked.durabilityLimit && v.staked.durabilityLimit - v.staked.durability > 0
        });
        let price = 0;
        let owned = 0;
        console.log(tokens);

        tokens.forEach(v => {
            price += (v.staked.durabilityLimit - Math.floor(v.staked.durability)) * v.staked.toolCost;
        });

        //获取货币数量
        if (Global.WalletConnected("SOL")) {
            const accounts = await TokenGet.getTokenAccountsByOwner(Global.Connection, Global.WalletAdapter.publicKey);
            for (const e of accounts.value) {
                const accountInfo = SPLToken.AccountLayout.decode(e.account.data);
                const publicKey = new PublicKey(accountInfo.mint).toString();
                // console.log(accountInfo, publicKey, amount);
                if (publicKey == MitCoinSOL) {
                    const amount = Number(Buffer.from(accountInfo.amount).readBigInt64LE());
                    owned = amount / Math.pow(10, MitCoinDecimalSOL);
                    break;
                }
            }

        }
        else if (Global.WalletConnected("BNB")) {
            owned = Number(await GetToken(MitCoinBSC))
        }

        if (owned < price) {
            Global.ShowPop("MitCoin not enough!", () => { });
            return;
        }

        Global.ShowLoading(true);
        await RepairNft(props.info.poolId, tokens.map(v => ({
            _id: v._id,
            address: v.address,
            amount: v.isNft ? "1" : "0",
            decimal: v.isNft ? 0 : 9,
            isNft: v.isNft,
        })))
        await RefreshAccounts();
        Global.ShowLoading(false);
    }

    const getPages = () => {
        const pageCount = Math.ceil(Filtered.length / (PageColumn * 2));
        // console.log(props.accountNFTs.length, PageColumn, pageCount);
        const slides = [];
        for (let index = 0; index < pageCount; index++) {
            const start = index * PageColumn * 2;
            const end = PageColumn * 2 + start;

            slides.push(
                <SwiperSlide key={index}>
                    <div className="sd-page">
                        {Filtered.slice(start, end).map((v, i) =>
                            <StakeItem
                                isMyStaked={AccountSelected == "my-staked"}
                                tokenIcons={props.coinIcon}
                                key={i}
                                poolInfo={props.info}
                                item={v}
                                staked={AccountSelected == "start-earn" ? undefined : v.staked}
                                disabled={PageDisable[start + i]}
                                onSelected={onSelected}
                                showCheckBox={CanSelect} id={start + i}
                                isSelected={PageSelected[start + i]} />)}
                    </div>
                </SwiperSlide>
            );
        }

        return (
            <Swiper className='inner-item-container' modules={[Scrollbar]}
                scrollbar={{
                    hide: false,
                    draggable: true,
                }}
                onSlideChange={(swiper) => {
                    setCurrentPage(swiper.realIndex);
                    // if (AccountSelected == "start-earn") {
                    setPageSelected([].fill(false, 0, Filtered.length));
                    setPageDisable([].fill(false, 0, Filtered.length));
                    // }
                }}
            >
                {slides}
            </Swiper>
        )
    }

    const onAccountSelect = (value: string) => {
        setAccountSelected(value)
        setPageSelected([].fill(false, 0, Filtered.length));
        setPageDisable([].fill(false, 0, Filtered.length));

        setControlFilter(value);
    }

    const stakeControl = () => {

        const stake = <button key={"stake"} onClick={onStakeClick} disabled={props.info.closeUTC.getTime() <= new Date().getTime()}>Stake</button>;
        const repair = <button key={"repair"} onClick={onRepairClick}>Repair</button>;
        const unstake = <button key={"unstake"} onClick={onUnStakeClick}>UnStake</button>;
        const res = [];
        //获取已选中的nft

        const sel = Filtered.filter((v, i) => { return PageSelected[i] });
        //stake状态
        if (AccountSelected == "start-earn") {
            res.push(stake);
        }
        else if (AccountSelected == "my-staked") {
            for (const itm of sel) {
                if (itm.staked.durabilityLimit - itm.staked.durability > 0) {
                    res.push(repair);
                    break;
                }
            }
            res.push(unstake);
        }

        return <div >
            {res}
        </div>
    }
    const onHarvestClick = async () => {
        Global.ShowLoading(true);
        await Harvest(props.info.poolId, props.account.address);
        await RefreshAccounts();
        Global.ShowLoading(false);
    }

    const OnRefreshClick = async () => {
        Global.ShowLoading(true);
        await RefreshAccounts();
        Global.ShowLoading(false);
    }
    const isExpire = (info: IStakingPoolSummary) => {
        if (!info)
            return false;
        const now_time = new Date().getTime()
        // info.closeUTC.getTime()返回的是UTC+8时间
        if (now_time - info.closeUTC.getTime() > 0)
            return true;
        return false;
    }


    return (
        <div className="staking-detail-container" ref={setRef}>
            <div className={`staking-detail ${hasReceive(props.account) ? "on" : "off"}-border ${isExpire(props.info) ? "is-expire" : ""}`} onClick={onFoldClick} >
                <StakePoolDetail info={props.info} coinIcon={props.coinIcon} />
                <div className="vert-line"></div>
                <MyStakeInfo pool={props.info} account={props.account} coinIcon={props.coinIcon} />
            </div>
            <div className="stake-item-container" ref={setFolder}>
                <div className="stake-items" ref={setInnerContent} >
                    {props.account ? <MyStakingControl account={props.account} pool={props.info} force={props.account.totalForce} bonus={props.account.totalBonus} onRefresh={OnRefreshClick} onHarvest={onHarvestClick} selected={AccountSelected} onSelected={onAccountSelect} /> : <></>}
                    <div className="main">
                        {filters}
                        {getPages()}
                        <div className="stake-loading-mask" style={{ display: Loading ? "block" : "none" }} >
                            <img src={icon}></img>
                        </ div>
                    </div>
                    <p>Each staking requires a certain amount of Mitcoin as a handling fee</p>
                    <div className="controls">
                        <div className="info">
                            <div>
                                <h1>Total Force：</h1>
                                <h2>{SelectedForce}</h2>
                            </div>
                            <div>
                                <h1>Bonus：</h1>
                                <h2>{`${SelectedBonus / 100}%`}</h2>
                            </div>
                        </div>
                        <div className="buttons">
                            <div >
                                <button onClick={() => {
                                    setPageSelected([].fill(false, 0, Filtered.length));
                                    setPageDisable([].fill(false, 0, Filtered.length));
                                    setCanSelect(pre => !pre);
                                }}>{CanSelect ? "Cancel" : "Select"}</button>
                                <button onClick={onAllClick}>All</button>
                            </div>
                            {stakeControl()}
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default StakingDetail;