import { PublicKey } from "@solana/web3.js";
import { SetStateAction, useEffect, useState } from "react";
import "../assets/css/stakeItem.css"
import { GetToken } from "../common/EvmAuction";
import { TokenGet } from "../common/tokenGet";
import { Global, bigintToFloat } from "../global/global";
import { DbStakeCoin } from "../prot/protocols/db/DbStaking";
import { IStakingPoolSummary } from "../prot/protocols/staking/PtlGetPoolList";
import { IStakeNFTItemSummary } from "../prot/protocols/staking/PtlGetStakingNFT";
import { StakedNFTState } from "../prot/protocols/staking/Staking";
import CountDown from "./countDown";
import * as SPLToken from "@solana/spl-token";
import { IStakedNFTSummary } from "../prot/protocols/staking/PtlGetStakeAccounts";
import { waitSeconds } from "../utils";
import { Define } from "../define/define";

function StakeItem(props: {
    item: IStakeNFTItemSummary,
    poolInfo: IStakingPoolSummary,
    disabled?: boolean,
    staked?: IStakedNFTSummary,
    showCheckBox: boolean,
    isMyStaked: boolean,
    id: number,
    isSelected: boolean,
    onSelected: (id: number, isSelected: boolean) => void,
    tokenIcons: DbStakeCoin[]
}) {

    const [Props, setProps] = useState([]);
    const [Token, setToken] = useState(0);
    // const [TokenCount, setTokenCount] = useState(null as any);
    const [RepairTime, setRepairTime] = useState("");
    const [CoinCount, setCoinCount] = useState("");

    const fetchToken = async () => {
        setCoinCount("");
        console.log(props.id, props.item.address);
        if (props.isMyStaked)
            return;
        if (props.item.isNft) {
            return;
        }

        //获取货币
        if (props.item.address.startsWith("0x") && Global.WalletConnected("BNB")) {
            //BSC
            const num = Number(await GetToken(props.item.address));

            setToken(num)
        }
        else if (Global.WalletConnected("SOL")) {
            //SOL
            console.log("FETCH");

            const accounts = await TokenGet.getTokenAccountsByOwner(Global.Connection, Global.WalletAdapter.publicKey);

            for (const e of accounts.value) {
                const accountInfo = SPLToken.AccountLayout.decode(e.account.data);
                const publicKey = new PublicKey(accountInfo.mint).toString();
                // console.log(accountInfo, publicKey, amount);
                if (publicKey == props.item.address) {
                    console.log(publicKey, accountInfo);

                    let amount = BigInt(0);
                    if (publicKey == Define.SOLAddress) {
                        let balance = await Global.Connection.getBalance(Global.WalletAdapter.publicKey);
                        amount = BigInt(balance);
                    }
                    else
                        amount = Buffer.from(accountInfo.amount).readBigInt64LE();
                    const coin = props.tokenIcons.find(a => a.address == props.item.address)
                    const owned = bigintToFloat(amount, coin.decimal)//amount / Math.pow(10, coin.decimal);
                    console.log(publicKey, amount, "OWNED", owned);

                    setToken(owned);
                    setCoinCount(owned.toFixed(2));
                    break;
                }
            }
        }
    }
    useEffect(() => {
        fetchToken();
    }, [props.item, props.staked]);

    useEffect(() => {
        if (props.staked) {
            console.log(props.staked);
            if (props.item.isNft) {
                setCoinCount("");
                return;
            }
            const amount = props.staked.amount;
            const coin = props.tokenIcons.find(a => a.address == props.item.address);
            if (!coin)
                return;
            const owned = bigintToFloat(BigInt(amount), coin.decimal) //Number(BigInt(amount) / ((BigInt(10) ** BigInt(coin.decimal))));
            setToken(owned);

            setCoinCount(owned.toFixed(2));
        }
    }, [props.staked, props.tokenIcons])


    useEffect(() => {

        if (!props.item)
            return;
        let plist: any[] = [];
        const normal = [];
        const info = props.item;
        let keyIdx = 0;
        const updateRepair = setInterval(() => {
            if (!info.staked) {
                clearInterval(updateRepair);
                return;
            }
            if (info.staked.state == StakedNFTState.repair) {
                const needMinute = info.staked.repairTime * (info.staked.durabilityLimit - Math.floor(info.staked.durability));
                const h = Math.floor(needMinute / 60);
                const m = Math.ceil(needMinute - h * 60);
                setRepairTime(`${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:00`);
            }

        }, 1000)

        const stakedAmount = props.item.staked ? props.item.staked.adjustedAmount : 1;
        console.log(props.item.isNft, props.isMyStaked);

        const tokenAfter = (!props.item.isNft && !props.isMyStaked) ? " per " + props.item.item.exchange : "";
        if (info.item.force)
            normal.push(<div key={keyIdx++} className="stake-item-labels">
                <h1>Force: </h1> <h2>{props.item.item.force * stakedAmount + tokenAfter}</h2>
            </div>);
        if (info.item.bonus)
            normal.push(<div key={keyIdx++} className="stake-item-labels">
                <h1>Bonus: </h1> <h2>{`${props.item.item.bonus * stakedAmount / 100}%` + tokenAfter}</h2>
            </div>);
        if (info.staked) {
            if (info.staked.state == StakedNFTState.open) {
                plist = [...normal];
                if (info.item.durabilityLimit && info.item.durabilityLimit > 0) {
                    plist.push(<div key={keyIdx++} className="stake-item-labels">
                        <h1>Durability：</h1> <h2>{`${Math.floor(info.staked.durability)}/${info.staked.durabilityLimit}`}</h2>
                    </div>)
                    plist.push(<div key={keyIdx++} className="stake-item-labels">
                        <h1>Available Working</h1> <h2>{`${props.item.staked ? ((new Date().getTime() - props.item.staked.startUTC.getTime()) / (1000 * 60 * 60)).toFixed(2) : 0}H`}</h2>
                    </div>)
                }
            }
            else if (info.staked.state == StakedNFTState.closed) {
                plist.push(<h3 key={keyIdx++} >Broke</h3>)
                plist.push(<div key={keyIdx++} className="stake-item-labels">
                    <h1>Durability：</h1> <h2>{`${Math.floor(info.staked.durability)}/${info.staked.durabilityLimit}`}</h2>
                </div>)
            }
            else if (info.staked.state == StakedNFTState.repair) {
                plist.push(<h3 key={keyIdx++} >Repairing...</h3>)
                plist.push(<div key={keyIdx++} className="stake-item-labels">
                    <h1>Time: </h1> <h2>{RepairTime}</h2>
                </div>);
                plist.push(<div key={keyIdx++} className="stake-item-labels">
                    <h1>Durability：</h1> <h2>{`${Math.floor(info.staked.durability)}/${info.staked.durabilityLimit}`}</h2>
                </div>)
            }
            // if (info.item.exchange) {
            //     plist.push(TokenCount)
            // }
        }
        else {
            plist = [...normal];
            if (info.item.durabilityLimit && info.item.durabilityLimit > 0) {
                plist.push(<div key={keyIdx++} className="stake-item-labels">
                    <h1>Durability：</h1> <h2>{`${info.item.durability}/${info.item.durabilityLimit}`}</h2>
                </div>)
                plist.push(<div key={keyIdx++} className="stake-item-labels">
                    <h1>Available working：</h1> <h2>{`${(info.item.durabilityLimit / props.poolInfo.durabilityLoss / 60).toFixed(2)}H`}</h2>
                </div>)
            }

            // if (info.item.exchange) {
            //     plist.push(TokenCount)
            // }
        }
        setProps(plist);

        return () => {
            clearInterval(updateRepair);
        }
    }, [props, props.item, Token,/** TokenCount*/])

    // useEffect(() => {
    //     setTokenCount(<div key={"tk"} className="stake-item-labels">
    //         <h1>Count：</h1> <h2>{`${Token}`}</h2>
    //     </div>)

    // }, [Token, props])

    const onClick = () => {
        if (!props.disabled) {
            props.onSelected(props.id, !props.isSelected);
        }
    }

    const getImage = () => {
        if (props.item.nft && props.item.nft.data)
            return props.item.nft.data.image;
        if (props.tokenIcons.length == 0)
            return ""
        return props.tokenIcons.find(v => v.address == props.item.address)?.imgUrl;
    }
    const genBSCName = (name: string, mintAddress: string) => {
        if (name.match("#"))
            return name;
        if (props.item.nft) {
            return name + "#" + mintAddress.split("_")[1];
        }
        return name + "#" + props.item.item.id;
    }
    const getName = () => {
        // console.log(props.item);

        if (!props.item.isNft) {
            return props.item.item.name;
        }
        if (props.item.address.startsWith("0x")) {
            return genBSCName(props.item.nft.name, props.item.nft.mintAddress);
            // if (props.item.nft) {
            //     return props.item.nft.name + "#" + props.item.nft.mintAddress.split("_")[1];
            // }
            // return props.item.item.name + "#" + props.item.item.id;
        }
        if (props.item.nft) {
            return props.item.nft.name;
        }
        return props.item.item.name + props.item.item.id;
    }

    return (<div className="stake-item-card" onClick={onClick}>
        <div className="stake-item-icon" style={{ backgroundImage: `url(${getImage()})` }}>
            <CountDown display={props.item.staked?.state == StakedNFTState.repair} child={""} progress={(1 - props.item.staked?.durability / props.item.staked?.durabilityLimit)} ></CountDown>
            <h5 className="stake-item-amount">{CoinCount}</h5>
        </div>
        <p className="stake-item-name">{getName()}</p>
        <input type="checkbox" readOnly disabled={props.disabled} checked={props.isSelected} style={{ display: props.showCheckBox ? "block" : "none" }} />
        {Props}
    </div>);
}

export default StakeItem;