import React, {FC, useCallback, useEffect, useContext, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "../assets/scss/swipteThree.scss";
import cs from "classnames";
import { useNavigate } from 'react-router-dom';
import { Define } from '../define/define';
import { GameStoreContext } from "../contexts/gameContext";
// import Swiper1 from "../assets/images/home/swiper-1.png";
// import Swiper2 from "../assets/images/home/swiper-2.png";
// import Swiper3 from "../assets/images/home/swiper-3.png";
// import Swiper4 from "../assets/images/home/swiper-4.png";
// import Swiper5 from "../assets/images/home/swiper-5.png";
// import Swiper6 from "../assets/images/home/swiper-6.png";

export const SwiperThree: FC = () => {
    const ctx = useContext(GameStoreContext);
    const [swiperController, setSwiperController] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [currentSymbolIndex, setCurrentSymbolIndex] = useState(0);
    // const pictures = [Swiper6, Swiper1, Swiper2, Swiper3, Swiper4, Swiper5];
    const pictures = [
        {
            "imgurl": "https://meta2150s.com/storage/Capverse/2024425/1.6dbf4.png",
            "project": "Capverse"
        },
        {
            "imgurl": "https://meta2150s.com/storage/Wormfare/202464/8753.95001.jpg",
            "project": "Wormfare"
        },
        {
            "imgurl": "https://meta2150s.com/storage/MasterPop/2024422/banner2.3083f.png",
            "project": "MasterPop"
        }
        // {
        //   "imgurl": "https://meta2150s.com/storage/EternalDragons/2023523/875x4902.a161a.jpg",
        //   "project": "EternalDragons"
        // },
        // {
        //   "imgurl": "https://meta2150s.com/storage/tap/202331/2.2ddd1.png",
        //   "project": "tap"
        // },
        // {
        //   "imgurl": "https://meta2150s.com/storage/metabsc/202332/plain-b.b0c96.jpg",
        //   "project": "metabsc"
        // }
    ]
    const navigate = useNavigate();
    useEffect(() => {
        if (swiperController) {
            setCurrentIndex(currentIndex);
            swiperController.slideTo(currentIndex);
        }
    }, [currentIndex, swiperController]);

    const onSwiper = useCallback((swiper: any) => {
        setSwiperController(swiper);
    }, [swiperController]);

    const onSwiperClick = useCallback((index: number) => {
        let tempIndex = index + 1;
        setCurrentIndex(tempIndex);
    }, []);

    const onSlideChange = useCallback(() => {
        if (swiperController) {
            let index = (swiperController.activeIndex - 1) % pictures.length;
            setCurrentSymbolIndex(index);
            setCurrentIndex(swiperController.activeIndex);
        }
    }, [currentIndex, swiperController]);

    return (
        <section className="middle-next">
            <section className="swiper-body">
                <Swiper
                    slidesPerView={3}
                    onSwiper={onSwiper}
                    onSlideChange={onSlideChange}
                    autoplay={{
                        disableOnInteraction: true,
                    }}
                    loop
                    grabCursor
                    centeredSlides
                > {
                    pictures.map((picture, index) => {
                        return (
                            <SwiperSlide key={index} >
                                <img src={picture.imgurl} alt="-_-" draggable={false} onClick={() => {
                                    ctx.project = picture.project;
                                    navigate(Define.Page.GameDetail);
                                }} />
                            </SwiperSlide>
                        );
                    })
                }
                </Swiper>
                {/* <ul className="symbol-list"> {
                    pictures.map((picture, index) => {
                        return (
                            <section key={index} onClick={() => onSwiperClick(index)}>
                                <li className={cs({"checked-line": currentSymbolIndex === index})}/>
                            </section>
                        );
                    })
                }
                </ul> */}
            </section>
        </section>
    );
}
