import { Global } from "../global/global";
import { Connection, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import bs58 from 'bs58';

export class TokenGet {
    public static async getBalance(connection: Connection) {
        let balance = await connection.getBalance(Global.WalletAdapter.publicKey);
        return balance;
    }

    public static async getTokenAccountsByOwner(connection: Connection, publicKey: PublicKey) {
        const balance = await connection.getTokenAccountsByOwner(publicKey, { programId: TOKEN_PROGRAM_ID });
        return balance;
    }


}

export async function getTokensByOwner(owner: PublicKey): Promise<string[]> {
    const tokens = await Global.Connection.getParsedTokenAccountsByOwner(owner, {
        programId: TOKEN_PROGRAM_ID,
    });

    // initial filter - only tokens with 0 decimals & of which 1 is present in the wallet
    return tokens.value
        .filter((t) => {
            const amount = t.account.data.parsed.info.tokenAmount;
            return amount.decimals === 0 && amount.uiAmount === 1;
        })
        .map((t) => t.account.data.parsed.info.mint);
}
