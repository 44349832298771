import { Global, hasReceive } from "../global/global";
import { DbStakeCoin } from "../prot/protocols/db/DbStaking";
import { IStakingPoolSummary } from "../prot/protocols/staking/PtlGetPoolList";
import { IStakingAccountSummary } from "../prot/protocols/staking/PtlGetStakeAccounts";
import { StakingPoolState } from "../prot/protocols/staking/Staking";
import ToolTips from "./toolTips";


function MyStakeInfo(props: { pool: IStakingPoolSummary, account?: IStakingAccountSummary, coinIcon: DbStakeCoin[] }) {

    function getRevenue(pool: IStakingPoolSummary, account: IStakingAccountSummary, generateAddress: string, index: number) {

        if (isNaN(account.commissionAuthority))
            return 0;

        if (account.commissionAuthority == 0)
            return 0;
        // console.log(account);

        // const date = new Date();
        // if (date.getTime() > pool.landEffectEndUTC.getTime())
        //     return 0;

        const poolgenerator = pool.generator[index];

        const revenue = poolgenerator.totalGenerated * pool.commissionRate / 10000 * (account.totalBonus / pool.totalBonus)
        if (!isFinite(revenue))
            return 0;
        return revenue;
    }
    const getTotalReward = () => {
        if (!props.coinIcon || props.coinIcon.length == 0)
            return <h4>0</h4>

        if (!props.account)
            return <h4>0</h4>
        if (props.account.generator.length == 0)
            return <h4>0</h4>;
        // else if (props.account.generator.length == 1)
        //     return <h4>{props.account.generator[0].rewards.toFixed(2)}</h4>;
        // else
        return <span className="total-generate-multi"
            onMouseEnter={(e) => {
                Global.AddOverlay("totalGenerated", <ToolTips key="tt" left={e.clientX} top={e.clientY} lines={
                    props.account.generator.map((v, i) => {
                        if (props.coinIcon.length == 0)
                            return v.rewards.toString();
                        const coin = props.coinIcon.find(a => a.address == v.address);
                        return `${coin.symbol}:${v.rewards}`;
                    })
                } />)
            }}
            onMouseLeave={(e) => {
                Global.RemoveOverlay("totalGenerated")
            }}
        >
            {
                props.account.generator.slice(0, 3).map((v, i) => {
                    const coin = props.coinIcon.find(a => a.address == v.address)
                    return <span className="generate-icon" key={i} style={{ backgroundImage: `url(${coin.imgUrl})` }} >
                        {/* <span>{v.rewards}</span> */}
                    </span>
                })
            }
            <h4>{props.account.generator.length > 3 ? "..." : ""}</h4>
        </span>;
    }
    const getTotalRevenue = () => {
        if (!props.coinIcon || props.coinIcon.length == 0)
            return <h4>0</h4>

        if (!props.account)
            return <h4>0</h4>
        if (props.account.generator.length == 0)
            return <h4>0</h4>;
        // else if (props.account.generator.length == 1) {
        //     const revenue = getRevenue(props.pool, props.account, props.account.generator[0].commissionAddress)
        //     return <h4>{isNaN(revenue) ? 0 : revenue.toFixed(2)}</h4>;
        // }
        // else 
        {
            return <span className="total-generate-multi"
                onMouseEnter={(e) => {
                    Global.AddOverlay("totalGenerated", <ToolTips key="tt" left={e.clientX} top={e.clientY} lines={
                        props.account.generator.map((v, i) => {
                            if (props.coinIcon.length == 0)
                                return "0";
                            const coin = props.coinIcon.find(a => a.address == v.commissionAddress);
                            const revenue = getRevenue(props.pool, props.account, v.commissionAddress, i);
                            return `${coin.symbol}:${revenue}`;
                        })
                    } />)
                }}
                onMouseLeave={(e) => {
                    Global.RemoveOverlay("totalGenerated")
                }}
            >{props.account.generator.slice(0, 3).map((v, i) => {
                const coin = props.coinIcon.find(a => a.address == v.commissionAddress);
                // const revenue = getRevenue(props.pool, props.account, v.address)
                return <span className="generate-icon" key={i} style={{ backgroundImage: `url(${coin.imgUrl})` }} >
                    {/* <span>{isNaN(revenue) ? 0 : revenue}</span> */}
                </span>
            })}
                <h4>{props.account.generator.length > 3 ? "..." : ""}</h4>
            </span>
        };
    }

    const isExpire = (info: IStakingPoolSummary) => {
        if (!info)
            return false;
        const now_time = new Date().getTime()
        // info.closeUTC.getTime()返回的是UTC+8时间
        if (now_time - info.closeUTC.getTime() > 0)
            return true;
        return false;
    }

    const empty = <div className="my-info">
        <h1>{isExpire(props.pool) ? "Ended" : "My Staked"}</h1>
        <h5>You have not staked any NFTs.</h5>
    </div>
    //TODO佣金收益 Land Revenue：
    const info = <div className="my-info">
        <h1>{isExpire(props.pool) ? "Ended" : "My Staked"}</h1>

        <div>
            <h3>Rewards：</h3>
            {getTotalReward()}
        </div>
        <div>
            <h3>Staked QF：</h3>
            <h4>{props.account?.quantumForce}</h4>
        </div>
        <div>
            <h3>Staked Lands：</h3>
            <h4>{props.account?.lands}</h4>
        </div>
        <div>
            <h3>Land Revenue：</h3>
            {getTotalRevenue()}
        </div>
        <div>
            <h3>Staked HQF：</h3>
            <h4>{props.account?.hyperQuantumForce}</h4>
        </div>
        <div>
            <h3>Staked NFTs：</h3>
            <h4>{props.account ? (props.account.nfts + props.account.lands + props.account.quantumForce + props.account.hyperQuantumForce) : 0}</h4>
        </div>
    </div>
    return (hasReceive(props.account) ? info : empty);
}

export default MyStakeInfo;