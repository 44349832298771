import { PublicKey } from "@solana/web3.js";
import React from "react";
import { Define } from "../define/define";
import { } from "../global/global";
import { OfficialItem } from "../prot/protocols/base";

export interface ILandDetail {
    FieldID: number;
    Level?: number;
    Type?: string;
    LandPlot?: number;
    Price?: number;
    Owner?: string;
    Location?: string;
    Stores?: number;
    Link?: string;
}

export interface ISelectedLand {
    config?: ILandDetail & { name?: string };
    official?: OfficialItem;
    owner?: string;
    price?: number;
}

export const SELECTED_LAND_DEFAULT: ISelectedLand = {
    config: {
        name: "",
        FieldID: -1,
        Level: 1,
        Type: 'FlatLand',
        LandPlot: 100,
        Price: 1000,
        Owner: '0xaaaaaaaaaaaaaaaaaaaaa',
        Location: 'Where ARE YOU',
    },
    official: {
        id: "",
        category: "",//item map
        spl: "SOL",//sol
        chain: "SOL",//sol
        supply: [0, 0],//[当前，最大]
        price: "0",
        soldNfts: [],
        nft: null,
    },
    owner: "",
}
export const defaultLandMarket = {
    showCoin: Define.Token.SOL,
    setCoin: (coin: number) => { },
    selected: SELECTED_LAND_DEFAULT,
    setSelected: (land: any) => { },
    detailShow: false,
    setDetailShow: (show: boolean) => { },
    landMarketShow: false,
    setLandMarketShow: (show: boolean) => { },
    sellLands: {} as {
        [key: string]: {
            config?: any,
            official?: OfficialItem,
            price?:number,
            owner?:string,
        }
    },
    ownedListShow: false,
    setOwnedListShow: (show: boolean) => { },
    reloadMap: async () => { },
}
export const LandMarketContext = React.createContext(defaultLandMarket);

