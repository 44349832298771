import { SetStateAction, useContext, useEffect, useState } from "react";
import StakingContainer from "../components/stakingContainer";
import StakingHeader from "../components/stakingHeader";
import { Define } from "../define/define";
import { INavigation } from "../event/navigation";
import my from "../assets/images/staking/Txt_MyStaking_b.png";
import asia from "../assets/images/staking/Txt_Asia.png"
import europe from "../assets/images/staking/Txt_Europe.png"
import africa from "../assets/images/staking/Txt_Africa.png"
import america from "../assets/images/staking/Txt_America.png"
import oceania from "../assets/images/staking/Txt_Oceania.png"
import PoolCard from "../components/poolCard";
import StakingFolder from "../components/stakingFolder";
import "../assets/css/myStake.css";
import StakingDetail from "../components/stakingDetail";
import { waitSeconds } from "../utils";
import { IStakingPoolSummary } from "../prot/protocols/staking/PtlGetPoolList";
import { IStakingAccountSummary } from "../prot/protocols/staking/PtlGetStakeAccounts";
import { apiClient, Global, hasReceive } from "../global/global";
import EventCenter from "../event/eventCenter";
import { StakingGenerateType, StakingPoolState } from "../prot/protocols/staking/Staking";
import { GetNFTs } from "../common/EvmAuction";
import { BigNumber } from "ethers";
import { IProjectCategory } from "../components/collectionFolder";
import { getTokensByOwner } from "../common/tokenGet";
import { ObjectId } from "mongodb";
import { IStakeNFTItemSummary } from "../prot/protocols/staking/PtlGetStakingNFT";
import { StakingNavigateContext } from "../contexts/stakingNavigateContext";
import { Harvest, HarvestAll, SolveUnfinishedOrder } from "../common/ServerAuction";
import { DbStakeCoin } from "../prot/protocols/db/DbStaking";
import ToolTips from "../components/toolTips";

function MyStake(navigation: INavigation) {
    const ctx = useContext(StakingNavigateContext);

    const { onShowWallet, onNavigation, onConnectWallet } = navigation;
    const [Folders, setFolders] = useState([true, false, false, false, false, false])
    const [CardScale, setCardScale] = useState(1);
    const [MyTitleHeight, setMyTitleHeight] = useState("47px");
    const [RefreshCounter, setRefreshCounter] = useState(0);
    const [OwnNFTs, setOwnNFTs] = useState([] as string[])
    const [TotalGenerateIcon, setTotalGenerateIcon] = useState([] as DbStakeCoin[]);
    const areaList = ["my", "asia", "europe", "oceania", "africa", "america"];

    const [MyAccounts, setMyAccounts] = useState({} as { [key: string]: IStakingAccountSummary })
    const [Pools, setPools] = useState([] as IStakingPoolSummary[]);

    useEffect(() => {
        onNavigation(Define.Page.Staking);
        fetchIcon();
        fetchPools();
        SolveUnfinishedOrder();

        const folders = [];
        for (let index = 0; index < areaList.length; index++) {
            if (ctx.area == areaList[index])
                folders.push(true);
            else
                folders.push(false);
        }
        setFolders(folders);

        const onResize = () => {
            setMyTitleHeight(getMyTitleHeight());
            if (window.innerWidth <= 1300) {
                setCardScale(window.innerWidth / 1300);
            }
            else
                setCardScale(1);
        }
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);


    const onRefreshProfile = (isLoadNftConfig: boolean) => {
        fetchAccounts();
    }
    useEffect(() => {
        EventCenter.addListener(Define.Event.RefreshProfile, onRefreshProfile);
        return () => {
            EventCenter.removeListener(Define.Event.RefreshProfile, onRefreshProfile);
        }
    }, [onRefreshProfile]);


    const onFolderClicked = (index: number, isUp: boolean) => {
        // console.log(index, isUp);
        let idx = [false, false, false, false, false, false];
        for (let i = 0; i < 6; i++) {
            if (index == i)
                idx[i] = isUp;
        }
        setFolders(idx);
    }

    const getMyTitleHeight = () => {
        if (window.innerWidth <= 1066) {
            return "calc(50px + 27px)";
        }
        return "47px"
    };

    const onDetailFoldClicked = async (isOpen: boolean) => {
        // await waitSeconds(0.21);
        setRefreshCounter(pre => pre + 1);
    }

    useEffect(() => {
        setRefreshCounter(pre => pre + 1);

    }, [OwnNFTs])



    const fetchSolOwnNFT = async () => {
        const tokenAccounts = await getTokensByOwner(Global.WalletAdapter.publicKey);
        setOwnNFTs(tokenAccounts)
    }

    const fetchBNBOwnNFT = async () => {
        console.log("FETCH BNB");

        //获取币安nft
        const addr = Global.WalletAddress;
        const items: string[] = [];

        let res = await GetNFTs(addr);
        while (true) {
            items.push(...res.raw.result.map(v => `${v.token_address.toLowerCase()}_${v.token_id}`));
            if (!res.hasNext())
                break;
            res = await res.next();
        }
        setOwnNFTs(items);
    }

    const fetchAccounts = async () => {
        //获取钱包下的nft
        if (Global.WalletConnected("BNB"))
            await fetchBNBOwnNFT();
        else if (Global.WalletConnected("SOL"))
            await fetchSolOwnNFT();
        else
            return;

        //获取账号与池子信息
        console.log("account", Global.WalletAddress);

        const call = await apiClient.callApi("staking/GetStakeAccounts", { address: Global.WalletAddress });

        if (call.isSucc) {
            const acc: { [key: string]: IStakingAccountSummary } = {};
            for (const account of call.res.list) {
                acc[account.poolId] = account;
            }
            setMyAccounts(acc);
            console.log("get account", acc);

        }
    }

    const fetchPools = async () => {
        const call = await apiClient.callApi("staking/GetPoolList", { deprecated: true });
        console.log("onREfresh", call);

        if (call.isSucc)
            setPools(call.res.list.filter(v => v.isShow).sort((a, b) => { if (a.state == StakingPoolState.open) return 1; if (b.state == StakingPoolState.open) return -1; return 0; }));
        await fetchAccounts();
        setRefreshCounter(pre => pre + 1);
    }

    const fetchIcon = async () => {
        const call = await apiClient.callApi("staking/GetStakeCoin", { addresses: [] })

        if (call.isSucc) {
            setTotalGenerateIcon(call.res.list);
        }
    }

    const accountTotalReward = () => {
        const keys = Object.keys(MyAccounts);
        // console.log(keys, TotalGenerateIcon);

        if (keys.length == 0 || TotalGenerateIcon.length == 0)
            return <h2>0</h2>
        const results: { address: string, icon: string, amount: number }[] = [];
        for (const key of Object.keys(MyAccounts)) {
            const account = MyAccounts[key];
            for (const gene of account.generator) {
                if (!results.find(a => a.address == gene.address)) {
                    results.push({
                        address: gene.address,
                        icon: TotalGenerateIcon.find(m => m.address == gene.address).imgUrl,
                        amount: 0,
                    });
                }
                results.find(a => a.address == gene.address).amount += gene.rewards;
            }
        }
        // if (results.length == 1)
        //     return <h2>{results[0].amount.toFixed(2)}</h2>
        return <div className="total-generate-multi"
            onMouseEnter={(e) => {
                Global.AddOverlay("totalGenerated", <ToolTips key="tt" left={e.clientX} top={e.clientY} lines={
                    results.map((v, i) => {
                        if (TotalGenerateIcon.length == 0)
                            return v.amount.toString();
                        const coin = TotalGenerateIcon.find(a => a.address == v.address);
                        return `${coin.symbol}:${v.amount}`;
                    })
                } />)
            }}
            onMouseLeave={(e) => {
                Global.RemoveOverlay("totalGenerated")
            }}
        >
            {results.slice(0, 3).map((v, i) =>
                <span className="generate-icon" key={i} style={{ backgroundImage: `url(${v.icon})` }} >
                    {/* <span id="under-pos">{v.amount}</span> */}
                </span>
            )}
            <h2>{(results.length > 3 ? "..." : "")}</h2>
        </div>;
    }
    function getRevenue(pool: IStakingPoolSummary, account: IStakingAccountSummary, generateAddress: string, index: number) {
        if (!pool || !account)
            return 0;
        if (isNaN(account.commissionAuthority))
            return 0;

        if (account.commissionAuthority == 0)
            return 0;

        // const date = new Date();
        // if (date.getTime() > pool.landEffectEndUTC.getTime())
        //     return 0;

        const poolgenerator = pool.generator[index];
        const revenue = poolgenerator.totalGenerated * pool.commissionRate / 10000 * account.totalBonus / pool.totalBonus

        if (isFinite(revenue))
            return revenue;
        return 0;
    }

    const accountTotalRevenue = () => {
        const keys = Object.keys(MyAccounts);
        if (keys.length == 0 || TotalGenerateIcon.length == 0)
            return <h2>0</h2>
        const results: { address: string, icon: string, amount: number }[] = [];
        for (const key of Object.keys(MyAccounts)) {
            const account = MyAccounts[key];
            for (let index = 0; index < account.generator.length; index++) {
                const gene = account.generator[index];
                if (!results.find(a => a.address == gene.commissionAddress)) {
                    results.push({
                        address: gene.commissionAddress,
                        icon: TotalGenerateIcon.find(m => m.address == gene.commissionAddress).imgUrl,
                        amount: 0,
                    });
                }
                const pool = Pools.find(m => m.poolId == account.poolId);
                results.find(a => a.address == gene.commissionAddress).amount += getRevenue(pool, account, gene.commissionAddress, index);

            }

        }
        // if (results.length == 1)
        //     return <h2>{results[0].amount.toFixed(2)}</h2>
        return <div className="total-generate-multi"
            onMouseEnter={(e) => {
                Global.AddOverlay("totalGenerated", <ToolTips key="tt" left={e.clientX} top={e.clientY} lines={
                    results.map((v, i) => {
                        if (TotalGenerateIcon.length == 0)
                            return v.amount.toString();
                        const coin = TotalGenerateIcon.find(a => a.address == v.address);
                        return `${coin.symbol}:${v.amount}`;
                    })
                } />)
            }}
            onMouseLeave={(e) => {
                Global.RemoveOverlay("totalGenerated")
            }}
        >
            {results.slice(0, 3).map((v, i) =>
                <span className="generate-icon" key={i} style={{ backgroundImage: `url(${v.icon})` }} >
                    {/* <span id="under-pos">{v.amount}</span> */}
                </span>
            )}
            <h2>{(results.length > 3 ? "..." : "")}</h2>
        </div>;
    }

    const onHarvestAllClick = async () => {
        //获取当前登录的所有账号
        if (!Global.WalletConnected(""))
            return;
        Global.ShowLoading(true);
        const address = Global.WalletAddress;
        try {
            const call = await apiClient.callApi("staking/GetStakeAccounts", { address });
            if (!call.isSucc)
                throw call.err;
            const harvests = [];
            const accounts = call.res.list;
            for (const account of accounts) {
                const pool = Pools.find(a => a.poolId == account.poolId);
                if (!pool.isShow)
                    continue;
                if (!pool)
                    continue;
                if (pool.rewardUTC.getTime() > new Date().getTime())
                    continue
                let reward = 0;
                account.generator.forEach((v, i) => {
                    if (v.rewards)
                        reward += v.rewards;
                    reward += getRevenue(pool, account, v.address, i)
                });
                if (reward == 0)
                    continue;
                harvests.push({ poolId: account.poolId, account: account.address });
            }
            console.log(harvests);

            await HarvestAll(harvests);

            await onRefresh();
        } catch (error) {
            console.error(error);
        }
        Global.ShowLoading(false);
    }

    const accountSummary = () => {
        if (!Global.WalletConnected(""))
            return <div id="my-total-info">
                <div>
                    <h1>Total Rewards:</h1><h2>{0}</h2>
                    <h1>Total Land Revenue:</h1><h2>{0}</h2>
                </div>
                {/* <button>Harvest All</button> */}
            </div>
        return <div id="my-total-info">
            <div>
                <h1>Total Rewards:</h1>{accountTotalReward()}
                <h1>Total Land Revenue:</h1>{accountTotalRevenue()}
            </div>
            <button onClick={onHarvestAllClick}>Harvest All</button>
        </div>
    }

    const onRefresh = async (): Promise<void> => {
        console.log("refresh1");
        await fetchPools();
        console.log("refresh2");
        // await fetchAccounts();
        return Promise.resolve();
    }

    const filterChain = (pool: IStakingPoolSummary) => {
        if (Global.WalletConnected("SOL"))
            return pool.supportChain.indexOf("SOL") != -1;
        if (Global.WalletConnected("BNB"))
            return pool.supportChain.indexOf("BSC") != -1;
        return true;
    }

    return (<StakingContainer>
        <StakingHeader isMy />
        <div id="pool">
            <div id="left-bar"></div>
            <div id="folder">
                <StakingFolder index={0} coinIcon={TotalGenerateIcon} defaultHeight={MyTitleHeight} title={my} onClick={onFolderClicked} isOpen={Folders[0]} refreshCounter={RefreshCounter}>
                    {accountSummary()}
                    {Pools.filter(v => hasReceive(MyAccounts[v.poolId])).map((v, i) => <StakingDetail id={`my${i}`} onRefresh={onRefresh} key={i} info={v} account={MyAccounts[v.poolId]} ownedNfts={OwnNFTs} zoom={CardScale} onFoldClick={onDetailFoldClicked} coinIcon={TotalGenerateIcon} />)}
                </StakingFolder>
                <StakingFolder index={1} coinIcon={TotalGenerateIcon} title={asia} onClick={onFolderClicked} isOpen={Folders[1]} refreshCounter={RefreshCounter}>
                    {Pools.filter(v => filterChain(v) && v.area == "asia").map((v, i) => <StakingDetail id={`a${i}`} onRefresh={onRefresh} key={i} info={v} account={MyAccounts[v.poolId]} zoom={CardScale} ownedNfts={OwnNFTs} onFoldClick={onDetailFoldClicked} coinIcon={TotalGenerateIcon} />)}
                </StakingFolder>
                <StakingFolder index={2} coinIcon={TotalGenerateIcon} title={europe} onClick={onFolderClicked} isOpen={Folders[2]} refreshCounter={RefreshCounter}>
                    {Pools.filter(v => filterChain(v) && v.area == "europe").map((v, i) => <StakingDetail id={`b${i}`} onRefresh={onRefresh} key={i} info={v} account={MyAccounts[v.poolId]} zoom={CardScale} ownedNfts={OwnNFTs} onFoldClick={onDetailFoldClicked} coinIcon={TotalGenerateIcon} />)}
                </StakingFolder>
                <StakingFolder index={3} coinIcon={TotalGenerateIcon} title={oceania} onClick={onFolderClicked} isOpen={Folders[3]} refreshCounter={RefreshCounter}>
                    {Pools.filter(v => filterChain(v) && v.area == "oceania").map((v, i) => <StakingDetail id={`c${i}`} onRefresh={onRefresh} key={i} info={v} account={MyAccounts[v.poolId]} zoom={CardScale} ownedNfts={OwnNFTs} onFoldClick={onDetailFoldClicked} coinIcon={TotalGenerateIcon} />)}
                </StakingFolder>
                <StakingFolder index={4} coinIcon={TotalGenerateIcon} title={africa} onClick={onFolderClicked} isOpen={Folders[4]} refreshCounter={RefreshCounter}>
                    {Pools.filter(v => filterChain(v) && v.area == "africa").map((v, i) => <StakingDetail id={`d${i}`} onRefresh={onRefresh} key={i} info={v} account={MyAccounts[v.poolId]} zoom={CardScale} ownedNfts={OwnNFTs} onFoldClick={onDetailFoldClicked} coinIcon={TotalGenerateIcon} />)}
                </StakingFolder>
                <StakingFolder index={5} coinIcon={TotalGenerateIcon} title={america} onClick={onFolderClicked} isOpen={Folders[5]} refreshCounter={RefreshCounter}>
                    {Pools.filter(v => filterChain(v) && v.area == "america").map((v, i) => <StakingDetail id={`e${i}`} onRefresh={onRefresh} key={i} info={v} account={MyAccounts[v.poolId]} zoom={CardScale} ownedNfts={OwnNFTs} onFoldClick={onDetailFoldClicked} coinIcon={TotalGenerateIcon} />)}
                </StakingFolder>
            </div>
            <div id="right-bar"></div>
        </div>
    </StakingContainer>);
}

export default MyStake;