export const BaseURL = "https://web.sypool.io";

interface IStatus {
    [int: string]: string;
}

export const HTTP_STATUS: IStatus = {
    400: 'Request error',
    401: 'Login failed, please login again',
    403: 'Access denied',
    404: 'The resource was not found',
    405: 'The request method is not allowed',
    408: 'Request timed out',
    426: 'Login failed, please login again',
    428: 'Verification code error',
    429: 'The current network is busy, please try again later',
    500: 'Server exception',
    501: 'Network not implemented',
    502: 'Network error',
    503: 'Service unavailable',
    504: 'The network status is poor, please try again later',
    505: 'HTTP access is not supported'
};
