import { useState } from "react";
import "../assets/css/gameFilterBar.css"
import GameSearchBar from "./gameSearchBar";

function GameFilterBar(props: { onFilterClicked: (filter: string) => void, onPriceSortChange: (isLowToHigh: boolean) => void, onSearch: (search: string) => void }) {
    const [filter, setFilter] = useState("items");
    const onTagChanged = (event: any) => {
        const value = event.target.value;
        setFilter(value);
        props.onFilterClicked(value);
    }

    return (<div className="game-filter-bar">
        <div id="radio-buttons">
            <div>
                <input type="radio" id="official-store" value="official" checked={filter == "official"} name="game-filter-bar-type" onChange={onTagChanged} />
                <div>
                    <label htmlFor="official-store">OFFICIAL</label>
                </div>
            </div>
            <div>
                <input type="radio" id="item-store" value="items" checked={filter == "items"} name="game-filter-bar-type" onChange={onTagChanged} />
                <div>
                    <label htmlFor="item-store">ITEMS</label>
                </div>
            </div>
        </div>
        <div id="search-price-filter">
            <GameSearchBar onValueChanged={props.onSearch}/>
            <select className="filter-price" title="price-sort" onChange={ref => {
                props.onPriceSortChange?.(ref.target.value == 'LowToHigh');
            }}>
                <option value="LowToHigh">Price Low To High</option>
                <option value="HighToLow">Price High To Low</option>
            </select>
        </div>
    </div>);
}

export default GameFilterBar;