import { CSSProperties, useContext, useEffect, useState } from "react";
import "../assets/css/chainSelector.css";
import { ChainContext } from "../contexts/chainContext";
import { Define } from "../define/define";
import { SupportChainType } from "../event/navigation";
import { Global } from "../global/global";
import EventCenter from "../event/eventCenter";

interface IProps {
    style?: CSSProperties;
    className?: string;
    onChanged: (chain: SupportChainType) => void;
}

function ChainSelector(props: IProps) {
    const ctx = useContext(ChainContext);
    const [Chain, setChain] = useState(ctx.Selected);
    useEffect(() => {
        console.log(ctx);


    }, [ctx.Selected])

    const onRefreshProfile = (isLoadNftConfig: boolean) => {
        if (!isLoadNftConfig)
            return;
        setChain(ctx.Connected)
    }
    useEffect(() => {
        EventCenter.addListener(Define.Event.RefreshProfile, onRefreshProfile);
        return () => {
            EventCenter.removeListener(Define.Event.RefreshProfile, onRefreshProfile);
        }
    }, [onRefreshProfile]);

    return (<div style={props.style} className={props.className} >
        <select
            className="filter-chain "
            title="chain-select"
            value={Chain}
            onChange={ref => {
                ctx.Selected = ref.target.value as SupportChainType;
                setChain(ctx.Selected);
                props.onChanged(ctx.Selected);
            }}>
            <option value="SOL">Solana</option>
            <option value="BNB">BNB Chain</option>
        </select>
    </div>);
}

export default ChainSelector;