import "../assets/css/loadingMask.css"
import icon from "../assets/images/loading/loading.png"

function LoadingMask(props: { display: boolean }) {

    // console.error(props.display);

    return (
        <div className="loading-mask" style={{ display: props.display ? "block" : "none" }} >
            <img src={icon}></img>
        </ div>
    );
}

export default LoadingMask;