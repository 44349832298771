import React, { Component } from 'react'
import '../assets/css/landItem.css';
import defaultImg from '../assets/images/map/Map.png';
import { copyTextToClipboard, Global, } from '../global/global';
import { ILandDetail, LandMarketContext, SELECTED_LAND_DEFAULT } from '../contexts/LandMarketContext';
import { moveToField } from '../map/map';
import { Define } from '../define/define';
import { clusterApiUrl, Connection, PublicKey } from '@solana/web3.js';
import BN from "bn.js"
import { getParsedAccountByMint } from '@nfteyez/sol-rayz';
import { OfficialItem } from '../prot/protocols/base';
import { Buy, BuyOfficialItem } from '../common/ServerAuction';
export default class LandItem extends Component<{ setBuyComplete: Function, refresh: Function, isSell: boolean, official: OfficialItem, owner: string, config: ILandDetail, setWalletShow: Function }> {
    state = {
        districtName: 0,
        localizeName: 'Wyoming SweetWater,USA',
        level: 1,
        landPlot: 100,
        type: 'Flatlands',
        owner: '4SvSmnfww9AHcrqPTn4sUuy5GScThZS9vqyqrBNeEJXk',
        solPrice: 1230001,
        usdcPrice: 11111,
    }

    // static defaultProps = {
    //     detail: SELECTED_LAND_DEFAULT
    // }

    constructor(props: any) {
        super(props);
    }

    render() {
        // const { detail } = this.props;
        return (
            <LandMarketContext.Consumer>
                {
                    ({ showCoin, setDetailShow, setLandMarketShow, setOwnedListShow }) =>
                        <section className='land-item'>
                            <img
                                src={`https://raw.githubusercontent.com/reimei/mapicons/master/${this.props.config.FieldID}.jpg`} />
                            <button className='pin-button'
                                onClick={() => this.OnPositionClicked(setDetailShow)}></button>
                            <h3>DISTRICT No.{this.props.config.FieldID}</h3>
                            <h6 id='land'>{this.props.config.Location}</h6>
                            <div className='div-grid'>
                                <div>
                                    <div>
                                        <h5>TIER</h5>
                                        <h6>{this.props.config.Level}</h6>
                                    </div>

                                    <div>
                                        <h5>LAND PLOT</h5>
                                        <h6>{this.props.config.LandPlot}</h6>
                                    </div>
                                </div>
                                <section className='border-line'></section>
                                <div>
                                    <div>
                                        <h5>TYPE</h5>
                                        <h6>{this.props.config.Type.split("_")[0]}</h6>
                                    </div>

                                    <div>
                                        <h5>OWNER</h5>
                                        <h6 onClick={() => copyTextToClipboard(this.props.owner ? this.props.owner : "")} style={{ cursor: "pointer" }}>{Global.getAddrShort(this.props.owner)}</h6>
                                    </div>
                                </div>
                            </div>

                            {Global.HasTradePermission() && this.props.owner != Global.WalletAdapter.publicKey.toBase58() ? this.buyButton(showCoin, setLandMarketShow, setOwnedListShow) : <></>}
                        </section>
                }
            </LandMarketContext.Consumer>
        )
    }

    buyButton(showCoin: any, setLandMarketShow: Function, setOwnedListShow: Function) {
        let show = this.props.isSell;
        if (Global.WalletConnected() && this.props.official?.nft?.order.seller == Global.WalletAdapter.publicKey.toBase58())
            show = false;
        const price = this.props.official.nft ? this.props.official.nft.order.price : this.props.official.price;
        if (show)
            return (<button className='buy-button' onClick={async () => {
                await this.OnBuyClicked(showCoin);
                setLandMarketShow(false);
                setOwnedListShow(false);
            }}>
                <div>
                    <img src={Define.TokenIcon[showCoin]}></img>
                    <label>{price}</label>
                </div>
                <h4>Buy</h4>
            </button>
            )
        return <></>
    }

    OnPositionClicked(setDetailShow: Function) {
        moveToField(this.props.config.FieldID);
        setDetailShow(false);
    }

    async OnBuyClicked(coin: number) {
        if (!Global.WalletAdapter) {
            this.props.setWalletShow(true);
            return;
        }
        this.props.setBuyComplete(false);
        let success = false;
        if (this.props.official.supply[0] > 0)
            success = await BuyOfficialItem(this.props.official);
        else
            success = await Buy(this.props.official.nft);
        console.log("BUY RESULT", success);
        this.props.setBuyComplete(true);

        this.props.refresh();
    }

}
