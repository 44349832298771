import { ReactNode, useState } from "react";
import "../assets/css/countDown.css";

const CountDown = (props: { child: ReactNode, progress: number, display: boolean }) => {

    return (
        <span className="cd-outer" style={{ display: props.display ? "block" : "none" }}>
            <span className="cd-inner" style={{ borderImageSource: `conic-gradient(transparent ${isNaN(props.progress) ? 0 : (props.progress) * 100}%, ${isNaN(props.progress) ? 0 : props.progress * 100}%, #cdc8ff)` }}></span>
        </span>
    );
};

export default CountDown;