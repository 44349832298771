import "../assets/css/popWindow.css"
import bg from "../assets/images/staking/popwindow.png"
import { Global } from "../global/global";
function PopWindow(props: { msg: string, display: boolean, onClick: (isOk: boolean) => void }) {

    const onCancelClick = () => {
        props.onClick(false);
        Global.HidePop();
    }
    const onCertainClick = () => {
        props.onClick(true);
        Global.HidePop();
    }

    return (<div id="pop-mask" style={{ display: props.display ? "block" : "none" }}>
        <div id="pop">
            <img src={bg} alt="pop-bg" />
            <div id="pop-bg">
                <p>{props.msg}</p>
                <div>
                    <button className="pop-button" onClick={onCertainClick}>Yes</button>
                    <button className="pop-button" onClick={onCancelClick}>No</button>
                </div>
            </div>
        </div>
    </div>);
}

export default PopWindow;