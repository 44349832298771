import StakingFolderHeader from "./stakingFolderHeader";
import "../assets/css/stakingFolder.css";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { DbStakeCoin } from "../prot/protocols/db/DbStaking";

interface IStakingFolderProps {
    children: ReactNode;
    folderStyle?: CSSProperties;
    defaultHeight?: string;
    isOpen?: boolean;
    title: string;
    index: number;
    onClick: (index: number, isUp: boolean) => void;
    refreshCounter?: number;
    coinIcon: DbStakeCoin[];
}

function StakingFolder(props: IStakingFolderProps) {
    const [IsFold, setIsFold] = useState(true);
    const [Folder, setFolder] = useState(null as HTMLDivElement);
    const [GameItems, setPoolItems] = useState(null as HTMLDivElement);
    const [Ref, setRef] = useState(null as HTMLElement)

    useEffect(() => {

        setIsFold(props.isOpen);
        if (!Folder)
            return
        // console.log(props.refreshCounter, props.isOpen, GameItems.clientHeight);

        if (props.isOpen) {
            Folder.style.height = GameItems.clientHeight + "px";

        }
        else {
            Folder.style.height = props.defaultHeight ? props.defaultHeight : "0px";
        }
    }, [props.isOpen, props.defaultHeight, Folder, props.refreshCounter])

    useEffect(() => {
        // if (IsFold && Ref)
        //     setTimeout(() => {
        //         console.log("SCROLL FOLDER", Ref.offsetTop);
        //         document.getElementById("need-scroll").scroll({ top: Ref.offsetTop, behavior: "smooth" })
        //     }, 20);

    }, [IsFold])



    const onFoldClick = (isUp: boolean) => {
        props.onClick(props.index, isUp);
        if (Ref)
            setTimeout(() => {
                console.log("SCROLL FOLDER", Ref.offsetTop);
                if (isUp)
                    document.getElementById("need-scroll").scroll({ top: Ref.offsetTop, behavior: "smooth" });
                else
                    document.getElementById("need-scroll").scroll({ top: 0, behavior: "smooth" });
            }, 10);
    }

    return (<div ref={setRef}>
        <StakingFolderHeader onFoldClick={onFoldClick} title={props.title} isUp={IsFold} />
        <div className="staking-folder-container" ref={(ref) => { setFolder(ref) }}>
            <div className="pool-items" style={props.folderStyle} ref={(ref) => { setPoolItems(ref) }}>
                {props.children}
            </div>
        </div>
    </div>);
}

export default StakingFolder;