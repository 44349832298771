import { useEffect, useState } from "react";
import "../assets/css/stakingFolderHeader.css"


function StakingFolderHeader(props: { onFoldClick: (isUp: boolean) => void, isUp?: boolean, title: string }) {
    const [Zoom, setZoom] = useState(1)

    useEffect(() => {
        const onResize = () => {
            // console.log(window.innerWidth);

            let z = 875 / window.innerWidth;
            z = Math.min(1, z);
            z = Math.max(0.65, z);
            setZoom(z);
            // console.log(z);

        }
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    return (<div className="header-container" style={{ zoom: Zoom }} onClick={() => {
        props.onFoldClick(!props.isUp);
    }}>
        <div className="line">
            <div />
            <div />
        </div>
        <div className="bar">
            <div className="block" />
            <div className="title"><img src={props.title} alt="" /></div>
            <div className="arrow"></div>
            <div className={props.isUp ? "arrow-button-up" : "arrow-button-down"} ></div>
        </div>
        <div className="line">
            <div />
            <div />
        </div>
    </div>);
}

export default StakingFolderHeader;