import { useEffect, useState } from "react";
import { getElementPagePosition, Global } from "../global/global";
import { DbStakeCoin } from "../prot/protocols/db/DbStaking";
import { IStakingPoolSummary } from "../prot/protocols/staking/PtlGetPoolList";
import ToolTips from "./toolTips";

// interface IStakePoolDetail {
//     title: string;
//     no: string;
//     generated: number;
//     region: string;
//     start: Date;
//     end: Date;
//     staked: number;
//     durabilityLoss: number;
// }

function StakePoolDetail(props: { info?: IStakingPoolSummary, coinIcon: DbStakeCoin[] }) {

    const [CoinIcon, setCoinIcon] = useState([] as DbStakeCoin[])

    useEffect(() => {
        setCoinIcon(props.coinIcon);
    }, [props.coinIcon])


    const getGenerated = () => {
        if (!props.info)
            return <h4>0</h4>
        if (props.info.generator.length == 0)
            return <h4>0</h4>
        // else if (props.info.generator.length == 1)
        //     return <h4>{props.info.generator[0].totalGenerated.toFixed(2)}</h4>;

        return <div className="total-generate-multi"
            onMouseEnter={(e) => {
                Global.AddOverlay("totalGenerated", <ToolTips key="tt" left={e.clientX} top={e.clientY} lines={
                    props.info.generator.map((v, i) => {
                        if (CoinIcon.length == 0)
                            return v.totalGenerated.toString();
                        const coin = CoinIcon.find(a => a.address == v.address);
                        return `${coin.symbol}:${v.totalGenerated}`;
                    })
                } />)
            }}
            onMouseLeave={(e) => {
                Global.RemoveOverlay("totalGenerated")
            }}
        >{
                props.info.generator.slice(0, 3).map((v, i) => {
                    if (CoinIcon.length == 0)
                        return <div key={i}></div>
                    const coin = CoinIcon.find(a => a.address == v.address)
                    return <span className="generate-icon" key={i} style={{ backgroundImage: `url(${coin.imgUrl})` }} >
                    </span>
                })
            }</div>;
    }

    return (<div className="detail-info">
        <div className="icon-rect">
            <div className='icon-inner-rect'>
                <span style={{ backgroundImage: `url(${props.info?.imgUrl})` }}></span>
            </div>
        </div>
        <div className="info">
            <div>
                <h1>{props.info ? props.info.name : "Default TITLE"}</h1>
                <h2>{props.info ? `#${props.info.poolId}` : "#defaultID"}</h2>
            </div>
            <div>
                <h3>Generated：</h3>
                {getGenerated()}
                <h4>{props.info.generator.length>3?"...":""}</h4>
            </div>
            <div>
                <h3>Region：</h3>
                <h4>{props.info ? props.info.area : "Asia"}</h4>
            </div>
            <div>
                <h3>StartDate：</h3>
                <h4>{props.info ? `${props.info.openUTC.getUTCFullYear()}.${props.info.openUTC.getUTCMonth() + 1}.${props.info.openUTC.getUTCDate()}` : "2022.06.15"}</h4>
            </div>
            <div>
                <h3>Staked：</h3>
                <h4>{props.info ? props.info.stakedCount : "666"}</h4>
            </div>
            <div>
                <h3>EndDate：</h3>
                <h4>{props.info ? `${props.info.closeUTC.getUTCFullYear()}.${props.info.closeUTC.getUTCMonth() + 1}.${props.info.closeUTC.getUTCDate()}` : "2022.06.15"}</h4>
            </div>
            <div>
                <h3>Durability loss： </h3>
                <h4>{props.info ? `${props.info.durabilityLoss * 60}/H` : "15/H"}</h4>
            </div>
        </div>
    </div>);
}

export default StakePoolDetail;