import "../assets/css/stakingHeader.css"
import title from "../assets/images/staking/Txt_Pools.png";
import title2 from "../assets/images/staking/Txt_MyStaked.png"

function StakingHeader(props: { isMy?: boolean }) {
    return (<div className="staking-header">
        <div id="staking-header-bg"></div>
        <img src={props.isMy ? title2 : title} alt="" />
    </div>);
}

export default StakingHeader;