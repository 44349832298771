import "../assets/css/collections.css"
import { useNavigate } from "react-router-dom";
import { Define } from "../define/define";
import { CollectionIntro } from "../prot/protocols/market/PtlGetCollections";
import { useContext } from "react";
import { GameStoreContext } from "../contexts/gameContext";
function CollectionIcon(info: { collection: CollectionIntro }) {
    const ctx = useContext(GameStoreContext)

    const { collection } = info;
    const navigate = useNavigate();
    const onClicked = () => {
        if (!collection.project || collection.project == "")
            return;
        if (info.collection.project=="Official"){
            navigate(Define.Page.Marketplace);
            return;
        }
        console.log(collection, "clicked");
        ctx.project = info.collection.project;
        navigate(Define.Page.GameStore);
    }

    return (<div className="collection-icon">
        <div className="rect" onClick={onClicked} >
            <div className="background" style={{ backgroundImage: `url(${collection.imageUrl})` }}></div>
            <div className="bottom">
                <label>{collection.title}</label>
                <label>{collection.developer}</label>
            </div>
        </div>

    </div>);
}

export default CollectionIcon;