import { Component } from 'react'
import bg from '../assets/images/map/masking.png'
import '../assets/css/landDetail.css'
import { ISelectedLand, LandMarketContext } from '../contexts/LandMarketContext'
import listMark from '../assets/images/map/triangle.png';
import { apiClient, copyTextToClipboard, GetOfficialItemPrice, Global, LimitInputNumber } from '../global/global';
import { Define } from '../define/define';
import { GlobalConfigContext } from '../contexts/GlobalConfigContext';
import { TradeState } from '../prot/protocols/auction/Commodity';
import { OfficialItem } from '../prot/protocols/base';
import { Buy, BuyOfficialItem, CancelSell, Sell } from '../common/ServerAuction';
export default class LandDetail extends Component<{ onRefresh: Function, setWalletShow: Function, setBuyComplete: Function }> {
    state = {
        showSelectIcon: false,
        coin: Define.Token.SOL,
        sellPrice: 0,
    }

    render() {

        return (
            <LandMarketContext.Consumer>
                {({ selected, detailShow, setDetailShow, setSelected, reloadMap }) =>
                    <section id='zoom' className={detailShow ? 'land-detail-container' : 'land-detail-container-hide'}
                        onClick={() => {
                            if (this.state.showSelectIcon)
                                this.setState({ showSelectIcon: false });
                        }}>
                        <div id={'black-dot' + (detailShow ? '' : '-hide')}>
                            <div id={'black-dot-v' + (detailShow ? '' : '-hide')}>
                                <div id={'black-dot-h' + (detailShow ? '' : '-hide')}></div>
                            </div>
                        </div>
                        <div className={'detail-box' + (detailShow ? '' : '-hide')}>
                            <div id='blue-bar-1'>
                                <div>
                                    <div></div>
                                </div>
                            </div>

                            <b className='big-title'>
                                SELECTED DISTRICT
                                <div>
                                    <p id='prow'></p>
                                    <b className='big-title-sub'>DISTRICT No.{selected.config?.FieldID}</b>
                                    <p id='prow'></p>
                                    <b className='big-title-sub-gray'>{selected.config?.Location}</b>
                                </div>
                            </b>


                            <div className='detail'>
                                <div id='blue-bar-2'>
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                </div>
                                <div>
                                    <div id='blue-bar-4'>
                                        <div id='blue-bar-4-top' />
                                        <div id='blue-bar-4-bottom' />
                                    </div>
                                    <h5 className='detail-blue-title'>TIER</h5>
                                    <h6 className='detail-gray-title'>{selected.config?.Level}</h6>
                                    <p />
                                    <h5 className='detail-blue-title'>OWNER</h5>
                                    <h6 className='detail-gray-title' onClick={() => copyTextToClipboard(selected.owner ? selected.owner :"")} style={{ cursor: "pointer",pointerEvents:"auto" }}>{Global.getAddrShort(selected.owner, 6, 6)}</h6>
                                    <p />
                                </div>

                                <div>
                                    <div id='blue-bar-4'>
                                        <div id='blue-bar-4-top' />
                                        <div id='blue-bar-4-bottom' />
                                    </div>
                                    <h5 className='detail-blue-title'>TYPE</h5>
                                    <h6 className='detail-gray-title'>{selected.config?.Type?.split("_")[0]}</h6>
                                    <p />
                                    <h5 className='detail-blue-title'>LAND PLOT</h5>
                                    <h6 className='detail-gray-title'>{selected.config?.LandPlot}</h6>
                                </div>
                            </div>

                            {this.detailPrice(selected)}
                            <div className='detail-buttons'>
                                <button className='button-close' onClick={() => {
                                    setDetailShow(false);
                                    setSelected({ config: { FieldID: -1 } })
                                }}>CLOSE
                                </button>
                                {this.tradeButton(selected, setDetailShow, setSelected)}
                            </div>
                        </div>

                    </section>}
            </LandMarketContext.Consumer>

        )
    }

    isOwner(selected: ISelectedLand) {
        // console.log(selected);

        if (!Global.WalletConnected())
            return false;
        if (!selected.official)
            return false;
        if (selected.official.supply[0] > 0)
            return false;
        return selected.owner && selected.owner == Global.WalletAdapter.publicKey.toBase58();
    }

    detailPrice(selected: ISelectedLand) {

        if (!selected.official && !this.isOwner(selected)) {
            return <></>
        }

        if (selected?.official?.nft) {
            if (!this.isOwner(selected) && selected?.official?.nft?.order.tradeState != TradeState.OnSale)
                return <></>
        }
        if (selected.official.supply[1] <= 0)
            return <></>

        return <div className='detail-price'>
            <div id='blue-bar-3'>
                <div />
            </div>
            <h5 className='detail-blue-title'>SALE PRICE</h5>
            <div id='icon-price'>
                <div className='coin-select' onClick={this.onListClicked.bind(this)}>
                    <div className={this.state.showSelectIcon ? 'coin-select-container' : 'hide'}>
                        {Object.keys(Define.TokenIcon)
                            .filter((e) => {
                                const val = Number.parseInt(e);
                                if (this.canSell(selected) && !this.canCancel(selected))
                                    return val != this.state.coin && this.context.ShowCoin[val];
                                if (this.isOfficial(selected)) {
                                    return val != this.state.coin && this.context.ShowCoin[val];
                                }
                                else {
                                    return val == Define.Token.SOL;
                                }
                            })
                            .map((key, index) =>
                            (<div key={index}
                                onClick={() => this.IconSelected(Number.parseInt(key))}>
                                <div>
                                    <img src={Define.TokenIcon[Number.parseInt(key)]}></img>
                                    <h6 className='price-title'>{Define.TokenType[Number.parseInt(key)]}</h6>
                                </div>
                            </div>)
                            )}
                    </div>
                    <img id='listMark' src={listMark}></img>
                    <h6 className='price-title'>{Define.TokenType[this.canSell(selected) && !this.canCancel(selected) ? this.state.coin : (this.isOfficial(selected) ? this.state.coin : Define.Token.SOL)]}</h6>
                    <img id='select-icon' src={Define.TokenIcon[this.canSell(selected) && !this.canCancel(selected) ? this.state.coin : (this.isOfficial(selected) ? this.state.coin : Define.Token.SOL)]}></img>
                </div>
                {this.tradePrice(selected)}

            </div>

        </div>
    }

    IconSelected(coin: number): void {
        this.setState({
            showSelectIcon: 'none',
            coin: coin,
        });
    }

    onListClicked() {
        this.setState({
            showSelectIcon: !this.state.showSelectIcon
        })
    }
    isOfficial(selected: ISelectedLand) {
        return selected.official && selected.official.supply[0] > 0;
    }
    isSeller(officialItem: OfficialItem) {
        return officialItem.nft.order.seller == Global.WalletAdapter.publicKey.toBase58();
    }
    canSell(selected: ISelectedLand) {
        return Global.WalletConnected() &&
            selected.official && selected.official.nft &&
            selected.official.nft.order.tradeState == TradeState.CanSell &&
            this.isOwner(selected);
    }
    canCancel(selected: ISelectedLand) {
        if (!selected.official || !selected.official.nft || !selected.official.nft.order)
            return false;
        return Global.WalletConnected() &&
            selected.official && selected.official.nft &&
            selected.official.nft.order.tradeState == TradeState.OnSale &&
            this.isSeller(selected.official);
    }

    canBuy(selected: ISelectedLand) {
        if (!Global.WalletConnected())
            return false;
        if (!selected)
            return false;

        if (selected.official?.supply[1] <= 0)
            return false;

        if (selected.official?.supply[0] > 0)
            return true;
        else if (!selected.official?.nft?.order)
            return false;
        else
            return selected.official?.nft?.order?.tradeState == TradeState.OnSale && !this.isSeller(selected.official);

    }
    tradeButton(selected: ISelectedLand, setDetailShow: Function, setSelected: Function) {
        console.log(this.state.sellPrice);
        if (!selected)
            return <></>
        const cancel = <button className='button-primary' onClick={() => { this.OnCancelClicked(selected, setDetailShow, setSelected) }}>CANCEL</button>
        const buy = <button className='button-primary' disabled={!this.canBuy(selected)} onClick={() => { this.OnBuyClicked(selected, setDetailShow, setSelected) }}>BUY</button>
        const sell = <button className='button-primary' disabled={isNaN(this.state.sellPrice) || this.state.sellPrice <= 0} onClick={() => { this.OnSellClicked(selected, setDetailShow, setSelected) }}>SELL</button>
        const auction = <button className='button-primary' disabled={!this.canBuy(selected)} onClick={() => { this.OnBuyClicked(selected, setDetailShow, setSelected) }}>AUCTION</button>

        // console.log("canSell", this.canSell(selected), "canCancel", this.canCancel(selected));
        // console.log(selected);

        if (this.canSell(selected)) {
            return sell;
        }
        if (this.canCancel(selected))
            return cancel;

        // if (selected.config.Stores == 1 && (!selected.order || !selected.order.listing || (selected.order.listing.buyer && selected.order.listing.buyer != Global.WalletAdapter?.publicKey.toBase58())))
        //     return <></>;

        if (selected.config.Stores == 2 && selected.official.supply[0] > 0 && selected.config.Link) {
            return auction;
        }
        if (selected.official?.supply[1] == 0)
            return <></>
        if (selected.official?.nft) {
            if (!this.isOwner(selected) && selected.official.nft.order.tradeState != TradeState.OnSale)
                return <></>
        }
        else if (selected.official?.supply[0] == 0)
            return <></>
        if (Global.OpenSell)
            return buy;
        else
            return <></>
    }
    tradePrice(selected: ISelectedLand) {
        const price = GetOfficialItemPrice(selected.official);
        const buy = <h6 className='price-title'>{price}</h6>;
        const sell = <div id='sell-price' style={{ width: `${Math.max((this.state.sellPrice.toString().length), `Before ${price}`.length) * 16}px` }}>
            <input type="text" placeholder={`Please Input Price`} onChange={(r) => {
                const v = LimitInputNumber(r);
                this.setState({
                    sellPrice: v,
                })
            }} min={0.01} step={0.01} /></div>;
        console.log("CAN BUY", this.canBuy(selected));

        if (this.canSell(selected))
            return sell;
        else
            return buy;
    }

    async OnCancelClicked(selected: ISelectedLand, setDetailShow: Function, setSelected: Function) {
        if (!Global.WalletConnected()) {
            this.props.setWalletShow(true);
            return;
        }
        this.props.setBuyComplete(false);

        // const success = await apiClient.callApi("auction/CancelSell", {
        //     mintAddress: selected.official.nft.mintAddress,
        //     order: selected.official.nft.commodity[0].order,
        //     project: "Official",
        // })
        const success = await CancelSell(selected.official.nft);
        console.log("CANCEL RESULT", success);
        setDetailShow(false);
        setSelected({ config: { FieldID: -1 } });
        this.props.setBuyComplete(true);

        this.props.onRefresh();
    }
    async OnBuyClicked(selected: ISelectedLand, setDetailShow: Function, setSelected: Function) {
        // console.log(selected);
        if (selected.config.Stores == 2 && !selected.official.nft) {
            Global.Open(selected.config.Link)
            return;
        }
        if (!Global.WalletConnected()) {
            this.props.setWalletShow(true);
            return;
        }
        console.log("buy", selected);
        this.props.setBuyComplete(false);
        let success = null;
        if (selected.official.supply[0] > 0) {
            success = await BuyOfficialItem(selected.official);
        }
        else {
            success = await Buy(selected.official.nft);
        }
        console.log("BUY RESULT", success);
        this.onBuyComplete(selected, setDetailShow, setSelected)
    }
    onBuyComplete(selected: ISelectedLand, setDetailShow: Function, setSelected: Function) {
        setDetailShow(false);
        setSelected({ config: { FieldID: -1 } });
        this.props.setBuyComplete(true);
        this.props.onRefresh();
    }
    async OnSellClicked(selected: ISelectedLand, setDetailShow: Function, setSelected: Function) {
        if (!Global.WalletConnected()) {
            this.props.setWalletShow(true);
            return;
        }
        console.log(this.state.sellPrice);
        if (isNaN(this.state.sellPrice) || this.state.sellPrice <= 0) {
            Global.AddMessage("Invalid Price", "", "please input correct price");
            return;
        }
        this.props.setBuyComplete(false);
        console.log(this.state.sellPrice);

        const success = await Sell(selected.official.nft, this.state.sellPrice.toString());// SellNFT(Global.Connection, Global.WalletAdapter, selected.meta.mint, this.state.sellPrice, this.state.coin, "map")
        console.log("SELL RESULT", success);
        setDetailShow(false);
        setSelected({ config: { FieldID: -1 } });
        this.props.setBuyComplete(true);

        this.props.onRefresh();
    }
}
LandDetail.contextType = GlobalConfigContext;